import { SALES_ORDER_LOG_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}

export const getSalesOrderLogHistory = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SALES_ORDER_LOG_URL() + 'getSalesOrderHistory/' + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSalesOrderHistory axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
