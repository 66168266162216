import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link, Redirect } from "react-router-dom";
import Box from "@mui/material/Box";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ToastContainer, toast } from "react-toastify";
import {
  getVendorDetailsByUUID,
  getVendorList,
  logVendorHistory,
} from "../../services/index";
import EditIcon from "@mui/icons-material/Edit";

const VendorView = (props) => {
  let { id } = useParams();
  let history = useHistory();
  const [logData, setLogData] = useState({});

  const [rows, setRows] = useState([]);
  const [vendorDetails, setVendorDetails] = useState([]);
  const [expand, setExpand] = useState(true);
  const [expandBank, setBankExpand] = useState(true);
  const [expandRemarks, setExpandRemarks] = useState(true);
  const [expandOther, setExpandOther] = useState(true);
  const [vendorAddressDetails, setVendorAddressDetails] = useState([]);
  const [redirState, setRedirState] = useState(false);
  const [dataId, setDataId] = useState();
  const [value, setValue] = useState("1");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleExpandAddress = () => {
    setExpand(!expand);
  };
  const handleExpandAccount = () => {
    setBankExpand(!expandBank);
  };
  const handleExpandRemarks = () => {
    setExpandRemarks(!expandRemarks);
  };
  const handleExpandOther = () => {
    setExpandOther(!expandOther);
  };

  const getVendorDetails = (id) => {
    getVendorDetailsByUUID(id)
      .then((res) => {
        if (res.errorCode == 0) {
          setVendorDetails(res.responsePacket);
          setVendorAddressDetails(res.responsePacket.addressList);
        } else {
          toast("👎" + res.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("👎" + err, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    logVendorHistory(id).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        console.log(d, "d");
        setLogData(d);
      } else {
        toast(res.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  }, [id]);

  useEffect(() => {
    let customer_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getVendorList(customer_data).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket.data;
        setRows(d);
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    getVendorDetails(id);
  }, []);

  console.log(logData, "logData");
  return (
    <>
      {/* left: 301px */}
      <div
        className="list-column column d-print-none"
        style={{ width: "300px" }}
      >
        <div className="fill list-header topgrad d-print-none">
          <div className="list-filter">
            <div className="btn-toolbar float-right">
              <Tooltip title="Create Vendor">
                <Link to="/inventory/vendors/new">
                  <button
                    id="ember3047"
                    className="btn btn-primary tooltip-container ember-view"
                  >
                    <span className="d-flex align-items-center">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        className="icon icon-xsmall mr-2"
                      >
                        <path d="M484 227H284V28c0-15.5-12.5-28-28-28s-28 12.5-28 28v199H28c-15.5 0-28 12.5-28 28v1c0 15.5 12.5 28 28 28h200v200c0 15.5 12.5 28 28 28s28-12.5 28-28V284h200c15.5 0 28-12.5 28-28v-1c0-15.5-12.5-28-28-28z"></path>
                      </svg>
                      New
                    </span>
                  </button>
                </Link>
              </Tooltip>
            </div>
            <div
              id="ember3068"
              className="clearfix list-title dropdown ember-view"
            >
              <a id="ember3069" className="dropdown-toggle no-caret ember-view">
                <span className="filter-title over-flow">All Vendors</span>
              </a>
            </div>
          </div>
        </div>
        <div className="scroll-y fill body scrollbox list-body">
          <div className="table-responsive overflow-initial">
            <table
              id="ember7912"
              className="table zi-table table-hover ember-view header-fixed"
            >
              <tbody>
                {rows.map((row, index) => (
                  <tr
                    key={index}
                    tabIndex="-1"
                    id="ember3096"
                    className="table-active ember-view"
                    onClick={() => getVendorDetails(row.recordId)}
                  >
                    <td>
                      <div className="list-primary">
                        <Link to={`/inventory/vendors/${row.recordId}`}>
                          <span className="name">
                            <a
                              id="ember3254"
                              className="text-uppercase  active ember-view"
                            >
                              {row.title}
                            </a>
                          </span>
                          <span className="text-muted d-block">
                            Phone.{row?.phone}
                          </span>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="column content-column " style={{ left: "301px" }}>
        <div className="header no-border d-flex align-items-center p-4">
          <a
            className="over-flow header-title text-uppercase"
            style={{ fontSize: "x-large" }}
          >
            &nbsp;
          </a>
          <div>
            <div className="btn-toolbar">
              <div className="btn-group">
                <button
                  id="ember3047"
                  className="btn btn-primary tooltip-container ember-view"
                >
                  <EditIcon
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={() => {
                      history.push(
                        "/inventory/vendors/" + vendorDetails.recordId + "/edit"
                      );
                    }}
                  />
                </button>
              </div>
              {redirState && (
                <Redirect
                  push
                  to={{
                    pathname: `/inventory/vendors/${dataId}/edit`,
                  }}
                />
              )}

              {/* Cross btn */}
              <Link to={"/inventory/vendors"}>
                <span
                  className="close-details pl-4"
                  data-ember-action=""
                  data-ember-action-3373="3373"
                >
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    className="icon icon-sm align-text-top text-black"
                  >
                    <path d="M455.2 9.2L256 208.4 56.8 9.2C44.5-3.1 24.6-3.1 12.2 9.2l-2.9 2.9C-3 24.4-3 44.4 9.3 56.7L208.4 256 9.2 455.2c-12.3 12.3-12.3 32.3 0 44.6l2.9 2.9c12.3 12.3 32.3 12.3 44.6 0L256 303.6l199.2 199.2c12.3 12.3 32.3 12.3 44.6 0l2.9-2.9c12.3-12.3 12.3-32.3 0-44.6L303.6 256 502.8 56.8c12.3-12.3 12.3-32.3 0-44.6l-2.9-2.9c-12.5-12.4-32.4-12.4-44.7-.1z"></path>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Primary Details</Tab>
            <Tab>Log History</Tab>
          </TabList>

          <TabPanel>
            <div className="ember-view scroll-y noscroll-x fill body scrollbox">
              <div className="col-lg-6">
                <br />
                <div className="row inline-fields">
                  <label className="col-lg-4 text-muted">Name:</label>
                  <div className="col-lg-4 edit-form-field">
                    {vendorDetails.title}
                  </div>
                </div>
                <div className="row inline-fields">
                  <label className="col-lg-4 text-muted">Email:</label>
                  <div className="col-lg-4 edit-form-field">
                    {vendorDetails.email}
                  </div>
                </div>
                <div className="row inline-fields">
                  <label className="col-lg-4 text-muted">Phone:</label>
                  <div className="col-lg-4 edit-form-field">
                    {vendorDetails.phone}
                  </div>
                </div>
                <div className="row inline-fields">
                  <label className="col-lg-4 text-muted">GST No:</label>
                  <div className="col-lg-4 edit-form-field">
                    {vendorDetails.gstNumber}
                  </div>
                </div>

                <div className="row inline-fields">
                  <label className="col-lg-4 text-muted">Price List:</label>
                  <div className="col-lg-4 edit-form-field">
                    {vendorDetails.itemPriceListTitle}
                  </div>
                </div>
                {vendorDetails?.tdsTitle ? (
                  <div className="row inline-fields">
                    <label className="col-lg-4 text-muted">TDS:</label>
                    <div className="col-lg-4 edit-form-field">
                      {vendorDetails.tdsTitle}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="row inline-fields">
                  <label className="col-lg-4 text-muted">Payment Term:</label>
                  <div className="col-lg-4 edit-form-field">
                    {vendorDetails.paymentTermTitle}
                  </div>
                </div>
                <br />
                {vendorAddressDetails?.map((address) => (
                  <div className="group">
                    {address.addressTypeEnum == "Billing" ? (
                      <>
                        <div className="text-muted">Billing Address</div>
                        <div
                          id="ember3323"
                          className="popovercontainer address-group ember-view"
                        >
                          <p>
                            <span>
                              <div>{address.street1}</div>
                              <div>{address.street2}</div>
                              <div>
                                {address.city} {address.state}
                              </div>
                              <div>
                                {address.zipcode} {address.country}
                              </div>
                            </span>
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <br />
                        <div className="text-muted">Shipping Address</div>
                        <div
                          id="ember3325"
                          className="popovercontainer address-group ember-view"
                        >
                          <p>
                            <span>
                              <div>{address.street1}</div>
                              <div>{address.street2}</div>
                              <div>
                                {address.city} {address.state}
                              </div>
                              <div>
                                {address.zipcode} {address.country}
                              </div>
                            </span>
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <table>
              {logData.length == 0 ? (
                "No Changes Found"
              ) : (
                <tr>
                  <td>Date</td>
                  <td>Changes</td>
                </tr>
              )}
              {logData.length >= 1 &&
                logData?.map((history) => {
                  return (
                    <>
                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          {history?.transactionDate?.substring(0, 10)}
                          :&nbsp;&nbsp;
                        </td>

                        <td>
                          {history?.message.split("\n").map((item, i) => (
                            <tr>{item}</tr>
                          ))}
                        </td>
                      </tr>
                      <hr />
                    </>
                  );
                })}
            </table>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default VendorView;
