import React from "react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Header } from "antd/lib/layout/layout";
import PreferencesNav from "./PreferencesNav";
import AddIcon from "@mui/icons-material/Add";

import {
  getPaymentTermKeyValuePair,
  addPaymentTerm,
  editPayTerm,
} from "../../../services/index";

const PaymentTerms = () => {
  const [edit, setEdit] = useState("");
  const [dataId, setDataId] = useState("");
  const [title, setTitle] = useState("");
  const [days, setDays] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setTitle("");
    setDays("");
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    getPayTermRecords();
  }, []);

  const getPayTermRecords = () => {
    getPaymentTermKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket;
        setFilteredData(temp);
      } else {
        toast(res.message, {
          autoClose: 2000,
        });
        return;
      }
    });
  };

  const columns = [
    {
      name: "Title",
      cell: (row) => row.value,
      sortable: true,
    },
    {
      name: "Days",
      cell: (row) => row.value2,
      sortable: true,
    },
  ];

  const handleSave = () => {
    if (title == "") {
      toast("Please fill title");
      return;
    }
    if (days == "") {
      toast("Please fill days");
      return;
    }

    const data = {
      noOfDays: days,
      title: title,
    };

    if (edit) {
      editPayTerm(dataId, data).then((res) => {
        if (res.errorCode == 0) {
          toast(res.message);
          getPayTermRecords();
          handleClose();
        } else {
          toast(res.message);
          return;
        }
      });
    } else {
      addPaymentTerm(data).then((res) => {
        if (res.errorCode == 0) {
          toast(res.message);
          getPayTermRecords();
          handleClose();
        } else {
          toast(res.message);
          return;
        }
      });
    }
  };

  const handleClicked = (data) => {
    setTitle(data.value);
    setDays(data.value2);
    setDataId(data.key);
    setEdit(true);
    setShow(true);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Header>Payment Terms Info</Header>
          <hr />

          <table>
            <tr>
              <td>Title:</td>
              <td>
                {" "}
                <input
                  className="ember-text-field ember-view form-control"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  value={title}
                />
              </td>
            </tr>
            <tr>
              <td>days:</td>
              <td>
                {" "}
                <input
                  className="ember-text-field ember-view form-control"
                  type="number"
                  onChange={(e) => {
                    setDays(e.target.value);
                  }}
                  value={days}
                />
              </td>
            </tr>
          </table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="column list-column d-print-none column-small">
        <PreferencesNav />
      </div>
      <div className="column content-column">
        <div className="header clearfix" style={{ borderBottom: "none" }}>
          <span className="float-left" style={{ fontSize: "35px" }}>
            Payment Terms
          </span>
        </div>

        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            columns={columns}
            data={filteredData}
            onRowClicked={(data) => {
              handleClicked(data);
            }}
            fixedHeader
            subHeader
            subHeaderComponent={
              <>
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                  onClick={handleShow}
                >
                  <AddIcon />
                  New
                </button>
              </>
            }
            subHeaderAlign="right"
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PaymentTerms;
