import { CUSTOMER_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}


export const activeDeactiveCustomer = (id) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(CUSTOMER_URL() + "activeOrDeActiveCustomer/"+id ,id, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in activeDeactiveCustomer axios!");
          });
      } catch (error) {
        console.error("in LoginService > activeDeactiveCustomer, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };

export const uploadCustomerExcel = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(CUSTOMER_URL() + "uploadCustomerExcel",data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in upload axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};







// /customer/downloadCustomerExcel
export const getDownloadCustomerExcel = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(CUSTOMER_URL() + "downloadCustomerExcel", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in downloadCustomerExcel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};



export const getCustomerList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios 
                .post(CUSTOMER_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getCustomerDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(CUSTOMER_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const addCustomer = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(CUSTOMER_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getSalutation = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(CUSTOMER_URL() + "salutation", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getCustomerKeyValuePair = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(CUSTOMER_URL() + "getKeyValueDetailsList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateCustomer = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(CUSTOMER_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};