// export const BASE_URL_USER_SERVICE = "https://dev.pyramidcafe.com:8443/userservice_v2/"  
// export const BASE_URL_INVENTORY = "https://dev.pyramidcafe.com:8443/InventoryService/"

const BASE_URL_USER_SERVICE = "https://erp.pyramidcafe.com:8443/userservice_v2/"
export const BASE_URL_INVENTORY = "https://erp.pyramidcafe.com:8443/InventoryService/"

// const BASE_URL_USER_SERVICE = "http://192.168.1.112:8002/";
// export const BASE_URL_INVENTORY = "http://192.168.1.112:8082/";

// const BASE_URL_USER_SERVICE = "http://192.168.1.89:8002/";
// export const BASE_URL_INVENTORY = "http://192.168.1.89:8001/";

export const DOWNLOAD_ITEM_URL = "items/downloadItemExcel/";
export const DOWNLOAD_VENDOR_URL = "vendor/downloadVendorExcel";
export const DOWNLOAD_CUSTOMER_URL = "customer/downloadCustomerExcel";

export const LOGIN_URL = () => BASE_URL_USER_SERVICE + "login";
export const REQUEST_PUBLIC_KEY = () =>
    BASE_URL_USER_SERVICE + "admin/requestPublicKey";

// url
export const URL = () => BASE_URL_USER_SERVICE + "url/";
export const URL_MAPPING = () => BASE_URL_USER_SERVICE + "urlMapping/";
export const DEF_URL_MAPPING = () => BASE_URL_USER_SERVICE + "defaultRoleUrlMapping/";
export const MODULE_URL = () => BASE_URL_USER_SERVICE + "module/";

// inventory
export const ALL_ITEM_URL = () => BASE_URL_INVENTORY + "items/getAllRecords";
export const ITEM_DETAIL_BY_UUID = () => BASE_URL_INVENTORY + "items/UUID/";
export const ITEM_URL = () => BASE_URL_INVENTORY + "items/";
export const PRICE_URL = () => BASE_URL_INVENTORY + "itemPriceList/";
export const ISSUANCE_URL = () => BASE_URL_INVENTORY + "issuance/";

// sales
export const INVOICES_URL = () => BASE_URL_INVENTORY + "invoice/";
export const PAYMENT_URL = () => BASE_URL_INVENTORY + "recordPayment/";
export const SALE_ORDER_URL = () => BASE_URL_INVENTORY + "salesOrder/";
export const CUSTOMER_URL = () => BASE_URL_INVENTORY + "customer/";

// purchase
export const TDS_URL = () => BASE_URL_INVENTORY + "tds/";
export const VENDOR_URL = () => BASE_URL_INVENTORY + "vendor/";
export const PURCHASE_URL = () => BASE_URL_INVENTORY + "purchaseOrder/";
export const BILL_URL = () => BASE_URL_INVENTORY + "bill/";
export const PURCHASE_PAYMENT_URL = () =>
    BASE_URL_INVENTORY + "purchaseRecordPayment/";

// production
export const RECIPE_URL = () => BASE_URL_INVENTORY + "recipe/";
export const BATCH_URL = () => BASE_URL_INVENTORY + "batches/";
export const REQUISITION_URL = () => BASE_URL_INVENTORY + "requisition/";
export const PRODUCTION_DETAILS_URL = () =>
    BASE_URL_INVENTORY + "production_details_status/";

// log

export const LOG_ITEM_TRANSACTION_URL = () =>
    BASE_URL_INVENTORY + "logItemTransaction/";
export const LOG_ITEM_HISTORY_URL = () =>
    BASE_URL_INVENTORY + "logItemHistory/";
export const SALES_ORDER_LOG_URL = () =>
    BASE_URL_INVENTORY + "logSalesOrderHistory/";
export const INVOICE_LOG_URL = () => BASE_URL_INVENTORY + "logInvoiceHistory/";

// tax
export const TAX_EXEMPTION_URL = () => BASE_URL_INVENTORY + "taxExemption/";
export const TAX_URL = () => BASE_URL_INVENTORY + "tax/";
export const TAX_GROUP_URL = () => BASE_URL_INVENTORY + "taxGroup/";

// warehouse
export const WAREHOUSE_URL = () => BASE_URL_INVENTORY + "warehouse/";
export const WAREHOUSEITEM_URL = () => BASE_URL_INVENTORY + "warehouseItems/";
export const WAREHOUSE_VENDOR_URL = () => BASE_URL_INVENTORY + "warehouseVendor/";

// others

export const ROUND_OFF_URL = () => BASE_URL_INVENTORY + "roundOff/";
export const CURRENCY_URL = () => BASE_URL_INVENTORY + "currency/";
export const GST_TREATMENT_URL = () => BASE_URL_INVENTORY + "gstTreatment/";
export const COUNTRY_URL = () => BASE_URL_INVENTORY + "country/";
export const STATE_REGION_URL = () => BASE_URL_INVENTORY + "stateRegion/";
export const SALE_PERSON_URL = () => BASE_URL_INVENTORY + "salesPerson/";
export const IMAGE_UPLOAD_URL = () => BASE_URL_USER_SERVICE + "file/";
export const ADMIN_URL = () => BASE_URL_USER_SERVICE + "admin/";
export const ADMIN_URL2 = () => BASE_URL_INVENTORY + "admin/";
export const COMMON_URL = () => BASE_URL_INVENTORY + "common/";
export const BRAND_URL = () => BASE_URL_INVENTORY + "brand/";
export const DELIVERY_METHOD_URL = () => BASE_URL_INVENTORY + "deliveryMethod/";
export const PAYMENT_TERM_URL = () => BASE_URL_INVENTORY + "paymentTerms/";
export const UNIT_URL = () => BASE_URL_INVENTORY + "units/";
export const HSN_URL = () => BASE_URL_INVENTORY + "hsn/";
export const MANUFACTRUER_URL = () => BASE_URL_INVENTORY + "manufacturer/";
export const ACCOUNT_URL = () => BASE_URL_INVENTORY + "account/";
export const ORGANISATION_SETTING_URL = () => BASE_URL_INVENTORY + "settings/";
export const PAYMENT_MODE_URL = () => BASE_URL_INVENTORY + "paymentModes/";
export const DELIVERY_URL = () => BASE_URL_INVENTORY + "deliveryPerson/";
export const ORGANISATION_URL = () => BASE_URL_USER_SERVICE + "organisation/";
export const TERMSCONDITION_URL = () => BASE_URL_INVENTORY + "termsCondition/";
export const DEPARTMENT_URL = () => BASE_URL_INVENTORY + "department/";