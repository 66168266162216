import { BATCH_URL } from '../CONSTANTS'
import { REQUISITION_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}


export const getRequisitionList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(REQUISITION_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
              
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getRequisitionUUID = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(REQUISITION_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
              
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const addRequisition = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(REQUISITION_URL() , data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
              
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};