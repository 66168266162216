import React, { useEffect, useState } from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getDefaultUrls,
  addUrlMapping1,
  urlAllRecords1,
  urlMappingUUID1,
} from "../../../services/index";
import toast, { Toaster } from "react-hot-toast";

const AssignRoles = (props) => {
  console.log(props, "props assign");
  let userId = props.userId;
  const [responsePacket, setResponsePacket] = useState([]);


  useEffect(() => {
    // getAdminRecords();
    getUserDetails(userId);
  }, [userId]);

  const getUserDetails = (id) => {

    urlMappingUUID1(id).then((res) => {
      if (res.errorCode == 0) {
        let urlList1 = res.responsePacket.urlList;

        urlAllRecords1().then((res) => {
          if (res.errorCode == 0) {
            let urlList2 = res.responsePacket;
            if (urlList1.length == 0) {
              getDefaultUrls(props.userType).then((res1) => {
                let abc = urlList2.map((a) => {
                  a.beanLinksList.map((b) => {
                    res1.responsePacket.map((c) => {
                      if (b.urlId === c.urlId) {
                        b.ans = true;
                      }
                    });
                    return b;
                  });
                  return a;
                });

                console.log(abc, "abc");
                setResponsePacket(abc);
              });
            } else {
              let abc = res.responsePacket.map((a) => {
                a.beanLinksList.map((b) => {
                  urlList1.map((c) => {
                    if (b.urlId === c.urlId) {
                      b.ans = true;
                    }
                  });
                  return b;
                });
                return a;
              });
              setResponsePacket(abc);
            }
          } else {
            toast(res.message, {
              autoClose: 1000,
            });
          }
        });
      } else {
        toast(res.message, {
          autoClose: 1000,
        });
      }
    });
  };

  useEffect(() => {
    console.log(responsePacket, "responsePacket");
  }, [responsePacket]);

  

  const handleSave = () => {
    let arr = responsePacket.map((a) => a.beanLinksList.filter((b) => b.ans));
    let brr = arr.filter((a) => a.length > 0);
    let array1 = [];
    brr.map((a) => {
      a.map((b) => array1.push(b));
    });

    let data = {
      adminRoleEnum: "ROLE_NONE",
      urlList: array1,
      userId: props.userId,
      userName: props.username,
    };

    console.log(data, "data");

    addUrlMapping1(data).then((res) => {
      if (res.errorCode == 0) {
        toast.success(res.message);
        props.handleClose3()
      } else {
        toast.error(res.message);
      }
    });
  };



  const handleUrlAnswer = (e, i, i2) => {
    const obj = [...responsePacket];
    obj[i].beanLinksList[i2].ans = e.target.checked;
    setResponsePacket(obj);
  };

  const handleUrlRight = (e, i) => {
    let x = e.target.value;
    const obj = [...responsePacket];

    if (x == "READ_ONLY") {
      obj[i].beanLinksList.map((a) =>
        a.methodType == "GET" ? (a.ans = true) : (a.ans = false)
      );
    } else if (x == "WRITE_ONLY") {
      obj[i].beanLinksList.map((a) =>
        a.methodType == "POST" || a.methodType == "PUT" || a.methodType == "GET"
          ? (a.ans = true)
          : (a.ans = false)
      );
    } else {
      obj[i].beanLinksList.map((a) =>
        a.methodType == "POST" ||
        a.methodType == "PUT" ||
        a.methodType == "GET" ||
        a.methodType == "DELETE"
          ? (a.ans = true)
          : (a.ans = false)
      );
    }
    setResponsePacket(obj);
  };

  useEffect(() => {
    console.log(responsePacket, "responsePacket");
  }, [responsePacket]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ fontSize: "20px" }}>{props.username}</span>{" "}
        <button
          className="btn btn-primary float-left "
          onClick={handleSave}
          style={{ marginLeft: "20px" }}
        >
          Save
        </button>
      </div>
      {props.userType != "ROLE_SUPER_ADMIN" ? (
        <>
          {responsePacket &&
            responsePacket.map((data, i) => {
              return (
                <>
                  <br />
                  <div>
                    <div style={{ fontSize: "20px" }}>{data?.title}</div>

                    <div>
                      <RadioGroup
                        aria-label="RIGHTS:"
                        name="controlled-radio-buttons-group"
                        value={data.urlRight}
                        onChange={(e) => handleUrlRight(e, i)}
                      >
                        <div>
                          <FormControlLabel
                            value="READ_ONLY"
                            control={<Radio size="small" />}
                            label="Read"
                          />
                          <FormControlLabel
                            value="WRITE_ONLY"
                            control={<Radio size="small" />}
                            label="Read-Write"
                          />
                          <FormControlLabel
                            value="FULL_ACCESS"
                            control={<Radio size="small" />}
                            label="Full Access"
                          />
                        </div>
                        <table>
                          {data.beanLinksList.map((url, i2) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={
                                        url?.ans == undefined
                                          ? (url.ans = false)
                                          : url.ans
                                      }
                                      onChange={(e) =>
                                        handleUrlAnswer(e, i, i2)
                                      }
                                    />
                                  </td>
                                  <td>{url.title}</td>
                                </tr>
                              </>
                            );
                          })}
                        </table>
                      </RadioGroup>{" "}
                      <hr />
                    </div>
                  </div>
                </>
              );
            })}
          <br />
          <br />
        </>
      ) : (
        <></>
      )}

      <Toaster />
    </>
  );
};

export default AssignRoles;
