import { ORGANISATION_SETTING_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}

export const addOrganisationSetting = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ORGANISATION_SETTING_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addOrganisation axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getSalesOrderTermsAndCondition = (key, id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(ORGANISATION_SETTING_URL() + key + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addOrganisation axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};