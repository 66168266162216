import React from "react";

const AccessDenied = () => {
  return (
    <>
      <div style={{ fontSize: "35px",textAlign:"center",paddingTop:"200px" }}>
        Access Denied!
      </div>
    </>
  );
};

export default AccessDenied;
