import React, { useEffect, useState } from 'react'
import CustomSelect from '../../SharedComponents/CustomSelect';
import { toast, ToastContainer } from 'react-toastify';
import { getStateKeyValue, addOrganisationProfile } from '../../../services';
import { useHistory } from 'react-router-dom';



const OrganizationProfile = () => {
    const tempRow = { label: '', value: '' }
    const [rows, setRows] = useState([tempRow]);
    const [paymentAddress, setPaymentAddress] = useState(false);
    const [stateKeyValue, setStateKeyValue] = useState([])
    const [requestData, setRequestData] = useState([])
    const history = useHistory()


    const [address, setAddress] = useState({
        street1: '',
        street2: '',
        city: '',
        state: stateKeyValue.value,
        postalCode: '',
        phone: '',
        fax: '',
        website: ''
    })

    const [organisation, setOrganisation] = useState({
        'defaultContactNo': 0,
        'defaultEmail': '',
        'organisationTitle': '',

    })

    const addRow = () => {
        setRows([...rows, tempRow])
    }

    const removeRow = (i) => {
        const filteredRow = [...rows]
        filteredRow.splice(i, 1)
        setRows(filteredRow)
    }

    // var profileAddress = []
    const handleOrganisation = (e) => {
        setOrganisation({
            ...organisation,
            [e.target.name]: e.target.value
        })
    }

    const handleSaveOrganisation = () => {

        let dict = {
            'defaultContactNo': Number(organisation.defaultContactNo),
            'defaultEmail': organisation.defaultEmail,
            'organisationTitle': organisation.organisationTitle,
        }

        addOrganisationProfile(dict).then((res) => {
            if (res.errorCode === 0) {
                
                toast('👏' + res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }



    // const handleRegion = (e) => {
    //     setAddress({
    //         ...address,
    //         regionValue: e.label,
    //         regionKey: e.value
    //     })
    // }

    // profileAddress.push(address)
    // console.log(profileAddress)
    // const [profileData, setProfileData] = useState({
    //     organizationName: '',
    //     industry: '',
    //     businessLocation: '',
    //     companyAddress: profileAddress,
    //     paymentReceiverAddress: '',
    //     fiscalYear: '',
    //     timeZone: '',
    //     dateFormate: '',
    //     dateSeparater: '',
    //     companyID: '',
    //     taxID: '',
    //     additionalfields: '',
    // })
    // console.log(profileData)
    // profileData.companyAddress.push(address)

    // const handleIndustry = (e) => {
    //     setProfileData({
    //         ...profileData,
    //         industry: e
    //     })
    // }

    // const handleBusinessLocation = (e) => {
    //     setProfileData({
    //         ...profileData,
    //         businessLocation: e
    //     })
    // }

    // const handleFiscalYear = (e) => {
    //     setProfileData({
    //         ...profileData,
    //         fiscalYear: e
    //     })
    // }

    // const handleTimeZone = (e) => {
    //     setProfileData({
    //         ...profileData,
    //         timeZone: e
    //     })
    // }
    // const handleDateFormate = (e) => {
    //     setProfileData({
    //         ...profileData,
    //         dateFormate: e
    //     })
    // }
    // const handleDateSeparater = (e) => {
    //     setProfileData({
    //         ...profileData,
    //         dateSeparater: e
    //     })
    // }
    // const handleCompanyID = (e) => {
    //     setProfileData({
    //         ...profileData,
    //         companyID: e
    //     })
    // }
    // const handleTaxID = (e) => {
    //     setProfileData({
    //         ...profileData,
    //         TaxID: e
    //     })
    // }
    // const handleAdditionalFields = (e) => {
    //     setProfileData({
    //         ...profileData,
    //         additionalfields: e.target.value
    //     })
    // }



    // console.log(profileData)

    const RegionKeyValue = () => {
        getStateKeyValue().then((res) => {
            if (res.errorCode == 0) {
                const options = res.responsePacket.map((d) => ({
                    value: d.key,
                    label: d.value,
                }));
                setStateKeyValue(options)
            } else {
                toast('👎' + res.message, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch((err) => {
            toast('👎' + err, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    useEffect(() => {
        RegionKeyValue()
    }, []);


    return (
        <>
            <div className=" content-column">
                <div className="fill header zerotop">

                    <div
                        id="ember719"
                        className="dropdown ember-view btn-group float-right mt-4"
                    >
                    </div>
                    <p style={{ fontSize: '24px' }}>
                        Organization Profile
                        <span className="font-medium separationline">ID: 60011709567</span>
                    </p>
                </div>
                <hr />
                <div
                    id="ember720"
                    className="ember-view scroll-y noscroll-x fill body scrollbox"
                >
                    <form className="mt-5">
                        <div tabIndex={-1} id="ember721" className="no-outline ember-view">

                        </div>
                        <div className="alert alert-warning d-none">
                            <button
                                className="close"
                                type="button"
                                data-ember-action
                                data-ember-action-722={722}
                            >
                                ×
                            </button>
                            <h4>Email Confirmation:</h4>
                            <div>
                                Click
                                <a href="#" data-ember-action data-ember-action-723={723}>
                                    Confirm
                                </a>
                                to enter the verification code sent in the email to . To send the
                                verification email again, click on
                                <a href="#" data-ember-action data-ember-action-724={724}>

                                    Resend
                                </a>

                            </div>
                        </div>
                        <div id="org-profile" className="highlight-section">

                            {/* <div className="form-group row">

                            <div className="col-form-label col-lg-2">Your Logo</div>
                            <div className="col-lg-10 ">
                                <div className="row">
                                    <div className="text-center col-lg-3">
                                        <div className="imgDIV">

                                            <input type="file" multiple accept="image/*" onChange={''} />

                                        </div>
                                    </div>
                                    <div className="col-lg-5 font-xs">
                                        <div className="text-muted">
                                            This logo will appear on transactions and email notifications.
                                        </div>
                                        <small className="form-text">
                                            Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of
                                            1MB.
                                        </small>
                                        <a
                                            className="d-block"
                                            href="#"
                                            data-ember-action
                                            data-ember-action-725={725}
                                        >
                                            Remove Logo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-lg-2 col-form-label required">
                                        Organization Name
                                    </legend>
                                    <div className="col-lg-4">
                                        <input
                                            placeholder="Organization Name"
                                            onChange={(e) => { handleOrganisation(e) }}
                                            name='organisationTitle'
                                            value={organisation.organisationTitle}
                                            className="ember-text-field ember-view form-control"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-lg-2 col-form-label required">
                                        Email
                                    </legend>
                                    <div className="col-lg-4">
                                        <input
                                            placeholder="Email"
                                            onChange={(e) => { handleOrganisation(e) }}
                                            name='defaultEmail'
                                            value={organisation.defaultEmail}
                                            className="ember-text-field ember-view form-control"
                                            type="email"
                                            required
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-lg-2 col-form-label required">
                                        Contact Number
                                    </legend>
                                    <div className="col-lg-4">
                                        <input
                                            placeholder="Contact Number"
                                            onChange={(e) => { handleOrganisation(e) }}
                                            name='defaultContactNo'
                                            value={organisation.defaultContactNo}
                                            className="ember-text-field ember-view form-control"
                                            type="number"
                                            min={0}
                                            required
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            {/* <div className="form-group row">
                            <div className="col-form-label col-lg-2 ">
                                <span
                                    id="ember727"
                                    className="tooltip-container text-dashed-underline ember-view"
                                >

                                    Industry
                                </span>
                            </div>
                            <div className="col-lg-4">
                                <div id="ember728" className="type-ahead ember-view">
                                    <div
                                        id="ember729"
                                        className="dropdown show ac-dropdown ember-view"
                                    >

                                        <div className="auto-select ac-selected" tabIndex={-1}>
                                            <CustomSelect
                                                value={''}
                                                options={''}
                                                changeOptionsData={''}
                                                fetchingData={false}
                                                // onChange={(e) => handleIndustry(e)}
                                                type="industry"
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <div className="form-group row">

                            <div className="col-form-label col-lg-2 required">
                                Business Location
                            </div>
                            <div className="col-lg-4 ">
                                <div id="ember731" className="ember-view">
                                    <div className="ac-box ">
                                        <CustomSelect
                                            value={''}
                                            options={''}
                                            changeOptionsData={''}
                                            fetchingData={false}
                                            // onChange={(e) => { handleBusinessLocation(e) }}
                                            type="industry"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="form-group row">

                            <div className="col-form-label col-lg-2">
                                <span
                                    id="ember732"
                                    className="tooltip-container text-dashed-underline ember-view"
                                >

                                    Company Address
                                </span>

                            </div>
                            <div className="col-lg-8 ">
                                <input
                                    placeholder="Street 1"
                                    // onChange={(e) => { handleAddress(e) }}
                                    name='street1'
                                    value={address.street1}
                                    className="ember-text-field ember-view form-control"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-8 offset-lg-2">
                                <input
                                    placeholder="Street 2"
                                    name='street2'
                                    // onChange={(e) => { handleAddress(e) }}
                                    value={address.street2}
                                    className="ember-text-field ember-view form-control"
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <input
                                            placeholder="City"
                                            // onChange={(e) => { handleAddress(e) }}
                                            name='city'
                                            value={address.city}
                                            className="ember-text-field ember-view form-control"
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-lg-4">

                                        <div id="ember736" className="ember-view">
                                            <div className="ac-box ">
                                                <CustomSelect
                                                    value={address.state}
                                                    options={stateKeyValue}
                                                    placeholder={'Select State'}
                                                    // changeOptionsData={() => setAddExemptionReason(true)}
                                                    fetchingData={''}
                                                    // onChange={(e) => handleRegion(e)}
                                                    type="exemptionReason"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <input
                                            placeholder="Zip/Postal Code"
                                            // onChange={(e) => { handleAddress(e) }}
                                            name='postalCode'
                                            value={address.postalCode}
                                            className="ember-text-field ember-view form-control"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <input
                                            placeholder="Phone"
                                            // onChange={(e) => { handleAddress(e) }}
                                            name='phone'
                                            value={address.phone}
                                            className="ember-text-field ember-view form-control"
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <input
                                            placeholder="Fax"
                                            // onChange={(e) => { handleAddress(e) }}
                                            name='fax'
                                            value={address.fax}
                                            className="ember-text-field ember-view form-control"
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <input
                                            placeholder="Website"
                                            // onChange={(e) => { handleAddress(e) }}
                                            name='website'
                                            value={address.website}
                                            className="ember-text-field ember-view form-control"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-form-label col-lg-2">  </div>
                            <div className="col-lg-4">
                                <div className="form-check">

                                    <input
                                        id="a9e11ac1e"
                                        className="ember-checkbox ember-view form-check-input"
                                        type="checkbox"
                                    // onChange={(e) => setPaymentAddress(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="a9e11ac1e">

                                        Would you like to add a separate address for receiving payments?
                                    </label>
                                </div>
                            </div>
                        </div>
                        {paymentAddress == true ?
                            <div class="form-group row">
                                <div class="col-form-label col-lg-2">
                                    <span id="ember628" class="tooltip-container text-dashed-underline ember-view"> Address To Receive Payment </span>
                                </div>
                                <div class="col-lg-4">
                                    <textarea rows="4" id="ember629" class="ember-text-area ember-view form-control"></textarea>
                                </div>
                            </div>
                            : <></>}
                        <br />
                        <br />
                        <div className="form-group seperator-col row">
                            <label className="col-lg-2 col-form-label">Primary Contact</label>
                            <div className="col-lg-3 coll">
                                <p className="font-xs text-muted text-uppercase">Sender</p>
                                <label className="font-medium mb-0">
                                    <b>Pyramid Eats</b>
                                </label>
                                <div className="font-small mb-0">(store@pyramidcafe.in)</div>
                            </div>
                            <div className="col-lg-5 coll">
                                <p className="font-xs text-muted text-uppercase">
                                    Emails Are Sent Through
                                </p>
                                <label className="font-medium mb-0">
                                    <b>Email address of Zoho Inventory</b>
                                </label>
                                <div className="font-small">
                                    (message-service@sender.zoho-inventory.in)
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="offset-lg-2 col-lg-6 preference mb-3 font-small">

                                <div className="gmail-ms-integ-note py-5 pl-0">

                                    <div className="row">
                                        <div className="col-lg-1 ml-3">

                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                className="icon icon-md align-text-bottom fill-orange"
                                            >
                                                <path d="M437.2 74.8C388.8 26.4 324.4-.2 256-.2c-68.4 0-132.8 26.7-181.2 75.1C26.4 123.2-.2 187.6-.2 256c0 68.4 26.7 132.8 75.1 181.2 48.4 48.4 112.7 75.1 181.2 75.1 68.4 0 132.8-26.7 181.2-75.1s75.1-112.7 75.1-181.2c-.2-68.4-26.8-132.8-75.2-181.2z" />
                                                <path
                                                    fill="#FFF"
                                                    d="M256.5 348.9c-17.7 0-32 14.1-32 31.6s14.3 31.6 32 31.6 32-14.2 32-31.6c.1-17.4-14.3-31.6-32-31.6zm-.8-246.2c-25.8 0-45.7 22.6-42.5 47.9 2.7 20.9 22.5 125.3 29.2 159.9 1.3 6.5 7 12.3 13.7 12.3h.3c6.5 0 12.1-5.7 13.3-12 6.5-34.1 26.5-138.9 29.1-160.2 3.3-25.6-17-47.9-43.1-47.9z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="col-lg-11" style={{ marginLeft: "-18px" }}>

                                            Your primary contact's email address belongs to an
                                            unauthenticated domain. So, emails will be sent from
                                            <b>message-service@sender.zoho-inventory.in</b> to prevent
                                            them from landing in the Spam folder. Authenticate the domain
                                            to send emails from your primary contact's email address.
                                            <a href="#" data-ember-action data-ember-action-741={741}>
                                                &nbsp;Authenticate Now
                                            </a>
                                        </div>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                            <div className="offset-lg-2 col-lg-6">

                                <a href="#" data-ember-action data-ember-action-742={742}>
                                    <b>Configure Email Preferences</b>&nbsp;
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.1"
                                        id="Layer_1"
                                        x={0}
                                        y={0}
                                        viewBox="0 0 512 512"
                                        xmlSpace="preserve"
                                        className="icon icon-sm icon-trigger"
                                    >
                                        <path d="M485.8 230.6L326.7 71.5c-6.9-6.9-16.1-10.7-25.8-10.7s-18.9 3.8-25.8 10.7c-6.9 6.9-10.7 16.1-10.7 25.8s3.8 18.9 10.7 25.8l96.8 96.8H46c-20.1 0-36.5 16.4-36.5 36.5s16.4 36.5 36.5 36.5h325.8L275 389.6c-6.9 6.9-10.7 16.1-10.7 25.8s3.8 18.9 10.7 25.8c6.9 6.9 16.1 10.7 25.8 10.7 9.8 0 19-3.8 25.8-10.7l159.1-159c6.9-6.9 10.7-16.1 10.7-25.8s-3.7-19-10.6-25.8z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <br /> */}
                        </div>
                        <div id="reg-settings" className="highlight-section">
                            {/* <div className="form-group row">
                            <div className="col-form-label col-lg-2">Fiscal Year</div>
                            <div className="col-lg-4">
                                <div id="ember743" className="ember-view">
                                    <div className="ac-box ">
                                        <CustomSelect
                                            value={''}
                                            options={''}
                                            // changeOptionsData={() => setAddExemptionReason(true)}
                                            fetchingData={''}
                                            // onChange={(e) => { handleFiscalYear(e) }}
                                            type="exemptionReason"
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>



                        <div className="form-group row">
                            <div className="col-form-label col-lg-2">Time Zone</div>
                            <div className="col-lg-4">
                                <div id="ember744" className="ember-view">
                                    <div className="ac-box ">
                                        <CustomSelect
                                            value={''}
                                            options={''}
                                            // changeOptionsData={() => setAddExemptionReason(true)}
                                            fetchingData={''}
                                            // onChange={(e) => { handleTimeZone(e) }}
                                            type="exemptionReason"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-form-label col-lg-2">Date Format</div>
                            <div className="col-lg-4">
                                <div id="ember745" className="ember-view">
                                    <div className="ac-box ">
                                        <CustomSelect
                                            value={''}
                                            options={''}
                                            // changeOptionsData={() => setAddExemptionReason(true)}
                                            fetchingData={''}
                                            // onChange={(e) => { handleDateFormate(e) }}
                                            type="exemptionReason"
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-1">

                                <div id="ember746" className="ember-view">
                                    <div className="ac-box ">
                                        <CustomSelect
                                            value={''}
                                            options={''}
                                            // changeOptionsData={() => setAddExemptionReason(true)}
                                            fetchingData={''}
                                            // onChange={(e) => { handleDateSeparater(e) }}
                                            type="exemptionReason"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="form-group row cb">
                            <div className="float-left col-lg-6">
                                <div className="form-group row">
                                    <div className="col-form-label col-lg-4">Company ID</div>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <div id="ember747" className="type-ahead ember-view">
                                                    <div
                                                        id="ember748"
                                                        className="dropdown show ac-dropdown ember-view"
                                                    >

                                                        <div className="auto-select ac-selected" tabIndex={-1}>
                                                            <CustomSelect
                                                                value={''}
                                                                options={''}
                                                                // changeOptionsData={() => setAddExemptionReason(true)}
                                                                fetchingData={''}
                                                                // onChange={(e) => { handleCompanyID(e) }}
                                                                type="exemptionReason"
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <input
                                                    id="ember750"
                                                    className="ember-text-field ember-view form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-form-label col-lg-4">Tax ID</div>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <div id="ember751" className="type-ahead ember-view">
                                                    <div
                                                        id="ember752"
                                                        className="dropdown show ac-dropdown ember-view"
                                                    >

                                                        <div className="auto-select ac-selected" tabIndex={-1}>
                                                            <CustomSelect
                                                                value={''}
                                                                options={''}
                                                                // changeOptionsData={() => setAddExemptionReason(true)}
                                                                fetchingData={''}
                                                                // onChange={(e) => { handleTaxID(e) }}
                                                                type="exemptionReason"
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <input
                                                    id="ember754"
                                                    className="ember-text-field ember-view form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="well well-sm col-lg-4 well-flat"
                                style={{ marginLeft: 10 }}
                            >
                                <span className="text-muted">
                                    You can have Company ID, Tax ID and Additional Fields show up on
                                    your PDF by inserting the required placeholders in your 'Address
                                    Format' under
                                </span>
                                <a href="#" data-ember-action data-ember-action-755={755}>

                                    Settings -&gt; Preferences -&gt; General
                                </a>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label col-lg-2">

                                Additional Fields
                                <br />
                            </label>
                            <div className="col-lg-8">
                                {
                                    rows.map((field, index) => (
                                        <div className="row form-group w-50" key={index}>
                                            <div className="col-lg-5">
                                                <input
                                                    placeholder="Label"
                                                    onChange={''}
                                                    name='additionalField'
                                                    // value={profileData.additionalfields}
                                                    className="ember-text-field ember-view form-control"
                                                    type="text"
                                                />
                                            </div>
                                            <div className="col-lg-7  d-flex">
                                                <input
                                                    placeholder="Value"
                                                    id="ember758"
                                                    name='value'
                                                    className="ember-text-field ember-view form-control"
                                                    type="text"
                                                />
                                                &nbsp;&nbsp;
                                                <i className="icon-trash " style={{ marginTop: '7px' }} onClick={(index) => removeRow(index)} />
                                            </div>
                                            <div className="col-lg-10">
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-8 offset-lg-2">
                                <a onClick={addRow} data-ember-action data-ember-action-759={759}>
                                    Add Additional Field
                                </a>
                            </div>
                        </div>
                        <hr /> */}
                            <div className="form-group row">
                                <div className="col-lg-2">&nbsp;</div>
                                <div className="col-lg-2">
                                    <a
                                        id="ember760"
                                        className="btn btn-primary ember-view"
                                        type="submit"
                                        onClick={(e) => handleSaveOrganisation(e)}
                                    >
                                        Save
                                    </a>
                                </div>
                                <div className="col-lg-6 text-right">
                                    <a
                                        href="https://zoho.in/privacy.html"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        style={{ textDecoration: "underline", color: "#5c5c5c" }}
                                    >

                                        Privacy Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default OrganizationProfile
