import React, { useState, useEffect, useRef } from "react";
import {
  getRecipeDetailsByUUID,
  getRecipeList,
  logRecipeHistory,
} from "../../services/index";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const ViewRecipe = (props) => {
  let { id } = useParams();
  const history = useHistory();
  const [logData, setLogData] = useState({});
  let recordId = useParams();
  const [data, setData] = useState(null);
  const [recipe, setRecipe] = useState(null);
  const [recipeDetails, setRecipeDetails] = useState(null);
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

  const handleRowClick = (id) => {
    getRecipeDetails(id);
  };
  useEffect(() => {
    logRecipeHistory(id).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        console.log(d, "d");
        setLogData(d);
      } else {
        toast(res.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  }, [id]);

  const getRecipeDetails = (id) => {
    getRecipeDetailsByUUID(id)
      .then((res) => {
        if (res.errorCode == 0) {
          setRecipe(res.responsePacket);
          setRecipeDetails(res.responsePacket.ingredientsBeanList);
          let sub = 0;
          let quantity = 0;
          res.responsePacket.ingredientsBeanList &&
            res.responsePacket.ingredientsBeanList.map((item, i) => {
              sub +=
                (item.quantity / item.itemWeightValue) * Number(item.itemRate);
              quantity += item.quantity;
              setQuantity(quantity);
              setTotal(sub);
            });
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("🙁" + err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleEditRecipe = (id) => {
    history.push("/inventory/recipe/" + id + "/edit");
  };

  useEffect(() => {
    getRecipeDetails(recordId.id);

    var request = {
      page: 0,
      search: "",
      size: -1,
    };
    getRecipeList(request)
      .then((res) => {
        if (res.errorCode == 0) {
          setData(res.responsePacket.data);
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("🙁" + err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: billDetails.billNo,
    // onAfterPrint: () => alert("print successfull"),
  });

  return (
    <>
      <div
        className="column list-column d-print-none "
        style={{ width: "300px" }}
      >
        <div className="fill list-header topgrad d-print-none">
          <div className="list-filter">
            <div className="btn-toolbar float-right">
              <Link to="/inventory/recipe/new">
                <button
                  id="ember3047"
                  className="btn btn-primary tooltip-container ember-view"
                >
                  <span className="d-flex align-items-center">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      viewBox="0 0 512 512"
                      xmlSpace="preserve"
                      className="icon icon-xsmall mr-2"
                    >
                      <path d="M484 227H284V28c0-15.5-12.5-28-28-28s-28 12.5-28 28v199H28c-15.5 0-28 12.5-28 28v1c0 15.5 12.5 28 28 28h200v200c0 15.5 12.5 28 28 28s28-12.5 28-28V284h200c15.5 0 28-12.5 28-28v-1c0-15.5-12.5-28-28-28z"></path>
                    </svg>
                    New
                  </span>
                </button>
              </Link>
            </div>
            <div
              id="ember429"
              className="dropdown ember-view clearfix list-title"
            >
              <a
                id="ember430"
                className="dropdown-toggle no-caret ember-view  "
              >
                <span className="filter-title over-flow ">All Recipe</span>
                &nbsp;
              </a>
            </div>
          </div>
          <div id="ember452" className="ember-view">
            <div id="ember453" className="ember-view" />
          </div>
        </div>
        <div className="scroll-y fill body scrollbox list-body ">
          <div className="table-responsive overflow-initial ">
            <table
              id="ember456"
              className="table zi-table table-hover ember-view header-fixed"
            >
              <tbody>
                {data &&
                  data.map((data, i) => (
                    <tr
                      tabIndex={-1}
                      id="ember474"
                      className="ember-view"
                      key={i}
                      onClick={() => handleRowClick(data.recordId)}
                    >
                      <td>
                        <Link to={`/inventory/recipe/${data.recordId}`}>
                          <div className="list-primary">
                            <div
                              className="name"
                              title="Krishan Padam IT Services"
                            >
                              {data?.itemTitle}
                            </div>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div id="ember493" className="ember-view">
            <div className="pagination clearfix d-print-none"> </div>
          </div>
        </div>
      </div>
      <div className="column content-column" style={{ left: "301px" }}>
        <div className="fill header">
          <h1
            className="text-uppercase"
            style={{ display: "contents", margin: "10px" }}
          >
            &nbsp;
          </h1>
          <div className="btn-toolbar float-right">
            <div className="btn-group">
              <button
                className="tooltip-container ember-view btn btn-primary float-right"
                onClick={handlePrint}
              >
                <LocalPrintshopOutlinedIcon />
              </button>
              <button
                id="ember499"
                className="tooltip-container ember-view btn btn-primary"
                type="button"
                onClick={() => handleEditRecipe(recipe.recordId)}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x={0}
                  y={0}
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                  className="icon icon-sm align-text-top"
                >
                  <path d="M469.6 42.4C420.9-6.2 382.3-.2 378.1.7l-4.8 1L42.1 332.8c-3.4 3.4-5.8 7.8-6.8 12.5L1.3 506c-.6 2.8 1.9 5.3 4.7 4.7l160.7-34.1c4.7-1 9.1-3.4 12.5-6.8l331.2-331.2.9-4.9c.9-4.1 6.7-42.8-41.7-91.3zM43.2 464l20.2-95.2c.5-2.3 2.8-3.7 5.1-3 12.4 3.9 29.7 12 46.3 28.6 17.1 17.1 26 35.8 30.5 49.2.8 2.3-.6 4.7-3 5.2l-94.4 20c-2.8.6-5.3-1.9-4.7-4.8zm135.6-39.4c-2 2-5.4 1.3-6.5-1.3-6.5-15.6-17.1-34.3-34.6-51.8C121.2 355 104 345 89.4 339c-2.6-1.1-3.3-4.5-1.3-6.5l150.8-150.8 95.5-95.5c1-1 2.3-1.4 3.7-1.1 11.8 2.5 34.1 9.9 55.1 30.9 21.4 21.4 29.9 45.4 33.2 58.2.3 1.4 0 2.8-1 3.8l-95.1 95.1-151.5 151.5zm278.4-278.4c-2 2-5.4 1.3-6.5-1.3-6.5-15.6-17.1-34.3-34.6-51.8-16.5-16.5-33.7-26.5-48.3-32.5-2.6-1.1-3.3-4.5-1.3-6.5L387 33.7c.9-.9 2-1.3 3.2-1.2 9.8 1 30.3 6.6 56.5 32.8 26.2 26.2 31.8 46.8 32.8 56.5.1 1.2-.3 2.4-1.2 3.2l-21.1 21.2z" />
                </svg>
              </button>
            </div>
            <span
              className="close-details pl-4 text-black"
              onClick={(e) => history.push("/inventory/recipe")}
              data-ember-action-520={520}
            >
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
                className="icon icon-sm align-text-top text-black"
              >
                <path d="M455.2 9.2L256 208.4 56.8 9.2C44.5-3.1 24.6-3.1 12.2 9.2l-2.9 2.9C-3 24.4-3 44.4 9.3 56.7L208.4 256 9.2 455.2c-12.3 12.3-12.3 32.3 0 44.6l2.9 2.9c12.3 12.3 32.3 12.3 44.6 0L256 303.6l199.2 199.2c12.3 12.3 32.3 12.3 44.6 0l2.9-2.9c12.3-12.3 12.3-32.3 0-44.6L303.6 256 502.8 56.8c12.3-12.3 12.3-32.3 0-44.6l-2.9-2.9c-12.5-12.4-32.4-12.4-44.7-.1z"></path>
              </svg>
            </span>
          </div>
        </div>
        <br />
        <Tabs>
          <TabList>
            <Tab>Primary Details</Tab>
            <Tab>Log History</Tab>
          </TabList>

          <TabPanel>
            <div
              className="ember-view scroll-y noscroll-x fill body scrollbox"
              id="pdf"
              ref={componentRef}
              style={{ paddingLeft: "20px" }}
            >
              <span className="page-header-title">{recipe?.title}</span>
              <div id="ember522" className="ember-view">
                <div
                  id="ember523"
                  className="ember-view scroll-y noscroll-x scrollbox"
                  style={{ width: "100%" }}
                >
                  <br />
                  <table style={{ display: "flex", justifyContent: "center" }}>
                    <tr>
                      <td className="font-xlarge text-uppercase font-xs text-open">
                        {recipe?.itemTitle}
                      </td>
                    </tr>
                  </table>

                  <br />

                  {quantity != 0 ? (
                    <>
                      <div
                        className="customTable"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <table>
                          <tr>
                            <td className="pl-4">Step</td>
                            <td className="pl-4">Ingredients</td>
                            <td className="pl-4">Quantity</td>
                            <td className="pl-4">Unit</td>
                            <td className="pl-4">Rate </td>
                            <td className="pl-4">Yield% </td>
                            <td className="pl-4">Instructions</td>
                            <td className="pl-4">Amount </td>
                          </tr>

                          {recipeDetails &&
                            recipeDetails.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item?.stepOrder}</td>
                                  <td>{item?.itemTitle}</td>
                                  <td>{item?.quantity}</td>
                                  <td> {item?.quantityUnitTitle}</td>
                                  <td>{item?.itemRate}</td>
                                  <td>{item?.yield} %</td>
                                  <td>{item?.instruction}</td>
                                  <td>{item?.itemWeightValue}</td>
                                </tr>
                              );
                            })}

                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total:</td>
                            <td className="font-large text-uppercase font-xs text-open">
                              ₹ {recipe?.totalAmount?.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              {recipe.isProduction
                                ? "for Production"
                                : "for Restaurant"}
                            </td>
                            <td></td>
                            <td>Batch Size:</td>

                            <td className="font-large text-uppercase font-xs ">
                              {recipe?.quantity}&nbsp;
                              {recipe?.quantityUnitTitle}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <br />

                  <table border="1px" display="flex">
                    <tr>
                      <td>item</td>
                      <td>customer</td>
                      <td style={{ width: "100px" }}>
                        <>
                          <div>tax</div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>t1</span>
                            <span>t2</span>
                          </div>
                        </>
                      </td>
                      <td style={{ width: "100px" }}>
                        <>
                          <div>tax2</div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>t11</span>
                            <span>t22</span>
                          </div>
                        </>
                      </td>
                    </tr>
                    <tr>
                      <td>apple</td>
                      <td>subham</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>a1</span>
                        <span>a2</span>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {" "}
                          <span>b1</span>
                          <span>b2</span>
                        </div>
                      </td>
                    </tr>
                  </table>

                  <table border="1px">
                    <tr>
                      <td></td>
                      <td></td>
                      <td>tax1</td>
                      <td></td>
                      <td>tax2</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>item</td>
                      <td>customer</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>apple</td>
                      <td>subham</td>
                      <td>a1</td>
                      <td>a2</td>
                      <td>b1</td>
                      <td>b2</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <table>
              {logData.length == 0 ? (
                "No Changes Found"
              ) : (
                <tr>
                  <td>Date</td>
                  <td>Changes</td>
                </tr>
              )}
              {logData.length >= 1 &&
                logData?.map((history) => {
                  return (
                    <>
                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          {history?.transactionDate?.substring(0, 10)}
                          :&nbsp;&nbsp;
                        </td>

                        <td>
                          {history?.message.split("\t").map((item, i) => (
                            <tr>{item}</tr>
                          ))}
                        </td>
                      </tr>
                      <hr />
                    </>
                  );
                })}
            </table>
          </TabPanel>
        </Tabs>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewRecipe;
