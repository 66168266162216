import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { toast,ToastContainer  } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Header } from "antd/lib/layout/layout";
import PreferencesNav from "../Preferences/PreferencesNav";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AddIcon from "@mui/icons-material/Add";

import {
  deliveryPersonList1,
  addDeliveryPerson,
  deliveryPersonUUID1,
  editDeliveryPerson1,
  activeInactiveDelivery,

} from "../../../services/index";

const DeliveryPersonList = () => {
  let history = useHistory();

  const [success, setSuccess] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setName(""); 
    setPhone("");
    setEmail("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [edit, setEdit] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);

  const [dataId, setDataId] = useState(false);

  const columns = [
    {
      name: "TITLE",
      cell: (row) => row.title,
      sortable: true,
    },
    {
      name: "PHONE ",
      selector: (row) => row.phone,
    },
    {
      name: "EMAIL ",
      selector: (row) => row.email,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {/* <EditIcon
            style={{ fontSize: "15px", cursor: "pointer" }}
            onClick={() => {
              history.push("/inventory/vendors/" + row.recordId + "/edit");
            }}
          />&nbsp;&nbsp; */}
          {row.active ? (
            <CheckCircleIcon
              style={{
                fontSize: "15px",
                cursor: "pointer",
                color: "greenyellow",
              }}
              onClick={() => handleActiveInactive(row)}
            />
          ) : (
            <CancelIcon
              style={{ fontSize: "15px", cursor: "pointer", color: "tomato" }}
              onClick={() => handleActiveInactive(row)}
            />
          )}
        </div>
      ),
    },
  ];

  const handleActiveInactive = (row) => {
    console.log(row, "row");
    activeInactiveDelivery(row.recordId).then((res) => {
      if (res.errorCode == 0) {
        setSuccess(!success)
        toast(res.message, { autoClose: 2000 });
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };

  const handleSave = () => {
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      alert("Please provide valid Mail");
      return;
    }

    if (name == "") {
      alert("Please fill name");
      return;
    }

    if (String(phone).length != 10 || phone == "") {
      alert("Please fill 10 digit phone ");
      return;
    }
    if (email == "") {
      alert("Please fill email ");
      return;
    }

    const data1 = {
      email: email,
      phone: phone,
      title: name,
    };

    edit
      ? editDeliveryPerson1(dataId, data1).then((res) => {
          if (res.errorCode == 0) {
            toast("👏" + "SUCCESS" + res.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            refreshList();
            handleClose();
            setEmail("");
            setPhone("");
            setName("");
          } else {
            toast( res.message,{autoClose: 2000});
            return;
          }
        })
      : addDeliveryPerson(data1).then((res) => {
          if (res.errorCode == 0) {
            toast("👏" + "SUCCESS" + res.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            refreshList();
            handleClose();

            setEmail("");
            setPhone("");
            setName("");
          } else {
            toast( res.message,{autoClose: 2000});
            return;
          }
        });
  };

  const handleClicked = (id) => {
    deliveryPersonUUID1(id).then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        setName(res.responsePacket.title);
        setPhone(res.responsePacket.phone);
        setEmail(res.responsePacket.email);
      } else {
        toast( res.message,{autoClose: 2000});
        return;
      }
    });

    handleShow();
    setEdit(true);
  };

  const refreshList = () => {
    var data = {
      page: 0,
      search: "",
      size: -1,
    };

    deliveryPersonList1(data).then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        setRows(temp);
        setFilteredData(temp);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  useEffect(() => {
    var data = {
      page: 0,
      search: "",
      size: -1,
    };

    deliveryPersonList1(data).then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        setRows(temp);
        setFilteredData(temp);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }, [success]);

  useEffect(() => {
    const result = rows.filter((data) => {
      return data.title.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  const handleNew = () => {
    setEdit(false);
    handleShow();
    setEmail("");
    setPhone("");
    setName("");
  };

  return (
    <>
      <div className="column list-column d-print-none column-small">
        <PreferencesNav />
      </div>
      <div className="column content-column">
        <div className="header clearfix" style={{ borderBottom: "none" }}>
          <span className="float-left" style={{ fontSize: "35px" }}>
            Delivery Person List
          </span>
        </div>

        <div className="list-filter">
          <Modal show={show} onHide={handleClose}>
            <Modal.Body>
              <Header>Delivery Person Info</Header>
              <br />
              <hr />

              <table>
                <tr>
                  <td>Name:</td>
                  <td>
                    {" "}
                    <input
                      className="ember-text-field ember-view form-control"
                      type="text"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Phone:</td>
                  <td>
                    <input
                      className="ember-text-field ember-view form-control"
                      type="number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      value={phone}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Email:</td>
                  <td>
                    <input
                      className="ember-text-field ember-view form-control"
                      type="text"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  </td>
                </tr>
              </table>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={handleSave}>
                Save
              </Button>

              
            </Modal.Footer>
          </Modal>
        </div>

        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            columns={columns}
            data={filteredData}
            fixedHeader
            pointerOnHover={true}
            highlightOnHover={true}
            onRowClicked={(data) => {
              setDataId(data.recordId);
              handleClicked(data.recordId);
            }}
            subHeader
            subHeaderComponent={
              <>
                {" "}
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder={"Search here"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ margin: "10px" }}
                />
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                  onClick={handleShow}
                >
                  <AddIcon />
                  New
                </button>
              </>
            }
            subHeaderAlign="right"
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default DeliveryPersonList;
