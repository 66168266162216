import { ROUND_OFF_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}

// export const addBrand = (data) => {
//     return new Promise((resolve, reject) => {
//         try {
//             axios
//                 .post(BRAND_URL(), data, { headers: headersdata })
//                 .then((res) => {
//                     resolve(res.data);
//                 })
//                 .catch((err) => {
//                     reject("Error in getItemList axios!");
//                 });
//         } catch (error) {
//             reject(SYSTEM_ERROR);
//         }
//     });
// };
export const getRoundOffKeyValuePair = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(ROUND_OFF_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};