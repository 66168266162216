import React from "react";
import { useState, useEffect, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  getRequisitionUUID,
  addRequisition,
  departmentKeyValue1,
  getActiveItemsByWarehouseid,
  addIssuance,
  getWarehouseKeyValueList,
} from "../../services/index";
import AddIcon from "@mui/icons-material/Add";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Header } from "antd/lib/layout/layout";

const IssuanceNew = (props) => {
  let history = useHistory();

  console.log(props, "props");
  let reqAdd = props?.location?.state?.reqAdd;
  let reqId = props?.location?.state?.reqId;
  let loginData1 = new Cookies().get("loginData1");

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date1 = curr.toISOString().substring(0, 10);

  const [issInfo, setIssInfo] = useState({
    ware: "",
    user: { value: loginData1.recordId, label: loginData1.fullName },
    dept: "",
    date: date1,
    note: "",
  });

  const tempRow = {
    item: "",
    stock: "",
    qty: "",
  };

  const [rows, setRows] = useState([tempRow]);
  const [itemOptions, setItemOptions] = useState("");
  const [itemOptions2, setItemOptions2] = useState("");
  const [wareOptions, setWareOptions] = useState("");
  const [deptOptions, setDeptOptions] = useState("");

  useEffect(() => {
    getWarehouseKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setWareOptions(options);
      } else {
        toast(res.message);
      }
    });

    departmentKeyValue1().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setDeptOptions(options);
      } else {
        toast(res.message);
      }
    });

    if (reqId) {
      getRequisitionUUID(reqId).then((res) => {
        if (res.errorCode == 0) {
          let d = res.responsePacket;
          let d2 = res.responsePacket.requisitionItemsBeanList;

          setIssInfo({
            ware: { label: d.warehouseTitle, value: d.warehouseId },
            user: {
              value: d.requisitionByUserId,
              label: d.requisitionByUserTitle,
            },
            dept: {
              label: d.requisitionDepartmentTitle,
              value: d.requisitionDepartmentId,
            },
            date: d.requisitionDate,
            note: "",
            reqCode: d.requisitionCode,
          });

          let abc = d2.map((a) => {
            return {
              item: {
                value: a.itemId,
                label: a.itemTitle,
                unitId: a.requisitionQuantityUnitId,
                unitName: a.requisitionQuantityUnitTitle,
              },
              qty: a.requisitionQuantity,
            };
          });
          setRows(abc);
          console.log(abc, "abc");
        } else {
          toast(res.message);
        }
      });
    }
  }, []);

  const handleWare = (e) => {
    setIssInfo({ ...issInfo, ware: e });

    let data = {
      page: 0,
      size: -1,
      search: "",
      uuid: e.value,
    };
    getActiveItemsByWarehouseid(data).then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.data.map((d) => ({
          value: d.itemId,
          label: d.itemTitle,
          stock: d.currentStockQuantity,
          warehouseId: d.warehouseId,
          unitId: d.unitId,
          unitName: d.unitName,
        }));
        setItemOptions(options);
        setItemOptions2(options);
      } else {
        toast(res.message);
      }
    });
  };

  const handleAddRow = () => {
    setRows([...rows, tempRow]);
  };

  const handleClear = (id, i) => {
    const obj = rows.slice();
    obj.splice(i, 1);
    setRows(obj);
  };

  const handleQty = (e, i) => {
    const obj = [...rows];
    let qt = Number(e.target.value);
    if (qt < obj[i].stock) {
      obj[i].qty = qt;
      setRows(obj);
    }
  };

  const handleItem = (e, i) => {
    const obj = [...rows];
    obj[i].item = e;
    obj[i].stock = e.stock;
    obj[i].qty = "";
    setRows(obj);

    var setSend = false;
    var filterOptions = [];
    itemOptions2 &&
      itemOptions2.map((a) => {
        rows &&
          rows.map((b) => {
            if (a.value === b?.item?.value) {
              setSend = true;
            }
          });
        if (!setSend) {
          filterOptions.push(a);
        }
        setSend = false;
      });
    setItemOptions(filterOptions);
  };

  const handleSave = () => {
    if (issInfo.ware == "") {
      toast("Select Warehouse");
      return;
    }

    if (issInfo.user == "") {
      toast("Select user");
      return;
    }
    if (issInfo.dept == "") {
      toast("Select Department");
      return;
    }

    if (reqAdd) {
      rows.map((a) => {
        a.requisitionQuantity = a.qty;
        a.warehouseId = a.item.warehouseId;
        a.itemId = a.item.value;
        a.itemTitle = a.item.label;
        a.requisitionQuantityUnitId = a.item.unitId;
        a.requisitionQuantityUnitTitle = a.item.unitName;
      });
      let rows2 = rows.filter((a) => a.item && a.qty > 0);
      if (rows2?.length == 0) {
        toast("Select item");
        return;
      }

      const data = {
        requisitionDate: issInfo.date,
        warehouseId: issInfo.ware.value,
        requisitionToUserId: issInfo.user.value,
        requisitionToUserTitle: issInfo.user.label,
        requisitionDepartmentId: issInfo.dept.value,
        requisitionDepartmentTitle: issInfo.dept.label,
        requisitionItemsBeanList: rows2,
      };
      console.log(data, "dataaa");
      addRequisition(data).then((res) => {
        if (res.errorCode == 0) {
          toast(res.message);
          history.push("/inventory/requisition/");
        } else {
          toast(res.message);
        }
      });
    } else {
      let rows2;
      rows.map((a) => {
        a.issuedQuantity = a.qty;
        a.warehouseId = a.item.warehouseId;
        a.itemId = a.item.value;
        a.itemTitle = a.item.label;
        a.issuedQuantityUnitId = a.item.unitId;
        a.issuedQuantityUnitTitle = a.item.unitName;
      });
      if (reqId) {
        rows2 = rows.filter((a) => a.item && a.qty > 0);
      } else {
        rows2 = rows.filter((a) => a.item && a.stock > 0 && a.qty > 0);
        if (rows2?.length == 0) {
          toast("Select item");
          return;
        }
      }

      const data = {
        issuanceDate: issInfo.date,
        warehouseId: issInfo.ware.value,
        issuedToUserId: issInfo.user.value,
        issuedToUserTitle: issInfo.user.label,
        issuedDepartmentId: issInfo.dept.value,
        issuedDepartmentTitle: issInfo.dept.label,
        issuedItemsList: rows2,
      };
      console.log(data, "dataaa");
      if (reqId) {
        data.requisitionCode = issInfo?.reqCode;
      }
      addIssuance(data).then((res) => {
        if (res.errorCode == 0) {
          toast(res.message);
          history.push("/inventory/issueitems/");
        } else {
          toast(res.message);
        }
      });
    }
  };

  useEffect(() => {
    console.log(rows, "rows");
    console.log(issInfo, "issInfo");
  }, [rows, issInfo]);

  return (
    <>
      <div style={{ paddingLeft: "20px" }}>
        <h1 className="fill list-header d-flex  align-items-center bg-white p-3 border-bottom">
          <ReceiptIcon />
          {reqAdd ? "New Requisition" : "New Item Issuance"}&nbsp;
          {issInfo?.reqCode ? issInfo.reqCode : ""}
        </h1>

        <br />
        <table className="customTable">
          <tr>
            <td>Warehouse:</td>
            <td>
              <Select
                isDisabled={reqId ? true : false}
                value={issInfo.ware}
                options={wareOptions}
                onChange={(e) => handleWare(e)}
              />
            </td>
          </tr>
          <tr>
            <td>User:</td>
            <td>
              <input className="form-control" value={issInfo.user.label} />
            </td>
          </tr>
          <tr>
            <td>Department:</td>
            <td>
              <Select
                isDisabled={reqId ? true : false}
                value={issInfo.dept}
                options={deptOptions}
                onChange={(e) => setIssInfo({ ...issInfo, dept: e })}
              />
            </td>
          </tr>
          <tr>
            <td>Date:</td>
            <td>
              <input
                disabled={reqId ? true : false}
                type="date"
                className="form-control"
                value={issInfo.date}
                onChange={(e) =>
                  setIssInfo({ ...issInfo, date: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Note:</td>
            <td>
              <input
                className="form-control"
                value={issInfo.note}
                onChange={(e) =>
                  setIssInfo({ ...issInfo, note: e.target.value })
                }
              />
            </td>
          </tr>
        </table>
        <br />
        <table className="customTable">
          <tr>
            <td>Item</td>
            {reqId ? <></> : <td>Stock</td>}

            <td>Qty</td>
            <td></td>
          </tr>

          {rows &&
            rows.map((a, i) => {
              return (
                <>
                  <tr>
                    <td style={{ width: "250px" }}>
                      {" "}
                      <Select
                        isDisabled={reqId ? true : false}
                        options={itemOptions}
                        value={a.item}
                        onChange={(e) => handleItem(e, i)}
                      />
                    </td>
                    {reqId ? (
                      <></>
                    ) : (
                      <td style={{ width: "100px" }}>
                        {" "}
                        <input disabled="true" type="number" value={a.stock} />
                      </td>
                    )}

                    <td style={{ width: "100px" }}>
                      {" "}
                      <input
                        disabled={reqId ? true : false}
                        type="number"
                        min={0}
                        value={a.qty}
                        onChange={(e) => handleQty(e, i)}
                      />
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClear(a.item.value, i)}
                    >
                      {rows.length > 1 && <i className="fa fa-times"></i>}
                    </td>
                  </tr>
                </>
              );
            })}
        </table>
        {reqId ? (
          <></>
        ) : (
          <div>
            <button
              id="ember1426"
              className="btn btn-default addbtn mt-3"
              onClick={handleAddRow}
            >
              <AddIcon style={{ color: "blue" }} /> New Row
            </button>
          </div>
        )}

        <br />
        <div>
          {" "}
          <button
            className="btn bg-primary text-white mr-2"
            onClick={(e) => handleSave(e)}
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default IssuanceNew;
