import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";

import { getRecipeList } from "../../services/index";
import { toast,ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
const RecipeList = (props) => {
  const history = useHistory();
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [manageRecipeModal, setManageRecipeModal] = useState(false);

  const columns = [
    {
      name: "ITEM",
      selector: (row) => row.itemTitle,
      sortable: true,
    },

    {
      name: "QUANTITY",
      selector: (row) => row.quantity,
    },
    {
      name: "UNIT",
      selector: (row) => row.quantityUnitTitle,
    },
    {
      name: "TOTAL AMOUNT",
      selector: (row) => row.totalAmount,
    },
    {
      name: "EDIT",
      selector: (row) => (
        <div>
          <Link>
            <EditIcon
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => {
                history.push("/inventory/recipe/" + row.recordId+"/edit");                
              }}
            />
          </Link>
        </div>
      ),
    },

  ];

  const handleRecipeView = (id) => {
    history.push({
      pathname: `/inventory/recipe/` + id,
      state: { data: rows },
    });
  };

  useEffect(() => {
    if (rows.length == 0) {
      var request = {
        page: 0,
        search: "",
        size: -1,
      };
      getRecipeList(request)
        .then((res) => {
          if (res.errorCode == 0) {
            res.responsePacket.data.reverse()
            setRows(res.responsePacket.data);
            setFilteredData(res.responsePacket.data);
          } else {
            toast("🙁" + res.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
      
    }
  }, []);
  useEffect(() => {
    const result = rows.filter((data) => {
      return data.itemTitle.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);
  return (
    <>
      <div style={{ height: 500, width: "100%" }}>
        <DataTable
          title="All Recipies"
          defaultSortDesc={true}
          columns={columns}
          data={filteredData}
          theme="light"
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          pointerOnHover={true}
          highlightOnHover={true}
          onRowClicked={(data) => {
            handleRecipeView(data.recordId);
          }}
          subHeader
          subHeaderComponent={
            <>
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control col-2"
                type="text"
                placeholder={"Search here"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{margin:"10px"}}
              />
              <Link to="/inventory/recipe/new">
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                >
                  <AddIcon />
                  New
                </button>
              </Link>
            </>
          }
          subHeaderAlign="right"
        />
      </div>

      <ToastContainer />
    </>
  );
};

export default RecipeList;
