import { ALL_ITEM_URL, ITEM_DETAIL_BY_UUID, ITEM_URL } from "../CONSTANTS";
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link, Redirect, useParams } from "react-router-dom";

const cook = new Cookies().get("loginData1");

const headersdata = {
  "content-type": "application/json",
  Accept: "application/json",
  Authorization: cook != undefined ? cook.accessToken : "",
  // 'Authorization': url.Auth_Token
};
// getAllActiveRecords 


export const getActiveItemRecords = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ITEM_URL() + "getAllActiveRecords/" ,data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAllActiveRecords axios!");
        });
    } catch (error) {
      console.error("in LoginService > getAllActiveRecords, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getKeyValueForBatches1 = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ITEM_URL() + "getKeyValueForBatches/" , { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getIsIngredientItemList axios!");
        });
    } catch (error) {
      console.error("in LoginService > getIsIngredientItemList, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};


export const getIsIngredientItemList1 = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ITEM_URL() + "getIsIngredientItemList/" , { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getIsIngredientItemList axios!");
        });
    } catch (error) {
      console.error("in LoginService > getIsIngredientItemList, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getNotIngredientItemList1 = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ITEM_URL() + "getNotIngredientItemList/" , { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getNotIngredientItemList axios!");
        });
    } catch (error) {
      console.error("in LoginService > getNotIngredientItemList, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};


export const activeDeactiveItem = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(ITEM_URL() + "activeOrDeActiveItem/" + id ,id, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in upload axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

 export const ItemListByWarePrice1 = (wId, pId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ITEM_URL() + "getKeyValueDetailsList/" + wId + "/" + pId, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in upload axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const uploadExcel = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ITEM_URL() + "uploadItemsExcel", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in upload axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const downloadItemExcel = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ITEM_URL() + "downloadItemExcel/" + id, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in downloadItemExcel axios!");
        });
    } catch (error) {
      console.error("in LoginService > downloadItemExcel, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getItemList = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ALL_ITEM_URL(), data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const getItemDetail = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ITEM_DETAIL_BY_UUID() + id, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemDetail axios!");
        });
    } catch (error) {
      console.error("in LoginService > getItemDetail, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
export const getItemDetailKeyValuePair = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ITEM_URL() + "getKeyValueDetailsList", { headers: headersdata })
        .then((res) => {
       
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemDetail axios!");
        });
    } catch (error) {
      console.error("in LoginService > getItemDetail, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
export const getItemKeyValueDetailsList = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ITEM_URL() + "getKeyValueDetailsList/" + uuid, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemDetail axios!");
        });
    } catch (error) {
      console.error("in LoginService > getItemDetail, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const addItem = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ITEM_URL(), data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const updateItem = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ITEM_URL() + id, data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
