import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import {
  PurchasePayment1,
  PurchasePaymentList1,
  PurchasePaymentUUID1,
} from "../../services/Purchase/PurchaseService";
import { Redirect, Link, useHistory } from "react-router-dom";
import {logPurchasePaymentHistory} from "../../services/index"
import Modal from "react-bootstrap/Modal";

const PayPurchaseList = () => {
  let history = useHistory();


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [logData, setLogData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [dataId, setDataId] = useState(false);
  const [redirectState, setRedirectState] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;

  const columns = [
    {
      name: "PAYMENT NO#",
      selector: (data) => data.paymentNo,
      sortable: true,
    },
    {
      name: "VENDOR NAME",
      selector: (data) => data.vendorTitle,
      sortable: true,
    },

    {
      name: "BILL NO#",
      selector: (data) => data.billNo,
      sortable: true,
    },

    {
      name: "DATE",
      selector: (data) => data.paymentDate,
      sortable: true,
    },

    {
      name: "AMOUNT",
      selector: (data) => parseFloat(data.amountReceived).toFixed(2),
    },
  ];

  const InvoicesList = () => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };
    PurchasePaymentList1(list_data)
      .then((res) => {
        if (res.errorCode == 0) {
          const d = res.responsePacket.data;
          setData(d);
          setFilteredData(d);
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("🙁" + err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  let redirecting = redirectState ? (
    <Redirect push to={`/inventory/PurchasePayment/view/${dataId}`} />
  ) : (
    ""
  );

  useEffect(() => {
    InvoicesList();
  }, [page]);

  useEffect(() => {
    const result = data.filter((data) => {
      return data.vendorTitle.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);



  const handleModel = (id) => {
    handleShow();
    logPurchasePaymentHistory(id).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        console.log(d, "d");
        setLogData(d);
      } else {
        toast(res.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  };

  return (
    <>

<Modal show={show} onHide={handleClose}>
        <Modal.Body>
          Log History
          <hr/>
          <table>
            {logData.length == 0 ? (
              "No Changes Found"
            ) : (
              <tr>
                <td>Date</td>
                <td>Changes</td>
              </tr>
            )}
            {logData.length >= 1 &&
              logData?.map((history) => {
                return (
                  <>
                    <tr>
                      <td style={{verticalAlign: "top"}}>
                        {history?.transactionDate?.substring(0, 10)}:&nbsp;&nbsp;
                      </td>

                      <td>
                        {history?.message.split("\n").map((item, i) => (
                          <tr>{item}</tr>
                        ))}
                      </td>
                    </tr>
                    <hr />
                  </>
                );
              })}
          </table>
        </Modal.Body>
      </Modal>

      <div className="column list-column expanded-list-column">
        <div style={{ height: 500, width: "100%" }}>
          <DataTable
          title="All Payments"
            defaultSortAsc={false}
            defaultSortFieldId={1}
            columns={columns}
            data={filteredData}
            theme="light"
            pagination
            fixedHeader
            pointerOnHover={true}
            highlightOnHover={true}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
            onRowClicked={(data) => {
              handleModel(data.recordId);
            }}
            subHeader
            subHeaderComponent={
             <><SearchRoundedIcon />
             <input
               className="ember-text-field ember-view form-control col-2"
               type="text"
               placeholder={"Search here"}
               value={search}
               onChange={(e) => setSearch(e.target.value)}
             /></>
            }
            subHeaderAlign="right"
          />
          {redirecting}
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default PayPurchaseList;
