import React, { useState, useEffect } from "react";
import Select from "react-select";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {
  getPaymentModeKeyValueList,
  addPaymentMode,

  
  // getVendorKeyValuePair,
  getTdsKeyValuePair,
  getAccountKeyValueListByEnumString,

  // GetBillDetailsByUUID,

  getBillDetailsByUUID,
  getVendorKeyValuePair,

  PurchasePayment1,
  PurchasePaymentList1,
  PurchasePaymentUUID1,
} from "../../services/index";
import { Link, useHistory } from "react-router-dom";
import CustomSelect from "../SharedComponents/CustomSelect";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";

let globalTemp1 = [];
let globalTemp2 = [];
const PayPurchase = (props) => {

  let paymentId=props.match.params.id
  console.log(props)
  console.log(paymentId,"paymentId")
  const history = useHistory();

  const [due, setDue] = useState("");
  const [salesAccountKeyValue, setSalesAccountKeyValue] = useState([]);

  const [salesAccount, setSalesAccount] = useState(null);

  const [vendorName, setVendorName] = useState("");
  const [packageDetails, setPackageDetails] = useState("");
  const [packageId, setPackageId] = useState("");
  const [newVendor, setNewVendor] = useState([]);
  const [billDetails, setBillDetails] = useState([]);
  const [billNo, setBillNo] = useState("");
  const [billDate, setBillDate] = useState("");

  const [totalAmount, setTotalAmount] = useState("");

  const { forBillUse, data } = props;
  const [vendorOption, setVendorOption] = useState(null);
  const [back, setBack] = useState(null);
  const [paymentDate, setPayentDate] = useState(null);
  const [vendorDetails, setVendorDetails] = useState(null);
  const [amountReceived, setAmountReceived] = useState(null);
  const [tdsKeyValue, setTdsKeyValue] = useState(null);
  const [tdsTax, setTdsTax] = useState(null);
  const [withHeldAmount, setWithHeldAmount] = useState(0);
  const [paymentModeKeyValue, setPaymentModeKeyValue] = useState(null);
  const [paymentMode, setPaymentMode] = useState(null);
  const [paymentModeTitle, setPaymentModeTitle] = useState(null);
  const [accountKeyValueList, setAccountKeyValueList] = useState([]);
  const [accountKeyValue, setAccountKeyValue] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [otherKeyValue, setOtherKeyValue] = useState(null);
  const [modalSwitches, setModalSwitches] = useState({
    paymentModal: false,
  });

  const [requestData, setRequestData] = useState({
    taxType: false,
    withHeldAmount: "",
    bankCharges: 0,
    refferences: "",
    notes: "",
  });

  const handleTaxType = (e) => {
    setRequestData({
      ...requestData,
      taxType: e.target.value,
    });
  };

  const handleSelectVendorName = (e) => {
    setVendorName(e);
  };

  const handleTdsTaxAccount = (e) => {
    setTdsTax(e);
  };

  const handlePaymentMode = (e) => {
    setPaymentMode(e);
  };

  const handleDepositeAccount = (e) => {
    setAccountKeyValue(e);
    if (e.label === "Petty Cash" || e.label === "UnDeposited Funds") {
      setAccountType("Cash");
    } else {
      setAccountType("OtherCurrentLiability");
    }
  };

  const handleNewPaymentMode = () => {
    let request = {
      title: paymentModeTitle,
    };
    addPaymentMode(request)
      .then((res) => {
        if (res.errorCode == 0) {
          toast("👍" + res.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setModalSwitches({
            ...modalSwitches,
            paymentModal: false,
          });
          getPaymentModeList();
        } else {
          toast("👎" + res.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("👎" + err, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSalesPayment = () => {
    let request = {
      reference:requestData?.refferences,
      bankCharge:requestData?.bankCharges,
      vendorTitle: billDetails.vendorTitle, 
      vendorId: billDetails.vendorId, 
      billId: billDetails.recordId, 
      paymentDate: billDate, 

      amountReceived: Number(amountReceived),
      accountTypeEnum: "Expense",
      tdsId: tdsTax?.value, 
      isTcs:billDetails.isTcs,
      taxTitle:billDetails.taxTitle,
      taxId:billDetails.taxId,

      isTdsDeducted: requestData?.taxType === "true", 

      paymentModeId: paymentMode?.value,
      paymentModeTitle: paymentMode?.label,
      paymentNotes: requestData?.notes,

      vendorEmail: billDetails?.vendorEmail,
      vendorPan: billDetails?.vendorPan,

    };

    if (request.paymentDate === null || request.paymentDate === undefined) {
      toast.warn("Please Select Payment Date First !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (request.paymentModeId == null || request.paymentModeId === undefined) {
      toast.warn("Please Select Payment Mode !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (request.accountTypeEnum === null || vendorName == undefined) {
      toast.warn("Please Deposite Account !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    PurchasePayment1(billDetails.recordId, request)
      .then((res) => {
        if (res.errorCode == 0) {
          toast("👍" + res.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push("/inventory/PurchasePayment");
          }, 200);
        } else {
          toast("👎" + res.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("👎" + err, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  //---------
  const CallBasicApi = () => {
    getVendorKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setVendorOption(options);
      } else {
        toast(res.message, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });;
      }
    });

    getTdsKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setTdsKeyValue(options);
      }
    });

    getAccountKeyValueListByEnumString("Cash").then((res) => {
      if (res.errorCode == 0) {
        res.responsePacket.map((d) => {
          let dict = {
            value: d.key,
            label: d.value,
          };
          accountKeyValueList.push(dict);
        });
      }
    });

    getAccountKeyValueListByEnumString("OtherCurrentLiability").then((res) => {
      if (res.errorCode == 0) {
        res.responsePacket.map((d) => {
          let dict = {
            value: d.key,
            label: d.value,
          };
          accountKeyValueList.push(dict);
        });
      }
    });
    getPaymentModeList();
    // if (globalTemp2.length != null || globalTemp2.length != 0) {
    //     setAccountKeyValueList([...globalTemp2, ...globalTemp1])
    // }
  };

  const getPaymentModeList = () => {
    getPaymentModeKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setPaymentModeKeyValue(options);
      } else {
        toast("👎" + res.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const setDefaultData = () => {
    // if (data != null) {
    let dict = {
      value: data.vendorId,
      label: data.vendorTitle,
    };
    // setVendorName(dict);
    setAmountReceived(data.amountDue);
  };

  const GetBillDetails = (id) => {
    if (id != null) {
      getBillDetailsByUUID(id).then((res) => {
        if (res.errorCode == 0) {
          setBillDetails(res.responsePacket);
          setAmountReceived(res.responsePacket.amountDue)
          setDue(res.responsePacket.amountDue.toFixed(2))
          setVendorName(res.responsePacket.vendorTitle);
          setBillNo(res.responsePacket.billNo);
          setBillDate(res.responsePacket.billDate);
          setTotalAmount(res.responsePacket.totalAmount);
      
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (forBillUse) {
      setBack("/inventory/bills");
      setVendorDetails(data);
      setDefaultData();
    } else {
      setBack("/inventory/SalesPayment");
    }

    GetBillDetails(paymentId);
    CallBasicApi();
  }, []);

  if (salesAccountKeyValue.length == 0) {
    getAccountKeyValueListByEnumString("Income").then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setSalesAccountKeyValue(options);
      } else {
        toast(res.message, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });;
      }
    });
  }




  return (
    <>
      <div className=" content-column txn-creation-column">
        <div id="ember716" className="ember-view">
          <div className="scroll-y noscroll-x fill body scrollbox">
            <div className="fill-container row header-separator" />
            <form>
    <h3>PURCHASE PAYMENT</h3>
              <div className="payment">
              <br/>

                <div className="zb-txn-form">

                <div className="form-group row">
                      <label className="col-form-label col-lg-2 required">
                      Vendor Name
                      </label>
                      <div className="col-lg-5">
                      <Select
                              // isDisabled={forBillUse}
                              isDisabled="true"
                              options={vendorOption}
                              placeholder={vendorName}
                              onChange={(e) => handleSelectVendorName(e)}
                              value={vendorName}
                            />
                      </div>
                    </div>
                  <div>
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2 required">
                        Amount to Pay
                      </label>
                      <div className="col-lg-10">
                        <div className="row">
                          <div className="col-lg-2">
                            <div className="input-group">
                              <input
                                // disabled="true"
                                id="ember719"
                                className="ember-text-field text-left ember-view form-control text-left"
                                type="text"
                                value={amountReceived}
                                
                                onChange={(e) => setAmountReceived(e.target.value)}
                            
                               
                              />
                            </div>

                            <div
                              id="ember720"
                              className="popover-container ember-view"
                            >
                              <div
                                id="ember721"
                                className="d-none popover bs-popover-bottom ember-view pop-over-div"
                              ></div>
                            </div>
                          </div>
                          <label className="col-form-label col-lg-2 px-0">
                            Bank Charges
                          </label>
                          <div className="col-lg-2">
                            <input
                              id="ember722"
                              className="ember-text-field text-left ember-view form-control"
                              type="text"
                              value={requestData.bankCharges}
                              onChange={(e) =>
                                setRequestData({
                                  ...requestData,
                                  bankCharges: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label col-lg-2 required">
                        Payment Date
                      </label>
                      <div className="col-lg-5">
                        <input
                          id="ember723"
                          className="ember-text-field zf-date-picker date-picker ember-view form-control"
                          type="date"
                          // onChange={(e) => setPayentDate(e.target.value)}
                          onChange={(e) => setBillDate(e.target.value)}
                          // value={paymentDate}
                          value={billDate}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2 required">
                        Payment No.
                      </label>

                      <div className="col-lg-5">
                        <input
                          disabled
                          id="ember724"
                          className="ember-text-field ember-view form-control"
                          type="text"
                          placeholder="(Auto Generated)"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2">
                        Payment Mode
                      </label>
                      <div className="col-lg-5">
                        <div id="ember726" className="ember-view">
                          <div className="ac-box ">
                            <CustomSelect
                              options={paymentModeKeyValue}
                              value={paymentMode}
                              onChange={(e) => handlePaymentMode(e)}
                              changeOptionsData={() =>
                                setModalSwitches({
                                  ...modalSwitches,
                                  paymentModal: true,
                                })
                              }
                              fetchingData={false}
                              type="paymentMode"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2">
                        Reference#
                      </label>
                      <div className="col-lg-5">
                        <input
                          maxLength={50}
                          id="ember729"
                          className="ember-text-field ember-view form-control"
                          type="text"
                          value={requestData.refferences}
                          onChange={(e) =>
                            setRequestData({
                              ...requestData,
                              refferences: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label col-lg-2">
                        Tax deducted?
                      </label>
                      <div className="col-lg-10 align-self-center">
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="row-radio-buttons-group"
                          checked={requestData.taxType}
                          value={requestData.taxType}
                          onChange={handleTaxType}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio size="small" />}
                            label="No Tax deducted"
                          />
                          <FormControlLabel
                            value={true}
                            control={<Radio size="small" />}
                            label="Yes, TDS (Income Tax)"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    {requestData.taxType == "true" ? (
                      <>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-2 required">
                            TDS Tax Account
                          </label>{" "}
                          <div className="col-lg-5">
                            <div id="ember3454" className="ember-view">
                              <div className="ac-box ">
                                <CustomSelect
                                  value={tdsTax}
                                  onChange={(e) => handleTdsTaxAccount(e)}
                                  options={tdsKeyValue}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {forBillUse ? (
                          <div className="form-group row">
                            <label className="col-form-label col-lg-2 required">
                              Amount Withheld
                            </label>
                            <div className="col-lg-5">
                              <input
                                maxLength={50}
                                id="ember729"
                                className="ember-text-field ember-view form-control"
                                type="text"
                                value={requestData.withHeldAmount}
                                onChange={(e) =>
                                  setRequestData({
                                    ...requestData,
                                    withHeldAmount: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {!forBillUse ? (
                      <>
                        <table
                          className="table line-item-table col-8"
                          style={{ paddingTop: "15px", paddingBottom: "30px" }}
                        >
                          <thead>
                            <tr className="line-item-header">
                              <th>Date</th>
                              <th>Bill Number</th>
                              <th>Bill Amount</th>
                              <th> Amount Due</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{billDetails.billDate}</td>
                              <td>{billDetails.billNo}</td>
                              <td>{billDetails.totalAmount}</td>
                              <td>{due}</td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      className="form-group"
                      style={{ paddingTop: "15px", paddingBottom: "30px" }}
                    >
                      <label>Notes (For Internal use.)</label>
                      <textarea
                        rows={2}
                        id="ember738"
                        className="ember-text-area ember-view form-control col-lg-7"
                        value={requestData.notes}
                        onChange={(e) =>
                          setRequestData({
                            ...requestData,
                            notes: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="btn-toolbar  fill-container">
                      <button
                        className="btn btn-primary ember-view"
                        type="button"
                        style={{ marginLeft: "15px" }}
                        onClick={handleSalesPayment}
                      >
                        Save
                      </button>

                      <Link to={"/inventory/bills"}>
                        <button
                          className="btn btn-light "
                          type="button"
                          data-ember-action=""
                          data-ember-action-598="598"
                        >
                          Cancel
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        show={modalSwitches.paymentModal}
        onHide={() =>
          setModalSwitches({
            ...modalSwitches,
            paymentModal: false,
          })
        }
        size="sm"
        style={{ marginTop: "-30px" }}
      >
        <Modal.Header>
          <Modal.Title>
            <h4 className="modal-title">Add New Payment Mode</h4>
            <button
              className="close no-outline"
              type="button"
              data-ember-action=""
              data-ember-action-2136="2136"
              onClick={() =>
                setModalSwitches({
                  ...modalSwitches,
                  countryModal: false,
                })
              }
              style={{ marginLeft: "640px", marginTop: "-30px" }}
            >
              ×
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body sales-person">
            <div class="row">
              <div class="col-lg-12 form-group">
                <label class="required">Payment Title</label>
                <input
                  id="ember868"
                  class="ember-text-field ember-view form-control"
                  type="text"
                  value={paymentModeTitle}
                  onChange={(e) => setPaymentModeTitle(e.target.value)}
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 btn-toolbar">
                <button
                  id="ember870"
                  class="btn btn-info ember-view btn-sm"
                  type="submit"
                  onClick={handleNewPaymentMode}
                >
                  Save
                </button>
                <button
                  class="btn btn-light btn-sm"
                  type="button"
                  data-ember-action=""
                  data-ember-action-871="871"
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    setModalSwitches({
                      ...modalSwitches,
                      paymentModal: false,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default PayPurchase;
