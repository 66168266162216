import React, { useState, useEffect } from "react";
import Select from "react-select";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {
  getPaymentModeKeyValueList,
  addPaymentMode,
  updateRecordPayment,
  getCustomerKeyValuePair,
  getTdsKeyValuePair,
  getAccountKeyValueListByEnumString,
  getInvoiceDetailsByUUID,
} from "../../services/index";
import { Link, useHistory } from "react-router-dom";
import CustomSelect from "../SharedComponents/CustomSelect";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";

const SalesPayment = (props) => {
  let paymentId = props.match.params.id;
  console.log(paymentId, "paymentId");
  const history = useHistory();

  const [salesAccountKeyValue, setSalesAccountKeyValue] = useState([]);

  const [salesAccount, setSalesAccount] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [packageDetails, setPackageDetails] = useState("");
  const [packageId, setPackageId] = useState("");
  const [newCustomer, setNewCustomer] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");

  const [totalAmount, setTotalAmount] = useState("");

  const { forInvoiceUse, data } = props;
  const [customerOption, setCustomerOption] = useState(null);
  const [back, setBack] = useState(null);
  const [paymentDate, setPayentDate] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [amountReceived, setAmountReceived] = useState(null);
  const [tdsKeyValue, setTdsKeyValue] = useState(null);
  const [tdsTax, setTdsTax] = useState(null);
  const [withHeldAmount, setWithHeldAmount] = useState(0);
  const [paymentModeKeyValue, setPaymentModeKeyValue] = useState(null);
  const [paymentMode, setPaymentMode] = useState(null);
  const [paymentModeTitle, setPaymentModeTitle] = useState(null);
  const [accountKeyValueList, setAccountKeyValueList] = useState([]);
  const [accountKeyValue, setAccountKeyValue] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [otherKeyValue, setOtherKeyValue] = useState(null);
  const [modalSwitches, setModalSwitches] = useState({
    paymentModal: false,
  });

  const [requestData, setRequestData] = useState({
    taxType: false,
    withHeldAmount: "",
    bankCharges: 0,
    refferences: "",
    notes: "",
  });

  const handleTaxType = (e) => {
    setRequestData({
      ...requestData,
      taxType: e.target.value,
    });
  };

  const handleSelectCustomerName = (e) => {
    setCustomerName(e);
  };

  const handleTdsTaxAccount = (e) => {
    setTdsTax(e);
  };

  const handlePaymentMode = (e) => {
    setPaymentMode(e);
  };

  const handleDepositeAccount = (e) => {
    setAccountKeyValue(e);
    if (e.label === "Petty Cash" || e.label === "UnDeposited Funds") {
      setAccountType("Cash");
    } else {
      setAccountType("OtherCurrentLiability");
    }
  };

  const handleNewPaymentMode = () => {
    let request = {
      title: paymentModeTitle,
    };
    addPaymentMode(request)
      .then((res) => {
        if (res.errorCode == 0) {
          toast("👍" + res.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setModalSwitches({
            ...modalSwitches,
            paymentModal: false,
          });
          getPaymentModeList();
        } else {
          toast("👎" + res.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("👎" + err, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSalesPayment = () => {
    let request = {
      customerTitle: invoiceDetails.customerTitle, //done
      customerId: invoiceDetails.customerId, //done
      invoiceId: invoiceDetails.recordId, //done
      paymentDate: invoiceDate, //done
      // amountReceived: totalAmount,
      // subham
      amountReceived: invoiceDetails.amountDue.toFixed(2),

      tdsId: tdsTax?.value, //done
      tdsTitle: tdsTax?.label, //done
      isTdsDeducted: requestData?.taxType === "true", //done

      paymentModeId: paymentMode?.value,
      paymentModeTitle: paymentMode?.label,
      thankYouNote: requestData?.notes, //done

      customerEmail: invoiceDetails?.customerEmail,
      customerPan: invoiceDetails?.customerPan,

      accountId: salesAccount?.value,
      accountTitle: salesAccount?.label,
      accountTypeEnum: salesAccount?.label,
    };

    if (request.paymentDate === null || request.paymentDate === undefined) {
      toast.warn("Please Select Payment Date First !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (request.paymentModeId == null || request.paymentModeId === undefined) {
      toast.warn("Please Select Payment Mode !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (request.accountTypeEnum === null || customerName == undefined) {
      toast.warn("Please Deposite Account !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    updateRecordPayment(invoiceDetails.recordId, request)
      .then((res) => {
        if (res.errorCode == 0) {
          toast("👍" + res.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push("/inventory/paymentlist");
          }, 200);
        } else {
          toast("👎" + res.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("👎" + err, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  //---------
  const CallBasicApi = () => {
    getCustomerKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setCustomerOption(options);
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    getTdsKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setTdsKeyValue(options);
      }
    });

    getAccountKeyValueListByEnumString("Cash").then((res) => {
      if (res.errorCode == 0) {
        res.responsePacket.map((d) => {
          let dict = {
            value: d.key,
            label: d.value,
          };
          accountKeyValueList.push(dict);
        });
      }
    });

    getAccountKeyValueListByEnumString("OtherCurrentLiability").then((res) => {
      if (res.errorCode == 0) {
        res.responsePacket.map((d) => {
          let dict = {
            value: d.key,
            label: d.value,
          };
          accountKeyValueList.push(dict);
        });
      }
    });
    getPaymentModeList();
    // if (globalTemp2.length != null || globalTemp2.length != 0) {
    //     setAccountKeyValueList([...globalTemp2, ...globalTemp1])
    // }
  };

  const getPaymentModeList = () => {
    getPaymentModeKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setPaymentModeKeyValue(options);
      } else {
        toast("👎" + res.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const setDefaultData = () => {
    // if (data != null) {
    let dict = {
      value: data.customerId,
      label: data.customerTitle,
    };
    // setCustomerName(dict);
    setAmountReceived(data.amountDue.toFixed(2));
  };

  const GetInvoiceDetails = (id) => {
    if (id != null) {
      getInvoiceDetailsByUUID(id).then((res) => {
        if (res.errorCode == 0) {
          setInvoiceDetails(res.responsePacket);

          setCustomerName(res.responsePacket.customerTitle);
          setInvoiceNo(res.responsePacket.invoiceNo);
          setInvoiceDate(res.responsePacket.invoiceDate);
          setTotalAmount(res.responsePacket.totalAmount.toFixed(2));
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (forInvoiceUse) {
      setBack("/inventory/invoices");
      setCustomerDetails(data);
      setDefaultData();
    } else {
      setBack("/inventory/SalesPayment");
    }

    GetInvoiceDetails(paymentId);
    CallBasicApi();
  }, []);

  if (salesAccountKeyValue.length == 0) {
    getAccountKeyValueListByEnumString("Income").then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setSalesAccountKeyValue(options);
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  return (
    <>
      <div className=" content-column txn-creation-column">
        <div id="ember716" className="ember-view">
          <div className="scroll-y noscroll-x fill body scrollbox">
            <div className="fill-container row header-separator" />
            <form>
              {" "}
              <h3>SALES PAYMENT</h3>
              <div className="payment">
                <br />
                <div className="zb-txn-form">
                  <div className="form-group row">
                    <label className="col-form-label col-lg-2 required">
                      Customer Name111
                    </label>
                    <div className="col-lg-5">
                      <Select
                        // isDisabled={forInvoiceUse}
                        isDisabled="true"
                        options={customerOption}
                        placeholder={customerName}
                        onChange={(e) => handleSelectCustomerName(e)}
                        value={customerName}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2 required">
                        Amount Received
                      </label>
                      <div className="col-lg-10">
                        <div className="row">
                          <div className="col-lg-2">
                            <div className="input-group">
                              <input
                                disabled="true"
                                id="ember719"
                                className="ember-text-field text-left ember-view form-control text-left"
                                type="text"
                                // value={amountReceived}
                                value={invoiceDetails.amountDue}
                                onChange={(e) => setTotalAmount(e.target.value)}
                              />
                            </div>

                            <div
                              id="ember720"
                              className="popover-container ember-view"
                            >
                              <div
                                id="ember721"
                                className="d-none popover bs-popover-bottom ember-view pop-over-div"
                              ></div>
                            </div>
                          </div>
                          <label className="col-form-label col-lg-2 px-0">
                            Bank Charges
                          </label>
                          <div className="col-lg-2">
                            <input
                              id="ember722"
                              className="ember-text-field text-left ember-view form-control"
                              type="text"
                              value={requestData.bankCharges}
                              onChange={(e) =>
                                setRequestData({
                                  ...requestData,
                                  bankCharges: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label col-lg-2 required">
                        Payment Date
                      </label>
                      <div className="col-lg-5">
                        <input
                          id="ember723"
                          className="ember-text-field zf-date-picker date-picker ember-view form-control"
                          type="date"
                          onChange={(e) => setInvoiceDate(e.target.value)}
                          value={invoiceDate}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2 required">
                        Payment No.
                      </label>

                      <div className="col-lg-5">
                        <input
                          disabled
                          id="ember724"
                          className="ember-text-field ember-view form-control"
                          type="text"
                          placeholder="(Auto Generated)"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2">
                        Payment Mode
                      </label>
                      <div className="col-lg-5">
                        <div id="ember726" className="ember-view">
                          <div className="ac-box ">
                            <CustomSelect
                              options={paymentModeKeyValue}
                              value={paymentMode}
                              onChange={(e) => handlePaymentMode(e)}
                              changeOptionsData={() =>
                                setModalSwitches({
                                  ...modalSwitches,
                                  paymentModal: true,
                                })
                              }
                              fetchingData={false}
                              type="paymentMode"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label col-lg-2">
                        Reference#
                      </label>
                      <div className="col-lg-5">
                        <input
                          maxLength={50}
                          id="ember729"
                          className="ember-text-field ember-view form-control"
                          type="text"
                          value={requestData.refferences}
                          onChange={(e) =>
                            setRequestData({
                              ...requestData,
                              refferences: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label col-lg-2">
                        Tax deducted?
                      </label>
                      <div className="col-lg-10 align-self-center">
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="row-radio-buttons-group"
                          checked={requestData.taxType}
                          value={requestData.taxType}
                          onChange={handleTaxType}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio size="small" />}
                            label="No Tax deducted"
                          />
                          <FormControlLabel
                            value={true}
                            control={<Radio size="small" />}
                            label="Yes, TDS (Income Tax)"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    {requestData.taxType == "true" ? (
                      <>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-2 required">
                            TDS Tax Account
                          </label>{" "}
                          <div className="col-lg-5">
                            <div id="ember3454" className="ember-view">
                              <div className="ac-box ">
                                <CustomSelect
                                  value={tdsTax}
                                  onChange={(e) => handleTdsTaxAccount(e)}
                                  options={tdsKeyValue}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {forInvoiceUse ? (
                          <div className="form-group row">
                            <label className="col-form-label col-lg-2 required">
                              Amount Withheld
                            </label>
                            <div className="col-lg-5">
                              <input
                                maxLength={50}
                                id="ember729"
                                className="ember-text-field ember-view form-control"
                                type="text"
                                value={requestData.withHeldAmount}
                                onChange={(e) =>
                                  setRequestData({
                                    ...requestData,
                                    withHeldAmount: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {!forInvoiceUse ? (
                      <>
                        <table
                          className="table line-item-table col-8"
                          style={{ paddingTop: "15px", paddingBottom: "30px" }}
                        >
                          <thead>
                            <tr className="line-item-header">
                              <th>Date</th>
                              <th>Invoice Number</th>
                              <th>Invoice Amount</th>
                              <th> Amount Due</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{invoiceDetails.invoiceDate}</td>
                              <td>{invoiceDetails.invoiceNo}</td>
                              <td>{invoiceDetails.totalAmount}</td>
                              <td>{invoiceDetails.amountDue}</td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      className="form-group"
                      style={{ paddingTop: "15px", paddingBottom: "30px" }}
                    >
                      <label>Notes (For Internal use.)</label>
                      <textarea
                        rows={2}
                        id="ember738"
                        className="ember-text-area ember-view form-control col-7"
                        value={requestData.notes}
                        onChange={(e) =>
                          setRequestData({
                            ...requestData,
                            notes: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="btn-toolbar  fill-container">
                      <button
                        className="btn btn-primary ember-view"
                        type="button"
                        style={{ marginLeft: "15px" }}
                        onClick={handleSalesPayment}
                      >
                        Save
                      </button>

                      <Link to={"/inventory/invoices"}>
                        <button
                          className="btn btn-light "
                          type="button"
                          data-ember-action=""
                          data-ember-action-598="598"
                        >
                          Cancel
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        show={modalSwitches.paymentModal}
        onHide={() =>
          setModalSwitches({
            ...modalSwitches,
            paymentModal: false,
          })
        }
        size="sm"
        style={{ marginTop: "-30px" }}
      >
        <Modal.Body>
          <div className="modal-body sales-person">
            <div class="row">
              <div class="col-lg-12 form-group">
                <label class="required">Payment Title</label>
                <input
                  id="ember868"
                  class="ember-text-field ember-view form-control"
                  type="text"
                  value={paymentModeTitle}
                  onChange={(e) => setPaymentModeTitle(e.target.value)}
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 btn-toolbar">
                <button
                  id="ember870"
                  className="btn btn-primary"
                  onClick={handleNewPaymentMode}
                >
                  Save
                </button>
                <button
                  class="btn btn-light btn-sm"
                  type="button"
                  data-ember-action=""
                  data-ember-action-871="871"
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    setModalSwitches({
                      ...modalSwitches,
                      paymentModal: false,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default SalesPayment;
