import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  getCustomerList,
  uploadCustomerExcel,
  urlMappingUUID1,
  activeDeactiveCustomer,
} from "../../services/index";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  BASE_URL_INVENTORY,
  DOWNLOAD_CUSTOMER_URL,
} from "../../services/CONSTANTS";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";

// const Export = ({ onExport }) => (
//   <button
//     className="btn btn-primary ember-view mr-2"
//     onClick={(e) => onExport(e.target.value)}
//   >
//     Export
//   </button>
// );

const CustomerList = () => {
  const [error, setError] = useState({});

  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const [file1, setFile1] = useState({
    fileBase64: "",
    fileName: "",
  });
  const [downloadItemExcel1, setDownloadItemExcel1] = useState({});
  const [issuanceOptions, setIssuanceOptions] = useState([]);
  const [warehouseName, setWarehouseName] = useState([]);
  const [downloadVendorExcel1, setDownloadVendorExcel1] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;

  let history = useHistory();

  // function convertArrayOfObjectsToCSV(array) {
  //   let result;

  //   const columnDelimiter = ",";
  //   const lineDelimiter = "\n";
  //   const keys = Object.keys(filteredData[0]);

  //   result = "";
  //   result += keys.join(columnDelimiter);
  //   result += lineDelimiter;

  //   array.forEach((item) => {
  //     let ctr = 0;
  //     keys.forEach((key) => {
  //       if (ctr > 0) result += columnDelimiter;

  //       result += item[key];
  //       // eslint-disable-next-line no-plusplus
  //       ctr++;
  //     });
  //     result += lineDelimiter;
  //   });

  //   return result;
  // }

  // function downloadCSV(array) {
  //   const link = document.createElement("a");
  //   let csv = convertArrayOfObjectsToCSV(array);
  //   if (csv == null) return;

  //   const filename = "export.csv";

  //   if (!csv.match(/^data:text\/csv/i)) {
  //     csv = `data:text/csv;charset=utf-8,${csv}`;
  //   }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();
  // }

  useEffect(() => {
    customerAllRecords();
    console.log(refresh, "refresh");
  }, [refresh]);

  const customerAllRecords = () => {
    var request = {
      page: 0,
      search: "",
      size: -1,
    };
    getCustomerList(request).then((res) => {
      if (res.errorCode == 0) {
        res.responsePacket.data.reverse();
        setRows(res.responsePacket.data);
        setFilteredData(res.responsePacket.data);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  useEffect(() => {
    const result =
      rows &&
      rows.filter((data) => {
        return data?.title?.toLowerCase().match(search.toLowerCase());
      });
    setFilteredData(result);
  }, [search]);

  const handleCustomSelect = (e) => {
    // alert(e)
    history.push("/inventory/contacts/" + e);
  };

  const handleActiveInactive = (row) => {
    console.log(row, "row");
    activeDeactiveCustomer(row.recordId).then((res) => {
      if (res.errorCode == 0) {
        setRefresh(!refresh);
        console.log(res, "res");
        toast(res.message, { autoClose: 2000 });
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };

  const columns = [
    {
      name: "NAME",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
    },
    {
      name: "WORK PHONE",
      selector: (row) => row.phone,
    },
    {
      name: "EDIT",
      selector: (row) => (
        <div>
          <Link>
            <EditIcon
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => {
                history.push("/inventory/contacts/" + row.recordId + "/edit");
              }}
            />
          </Link>
          &nbsp;&nbsp;
          {row.active ? (
            <CheckCircleIcon
              style={{
                fontSize: "15px",
                cursor: "pointer",
                color: "greenyellow",
              }}
              onClick={() => handleActiveInactive(row)}
            />
          ) : (
            <CancelIcon
              style={{ fontSize: "15px", cursor: "pointer", color: "tomato" }}
              onClick={() => handleActiveInactive(row)}
            />
          )}
        </div>
      )
    }
  ];

  //const baseUrl = `${BASE_URL_INVENTORY}` + `${DownloadItemExcel_URL}` + warehouseId;

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    const base = base64.split(",")[1];
    setFile1({
      fileBase64: base,
      fileName: file.name,
    });
  };

  const handleUpload = () => {
    uploadCustomerExcel(file1).then((res) => {
      if (res.errorCode == 0) {
        customerAllRecords();
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setError(res);
        setShow2(true);
      }
    });
    setTimeout(handleClose(), 6000);
  };

  const [userData, setUserData] = useState("");
  const [urlList, setUrlList] = useState("");
  const [userRights, setUserRights] = useState("");

  useEffect(() => {
    let loginData1 = new Cookies().get("loginData1");
    setUserData(new Cookies().get("loginData1"));
    let id = loginData1.recordId;

    urlMappingUUID1(id).then((res) => {
      if (res.errorCode == 0) {
        setUrlList(res.responsePacket.urlList);
        setUserRights(res.responsePacket);
      } else {
        toast("🙁" + res.message);
      }
    });
  }, []);

  const handleWarn = () => {
    alert("Access Denied!");
    return;
  };

  return (
    <>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Body>
          <div>{error.message}</div>
          <br />
          {error.listOfErrors &&
            error.listOfErrors?.map((item) => {
              return <div>{item}</div>;
            })}
        </Modal.Body>
      </Modal>
      <div className="fill list-header d-print-none">
        <Modal show={show} onHide={handleClose} size="sm">
          <Modal.Body>
           

            <input
              className="ember-text-field ember-view form-control "
              id="originalFileName"
              type="file"
              inputProps={{
                accept: "image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt",
              }}
              required
              label="Document"
              name="originalFileName"
              onChange={(e) => handleFileRead(e)}
              size="small"
              variant="standard"
            />
          </Modal.Body>

          <Modal.Footer>
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
            (urlList &&
              urlList.some(
                (data) => data.url === "/customer/downloadCustomerExcel"
              )) ? (
              <a
                href={`${BASE_URL_INVENTORY}` + `${DOWNLOAD_CUSTOMER_URL}`}
                target="_blank"
                download
              >
                <Button variant="primary" >
                <DownloadIcon /> Download
              </Button>
           
              </a>
            ) : (
              <Button variant="secondary" onClick={handleWarn}>
                <DownloadIcon /> Download
              </Button>
            )}

            {/* <a
              href={`${BASE_URL_INVENTORY}` + `${DOWNLOAD_CUSTOMER_URL}`}
              target="_blank"
              download
            >
              <DownloadIcon /> Customer List
            </a> */}
            {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
            (urlList &&
              urlList.some(
                (data) => data.url === "/customer/uploadPriceExcel/"
              )) ? (
              <Button variant="primary" onClick={handleUpload}>
               <UploadIcon/> Upload
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleWarn}>
              <UploadIcon/>  Upload
              </Button>
            )}
            {/* <Button variant="primary" onClick={handleUpload}>
              Upload
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
      <div style={{ height: 500, width: "100%" }}>
        <DataTable
          title="All Customers"
          columns={columns}
          data={filteredData}
          theme="light"
          pagination
          // defaultSortAsc={false}
          // defaultSortFieldId={1}
          fixedHeader
          pointerOnHover={true}
          highlightOnHover={true}
          paginationPerPage={countPerPage}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          onChangePage={(page) => setPage(page)}
          onRowClicked={(data) => {
            handleCustomSelect(data.recordId);
          }}
          subHeader
          subHeaderComponent={
            <>
              {" "}
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control col-2"
                type="text"
                placeholder={"Search here"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ margin: "10px" }}
              />
              <Button
                variant="primary"
                onClick={handleShow}
                className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
              >
                <ImportExportIcon /> Excel
              </Button>
              <Link to="/inventory/contacts/new">
                <Button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                >
                  <AddIcon />
                  New
                </Button>
              </Link>
            </>
          }
          subHeaderAlign="right"
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default CustomerList;
