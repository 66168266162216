import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  getOrgList,
  getOrgById,
  getOrgUUID,
  addOrganisationProfile,
  getOrgKeyValue,
  updateOrgList,
  getCountryKeyValuePair,
  getStateKeyValue,
} from "../../../services/index";
import Select from "react-select";
import CircleIcon from "@mui/icons-material/Circle";
import { toast, ToastContainer } from "react-toastify";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Checkbox from "@mui/material/Checkbox";
const OrgList = (props) => {
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [isDefault, setIsDefault] = useState(false);
  const [success, setSuccess] = useState(false);
  const [recId, setRecId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setName("");
    setPhone("");
    setEmail("");
    setShow(false);
    setRecId("");
  };

  const [stateOptions, setStateOptions] = useState("");
  const [countryOptions, setCountryOptions] = useState("");

  const [orgInfo, setOrgInfo] = useState({
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
    addressType: "",
    gstNumber: "06AADCL7418D1AA",
    fssaiNumber: "10821001000888",
  });

  console.log(orgInfo, "orgInfo");

  const columns = [
    {
      name: "TITLE",
      selector: (row) => row.organisationTitle?.toUpperCase(),
      sortable: true,
    },

    {
      name: "EMAIL",
      selector: (row) => row.defaultEmail,
    },

    {
      name: "PHONE",
      selector: (row) => row.defaultContactNo,
    },
    {
      name: "DEFAULT",
      selector: (row) =>
        row.isDefault ? (
          <CircleIcon style={{ fontSize: "10px", color: "greenyellow" }} />
        ) : (
          <CircleIcon style={{ fontSize: "10px" }} />
        ),
    },
    // {
    //   name: "EDIT",
    //   selector: (row) => (
    //     <div>
    //       <Link>
    //         <EditIcon
    //           style={{ fontSize: "15px", cursor: "pointer" }}
    //           onClick={() => {
    //             handleClicked(row.recordLongId,row.recordId);
    //           }}
    //         />
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    getCountryKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setCountryOptions(options);
      } else {
        toast(res.message);
      }
    });

    getStateKeyValue().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setStateOptions(options);
      } else {
        toast(res.message);
      }
    });
  }, []);

  useEffect(() => {
    var request = {
      page: 0,
      search: "",
      size: -1,
    };
    getOrgList(request).then((res) => {
      if (res.errorCode == 0) {
        setRows(res.responsePacket);
        setFilteredData(res.responsePacket);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }, [success]);

  useEffect(() => {
    const result = rows.filter((data) => {
      return (
        data.organisationTitle.toLowerCase().match(search.toLowerCase()) ||
        data.defaultEmail.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  const handleSave = () => {
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      alert("Please provide valid Mail");
      return;
    }

    if (name == "") {
      alert("Please fill title");
      return;
    }
    if (phone == "") {
      alert("Please fill phone ");
      return;
    }
    if (String(phone).length != 10) {
      alert("Please fill 10 digit phone ");
      return;
    }

    if (email == "") {
      alert("Please fill email ");
      return;
    }

    if (name == "") {
      alert("Please fill title");
      return;
    }

    const data1 = {
      defaultContactNo: Number(phone),
      defaultEmail: email,
      organisationTitle: name,
      gstNumber: orgInfo.gstNumber,
      fssaiNumber: orgInfo.fssaiNumber,
      beanAddress: {
        addressLine1: orgInfo.addressLine1,
        addressLine2: orgInfo.addressLine2,
        city: orgInfo.city,
        country: orgInfo.country.label,
        pinCode: orgInfo.pinCode,
        state: orgInfo.state.label,
        addressType: "Organisation",
      },
    };
    if (recId) {
      data1.isDefault = isDefault;
      data1.recordId = recId.uuid;
      data1.recordLongId = recId.id;
    }
    recId
      ? updateOrgList(recId.uuid, data1).then((res) => {
          if (res.errorCode == 0) {
            toast("👏" + "Record Updated", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setSuccess(!success);
            handleClose();
            setEmail("");
            setPhone("");
            setName("");
          } else {
            toast(res.message, { autoClose: 2000 });
            return;
          }
        })
      : addOrganisationProfile(data1).then((res) => {
          if (res.errorCode == 0) {
            toast("👏" + "Record added", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setSuccess(!success);
            handleClose();

            setEmail("");
            setPhone("");
            setName("");
          } else {
            toast(res.message, { autoClose: 2000 });
            return;
          }
        });
  };

  const handleClicked = (id, uuid) => {
    setRecId({ id: id, uuid: uuid });
    getOrgById(id).then((res) => {
      if (res.errorCode == 0) {
        var d = res.responsePacket;
        var c = res.responsePacket?.beanAddress;
        setName(d.organisationTitle);
        setPhone(d.defaultContactNo);
        setEmail(d.defaultEmail);
        setIsDefault(d.isDefault);
        if (d.beanAddress) {
          let state = { value: c.state, label: c.state };
          let country = { value: c.country, label: c.country };
          setOrgInfo({
            ...orgInfo,
            addressLine1: c.addressLine1,
            addressLine2: c.addressLine2,
            country: country,
            state: state,
            city: c.city,
            pinCode: c.pinCode,
            addressType: "Organisation",
            fssaiNumber: d.fssaiNumber,
            gstNumber: d.gstNumber,
          });
        }
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });

    setShow(true);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          Organization Details
          <hr />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <table>
              <tr>
                <td style={{ width: "100px" }}>Name:</td>
                <td>
                  {" "}
                  <input
                    // disabled={recId ? true : false}
                    className="ember-text-field ember-view form-control"
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                  />
                </td>
              </tr>

              <tr>
                <td>Phone:</td>
                <td>
                  <input
                    // disabled={recId ? true : false}
                    className="ember-text-field ember-view form-control"
                    type="number"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    value={phone}
                  />
                </td>
              </tr>

              <tr>
                <td>Email:</td>
                <td>
                  <input
                    // disabled={recId ? true : false}
                    className="ember-text-field ember-view form-control"
                    type="text"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                </td>
              </tr>

              <tr>
                <td>gstNumber:</td>
                <td>
                  <input
                    // disabled={recId ? true : false}
                    className="form-control"
                    value={orgInfo.gstNumber}
                    onChange={(e) => {
                      setOrgInfo({ ...orgInfo, gstNumber: e.target.value });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>fssaiNumber:</td>
                <td>
                  <input
                    // disabled={recId ? true : false}
                    className="form-control"
                    value={orgInfo.fssaiNumber}
                    onChange={(e) => {
                      setOrgInfo({ ...orgInfo, fssaiNumber: e.target.value });
                    }}
                  />
                </td>
              </tr>

              {recId ? (
                <tr>
                  <td>isDefault:</td>
                  <td>
                    <Checkbox
                      onChange={(e) => {
                        setIsDefault(e.target.checked);
                      }}
                      checked={isDefault}
                    />
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </table>
            <table>
              <tr>
                <td>addressLine1:</td>
                <td>
                  <input
                    // disabled={recId ? true : false}
                    className="ember-text-field ember-view form-control"
                    onChange={(e) => {
                      setOrgInfo({ ...orgInfo, addressLine1: e.target.value });
                    }}
                    value={orgInfo.addressLine1}
                  />
                </td>
              </tr>
              <tr>
                <td>addressLine2:</td>
                <td>
                  <input
                    // disabled={recId ? true : false}
                    className="ember-text-field ember-view form-control"
                    onChange={(e) => {
                      setOrgInfo({ ...orgInfo, addressLine2: e.target.value });
                    }}
                    value={orgInfo.addressLine2}
                  />
                </td>
              </tr>
              <tr>
                <td>city:</td>
                <td>
                  <input
                    // disabled={recId ? true : false}
                    className="ember-text-field ember-view form-control"
                    onChange={(e) => {
                      setOrgInfo({ ...orgInfo, city: e.target.value });
                    }}
                    value={orgInfo.city}
                  />
                </td>
              </tr>
              <tr>
                <td>state:</td>
                <td>
                  <Select
                    // isDisabled={recId ? true : false}
                    options={stateOptions}
                    onChange={(e) => {
                      setOrgInfo({ ...orgInfo, state: e });
                    }}
                    value={orgInfo.state}
                  />
                </td>
              </tr>
              <tr>
                <td>country:</td>
                <td>
                  <Select
                    // isDisabled={recId ? true : false}
                    options={countryOptions}
                    onChange={(e) => {
                      setOrgInfo({ ...orgInfo, country: e });
                    }}
                    value={orgInfo.country}
                  />
                </td>
              </tr>

              <tr>
                <td>pinCode:</td>
                <td>
                  <input
                    // disabled={recId ? true : false}
                    className="ember-text-field ember-view form-control"
                    onChange={(e) => {
                      setOrgInfo({ ...orgInfo, pinCode: e.target.value });
                    }}
                    value={orgInfo.pinCode}
                  />
                </td>
              </tr>
            </table>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ height: 500, width: "100%" }}>
        <DataTable
          title="All Organization"
          defaultSortAsc={false}
          defaultSortFieldId={1}
          columns={columns}
          data={filteredData}
          theme="light"
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          highlightOnHover={true}
          onRowClicked={(row) => handleClicked(row.recordLongId, row.recordId)}
          subHeader
          subHeaderComponent={
            <>
              {" "}
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control col-2"
                type="text"
                placeholder={"Search here"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ margin: "10px" }}
              />
              <button
                id="ember5839"
                className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                type="button"
                onClick={handleShow}
              >
                <AddIcon />
                New
              </button>
            </>
          }
          subHeaderAlign="right"
        />
      </div>

      <ToastContainer />
    </>
  );
};

export default OrgList;
