import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Header } from "antd/lib/layout/layout";
import PreferencesNav from "../Preferences/PreferencesNav";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AddIcon from "@mui/icons-material/Add";
import { BASE_URL_INVENTORY } from "../../../services/CONSTANTS";
import DownloadIcon from "@mui/icons-material/Download";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import {
  getHSNList,
  addHSN,
  uploadHsnExcel,
  downloadHsnExcel,
  uploadCustomerExcel,
} from "../../../services/index";

const HsnList = () => {
  let history = useHistory();
  const [error, setError] = useState({});
  const [file1, setFile1] = useState({
    fileBase64: "",
    fileName: "",
  });
  const [taxInfo, setTaxInfo] = useState("");
  const [note, setNote] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setShow1(false);
  };
  const handleShow1 = () => {
    setShow1(true);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setTaxInfo("");
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const columns = [
    {
      name: "HSN/SAC",
      cell: (row) => row.hsnSac,
      sortable: true,
    },
    {
      name: "NOTE",
      cell: (row) => row.description,
      sortable: true,
    },
  ];

  const handleSave = () => {
    if (taxInfo == "") {
      toast("Please fill hsnSac");
      return;
    }

    const data = {
      description: note,
      hsnSac: taxInfo,
    };
    addHSN(data).then((res) => {
      if (res.errorCode == 0) {
        toast(res.message);
        getHSNrecords();
        handleClose();
      } else {
        toast(res.message);
        return;
      }
    });
  };

  useEffect(() => {
    getHSNrecords();
  }, []);

  const getHSNrecords = () => {
    getHSNList().then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        setFilteredData(temp);
      } else {
        toast(res.data.message, {
          autoClose: 2000,
        });
        return;
      }
    });
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    const base = base64.split(",")[1];
    setFile1({
      fileBase64: base,
      fileName: file.name,
    });
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleUpload = () => {
    uploadHsnExcel(file1).then((res) => {
      if (res.errorCode == 0) {
        getHSNrecords();
        toast(res.message);
      } else {
        setError(res);
        setShow2(true);
      }
    });
    setTimeout(handleClose(), 6000);
  };

  console.log(file1, "file1");

  return (
    <>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Body>
          <div>{error.message}</div>
          <br />
          {error.listOfErrors &&
            error.listOfErrors?.map((item) => {
              return <div>{item}</div>;
            })}
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1} size="sm">
        <Modal.Body>
          <input
            className="ember-text-field ember-view form-control "
            id="originalFileName"
            type="file"
            inputProps={{
              accept: "image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt",
            }}
            required
            label="Document"
            name="originalFileName"
            onChange={(e) => handleFileRead(e)}
            size="small"
            variant="standard"
          />
        </Modal.Body>

        <Modal.Footer>
          <a
            href={`${BASE_URL_INVENTORY}` + `hsn/downloadHsnExcel`}
            target="_blank"
            download
          >
            <Button variant="primary">
              <DownloadIcon /> Hsn List
            </Button>
          </a>

          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Header>HSN Info</Header>
          <hr />

          <table>
            <tr>
              <td>HSN:</td>
              <td>
                {" "}
                <input
                  className="ember-text-field ember-view form-control"
                  type="number"
                  onChange={(e) => {
                    setTaxInfo(e.target.value);
                  }}
                  value={taxInfo}
                />
              </td>
            </tr>
            <tr>
              <td>Note:</td>
              <td>
                {" "}
                <input
                  className="ember-text-field ember-view form-control"
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  value={note}
                />
              </td>
            </tr>
          </table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="column list-column d-print-none column-small">
        <PreferencesNav />
      </div>
      <div className="column content-column">
        <div className="header clearfix" style={{ borderBottom: "none" }}>
          <span className="float-left" style={{ fontSize: "35px" }}>
            HSN List
          </span>
        </div>

        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            columns={columns}
            data={filteredData}
            fixedHeader
            subHeader
            subHeaderComponent={
              <>
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                  onClick={handleShow}
                >
                  <AddIcon />
                  New
                </button>
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                  onClick={() => setShow1(true)}
                >
                  <ImportExportIcon />
                  Export
                </button>
              </>
            }
            subHeaderAlign="right"
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default HsnList;
