import { WAREHOUSE_URL, WAREHOUSEITEM_URL,WAREHOUSE_VENDOR_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
    // 'Authorization': url.Auth_Token
}
// /warehouseVendor/{uuid} get
// /warehouseVendor/addVendorByWarehouseId/{uuid}  put
// /warehouseVendor/getVendorThatNotExistByWarehouseId/{uuid} get

// /warehouseVendor/removeVendorByWarehouseId/{uuid} put

export const getVendorByWare1 = (id) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(WAREHOUSE_VENDOR_URL() + id ,{ headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in removeVendorByWarehouseId axios!");
          });
      } catch (error) {
        console.error("in LoginService > removeVendorByWarehouseId, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };


export const removeVendorByWarehouseId1 = (id,data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(WAREHOUSE_VENDOR_URL() +"removeVendorByWarehouseId/"+ id,data ,{ headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in removeVendorByWarehouseId axios!");
          });
      } catch (error) {
        console.error("in LoginService > removeVendorByWarehouseId, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };

export const addVendorByWarehouseId1 = (id,data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(WAREHOUSE_VENDOR_URL() +"addVendorByWarehouseId/"+ id,data ,{ headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in addVendorByWarehouseId axios!");
          });
      } catch (error) {
        console.error("in LoginService > addVendorByWarehouseId, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };

  export const getVendorNotInWarehouseId1 = (id) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(WAREHOUSE_VENDOR_URL() +"getVendorThatNotExistByWarehouseId/"+ id, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getVendorThatNotExistByWarehouseId axios!");
          });
      } catch (error) {
        console.error("in LoginService > getVendorThatNotExistByWarehouseId, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };


export const getAllActiveWareRecords = (data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(WAREHOUSE_URL() + "getAllActiveRecords/",data, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getAllActiveRecords axios!");
          });
      } catch (error) {
        console.error("in LoginService > getAllActiveRecords, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };

export const getActiveItemsByWarehouseid = (data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(WAREHOUSEITEM_URL() + "getAllActiveRecordsByWarehouseid/",data, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getAllActiveRecordsByWarehouseid axios!");
          });
      } catch (error) {
        console.error("in LoginService > getAllActiveRecordsByWarehouseid, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };



export const activeDeactiveItemByWare = (id,wId) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(WAREHOUSEITEM_URL() + "activeOrDeActiveItemOnWarehouse/"+id+"/"+wId ,id, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in activeOrDeActiveItem axios!");
          });
      } catch (error) {
        console.error("in LoginService > activeOrDeActiveItem, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };


export const getInventorySummary1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(WAREHOUSEITEM_URL()+"getInventorySummery/",data, { headers: headersdata })
                .then((res) => {                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getBelowOrderLevelItemReport axios!");
                });
        } catch (error) {
            console.error("in LoginService > getBelowOrderLevelItemReport, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getBelowOrderLevelItemReport1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(WAREHOUSEITEM_URL()+"getBelowOrderLevelItemReport/",data, { headers: headersdata })
                .then((res) => {                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getBelowOrderLevelItemReport axios!");
                });
        } catch (error) {
            console.error("in LoginService > getBelowOrderLevelItemReport, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const deActiveItem1 = (itemId,warehouseId) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(WAREHOUSEITEM_URL()+"deActiveItem/"+itemId+"/"+warehouseId, { headers: headersdata })
                .then((res) => {                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deActiveItem1 axios!");
                });
        } catch (error) {
            console.error("in LoginService > deActiveItem1, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const activeItem1 = (itemId,warehouseId) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(WAREHOUSEITEM_URL()+"activeItem/"+itemId+"/"+warehouseId, { headers: headersdata })
                .then((res) => {
                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in activeItem1 axios!");
                });
        } catch (error) {
            console.error("in LoginService > activeItem1, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateItemOnWarehouses1 = (itemId,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(WAREHOUSEITEM_URL()+"updateItemOnWarehouses/"+itemId,data, { headers: headersdata })
                .then((res) => {
                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateItemOnWarehouses axios!");
                });
        } catch (error) {
            console.error("in LoginService > updateItemOnWarehouses, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const getItemThatNotExistByWarehouseId1 = (warehouseId) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(WAREHOUSEITEM_URL()+"getItemThatNotExistByWarehouseId/"+warehouseId, { headers: headersdata })
                .then((res) => {
                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addItemByWarehouseId axios!");
                });
        } catch (error) {
            console.error("in LoginService > addItemByWarehouseId, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
}; 


export const addItemByWarehouseId1 = (warehouseId,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(WAREHOUSEITEM_URL()+"addItemByWarehouseId/"+warehouseId,data, { headers: headersdata })
                .then((res) => {
                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addItemByWarehouseId axios!");
                });
        } catch (error) {
            console.error("in LoginService > addItemByWarehouseId, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
}; 
// /warehouseItems/removeItemByWarehouseId/{uuid}
export const removeItemByWarehouseId1 = (warehouseId,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(WAREHOUSEITEM_URL()+"removeItemByWarehouseId/"+warehouseId,data, { headers: headersdata })
                .then((res) => {
                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addItemByWarehouseId axios!");
                });
        } catch (error) {
            console.error("in LoginService > addItemByWarehouseId, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
}; 


export const getAllRecordsByWarehouseid = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(WAREHOUSEITEM_URL() + "getAllRecordsByWarehouseid", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getIssuanceRecords axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const getWareHouseItemDetail = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(WAREHOUSEITEM_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getWareHouseItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getWareHouseItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};




export const getWarehouseByItemId1 = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(WAREHOUSEITEM_URL() + "getRecordsByItemId/"+id, { headers: headersdata })
                .then((res) => {
                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getWarehouseKeyValueList axios!");
                });
        } catch (error) {
            console.error("in LoginService > getWarehouseKeyValueList, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getWarehouseKeyValueDetailsList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(WAREHOUSE_URL() + "getWarehouseKeyValueDetailsList", { headers: headersdata })
                .then((res) => {
                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getWarehouseKeyValueDetailsList axios!");
                });
        } catch (error) {
            console.error("in LoginService > getWarehouseKeyValueList, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getWarehouseKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(WAREHOUSE_URL() + "getKeyValueDetailsList", { headers: headersdata })
                .then((res) => {
                  
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getWarehouseKeyValueList axios!");
                });
        } catch (error) {
            console.error("in LoginService > getWarehouseKeyValueList, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const addNewWarehouse = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(WAREHOUSE_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in newWarehouse axios!");
                });
        } catch (error) {
            console.error("in LoginService > newWarehouse, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getAllWarehouse = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(WAREHOUSE_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getWarehouseDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(WAREHOUSE_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getWarehouseDetails axios!");
                });
        } catch (error) {
            console.error("in LoginService > getWarehouseDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateWarehouseDetails = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(WAREHOUSE_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateWarehouseDetails axios!");
                });
        } catch (error) {
            console.error("in LoginService > updateWarehouseDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteWarehouse = (uuid) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(WAREHOUSE_URL() + uuid, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteWarehouse axios!");
                });
        } catch (error) {
            console.error("in LoginService > deleteWarehouse, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const activeInactiveWarehouse = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(WAREHOUSE_URL() + "activeInactive/" + id, id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in activeInactiveWarehouse axios!");
                });
        } catch (error) {
            console.error("in LoginService > activeInactiveWarehouse, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};