import React from 'react'

const InvoicesValidation = () => {
    return (
        <>
            <div className="scroll-y noscroll-x  body scrollbox">
                <div id="ember27455" className="ember-view" />
                <div className="fill-container">
                    <div className="customization-empty-state domain-mapping-intro">
                        <h4 className="font-xxlarge text-semibold">Create Validation Rules</h4>
                        <p className="text-muted mb-7">
                            Validation Rules helps you to validate the data entered while creating,
                            editing, or converting transactions and to prevent users from performing
                            specific actions.
                        </p>
                        <span className="plan-alert-banner">
                            This feature is unavailable in your plan.
                            <a target="_blank" rel="noopener noreferrer">
                                Upgrade Now
                            </a>
                        </span>
                        <img
                            className="mt-7"
                            alt="No results found"
                            src="https://d12h6dzwzn4m10.cloudfront.net/zbooks/assets/images/validation-rules-empty@1x-6742f36fd59024ab02eecf3a1675389e.png"
                            srcSet="https://d12h6dzwzn4m10.cloudfront.net/zbooks/assets/images/validation-rules-empty@1x-6742f36fd59024ab02eecf3a1675389e.png 1x, https://d12h6dzwzn4m10.cloudfront.net/zbooks/assets/images/validation-rules-empty@2x-e311c80627ca2bb1615b13f011257b7d.png 2x"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoicesValidation