import { useHistory } from "react-router-dom";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import {
  getBrandKeyValue,
  addItem,
  updateItem,
  getItemDetail,
  getUnitKeyValueListByBaseUnit,
  getHsnKeyValuePair,
  getTaxExemptionKeyValuePair,
  getWarehouseKeyValueList,
  getInterStateTaxKeyValueList,
  getIntraStateTaxKeyValueList,
} from "../../services/index";
import toast, { Toaster } from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Modal } from "react-bootstrap";

const ItemNew = (props) => {
  let history = useHistory();
  let itemId = props.match.params.id;

  const [taxExOptions, setTaxExOptions] = useState([]);
  const [intraOptions, setIntraOptions] = useState([]);
  const [interOptions, setInterOptions] = useState([]);
  const [hsnOptions, setHsnOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [weightOptions, setWeightOptions] = useState([]);
  const [measureOptions, setMeasureOptions] = useState([]);
  const [otherOptions, setOtherOptions] = useState([]);

  // "warehouseId": "09200fc5-69dd-4d0c-8da3-8ac42ff79c64",
  // "openingStockQuantity": 0.0,
  // "openingStockValue": 0.0,
  // "currentStockQuantity": 0.0,
  // "warehouseTitle": "Mohra",
  // "reorderPoint": 0

  const tempRow = {
    ware: "",
    reorderPoint: 0,
    openingStockQuantity: 0,
    openingStockValue: 0,
  };
  const [rows, setRows] = useState([tempRow]);
  const [wareOptions, setWareOptions] = useState("");
  const [wareOptions2, setWareOptions2] = useState("");

  const [itemInfo, setItemInfo] = useState({
    type: "Goods",
    isReturnable: false,
    isIngredient: false,
    isFloating: false,
    name: "",
    packUnit: "",
    itemWeight: "",
    itemWeightUnit: "",
    boxWeight: "",
    length: "",
    breadth: "",
    height: "",
    dimensionUnit: "",
    category: "",
    sku: "",
    hsn: "",
    taxPref: "Taxable",
    taxExempt: "",
    intraTax: "",
    interTax: "",
    sellingPrice: 0,
    purchasePrice: 0,
  });

  useEffect(() => {
    if (itemId) {
      getItemDetail(itemId).then((res) => {
        if (res.errorCode == 0) {
          const d = res.responsePacket;

          let abc = d.warehouseItemsBeanList.map((a) => {
            return {
              ware: {
                value: a.warehouseId,
                label: a.warehouseTitle,
              },
              currentStockQuantity: a.currentStockQuantity,
              openingStockValue: a.openingStockValue,
              openingStockQuantity: a.openingStockQuantity,
              reorderPoint: a.reorderPoint,
            };
          });
          setRows(abc);

          setItemInfo({
            type: d.itemTypeEnum,
            isReturnable: d.isReturnable,
            isIngredient: d.isIngredient,
            isFloating: false,
            name: d.title,

            packUnit: { value: d.packUnitId, label: d.packUnitTitle },

            itemWeight: d.itemWeightQuantity,
            itemWeightUnit: { value: d.itemUnitId?d.itemUnitId:"", label: d.itemUnitTitle?d.itemUnitTitle:"" },

            boxWeight: d.weightValue?d.weightValue:"",
            length: d.dimensionLength?d.dimensionLength:"",
            breadth: d.dimensionBreadth?d.dimensionBreadth:"",
            height: d.dimensionHeight?d.dimensionHeight:"",
            dimensionUnit: {
              value: d.dimensionUnitId?d.dimensionUnitId:"",
              label: d.dimensionUnitTitle?d.dimensionUnitTitle:"",
            },
            category: { value: d.brandId, label: d.brandTitle },
            sku: d?.sku?d.sku:"",
            hsn: { value: d.hsnId, label: d.hsnSac },
            taxPref: d.isTaxable ? "Taxable" : "NonTaxable",
            taxExempt: {
              value: d.taxExemptId,
              label: d.taxExemptTitle,
            },
            intraTax: { value: d.taxIdIntrastate, label: d.taxTitleIntrastate },
            interTax: { value: d.taxIdInterstate, label: d.taxTitleInterstate },
            sellingPrice: d.sellingPriceValue?d.sellingPriceValue:0,
            purchasePrice: d.purchasePriceValue?d.purchasePriceValue:0,
          });
        }
      });
    }
    console.log(itemId, "itemId");
  }, [itemId]);

  const handleReorder = (e, i) => {
    const obj = [...rows];
    let qt = Number(e.target.value);
    obj[i].reorderPoint = qt;
    obj[i].openingStockValue = qt * obj[i].openingStockQuantity;
    setRows(obj);
  };

  const handleOpenStock = (e, i) => {
    const obj = [...rows];
    let qt = Number(e.target.value);
    obj[i].openingStockQuantity = qt;

    obj[i].openingStockValue = qt * obj[i].reorderPoint;
    setRows(obj);
  };

  const handleWare = (e, i) => {
    const obj = [...rows];
    obj[i].ware = e;
    setRows(obj);

    var setSend = false;
    var filterOptions = [];
    wareOptions2 &&
      wareOptions2.map((a) => {
        rows &&
          rows.map((b) => {
            if (a.value === b?.ware?.value) {
              setSend = true;
            }
          });
        if (!setSend) {
          filterOptions.push(a);
        }
        setSend = false;
      });
    setWareOptions(filterOptions);
  };

  const handleAddRow = () => {
    setRows([...rows, tempRow]);
  };

  const handleClear = (id, i) => {
    const obj = rows.slice();
    obj.splice(i, 1);
    setRows(obj);
  };

  useEffect(() => {
    getTaxExemptionKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setTaxExOptions(options);
      }
    });

    getWarehouseKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setWareOptions(options);
        setWareOptions2(options);
      }
    });

    getInterStateTaxKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.recordId,
          label: d.title,
        }));
        setInterOptions(options);
      }
    });

    getIntraStateTaxKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.recordId,
          label: d.title,
        }));
        setIntraOptions(options);
      }
    });

    getHsnKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setHsnOptions(options);
      }
    });

    getBrandKeyValue().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setCategoryOptions(options);
      }
    });

    getUnitKeyValueListByBaseUnit("Other").then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setOtherOptions(options);
      }
    });
    getUnitKeyValueListByBaseUnit("Weight").then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setWeightOptions(options);
        console.log(options[0]);
        if (itemId == undefined) {
          setItemInfo({ ...itemInfo, itemWeightUnit: options[0] });
        }
      }
    });
    getUnitKeyValueListByBaseUnit("Measure").then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setMeasureOptions(options);
      }
    });
  }, []);

  const handleData = (e, key) => {
    console.log(e, key, "handleData");
    if (key == "check") {
      setItemInfo({
        ...itemInfo,
        [e.target.name]: e.target.checked,
      });
    } else if (e.value && e.label) {
      setItemInfo({
        ...itemInfo,
        [key]: e,
      });
    } else {
      setItemInfo({
        ...itemInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSave = () => {
    if (itemInfo.name == "") {
      toast("please select item name");
      return;
    }

    if (itemInfo.packUnit == ""||itemInfo.packUnit?.value=="") {
      toast("please select packUnit");
      return;
    }
    if (itemInfo.itemWeight == "") {
      toast("please select itemWeight");
      return;
    }
    if (itemInfo.itemWeightUnit == ""||itemInfo.itemWeightUnit?.value=="") {
      toast("please select itemWeight unit");
      return;
    }
    if (itemInfo.boxWeight == "") {
      toast("please select boxWeight");
      return;
    }

    if (
      (itemInfo.dimensionUnit?.value!="") &&
      (itemInfo.height == 0 || itemInfo.length == 0 || itemInfo.breadth == 0)
    ) {
      toast(" all dimensions should be > 0");
      return;
    }

    if (itemInfo.hsn == "") {
      toast("please select hsn");
      return;
    }
    if (itemInfo.category == "") {
      toast("please select category");
      return;
    }

    if (
      itemInfo.taxPref == "Taxable" &&
      (itemInfo.intraTax == "" || itemInfo.interTax == "")
    ) {
      toast("please select respective Taxes");
      return;
    }

    if (itemInfo.taxPref == "Taxable" && itemInfo.taxExempt == "") {
      toast("please select tax exempt reason");
      return;
    }
    rows.map((a) => {
      a.warehouseId = a.ware.value;
      a.warehouseTitle = a.ware.label;
    });

    let rows2 = rows.filter((a) => a.ware);

    let data = {
      warehouseItemsBeanList: rows2.length == 0 ? [] : rows2,
      title: itemInfo.name,
      itemTypeEnum: itemInfo.type,

      isFloating: itemInfo.isFloating,
      isIngredient: itemInfo.isIngredient,
      isReturnable: itemInfo.isReturnable,

      brandId: itemInfo.category.value,
      brandTitle: itemInfo.category.label,

      dimensionLength: itemInfo.dimensionUnit != "" ? itemInfo.length : "",
      dimensionBreadth: itemInfo.dimensionUnit != "" ? itemInfo.breadth : "",
      dimensionHeight: itemInfo.dimensionUnit != "" ? itemInfo.height : "",
      dimensionUnitId: itemInfo.dimensionUnit?.value?itemInfo.dimensionUnit.value:"",
      dimensionUnitTitle: itemInfo.dimensionUnit?.label?itemInfo.dimensionUnit.label:"",

      hsnId: itemInfo.hsn.value,
      hsnSac: itemInfo.hsn.label,
      sku: itemInfo.sku,
      isPurchase: itemInfo.purchasePrice > 0 ? true : false,
      purchaseAccountTypeEnum: "Expense",
      purchasePriceValue: itemInfo.purchasePrice,
      isSales: itemInfo.sellingPrice > 0 ? true : false,
      sellingAccountTypeEnum: "Income",
      sellingPriceValue: itemInfo.sellingPrice,
      taxExemptId:
        itemInfo.taxPref == "NonTaxable" ? itemInfo.taxExempt.value : "",
      taxExemptTitle:
        itemInfo.taxPref == "NonTaxable" ? itemInfo.taxExempt.label : "",
      taxIdInterstate:
        itemInfo.taxPref == "Taxable" ? itemInfo.interTax.value : "",
      taxIdIntrastate:
        itemInfo.taxPref == "Taxable" ? itemInfo.intraTax.value : "",
      taxTitleInterstate:
        itemInfo.taxPref == "Taxable" ? itemInfo.interTax.label : "",
      taxTitleIntrastate:
        itemInfo.taxPref == "Taxable" ? itemInfo.intraTax.label : "",
      isTaxable: itemInfo.taxPref == "Taxable" ? true : false,
      //box
      weightUnitId: itemInfo.packUnit.value,
      weightUnitTitle: itemInfo.packUnit.label,
      weightValue: itemInfo.boxWeight,
      //pack
      packUnitId: itemInfo.packUnit.value,
      packUnitTitle: itemInfo.packUnit.label,

      //item wt
      itemUnitId: itemInfo.itemWeightUnit.value,
      itemUnitTitle: itemInfo.itemWeightUnit.label,
      itemWeightQuantity: itemInfo.itemWeight,
    };

    console.log(data, "data");

    if (itemId) {
      updateItem(itemId, data).then((res) => {
        if (res.errorCode === 0) {
          toast.success(res.message);

          history.push("/inventory/items");
        } else {
          toast.error(res.message);
          return;
        }
      });
    } else {
      addItem(data).then((res) => {
        if (res.errorCode === 0) {
          toast.success(res.message);

          history.push("/inventory/items");
        } else {
          toast.error(res.message);
          return;
        }
      });
    }
  };
  useEffect(() => {
    console.log(rows, "rows");
    console.log(itemInfo, "itemInfo");
  }, [rows, itemInfo]);

  const handleDimension = (e) => {
    if (e.target.value > 0) {
      setItemInfo({
        ...itemInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      <div
        className="content-column"
        style={{ paddingBottom: "300px", paddingLeft: "15px" }}
      >
        <div className="fill list-header d-flex justify-content-between align-items-center bg-white p-3 border-bottom">
          <h1>
            <strong>
              <RamenDiningIcon /> {itemId ? "Edit Item" : "New Item"}
            </strong>{" "}
          </h1>
        </div>
        <table>
          <tr>
            <td style={{ width: "150px" }}>Type:</td>
            <td>
              {" "}
              <RadioGroup
                row
                name="type"
                value={itemInfo.type}
                onChange={(e) => handleData(e)}
              >
                <FormControlLabel
                  value="Goods"
                  control={<Radio size="small" />}
                  label="Goods"
                />
                <FormControlLabel
                  value="Service"
                  control={<Radio size="small" />}
                  label="Service"
                />
              </RadioGroup>
            </td>
            <td>
              <Checkbox
                size="small"
                name="isReturnable"
                checked={itemInfo.isReturnable}
                onChange={(e) => handleData(e, "check")}
              />
              Returnable{" "}
              <Checkbox
                size="small"
                name="isIngredient"
                checked={itemInfo.isIngredient}
                onChange={(e) => handleData(e, "check")}
              />
              Ingredient{" "}
              <Checkbox
                size="small"
                name="isFloating"
                checked={itemInfo.isFloating}
                onChange={(e) => handleData(e, "check")}
              />
              Floating
            </td>
          </tr>
        </table>

        <table className="requiredTable">
          <tr>
            <td style={{ width: "150px" }}>Name:</td>
            <td>
              <input
                onChange={(e) => handleData(e)}
                name="name"
                value={itemInfo.name}
                className="form-control"
              />
            </td>
          </tr>
          <tr>
            <td style={{ width: "150px" }}>Pack Unit:</td>
            <td>
              <Select
                value={itemInfo.packUnit}
                options={otherOptions}
                onChange={(e) => {
                  handleData(e, "packUnit");
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ width: "150px" }}>Item Wt.:</td>
            <td>
              <input
              type="number"
                onChange={(e) => handleData(e)}
                name="itemWeight"
              min={0}
                value={itemInfo.itemWeight}
                className="form-control"
              />
            </td>
            <td style={{ width: "100px" }}>
              {" "}
              <Select
                value={itemInfo.itemWeightUnit}
                options={weightOptions}
                onChange={(e) => {
                  handleData(e, "itemWeightUnit");
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ width: "150px" }}>Box Wt.:</td>
            <td>
              <input
                onChange={(e) => handleData(e)}
                name="boxWeight"
                value={itemInfo.boxWeight}
                className="form-control"
              />
            </td>
            <td style={{ color: "black" }}>{itemInfo.packUnit.label}</td>
          </tr>

          <tr>
            <td style={{ color: "black" }}>Dimensions</td>
            <td style={{ display: "flex" }}>
              <input
                style={{ width: "70px" }}
                type="number"
              min={1}
                onChange={(e) => handleDimension(e)}
                name="length"
                value={itemInfo.length}
                className="form-control"
              />

              <input
                style={{ width: "70px" }}
                type="number"
                min={1}
                onChange={(e) => handleDimension(e)}
                name="breadth"
                value={itemInfo.breadth}
                className="form-control"
              />

              <input
                style={{ width: "70px" }}
                type="number"
                min={1}
                onChange={(e) => handleDimension(e)}
                name="height"
                value={itemInfo.height}
                className="form-control"
              />
            </td>
            <td>
              <Select
                value={itemInfo.dimensionUnit}
                options={measureOptions}
                onChange={(e) => {
                  handleData(e, "dimensionUnit");
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Category</td>
            <td>
              <Select
                value={itemInfo.category}
                options={categoryOptions}
                onChange={(e) => {
                  handleData(e, "category");
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ color: "black" }}>SKU:</td>
            <td>
              <input
                onChange={(e) => handleData(e)}
                name="sku"
                value={itemInfo.sku}
                className="form-control"
              />
            </td>
          </tr>
          <tr>
            <td>HSN</td>
            <td>
              <Select
                value={itemInfo.hsn}
                options={hsnOptions}
                onChange={(e) => {
                  handleData(e, "hsn");
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Tax Preference:</td>
            <td>
              <RadioGroup
                row
                name="taxPref"
                value={itemInfo.taxPref}
                onChange={(e) => handleData(e)}
              >
                <FormControlLabel
                  value="Taxable"
                  control={<Radio size="small" />}
                  label="Taxable"
                />
                <FormControlLabel
                  value="NonTaxable"
                  control={<Radio size="small" />}
                  label="NonTaxable"
                />
              </RadioGroup>
            </td>
          </tr>
          {itemInfo.taxPref == "Taxable" ? (
            <>
              {" "}
              <tr>
                <td>IntraState Tax</td>
                <td>
                  <Select
                    value={itemInfo.intraTax}
                    options={intraOptions}
                    onChange={(e) => {
                      handleData(e, "intraTax");
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>InterState Tax</td>
                <td>
                  <Select
                    value={itemInfo.interTax}
                    options={interOptions}
                    onChange={(e) => {
                      handleData(e, "interTax");
                    }}
                  />
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>Reason</td>
                <td>
                  <Select
                    value={itemInfo.taxExempt}
                    options={taxExOptions}
                    onChange={(e) => {
                      handleData(e, "taxExempt");
                    }}
                  />
                </td>
              </tr>
            </>
          )}
          <tr>
            <td>SellingPrice:</td>
            <td>
              <input
                className="form-control"
                type="number"
                min={0}
                name="sellingPrice"
                value={itemInfo.sellingPrice}
                onChange={(e) => handleData(e)}
              />
            </td>
          </tr>
          <tr>
            <td>PurchasePrice:</td>
            <td>
              <input
                className="form-control"
                type="number"
                min={0}
                name="purchasePrice"
                value={itemInfo.purchasePrice}
                onChange={(e) => handleData(e)}
              />
            </td>
          </tr>
        </table>

        <br />
        <table className="customTable">
          <tr>
            <td style={{ width: "200px" }}>Warehouse</td>
            <td>Reorder</td>
            <td>Opening Stock</td>
            <td>Opening Stock Value</td>
            <td></td>
          </tr>
          {rows &&
            rows.map((a, i) => {
              return (
                <>
                  <tr>
                    <td style={{ width: "200px" }}>
                      {" "}
                      <Select
                        options={wareOptions}
                        value={a.ware}
                        onChange={(e) => handleWare(e, i)}
                      />
                    </td>

                    <td>
                      {" "}
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        value={a.reorderPoint}
                        onChange={(e) => handleReorder(e, i)}
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        value={a.openingStockQuantity}
                        onChange={(e) => handleOpenStock(e, i)}
                      />
                    </td>
                    <td>
                      <input
                        disabled="true"
                        className="form-control"
                        type="number"
                        min={0}
                        value={a.openingStockValue}
                      />
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClear(a.ware.value, i)}
                    >
                      {rows.length > 1 && <i className="fa fa-times"></i>}
                    </td>
                  </tr>
                </>
              );
            })}
        </table>

        <div>
          <button
            id="ember1426"
            className="btn btn-default addbtn mt-3"
            onClick={handleAddRow}
          >
            <AddIcon style={{ color: "blue" }} /> New Row
          </button>
        </div>

        <br />
        <div>
          <Button variant="contained" onClick={() => handleSave()}>
            Save
          </Button>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default ItemNew;
