import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import { TabContext } from "@mui/lab";
import TabList from '@mui/lab/TabList';
import ItemsGeneral from './ItemsGeneral';
import ItemsFieldCustomizationItems from './ItemsFieldCustomization';

const ItemsComponent = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div>
            <div className="column content-column">
                <div className="header d-flex border-bottom-0">
                    <span className="text-ellipsis" style={{fontSize:'35px'}}> Items</span>
                    <div className="ml-auto" id="preferences-top-menu" />
                </div>
                <Box sx={{ width: '100%', typography: 'body1', marginTop: '27px', marginLeft: '10px' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="General" value="1" />
                                <Tab label="Field Customization" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <ItemsGeneral />
                        </TabPanel>
                        <TabPanel value="2">
                            <ItemsFieldCustomizationItems />
                        </TabPanel>
                    </TabContext>
                </Box>

            </div>

        </div>
    )
}

export default ItemsComponent