import { BATCH_URL } from '../CONSTANTS'
import { PRODUCTION_DETAILS_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}



export const getBatchesList1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(BATCH_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getRecipeList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getBatchesKeyValueList1 = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(BATCH_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSubItemKeyValueList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addBatches1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(BATCH_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addBatches1 axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getBatchesUUID1 = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(BATCH_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getBatchesUUID axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateBatches1 = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(BATCH_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateBatches1 axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getProductionDetailsKeyValue1 = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PRODUCTION_DETAILS_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSubItemKeyValueList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const addProductionDetails1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PRODUCTION_DETAILS_URL() ,data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSubItemKeyValueList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};