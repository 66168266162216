import React from "react";

const ContactUsComponent = (props) => {
  const uploadImage = (files) => {

  };

  return (
    <>
      {props.showContactUs == true ? (
        <div id="ember1714" className="modal-open ember-view">
          <div className="modal-backdrop fade show ">&nbsp;</div>
          <div className="modal fade d-block show">
            <div className="modal-dialog  ">
              <div className="modal-content ">
                <div className="modal-header" style={{ padding: "16px 20px" }}>
                  <div className="form-group row" style={{ margin: 0 }}>
                    <label
                      className="col-form-label col-lg-8"
                      style={{ padding: "3px 0 0" }}
                    >
                      <h3 className="modal-title" style={{ fontSize: "18px" }}>
                        Contact Us
                      </h3>
                    </label>
                    <label className="col-form-label">
                      Responses to this email will be sent to
                      store@pyramidcafe.in.
                    </label>
                  </div>
                </div>
                <form>
                  <div className="modal-body">
                    <div
                      tabIndex={-1}
                      id="ember1715"
                      className="no-outline ember-view"
                    ></div>

                    <div id="ember1716" className="ember-view form-group">
                      <div id="ember1717" className="ember-view">
                        <label className="col-form-label"> Subject </label>
                        <input
                          id="ember1718"
                          className="ember-text-field ember-view form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">
                        How can we help you today?
                      </label>
                      <textarea
                        rows={3}
                        id="ember1719"
                        className="ember-text-area ember-view form-control no-resize"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">
                        Attachments
                        <i
                          id="ember1720"
                          className="tooltip-container ember-view"
                        >
                          <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x={0}
                            y={0}
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                            className="icon icon-sm align-text-top text-muted cursor-pointer"
                          >
                            <path d="M255.4 31.9c30.2 0 59.4 5.9 87 17.5 26.6 11.2 50.4 27.4 71 47.9 20.5 20.5 36.6 44.3 47.9 71 11.6 27.6 17.5 56.7 17.5 87s-5.9 59.4-17.5 87c-11.2 26.6-27.4 50.4-47.9 71-20.5 20.5-44.3 36.6-71 47.9-27.6 11.6-56.7 17.5-87 17.5s-59.4-5.9-87-17.5c-26.6-11.2-50.4-27.4-71-47.9-20.5-20.5-36.6-44.3-47.9-71-11.6-27.6-17.5-56.7-17.5-87s5.9-59.4 17.5-87c11.2-26.6 27.4-50.4 47.9-71s44.3-36.6 71-47.9c27.5-11.6 56.7-17.5 87-17.5m0-31.9C114.3 0 0 114.3 0 255.4s114.3 255.4 255.4 255.4 255.4-114.3 255.4-255.4S396.4 0 255.4 0z" />
                            <path d="M303.4 351.1h-8.2c-4.4 0-8-3.6-8-8v-94.4c0-15.3-11.4-28-26.6-29.7-2.5-.3-4.8-.5-6.7-.5-23.6 0-44.4 11.9-56.8 30l-.1.1v-.1c-1 2-1.7 5.2.7 6.5.6.3 1.2.5 1.8.5h15.9c4.4 0 8 3.6 8 8v79.8c0 4.4-3.6 8-8 8h-8.1c-8.7 0-15.8 7.1-15.8 15.8v.3c0 8.7 7.1 15.8 15.8 15.8h96.1c8.7 0 15.8-7.1 15.8-15.8v-.3c0-8.9-7.1-16-15.8-16zM255.4 127.7c-17.6 0-31.9 14.3-31.9 31.9s14.3 31.9 31.9 31.9 31.9-14.3 31.9-31.9-14.3-31.9-31.9-31.9z" />
                          </svg>
                        </i>
                      </label>
                      <div className="row">
                        <div className="col-lg-3">
                          <span className="btn btn-primary file-upload">
                            <input
                              multiple
                              onChange={(e) => {
                                uploadImage(e.target.files);
                              }}
                              className="upload ember-view "
                              type="file"
                            />
                            <span>Choose File</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label ">Contact Number</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x={0}
                              y={0}
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                              className="icon icon-sm align-text-bottom"
                            >
                              <path d="M384 0H160c-17.7 0-32 14.3-32 32v448c0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32zM224 24h96c4.4 0 8 3.6 8 8s-3.6 8-8 8h-96c-4.4 0-8-3.6-8-8s3.6-8 8-8zm48 466c-14.3 0-26-11.7-26-26s11.7-26 26-26 26 11.7 26 26-11.7 26-26 26zm112-90c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h192c8.8 0 16 7.2 16 16v320z" />
                            </svg>
                          </span>
                        </div>
                        <input
                          id="ember1722"
                          className="ember-text-field ember-view form-control"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-form-label">
                        How critical is your request?
                      </label>
                      <div id="ember1723" className="ember-view">
                        <div className="ac-box ">
                          <span
                            className="ac-selected form-control "
                            tabIndex={0}
                          >
                            <span data-integrity-label>None</span>

                            <div>
                              <i className="zf-ac-toggler">
                                <svg
                                  version="1.1"
                                  id="Layer_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x={0}
                                  y={0}
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                >
                                  <path d="M493.9 227.8c-21.4-21-56.1-21.1-77.6-.3L256 382.9 95.7 227.6c-21.5-20.8-56.3-20.7-77.6.3-10.6 10.4-15.9 24-15.9 37.7 0 13.8 5.4 27.5 16.2 38l199 192.9c21.4 20.7 55.9 20.7 77.3 0l199-192.9c21.4-20.9 21.6-54.8.2-75.8z" />
                                </svg>
                              </i>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-5">
                      <div className="col-md-4">
                        <button
                          className="btn btn-primary ember-view"
                          type="submit"
                        >
                          Send
                        </button>
                        <button
                          className="btn btn-link"
                          onClick={() => props.setShowContactUs(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="ml-auto pr-4">
                        <div>
                          Zoho Inventory Helpline - +91 (44) 71817070
                          <small className="text-muted d-block text-right">
                            Mon - Fri 9:00AM - 7:00PM
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ContactUsComponent;
