import { TAX_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
    // 'Authorization': url.Auth_Token
}

export const getTaxDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TAX_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getTaxKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TAX_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};
export const getTaxDetailKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TAX_URL() + "getKeyValueDetailsList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getInterStateTaxKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TAX_URL() + "interstateTaxOptions", { headers: {'Authorization': cook != undefined ? cook.accessToken : ''} })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getIntraStateTaxKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TAX_URL() + "intrastateTaxOptions", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getAllTaxSansGroup = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TAX_URL() + 'getAllTaxSansGroup',  { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getAllTaxSansGroup axios!");
                });
        } catch (error) {
            console.error("in LoginService > getAllTaxSansGroup, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getTaxTypeKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TAX_URL() + 'getTaxTypeKeyValueList',  { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getTaxTypeKeyValueList axios!");
                });
        } catch (error) {
            console.error("in LoginService > getTaxTypeKeyValueList, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const addNewTax = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(TAX_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addNewTax axios!");
                });
        } catch (error) {
            console.error("in LoginService > addNewTax, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getAllTaxRecord = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(TAX_URL() + 'getAllRecords', data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getAllTaxRecord axios!");
                });
        } catch (error) {
            console.error("in LoginService > getAllTaxRecord, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteTax = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(TAX_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteTax axios!");
                });
        } catch (error) {
            console.error("in LoginService > deleteTax, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const taxDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TAX_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in taxDetails axios!");
                });
        } catch (error) {
            console.error("in LoginService > taxDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateTaxDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(TAX_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateTaxDetails axios!");
                });
        } catch (error) {
            console.error("in Tax > updateTaxDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};