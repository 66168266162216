import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "universal-cookie";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DataTable, { createTheme } from "react-data-table-component";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadIcon from "@mui/icons-material/Upload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SyncIcon from "@mui/icons-material/Sync";

import {
  addItemByWarehouseId1, //sync
  removeItemByWarehouseId1, //sync
  activeDeactiveItem,
  activeDeactiveItemByWare,
  uploadExcel,
  getItemList,
  getAllRecordsByWarehouseid,
  getWarehouseKeyValueList,
  getItemThatNotExistByWarehouseId1,
  deActiveItem1,
  activeItem1,
  urlMappingUUID1,
  updateItem,
  getItemDetail,
} from "../../services/index";
import Select from "react-select";
import {
  BASE_URL_INVENTORY,
  DOWNLOAD_ITEM_URL,
} from "../../services/CONSTANTS";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast, { Toaster } from "react-hot-toast";
import { Link, Redirect, useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";

const ItemList = () => {
  let history = useHistory();

  const [itemSync1, setItemSync1] = useState([]);
  const [itemSync2, setItemSync2] = useState([]);
  const [itemExist, setItemExist] = useState([]);
  const [itemExistClone, setItemExistClone] = useState([]);
  const [itemPurchasePrice, setItemPurchasePrice] = useState(0);
  const [itemSalesPrice, setItemSalesPrice] = useState(0);
  const [itemDetail, setItemDetail] = useState([]);

  const [error, setError] = useState({});

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);

  const handleShow3 = (row) => {
    setShow3(true);
    getItemDetail(row.recordId).then((res) => {
      if (res.errorCode == 0) {
        setItemDetail(res.responsePacket);
      }
    });

    setItemPurchasePrice(row.purchasePriceValue);
    setItemSalesPrice(row.sellingPriceValue);
  };

  const [file1, setFile1] = useState({
    fileBase64: "",
    fileName: "",
  });

  const [isSales, setIsSales] = useState(false);
  const [isPurchase, setIsPurchase] = useState(false);
  const [downloadItemExcel1, setDownloadItemExcel1] = useState({});
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [warehouseOptions3, setWarehouseOptions3] = useState([]);
  const [warehouseName, setWarehouseName] = useState("");
  const [warehouseName3, setWarehouseName3] = useState("");

  const [warehouseId, setWarehouseId] = useState("");
  const [issuanceKeyValueList, setIssuanceKeyValueList] = useState([]);
  const [details, setDetails] = useState([]);
  const [allRecordsByWarehouseid, setAllRecordsByWarehouseid] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(10);

  const [rows, setRows] = useState([]);
  const [redirState, setRedirState] = useState(false);
  const [dataId, setDataId] = useState(false);
  const [a, setA] = useState("");
  const [allItems, setAllItems] = useState(true);

  const [listState, setListState] = useState("list1");

  const [filteredData2, setFilteredData2] = useState([]);
  const [search2, setSearch2] = useState("");
  const [rows2, setRows2] = useState([]);

  const [filteredData3, setFilteredData3] = useState([]);
  const [search3, setSearch3] = useState("");
  const [rows3, setRows3] = useState([]);
  const [itemId1, setItemId1] = useState("");

  const columns = [
    {
      name: "NAME",
      cell: (row) => row.title,
      sortable: true,
    },

    {
      name: "Selling Price",
      selector: (row) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleShow3(row)}>
          {row.isSales ? (
            <Tooltip title="Sales" arrow placement="top">
              <CircleIcon style={{ fontSize: "10px", color: "green" }} />
            </Tooltip>
          ) : (
            <Tooltip title="not Sales" arrow placement="top">
              <CircleIcon style={{ fontSize: "10px", color: "white" }} />
            </Tooltip>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp; {row.sellingPriceValue}
        </div>
      ),
      sortable: true,
    },

    {
      name: "Purchase Price",
      selector: (row) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleShow3(row)}>
          {row.isPurchase ? (
            <Tooltip title="Purchase" arrow placement="top">
              <CircleIcon style={{ fontSize: "10px", color: "green" }} />
            </Tooltip>
          ) : (
            <Tooltip title="not Purchase" arrow placement="top">
              <CircleIcon style={{ fontSize: "10px", color: "white" }} />
            </Tooltip>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;{row.purchasePriceValue}
        </div>
      ),
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => (
        <div>
          <Link>
            <EditIcon
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => {
                history.push("/inventory/items/" + row.recordId + "/edit");
              }}
            />
          </Link>
          &nbsp;&nbsp;
          {row.active ? (
            <CheckCircleIcon
              style={{
                fontSize: "15px",
                cursor: "pointer",
                color: "greenyellow",
              }}
              onClick={() => handleActiveInactive1(row)}
            />
          ) : (
            <CancelIcon
              style={{ fontSize: "15px", cursor: "pointer", color: "tomato" }}
              onClick={() => handleActiveInactive1(row)}
            />
          )}
        </div>
      ),
    },
  ];

  const [active, setActive] = useState("");
  const [inActive, setInActive] = useState("");

  const handleActive = (e, row) => {
    activeItem1(row.recordId, row.warehouseId).then((res) => {
      if (res.errorCode == 0) {
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };
  const handleInActive = (e, row) => {
    deActiveItem1(row.recordId, row.warehouseId).then((res) => {
      if (res.errorCode == 0) {
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };

  const handleActiveInactive = (row) => {
    activeDeactiveItemByWare(row.itemId, row.warehouseId).then((res) => {
      if (res.errorCode == 0) {
        let e = {
          value: row.warehouseId,
          label: warehouseName.label,
        };
        handleWarehouseChange2(e);
        toast(res.message, { autoClose: 2000 });
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };

  const handleActiveInactive1 = (row) => {
    activeDeactiveItem(row.recordId).then((res) => {
      if (res.errorCode == 0) {
        getItemRecords();
        toast(res.message, { autoClose: 2000 });
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };

  const columns2 = [
    {
      name: "NAME",
      cell: (row) => row.itemTitle,
      sortable: true,
    },
    {
      name: "Current Stock ",
      cell: (row) => row.currentStockQuantity.toFixed(2),
      sortable: true,
    },
    {
      name: "Selling Price",
      selector: (row) => row.sellingPriceValue,
      sortable: true,
    },

    {
      name: "Purchase Price",
      selector: (row) => row.purchasePriceValue,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) =>
        row.active ? (
          <CheckCircleIcon
            style={{
              fontSize: "15px",
              cursor: "pointer",
              color: "greenyellow",
            }}
            onClick={() => handleActiveInactive(row)}
          />
        ) : (
          <CancelIcon
            style={{ fontSize: "15px", cursor: "pointer", color: "tomato" }}
            onClick={() => handleActiveInactive(row)}
          />
        ),
    },
  ];

  let redirecting = redirState && (
    <Redirect push to={`/inventory/items/${dataId}`} />
  );

  const handleWarehouseChange = (e) => {
    setWarehouseName(e);
    setWarehouseId(e.value);
    if (e.value != null || e.value != "") {
      let list_data = {
        page: 0,
        size: -1,
        search: "",
        uuid: e.value,
      };

      getAllRecordsByWarehouseid(list_data).then((res) => {
        if (res.errorCode == 0) {
          setAllRecordsByWarehouseid(res.responsePacket.data);

          const data = res.responsePacket.data;
          const options = data.map((d) => ({
            recordId: d.recordId,
            currentStockQuantity: d.currentStockQuantity,
            itemTitle: d.itemTitle,
          }));

          setDetails(options, "setDetails----");
        } else {
          toast(res.message, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    }
  };

  const handleWarehouseChange2 = (e) => {
    setWarehouseName(e);
    setWarehouseId(e.value);
    let list_data = {
      page: 0,
      size: -1,
      search: "",
      uuid: e.value,
    };

    getAllRecordsByWarehouseid(list_data).then((res) => {
      if (res.errorCode == 0) {
        setAllRecordsByWarehouseid(res.responsePacket.data);

        const data = res.responsePacket.data;
        setRows2(data);
        setFilteredData2(data);
        const options = data.map((d) => ({
          recordId: d.recordId,
          currentStockQuantity: d.currentStockQuantity,
          itemTitle: d.itemTitle,
        }));

        setDetails(options, "setDetails----");
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    if (e.value == "allItems") {
      setListState("list1");
    } else {
      setListState("list2");
    }
  };

  useEffect(() => {
    getWarehouseKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        setIssuanceKeyValueList(res.responsePacket);
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        options.push({ label: "All Items", value: "allItems" });
        setWarehouseOptions(options);

        const options2 = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));

        setWarehouseOptions3(options2);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }, [listState]);

  //-------

  const getItemRecords = () => {
    var data = {
      page: 0,
      search: "",
      size: -1,
    };
    getItemList(data).then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        // temp.reverse();
        setRows(temp);
        setFilteredData(temp);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  useEffect(() => {
    getItemRecords();
  }, [listState]);

  useEffect(() => {
    const result = rows.filter((data) => {
      return data.title.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  useEffect(() => {
    const result = rows2.filter((data) => {
      return data.itemTitle.toLowerCase().match(search2.toLowerCase());
    });
    setFilteredData2(result);
  }, [search2]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    const base = base64.split(",")[1];
    setFile1({
      fileBase64: base,
      fileName: file.name,
    });
  };

  const handleUpload = () => {
    uploadExcel(file1).then((res) => {
      if (res.errorCode == 0) {
        toast("File Uploaded Successfull", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setError(res);
        setShow5(true);
      }
    });
    setTimeout(handleClose(), 6000);
  };

  const handleItemSelect = (e) => {
    setItemId1(e.selectedRows);
  };

  const [userData, setUserData] = useState("");
  const [urlList, setUrlList] = useState("");
  const [userRights, setUserRights] = useState("");

  useEffect(() => {
    let loginData1 = new Cookies().get("loginData1");
    setUserData(new Cookies().get("loginData1"));
    let id = loginData1.recordId;

    urlMappingUUID1(id).then((res) => {
      if (res.errorCode == 0) {
        setUrlList(res.responsePacket.urlList);
        setUserRights(res.responsePacket);
      } else {
        toast("🙁" + res.message);
      }
    });
  }, []);

  const handleWarn = () => {
    alert("Access Denied!");
    return;
  };

  const handlePriceUpdate = () => {
    let data = {
      brandId: itemDetail.brandId,
      brandTitle: itemDetail.brandTitle,
      dimensionBreadth: itemDetail.dimensionBreadth,
      dimensionHeight: itemDetail.dimensionHeight,
      dimensionLength: itemDetail.dimensionLength,
      dimensionUnitId: itemDetail.dimensionUnitId,
      dimensionUnitTitle: itemDetail.dimensionUnitTitle,
      hsnId: itemDetail?.hsnId,
      hsnSac: itemDetail?.hsnSac,
      isIngredient: itemDetail?.isIngredient,
      itemTypeEnum: itemDetail?.itemTypeEnum,
      manufacturerId: itemDetail?.manufacturerId,
      manufacturerTitle: itemDetail?.manufacturerTitle,
      isReturnable: itemDetail?.isReturnable,
      sku: itemDetail?.sku,

      isPurchase: isPurchase ? isPurchase : itemDetail?.isPurchase,
      purchasePriceValue: isPurchase
        ? itemPurchasePrice
        : itemDetail?.purchasePriceValue,
      purchaseAccountId: "c83d1e77-2a18-4437-ba21-fef2b2e97abb",
      purchaseAccountTitle: "Expense",
      purchaseAccountTypeEnum: "Expense",
      // purchaseCurrencyId: "dad7fa51-f498-42fb-a2d9-8efe52269ffc",
      // purchaseCurrencyTitle: "INR - Indian Rupee",
      purchaseDescription: "p",

      isSales: isSales ? isSales : itemDetail?.isSales,
      sellingPriceValue: isSales
        ? itemSalesPrice
        : itemDetail?.sellingPriceValue,
      sellingAccountId: "e0ebbd3e-31bc-43c1-ac36-e01be0801d82",
      sellingAccountTitle: "Income",
      sellingAccountTypeEnum: "Income",
      // sellingCurrencyId: "dad7fa51-f498-42fb-a2d9-8efe52269ffc",
      // sellingCurrencyTitle: "INR - Indian Rupee",
      sellingDescription: "s",

      // isPurchase: itemPurchasePrice!="" ? true : itemDetail?.isPurchase,
      // isSales: itemSalesPrice!="" ? true : itemDetail?.isSales,

      taxExemptId: itemDetail?.taxExemptId,
      taxExemptTitle: itemDetail?.taxExemptTitle,

      taxIdInterstate: itemDetail?.taxIdInterstate,
      taxIdIntrastate: itemDetail?.taxIdIntrastate,

      taxTitleInterstate: itemDetail?.taxTitleInterstate,
      taxTitleIntrastate: itemDetail?.taxTitleIntrastate,

      isTaxable: itemDetail.isTaxable,

      title: itemDetail.title,
      isIngredient: itemDetail.isIngredient,

      warehouseItemsBeanList: itemDetail.warehouseItemsBeanList,
      weightUnitId: itemDetail.packUnitId,
      weightUnitTitle: itemDetail.packUnitTitle,
      weightValue: itemDetail.weightValue,

      packUnitId: itemDetail.packUnitId,
      packUnitTitle: itemDetail.packUnitTitle,
    };

    updateItem(itemDetail.recordId, data).then((res) => {
      if (res.errorCode === 0) {
        getItemRecords();
        handleClose3();
        setIsPurchase(false);
        setIsSales(false);
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };

  const handlePurchase = (a) => {
    setItemPurchasePrice(a);
  };

  const handleSales = (a) => {
    setItemSalesPrice(a);
  };

  const handleIsPurchase = (e) => {
    setIsPurchase(e.target.checked);
  };

  const handleIsSales = (e) => {
    setIsSales(e.target.checked);
  };

  // const handleOpeningStock = (e, item,i) => {
  //   item.openingStock = e.target.value;
  // };

  // const handleReorderPoint = (e, item,i) => {
  //   item.reorderPoint = e.target.value;
  // };

  const handleOpeningStock = (e, i) => {
    const obj = [...filteredData3];
    obj[i].openingStock = e.target.value;
    setFilteredData3(obj);
  };

  const handleReorderPoint = (e, i) => {
    const obj = [...filteredData3];
    obj[i].reorderPoint = e.target.value;
    setFilteredData3(obj);
  };

  const handleWarehouseChange3 = (e) => {
    console.log(e, "eeeeeee123");
    setWarehouseName3(e);
    if (e.value != null || e.value != "") {
      getItemThatNotExistByWarehouseId1(e.value).then((res) => {
        if (res.errorCode == 0) {
          var d = res.responsePacket;
          setRows3(d);
          setFilteredData3(d);
        } else {
          toast("🙁" + res.message, {
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });

      let list_data = {
        page: 0,
        size: -1,
        search: "",
        uuid: e.value,
      };

      getAllRecordsByWarehouseid(list_data).then((res) => {
        if (res.errorCode == 0) {
          const d = res.responsePacket.data;
          setItemExistClone(d);
          setItemExist(d);
          // const options = data.map((d) => ({
          //   recordId: d.recordId,
          //   currentStockQuantity: d.currentStockQuantity,
          //   itemTitle: d.itemTitle,
          // }));
        } else {
          toast(res.message, {
            autoClose: 1000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });
    }
  };

  const handleRemoveSync = () => {
    console.log(warehouseName3, "warehouseName3");
    if (itemSync1.length == 0) {
      toast("please select an item to add");
      return;
    }
    if (itemSync1.length > 0) {
      removeItemByWarehouseId1(warehouseName3.value, itemSync1).then((res) => {
        if (res.errorCode == 0) {
          handleWarehouseChange3(warehouseName3);
          setItemSync1("");
          toast(res.message);
        } else {
          toast("🙁" + res.message, {
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });
    }
  };

  const handleAddSync = () => {
    if (itemSync2.length == 0) {
      toast("please select an item to remove");
      return;
    }

    addItemByWarehouseId1(warehouseName3.value, itemSync2).then((res) => {
      if (res.errorCode == 0) {
        handleWarehouseChange3(warehouseName3);
        setItemSync2("");
        toast(res.message);
      } else {
        toast("🙁" + res.message, {
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };

  const colExist = [
    {
      name: "EXISTING ITEMS",
      cell: (row) => row.itemTitle,
      sortable: true,
    },
  ];

  const colNotExist = [
    {
      name: "NOT EXISTING ITEMS",
      cell: (row) => row.title,
      sortable: true,
    },
  ];

  //exist
  const handleItem1 = (row) => {
    let abc = row.selectedRows.map((a) => {
      return { itemId: a.itemId, itemTitle: a.itemTitle };
    });
    setItemSync1(abc);
  };

  //not exist
  const handleItem2 = (row) => {
    let abc = row.selectedRows.map((a) => {
      return { itemId: a.recordId, itemTitle: a.title };
    });
    setItemSync2(abc);
  };

  const handleSearch = (e) => {
    const result = rows3.filter((a) => {
      return a.title.toLowerCase().match(e.target.value.toLowerCase());
    });
    setFilteredData3(result);

    const result2 = itemExistClone.filter((a) => {
      return a.itemTitle.toLowerCase().match(e.target.value.toLowerCase());
    });
    setItemExist(result2);
  };

  return (
    <>
      <>
        <Modal show={show2} onHide={handleClose2} size="lg">
          <div
            className="row"
            style={{ padding: "5px", justifyContent: "space-between" }}
          >
            <div className="col-4" style={{ zIndex: 6 }}>
              {" "}
              <Select
                options={warehouseOptions3}
                placeholder={"Select WareHouse"}
                onChange={(e) => handleWarehouseChange3(e)}
                value={warehouseName3}
                maxMenuHeight={220}
              />
            </div>
            <div>
              {/* < */}
              {filteredData3 && filteredData3.length > 0 ? (
                <>
                  Remove
                  <ArrowBackIosIcon
                    style={{
                      color: "#00a2ca",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                    onClick={handleAddSync}
                  />
                </>
              ) : (
                ""
              )}

              {/* > */}
              {itemExist && itemExist.length > 0 ? (
                <>
                  {" "}
                  <ArrowForwardIosIcon
                    style={{
                      color: "#00a2ca",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                    onClick={handleRemoveSync}
                  />
                  Add
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-4 d-flex align-items-center">
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control "
                type="text"
                placeholder="Search item "
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
              }}
            >
              <DataTable
                columns={colExist}
                data={itemExist}
                fixedHeader
                selectableRows
                onSelectedRowsChange={(rows) => {
                  handleItem1(rows);
                }}
              />
              {filteredData3 && filteredData3.length > 0 ? (
                <DataTable
                  columns={colNotExist}
                  data={filteredData3}
                  fixedHeader
                  selectableRows
                  onSelectedRowsChange={(rows) => {
                    handleItem2(rows);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={show3} onHide={handleClose3} size="sm">
          <Modal.Body>
            <tr>{itemDetail.title}</tr>
            <br />
            <table className="customTable">
              <tr>
                <td>Purchase Price:</td>&nbsp;&nbsp;&nbsp;
                <td>
                  <input
                    type="checkbox"
                    value={isPurchase}
                    onChange={(e) => {
                      handleIsPurchase(e);
                    }}
                  />
                  <input
                    style={{ width: "90px" }}
                    type="number"
                    value={itemPurchasePrice}
                    onChange={(e) => {
                      handlePurchase(e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Selling Price:</td>
                &nbsp;&nbsp;&nbsp;
                <td>
                  <input
                    type="checkbox"
                    value={isSales}
                    onChange={(e) => {
                      handleIsSales(e);
                    }}
                  />
                  <input
                    style={{ width: "90px" }}
                    type="number"
                    value={itemSalesPrice}
                    onChange={(e) => {
                      handleSales(e.target.value);
                    }}
                  />
                </td>
              </tr>
            </table>
            <br />{" "}
            <Button variant="primary" onClick={handlePriceUpdate}>
              Update
            </Button>
          </Modal.Body>
        </Modal>

        <Modal show={show} onHide={handleClose} size="sm">
          <Modal.Body>
            <Select
              options={warehouseOptions}
              placeholder={"Select Warehouse"}
              onChange={(e) => handleWarehouseChange(e)}
              value={warehouseName}
            />

            <hr />
            <br />
            <input
              className="ember-text-field ember-view form-control"
              id="originalFileName"
              type="file"
              inputProps={{
                accept: "image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt",
              }}
              required
              label="Document"
              name="originalFileName"
              onChange={(e) => handleFileRead(e)}
              size="small"
              variant="standard"
            />
          </Modal.Body>

          <Modal.Footer>
            {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
            (urlList &&
              urlList.some(
                (data) => data.url === "/items/downloadItemExcel/{warehouseId}"
              )) ? (
              <a
                style={{ paddingRight: "15px" }}
                href={
                  `${BASE_URL_INVENTORY}` + `${DOWNLOAD_ITEM_URL}` + warehouseId
                }
                target="_blank"
                download
              >
                {warehouseId ? (
                  <Button variant="primary">
                    <DownloadIcon />
                    Download
                  </Button>
                ) : (
                  <></>
                )}
              </a>
            ) : (
              <Button
                id="downloadBtn"
                variant="secondary"
                style={{ color: "red" }}
                onClick={handleWarn}
              >
                <DownloadIcon />
                Download
              </Button>
            )}
            {/* <a
              href={
                `${BASE_URL_INVENTORY}` + `${DOWNLOAD_ITEM_URL}` + warehouseId
              }
              target="_blank"
              download
              style={{ paddingRight: "10px" }}
            >
              <DownloadIcon />
              Download Item
            </a> */}

            {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
            (urlList &&
              urlList.some(
                (data) => data.url === "/items/uploadItemsExcel"
              )) ? (
              <Button variant="primary" onClick={handleUpload}>
                <UploadIcon /> Upload
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleWarn}>
                <UploadIcon /> Upload
              </Button>
            )}
            {/* <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button> */}
          </Modal.Footer>
        </Modal>

        <Modal show={show5} onHide={handleClose5}>
          <Modal.Body>
            <div> {error.message ? error.message : "Error In Uploading"}</div>
            <br />
            {error.listOfErrors ? "List Of ERRORS :" : ""}
            {error.listOfErrors &&
              error.listOfErrors?.map((item) => {
                return (
                  <div>
                    {item}
                    <hr />
                  </div>
                );
              })}
          </Modal.Body>
        </Modal>
      </>

      <div
        className="fill list-header  d-print-none"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="list-filter">
          <div className="btn-toolbar float-right">
            <div>
              {" "}
              {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ? (
                <Button variant="primary" onClick={handleShow2}>
                  <SyncIcon /> Sync
                </Button>
              ) : (
                <></>
              )}
            </div>
            <div>
              <Button variant="primary" onClick={handleShow}>
                <ImportExportIcon /> Excel
              </Button>
            </div>

            <span className="btn btn-primary ">
              <Link
                to={"/inventory/items/new"}
                className="d-flex align-items-center"
                style={{ color: "#fff" }}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                  className="icon icon-xsmall align-text-bottom"
                >
                  <path d="M484 227H284V28c0-15.5-12.5-28-28-28s-28 12.5-28 28v199H28c-15.5 0-28 12.5-28 28v1c0 15.5 12.5 28 28 28h200v200c0 15.5 12.5 28 28 28s28-12.5 28-28V284h200c15.5 0 28-12.5 28-28v-1c0-15.5-12.5-28-28-28z"></path>
                </svg>
                &nbsp;New
              </Link>
            </span>
          </div>

          <div className="d-flex align-items-center row">
            <div
              id="ember1611"
              className="dropdown ember-view clearfix  col-3"
              style={{ zIndex: "6" }}
            >
              <Select
                options={warehouseOptions}
                placeholder={"Select WareHouse"}
                onChange={(e) => handleWarehouseChange2(e)}
                value={warehouseName}
                maxMenuHeight={220}
              />
            </div>

            <div
              id="ember1611"
              className="dropdown ember-view clearfix  col-1"
            ></div>
            <div
              id="ember1611"
              className="dropdown ember-view clearfix  col-2"
            ></div>
          </div>
        </div>
      </div>

      <div style={{ height: 500, width: "100%", zIndex: "-3" }}>
        {listState == "list1" ? (
          <DataTable
            theme="light"
            title="Items List"
            columns={columns}
            data={filteredData}
            pagination
            fixedHeader
            highlightOnHover={true}
            onRowClicked={(rows) => {
              setRedirState(true);
              setDataId(rows.recordId);
            }}
            subHeader
            subHeaderAlign="right"
            subHeaderComponent={
              <>
                {" "}
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder="Search item"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ margin: "10px" }}
                />
              </>
            }
          />
        ) : (
          <></>
        )}

        {listState == "list2" ? (
          <DataTable
            theme="light"
            title="Items by Warehouse"
            columns={columns2}
            data={filteredData2}
            pagination
            fixedHeader
            highlightOnHover={true}
            onRowClicked={(rows) => {
              setRedirState(true);
              setDataId(rows.itemId);
            }}
            subHeader
            subHeaderComponent={
              <>
                {" "}
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder="Search item"
                  value={search2}
                  onChange={(e) => setSearch2(e.target.value)}
                />
              </>
            }
            subHeaderAlign="right"
          />
        ) : (
          <></>
        )}

        {redirecting}
      </div>
      <Toaster />
    </>
  );
};

export default ItemList;
