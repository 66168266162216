import { RECIPE_URL } from '../CONSTANTS'
import { ITEM_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}

export const getRecipeList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(RECIPE_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getRecipeList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getRecipeKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(RECIPE_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSubItemKeyValueList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addRecipeIngredients = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(RECIPE_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getRecipeList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getRecipeDetailsByUUID = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(RECIPE_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getRecipeList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateRecipeIngradients = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(RECIPE_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getRecipeList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getItemsForRecipe = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(ITEM_URL() + 'getKeyValueForRecipe', { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getKeyValueForRecipe axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};