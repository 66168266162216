import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import DataTable from "react-data-table-component";
import EditIcon from "@mui/icons-material/Edit";

import { getAllRecords } from "../../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AddIcon from "@mui/icons-material/Add";

const PurchaseList = () => {
  let history = useHistory();
  //setting up variables
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [data, setData] = useState([]);
  const [invisible, setInvisible] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [redirState, setRedirState] = useState(false);
  const [dataId, setDataId] = useState(false);

  useEffect(() => {
    vendorAllrecords()
     
  }, []);

  const vendorAllrecords=()=>{
    let list_data = {
      page: 0,
      search: "",
      size: -1,
    };
    getAllRecords(list_data)
      .then((res) => {
        if (res.errorCode == 0) {
          const d = res.responsePacket.data;
          setData(d);
          setFilteredData(d);
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
  }

  useEffect(() => {
    const result = data.filter((data) => {
      return (
        data.vendorTitle.toLowerCase().match(search.toLowerCase()) ||
        data.purchaseOrderNo.toLowerCase().match(search.toLowerCase()) ||
        data.purchaseOrderStatusEnum
          .toLowerCase()
          .match(search.toLowerCase()) ||
        data.purchaseOrderDate.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  let redirecting = redirState ? (
    <Redirect push to={`/inventory/purchase/${dataId}`} />
  ) : (
    ""
  );

  const columns = [
    {
      name: "ORDER No.",
      selector: (data) => data.purchaseOrderNo,
      sortable: true,
    },
    {
      name: "VENDOR NAME",
      selector: (data) => data.vendorTitle,
      sortable: true,
    },

    {
      name: "ORDER DATE",
      selector: (data) => data.purchaseOrderDate,
      sortable: true,
    },

    {
      name: "BILLED",
      selector: (data) => (
        <div className="text-center">
          {data.isBilled ? (
            <Tooltip title="Billed" arrow placement="top">
              <i id="ember591" className="tooltip-container ember-view">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={512}
                  height={512}
                  viewBox="0 0 512 512"
                  className="icon icon-xxs text-fulfilled"
                >
                  <circle cx={256} cy={256} r={256} />
                </svg>{" "}
              </i>
            </Tooltip>
          ) : (
            <>
              <ReceiptIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.push({
                    pathname: "/inventory/convertbill/" + data.recordId,
                    state:"bill"
                  })
                }
              />
            </>
          )}
        </div>
      ),
    },
    {
      name: "PAID",
      selector: (data) => (
        <div className="text-center">
          {data.isPaid ? (
            <Tooltip title="Paid" arrow placement="top">
              <i id="ember591" className="tooltip-container ember-view">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={512}
                  height={512}
                  viewBox="0 0 512 512"
                  className="icon icon-xxs text-fulfilled"
                >
                  <circle cx={256} cy={256} r={256} />
                </svg>{" "}
              </i>
            </Tooltip>
          ) : (
            <Tooltip title="Not Paid" arrow placement="top">
              <i id="ember579" className="tooltip-container ember-view">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={512}
                  height={512}
                  viewBox="0 0 512 512"
                  className="icon icon-xxs fill-empty"
                >
                  <circle cx={256} cy={256} r={256} />
                </svg>{" "}
              </i>
            </Tooltip>
          )}
        </div>
      ),
    },

    {
      name: "AMOUNT",
      selector: (data) => parseFloat(data.totalAmount).toFixed(2),
    },

    {
      name: "EDIT",
      selector: (row) => (
        <div>
          {row.isBilled ? (
            <></>
          ) : (
            <Link>
              <EditIcon
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => {
                  history.push("/inventory/purchase/" + row.recordId + "/edit");
                }}
              />
            </Link>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-container">
        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            title="All Purchase Order"
            columns={columns}
            data={filteredData}
            theme="light"
            pagination
            fixedHeader
            defaultSortAsc={false}
            defaultSortFieldId={1}
            // pointerOnHover={true}
            highlightOnHover={true}
            onRowClicked={(data) => {
              setRedirState(true);
              setDataId(data.recordId);
            }}
            subHeader
            subHeaderComponent={
              <>
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder={"Search here"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ margin: "10px" }}
                />{" "}
                <Link to="/inventory/purchase/new">
                  <button
                    id="ember5839"
                    className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                    type="button"
                  >
                    <AddIcon />
                    New
                  </button>
                </Link>
              </>
            }
            subHeaderAlign="right"
          />
          {redirecting}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PurchaseList;
