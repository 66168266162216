import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link, Redirect, useHistory } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import DataTable, { createTheme } from "react-data-table-component";
import { getSalesOrderList } from "../../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import EditIcon from "@mui/icons-material/Edit";

const SalesList = () => {
  let history = useHistory();
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [data, setData] = useState([]);
  const [redirState, setState] = useState(false);
  const [dataId, setDataId] = useState(false);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  useEffect(() => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };

    getSalesOrderList(list_data)
      .then((res) => {
        if (res.errorCode == 0) {
          const d = res.responsePacket.data;
          setData(d);
          setFilteredData(d);
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("🙁" + err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  useEffect(() => {
    const result = data.filter((data) => {
      return (
        data.customerTitle.toLowerCase().match(search.toLowerCase()) ||
        data.orderNo.toLowerCase().match(search.toLowerCase()) ||
        data.salesOrderStatusEnum.toLowerCase().match(search.toLowerCase()) ||
        data.salesOrderDate.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  let redirecting = redirState && (
    <Redirect push to={`/inventory/salesorders/${dataId}`} />
  );

  const columns = [
    {
      name: "ORDER No.",
      selector: (data) => data.orderNo,
      sortable: true,
    },
    {
      name: "CUSTOMER NAME",
      selector: (data) => data.customerTitle,
      sortable: true,
    },

    {
      name: "INVOICED",
      selector: (data) => (
        <div className="text-center">
          {data.isInvoiced ? (
            <Tooltip title="Invoiced" arrow placement="top">
              <i id="ember591" className="tooltip-container ember-view">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={512}
                  height={512}
                  viewBox="0 0 512 512"
                  className="icon icon-xxs text-fulfilled"
                >
                  <circle cx={256} cy={256} r={256} />
                </svg>{" "}
              </i>
            </Tooltip>
          ) : (
            <>
              <ReceiptIcon
                style={{ cursor: "pointer", align: "right" }}
                onClick={() =>
                  history.push({
                    pathname: `/inventory/invoices/new`,
                    state: { invoiceId: data.recordId,invoiceOrder:true },
                  })
                }
              />
            </>
          )}
        </div>
      ),
    },
    {
      name: "PAYMENT",
      selector: (data) => (
        <div className="text-center">
          {data.isPaid ? (
            <Tooltip title="Paid" arrow placement="top">
              <i id="ember591" className="tooltip-container ember-view">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={512}
                  height={512}
                  viewBox="0 0 512 512"
                  className="icon icon-xxs text-fulfilled"
                >
                  <circle cx={256} cy={256} r={256} />
                </svg>{" "}
              </i>
            </Tooltip>
          ) : (
            <Tooltip title="Not Paid" arrow placement="top">
              <i id="ember579" className="tooltip-container ember-view">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={512}
                  height={512}
                  viewBox="0 0 512 512"
                  className="icon icon-xxs fill-empty"
                >
                  <circle cx={256} cy={256} r={256} />
                </svg>{" "}
              </i>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      name: "SHIPPED",
      selector: (data) => (
        <div className="text-center">
          {data.isShipped ? (
            <Tooltip title="Shipped" arrow placement="top">
              <i id="ember591" className="tooltip-container ember-view">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={512}
                  height={512}
                  viewBox="0 0 512 512"
                  className="icon icon-xxs text-fulfilled"
                >
                  <circle cx={256} cy={256} r={256} />
                </svg>{" "}
              </i>
            </Tooltip>
          ) : (
            <Tooltip title="not Shipped" arrow placement="top">
              <i id="ember579" className="tooltip-container ember-view">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={512}
                  height={512}
                  viewBox="0 0 512 512"
                  className="icon icon-xxs fill-empty"
                >
                  <circle cx={256} cy={256} r={256} />
                </svg>{" "}
              </i>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      name: "AMOUNT",
      selector: (data) => parseFloat(data.totalAmount).toFixed(2),
    },
    {
      name: "EDIT",
      selector: (row) => (
        <div>
          {row.isInvoiced ? (
            <></>
          ) : (
            <Link>
              <EditIcon
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => {
                  history.push(
                    "/inventory/salesorders/" + row.recordId + "/edit"
                  );
                }}
              />
            </Link>
          )}
        </div>
      ),
    },
  ];


  return (
    <>
      <div className="page-container">
        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            title="All Sales Orders"
            columns={columns}
            data={filteredData}
            theme="light"
            pagination
            fixedHeader
            highlightOnHover={true}
            defaultSortAsc={false}
            defaultSortFieldId={1}
            onRowClicked={(data) => {
              setState(true);
              setDataId(data.recordId);
            }}
            subHeader
            subHeaderComponent={
              <>
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder={"Search here"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ margin: "10px" }}
                />{" "}
                <Link to="/inventory/salesorders/new">
                  <button
                    id="ember5839"
                    className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                    type="button"
                  >
                    <AddIcon />
                    New
                  </button>
                </Link>
              </>
            }
            subHeaderAlign="right"
          />

          {redirecting}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SalesList;
