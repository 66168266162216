import React, { useState, useEffect } from "react";
import Select from "react-select";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import {
  addCountry,
  addStateRegion,
  getCountryKeyValuePair,
  getStateKeyValue,
} from "../../services/index";
import { Modal } from "react-bootstrap";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Address = ({ child1, child2, ...props }) => {
  useEffect(() => {
    getCountryList();
    getStateList();
  }, []);

  const [countryKeyValue, setCountryKeyValue] = useState([]);
  const [stateKeyValue, setStateKeyValue] = useState([]);
  const [modalData, setModalData] = useState({
    countryCode: "",
    countryTitle: "",
    stateCode: "",
    stateTitle: "",
  });

  const [modalSwitches, setModalSwitches] = useState({
    countryModal: false,
    stateModal: false,
  });

  const handleAddNewState = () => {
    let dict = {
      code: modalData.stateCode,
      title: modalData.stateTitle,
    };
    addStateRegion(dict).then((res) => {
      if (res.err === 0) {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          pauseOnHover: true,
        });
      }
      getStateList();
    });
    setModalSwitches({
      ...modalSwitches,
      stateModal: false,
    });
  };

  const handleAddNewCountry = () => {
    let dict = {
      code: modalData.countryCode,
      title: modalData.countryTitle,
    };
    addCountry(dict).then((res) => {
      if (res.err === 0) {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          pauseOnHover: true,
        });
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
      getCountryList();
    });
    setModalSwitches({
      ...modalSwitches,
      countryModal: false,
    });
  };

  const getCountryList = () => {
    getCountryKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setCountryKeyValue(options);
      }
    });
  };

  const getStateList = () => {
    getStateKeyValue().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setStateKeyValue(options);
      }
    });
  };

  return (
    <>
      <div className="" style={{ paddingBottom: "50px", paddingTop: "20px" }}>
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <label className="col-form-label add-header col-lg-12 ">
                BILLING ADDRESS
              </label>
            </div>

            <fieldset className="form-group"></fieldset>
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required ">
                  country
                </legend>
                <div className="col-lg-7 ">
                  <Select
                    name="country"
                    options={countryKeyValue}
                    value={child1.country}
                    onChange={(e) => {
                      props.handleChild1(e, "country");
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required">
                  Address
                </legend>
                <div className="col-lg-7 ">
                  <textarea
                    placeholder="Street 1"
                    name="street1"
                    className="form-control ember-text-area ember-view"
                    value={child1.street1}
                    onChange={(e) => {
                      props.handleChild1(e);
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="row">
                <div className="col-lg-7 offset-lg-4">
                  <textarea
                    placeholder="Street 2"
                    name="street2"
                    className="form-control ember-text-area ember-view"
                    value={child1.street2}
                    onChange={(e) => {
                      props.handleChild1(e);
                    }}
                  />
                </div>
              </div>
            </fieldset>

            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required">
                  city
                </legend>
                <div className="col-lg-7 ">
                  <input
                    name="city"
                    className="form-control ember-text-field ember-view"
                    value={child1.city}
                    onChange={(e) => {
                      props.handleChild1(e);
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required">
                  state
                </legend>
                <div className="col-lg-7 ">
                  <Select
                    options={stateKeyValue}
                    value={child1.state}
                    onChange={(e) => {
                      props.handleChild1(e, "state");
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required ">
                  Zip Code{" "}
                </legend>
                <div className="col-lg-7 ">
                  <input
                    className="form-control ember-text-field ember-view"
                    type="number"
                    name="zipcode"
                    value={child1.zipcode}
                    onChange={(e) => {
                      props.handleChild1(e);
                    }}
                  />
                </div>
              </div>
            </fieldset>

            {/* <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label ">Fax</legend>
                <div className="col-lg-7 ">
                  <input
                    name="fax"
                    className="form-control ember-text-field ember-view"
                    type="number"
                    value={child1.fax}
                    onChange={(e) => {
                      props.handleChild1(e);
                    }}
                  />
                </div>
              </div>
            </fieldset> */}
          </div>

          <div className="col-lg-6">
            <div
              className="row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="col-form-label add-header  col-lg-12 ">
                SHIPPING ADDRESS&nbsp;
                <Tooltip title="Copy Billing Address">
                  <ContentCopyIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      var deepCopy1 = JSON.parse(JSON.stringify(child1));
                      if(!child2.recordId){

                        delete deepCopy1.recordId;
                      }
                      props.handleCopy(deepCopy1);
                    }}
                  />
                </Tooltip>
              </div>
            </div>

            <fieldset className="form-group"></fieldset>
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required ">
                  country
                </legend>
                <div className="col-lg-7 ">
                  <Select
                    options={countryKeyValue}
                    value={child2.country}
                    onChange={(e) => {
                      props.handleChild2(e, "country");
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required">
                  Address
                </legend>
                <div className="col-lg-7 ">
                  <textarea
                    placeholder="Street 1"
                    name="street1"
                    className="form-control ember-text-area ember-view"
                    value={child2.street1}
                    onChange={(e) => {
                      props.handleChild2(e);
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="row">
                <div className="col-lg-7 offset-lg-4">
                  <textarea
                    placeholder="Street 2"
                    name="street2"
                    className="form-control ember-text-area ember-view"
                    value={child2.street2}
                    onChange={(e) => {
                      props.handleChild2(e);
                    }}
                  />
                </div>
              </div>
            </fieldset>

            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required">
                  city
                </legend>
                <div className="col-lg-7 ">
                  <input
                    name="city"
                    className="form-control ember-text-field ember-view"
                    value={child2.city}
                    onChange={(e) => {
                      props.handleChild2(e);
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required">
                  state
                </legend>
                <div className="col-lg-7 ">
                  <Select
                    options={stateKeyValue}
                    value={child2.state}
                    onChange={(e) => {
                      props.handleChild2(e, "state");
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label required ">
                  Zip Code{" "}
                </legend>
                <div className="col-lg-7 ">
                  <input
                    name="zipcode"
                    className="form-control ember-text-field ember-view"
                    type="number"
                    value={child2.zipcode}
                    onChange={(e) => {
                      props.handleChild2(e);
                    }}
                  />
                </div>
              </div>
            </fieldset>

            {/* <fieldset className="form-group">
              <div className="row">
                <legend className="col-lg-4 col-form-label ">Fax</legend>
                <div className="col-lg-7 ">
                  <input
                    name="fax"
                    className="form-control ember-text-field ember-view"
                    type="number"
                    value={child2.fax}
                    onChange={(e) => {
                      props.handleChild2(e);
                    }}
                  />
                </div>
              </div>
            </fieldset> */}
          </div>
        </div>
      </div>
      <Modal
        show={modalSwitches.countryModal}
        onHide={() =>
          setModalSwitches({
            ...modalSwitches,
            countryModal: false,
          })
        }
        size="sm"
        style={{ marginTop: "-30px" }}
      >
        <Modal.Header>
          <Modal.Title>
            <h4 className="modal-title">Add New country</h4>
            <button
              className="close no-outline"
              type="button"
              data-ember-action=""
              data-ember-action-2136="2136"
              onClick={() =>
                setModalSwitches({
                  ...modalSwitches,
                  countryModal: false,
                })
              }
              style={{ marginLeft: "640px", marginTop: "-30px" }}
            >
              ×
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body sales-person">
            <div class="row">
              <div class="col-lg-12 form-group">
                <label class="required">country Name*</label>
                <input
                  id="ember868"
                  class="ember-text-field ember-view form-control"
                  type="text"
                  value={modalData.countryTitle}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      countryTitle: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 form-group">
                <label class="required">country Code*</label>
                <textarea
                  id="ember868"
                  class="ember-text-field ember-view form-control"
                  type="text"
                  value={modalData.countryCode}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      countryCode: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 btn-toolbar">
                <button
                  id="ember870"
                  class="btn btn-info ember-view btn-sm"
                  type="submit"
                  onClick={handleAddNewCountry}
                >
                  Save
                </button>
                <button
                  class="btn btn-light btn-sm"
                  type="button"
                  data-ember-action=""
                  data-ember-action-871="871"
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    setModalSwitches({
                      ...modalSwitches,
                      countryModal: false,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalSwitches.stateModal}
        onHide={() =>
          setModalSwitches({
            ...modalSwitches,
            stateModal: false,
          })
        }
        size="sm"
        style={{ marginTop: "-30px" }}
      >
        <Modal.Header>
          <Modal.Title>
            <h4 className="modal-title">Add New country</h4>
            <button
              className="close no-outline"
              type="button"
              data-ember-action=""
              data-ember-action-2136="2136"
              onClick={() =>
                setModalSwitches({
                  ...modalSwitches,
                  countryModal: false,
                })
              }
              style={{ marginLeft: "640px", marginTop: "-30px" }}
            >
              ×
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body sales-person">
            <div class="row">
              <div class="col-lg-12 form-group">
                <label class="required">state Name</label>
                <input
                  id="ember868"
                  class="ember-text-field ember-view form-control"
                  type="text"
                  value={modalData.stateTitle}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      stateTitle: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 form-group">
                <label class="required">state Code</label>
                <textarea
                  id="ember868"
                  class="ember-text-field ember-view form-control"
                  type="text"
                  value={modalData.stateCode}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      stateCode: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 btn-toolbar">
                <button
                  id="ember870"
                  class="btn btn-info ember-view btn-sm"
                  type="submit"
                  onClick={handleAddNewState}
                >
                  Save
                </button>
                <button
                  class="btn btn-light btn-sm"
                  type="button"
                  data-ember-action=""
                  data-ember-action-871="871"
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    setModalSwitches({
                      ...modalSwitches,
                      stateModal: false,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};
export default Address;
