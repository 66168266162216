import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  getCustomerDetails,
  getCustomerList,
  logCustomerHistory,
} from "../../services/index";
import { ToastContainer, toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const CustomerView = (props) => {
  const [logData, setLogData] = useState({});
  const [rows, setRows] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [expand, setExpand] = useState(true);
  const [expandRemarks, setExpandRemarks] = useState(true);
  const [expandOther, setExpandOther] = useState(true);
  const [customerAddressDetails, setCustomerAddressDetails] = useState([]);
  const [redirState, setRedirState] = useState(false);
  const [dataId, setDataId] = useState();
  const [value, setValue] = useState("1");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const getRecordsID = (id) => {
    getCustomerDetails(id)
      .then((res) => {
        if (res.errorCode == 0) {
          setCustomerDetails(res.responsePacket);
          setCustomerAddressDetails(res.responsePacket.addressList);
        } else {
          toast("👎" + res.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("👎" + err, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleExpandAddress = () => {
    setExpand(!expand);
  };
  const handleExpandRemarks = () => {
    setExpandRemarks(!expandRemarks);
  };
  const handleExpandOther = () => {
    setExpandOther(!expandOther);
  };
  const editItems = () => {
    setRedirState(true);
    setDataId(customerDetails.recordId);
  };
  useEffect(() => {
    logCustomerHistory(props.match.params.id).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        console.log(d, "d");
        setLogData(d);
      } else {
        toast(res.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  }, [props.match.params.id]);

  useEffect(() => {
    let customer_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getCustomerList(customer_data).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket.data;
        setRows(d);
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
    getRecordsID(props.match.params.id);
  }, []);

  return (
    <>
      <div
        className="list-column column d-print-none"
        style={{ width: "300px" }}
      >
        <div className="fill list-header topgrad d-print-none">
          <div className="list-filter">
            <div className="btn-toolbar float-right">
              <Link to="/inventory/contacts/new">
                <button
                  id="ember3047"
                  className="btn btn-primary tooltip-container ember-view"
                >
                  <span className=" d-flex align-items-center">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      viewBox="0 0 512 512"
                      xmlSpace="preserve"
                      className="icon icon-xsmall mr-2"
                    >
                      <path d="M484 227H284V28c0-15.5-12.5-28-28-28s-28 12.5-28 28v199H28c-15.5 0-28 12.5-28 28v1c0 15.5 12.5 28 28 28h200v200c0 15.5 12.5 28 28 28s28-12.5 28-28V284h200c15.5 0 28-12.5 28-28v-1c0-15.5-12.5-28-28-28z"></path>
                    </svg>
                    New
                  </span>
                </button>
              </Link>
            </div>
            <div
              id="ember3068"
              className="clearfix list-title dropdown ember-view"
            >
              <a id="ember3069" className="dropdown-toggle no-caret ember-view">
                <span className="filter-title over-flow">All Customers</span>
              </a>
            </div>
          </div>
        </div>

        <div className="scroll-y fill body scrollbox list-body">
          <div className="table-responsive overflow-initial">
            <table
              id="ember7912"
              className="table zi-table table-hover ember-view header-fixed"
            >
              <tbody>
                {rows.map((row) => (
                  <tr
                    key={row.recordId}
                    tabIndex="-1"
                    id="ember3096"
                    className="table-active ember-view"
                    onClick={() => getRecordsID(row.recordId)}
                  >
                    <td>
                      <Link to={`/inventory/contacts/${row.recordId}`}>
                        <div className="list-primary text-black">
                          <div className="name text-uppercase">
                            <span
                              id="ember3254"
                              className="text-uppercase text-open"
                            >
                              {row.title}
                            </span>
                          </div>
                          <div className="text-muted">Phone.{row?.phone}</div>
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="column content-column " style={{ left: "301px" }}>
        <div className="header no-border d-flex align-items-center p-4">
          <a
            className="over-flow header-title text-uppercase"
            style={{ fontSize: "x-large" }}
          >
            {/* {customerDetails.title} */}
            &nbsp;
          </a>
          <div className="btn-toolbar d-flex justify-content-end align-items-center">
            <div className="btn-toolbar">
              <div className="btn-group">
                <button
                  className="btn btn-primary"
                  data-ember-action=""
                  data-ember-action-3262="3262"
                  onClick={editItems}
                >
                  <EditIcon />
                </button>
              </div>
              {redirState && (
                <Redirect
                  push
                  to={{
                    pathname: `/inventory/contacts/${dataId}/edit`,
                  }}
                />
              )}

           
            </div>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Primary Details</Tab>
            <Tab>Log History</Tab>
          </TabList>
          <TabPanel>
            {" "}
            <div className="ember-view scroll-y noscroll-x fill body scrollbox">
              <div className="row">
                <div className="col-lg-6">
                  <br />
                  <div className="row inline-fields">
                    <label className="col-lg-3 text-muted">Name:</label>
                    <div className="col-lg-4 edit-form-field">
                      {customerDetails.title}
                    </div>
                  </div>
                  <div className="row inline-fields">
                    <label className="col-lg-3 text-muted">Email:</label>
                    <div className="col-lg-4 edit-form-field">
                      {customerDetails.email}
                    </div>
                  </div>
                  <div className="row inline-fields">
                    <label className="col-lg-3 text-muted">Phone:</label>
                    <div className="col-lg-4 edit-form-field">
                      {customerDetails.phone}
                    </div>
                  </div>
                  <div className="row inline-fields">
                    <label className="col-lg-3 text-muted">GST No:</label>
                    <div className="col-lg-4 edit-form-field">
                      {customerDetails.gstNumber}
                    </div>
                  </div>
                  <div className="row inline-fields">
                    <label className="col-lg-3 text-muted">Fssai:</label>
                    <div className="col-lg-4 edit-form-field">
                      {customerDetails.fssaiLicenseNumber}
                    </div>
                  </div>
                  
                  <div className="row inline-fields">
                    <label className="col-lg-3 text-muted">Price List:</label>
                    <div className="col-lg-4 edit-form-field">
                      {customerDetails.itemPriceListTitle}
                    </div>
                  </div>
                  <div className="row inline-fields">
                    <label className="col-lg-3 text-muted">Payment Term:</label>
                    <div className="col-lg-4 edit-form-field">
                      {customerDetails.paymentTermTitle}
                    </div>
                  </div>
<br/>
                  {customerAddressDetails?.map((address) => (
                    <div className="group">
                      {address.addressTypeEnum == "Billing" ? (
                        <>
                          <div className="text-muted">Billing Address</div>
                          <div
                            id="ember3323"
                            className="popovercontainer address-group ember-view"
                          >
                            <p>
                              <span>
                                <div>{address.street1}</div>
                                <div>{address.street2}</div>
                                <div>
                                  {address.city} {address.state}
                                </div>
                                <div>
                                  {address.zipcode} {address.country}
                                </div>
                              </span>
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <br />
                          <div className="text-muted">Shipping Address</div>
                          <div
                            id="ember3325"
                            className="popovercontainer address-group ember-view"
                          >
                            <p>
                              <span>
                                <div>{address.street1}</div>
                                <div>{address.street2}</div>
                                <div>
                                  {address.city} {address.state}
                                </div>
                                <div>
                                  {address.zipcode} {address.country}
                                </div>
                              </span>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            {" "}
            <table>
              {logData.length == 0 ? (
                "No Changes Found"
              ) : (
                <tr>
                  <td>Date</td>
                  <td>Changes</td>
                </tr>
              )}
              {logData.length >= 1 &&
                logData?.map((history) => {
                  return (
                    <>
                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          {history?.transactionDate?.substring(0, 10)}
                          :&nbsp;&nbsp;
                        </td>

                        <td>
                          {history?.message.split("\n").map((item, i) => (
                            <tr>{item}</tr>
                          ))}
                        </td>
                      </tr>
                      <hr />
                    </>
                  );
                })}
            </table>
          </TabPanel>
        </Tabs>
      </div>
      <ToastContainer />
    </>
  );
};

export default CustomerView;
