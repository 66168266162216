import React from 'react'

const ItemsGeneral = () => {
    return (
        <>
            <div className="scroll-y noscroll-x fill body scrollbox">

                <div id="ember1427" className="ember-view">
                    <form className="preferences-body product-preference ">
                        <div tabIndex={-1} id="ember1428" className="no-outline ember-view">

                        </div>
                        <div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-6 itemMeasurement">

                                    Set a decimal rate for your item quantity
                                </label>
                                <div className="col-lg-2">
                                    <div id="ember1429" className="ember-view">
                                        <div className="ac-box ">
                                            <span className="ac-selected form-control " tabIndex={0}>
                                                <span data-integrity-label>2</span>

                                                <div>

                                                    <i className="zf-ac-toggler">
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x={0}
                                                            y={0}
                                                            viewBox="0 0 512 512"
                                                            xmlSpace="preserve"
                                                        >
                                                            <path d="M493.9 227.8c-21.4-21-56.1-21.1-77.6-.3L256 382.9 95.7 227.6c-21.5-20.8-56.3-20.7-77.6.3-10.6 10.4-15.9 24-15.9 37.7 0 13.8 5.4 27.5 16.2 38l199 192.9c21.4 20.7 55.9 20.7 77.3 0l199-192.9c21.4-20.9 21.6-54.8.2-75.8z" />
                                                        </svg>
                                                    </i>
                                                </div>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-form-label col-lg-6 itemMeasurement">

                                    Measure item dimensions in:
                                </label>
                                <div className="col-lg-2">
                                    <div id="ember1430" className="ember-view">
                                        <div className="ac-box ">
                                            <span className="ac-selected form-control " tabIndex={0}>
                                                <span data-integrity-label>cm</span>

                                                <div>

                                                    <i className="zf-ac-toggler">
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x={0}
                                                            y={0}
                                                            viewBox="0 0 512 512"
                                                            xmlSpace="preserve"
                                                        >
                                                            <path d="M493.9 227.8c-21.4-21-56.1-21.1-77.6-.3L256 382.9 95.7 227.6c-21.5-20.8-56.3-20.7-77.6.3-10.6 10.4-15.9 24-15.9 37.7 0 13.8 5.4 27.5 16.2 38l199 192.9c21.4 20.7 55.9 20.7 77.3 0l199-192.9c21.4-20.9 21.6-54.8.2-75.8z" />
                                                        </svg>
                                                    </i>
                                                </div>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-form-label col-lg-6 itemMeasurement">

                                    Measure item weights in:
                                </label>
                                <div className="col-lg-2">
                                    <div id="ember1431" className="ember-view">
                                        <div className="ac-box ">
                                            <span className="ac-selected form-control " tabIndex={0}>
                                                <span data-integrity-label>kg</span>

                                                <div>

                                                    <i className="zf-ac-toggler">
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x={0}
                                                            y={0}
                                                            viewBox="0 0 512 512"
                                                            xmlSpace="preserve"
                                                        >
                                                            <path d="M493.9 227.8c-21.4-21-56.1-21.1-77.6-.3L256 382.9 95.7 227.6c-21.5-20.8-56.3-20.7-77.6.3-10.6 10.4-15.9 24-15.9 37.7 0 13.8 5.4 27.5 16.2 38l199 192.9c21.4 20.7 55.9 20.7 77.3 0l199-192.9c21.4-20.9 21.6-54.8.2-75.8z" />
                                                        </svg>
                                                    </i>
                                                </div>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-form-label col-lg-6 itemMeasurement">
                                    <span>Select items when barcodes are scanned using:</span>
                                    <i id="ember1432" className="tooltip-container ember-view">

                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x={0}
                                            y={0}
                                            viewBox="0 0 512 512"
                                            xmlSpace="preserve"
                                            className="icon icon-sm icon-info-circled align-middle"
                                        >
                                            <path d="M255.4 31.9c30.2 0 59.4 5.9 87 17.5 26.6 11.2 50.4 27.4 71 47.9 20.5 20.5 36.6 44.3 47.9 71 11.6 27.6 17.5 56.7 17.5 87s-5.9 59.4-17.5 87c-11.2 26.6-27.4 50.4-47.9 71-20.5 20.5-44.3 36.6-71 47.9-27.6 11.6-56.7 17.5-87 17.5s-59.4-5.9-87-17.5c-26.6-11.2-50.4-27.4-71-47.9-20.5-20.5-36.6-44.3-47.9-71-11.6-27.6-17.5-56.7-17.5-87s5.9-59.4 17.5-87c11.2-26.6 27.4-50.4 47.9-71s44.3-36.6 71-47.9c27.5-11.6 56.7-17.5 87-17.5m0-31.9C114.3 0 0 114.3 0 255.4s114.3 255.4 255.4 255.4 255.4-114.3 255.4-255.4S396.4 0 255.4 0z" />
                                            <path d="M303.4 351.1h-8.2c-4.4 0-8-3.6-8-8v-94.4c0-15.3-11.4-28-26.6-29.7-2.5-.3-4.8-.5-6.7-.5-23.6 0-44.4 11.9-56.8 30l-.1.1v-.1c-1 2-1.7 5.2.7 6.5.6.3 1.2.5 1.8.5h15.9c4.4 0 8 3.6 8 8v79.8c0 4.4-3.6 8-8 8h-8.1c-8.7 0-15.8 7.1-15.8 15.8v.3c0 8.7 7.1 15.8 15.8 15.8h96.1c8.7 0 15.8-7.1 15.8-15.8v-.3c0-8.9-7.1-16-15.8-16zM255.4 127.7c-17.6 0-31.9 14.3-31.9 31.9s14.3 31.9 31.9 31.9 31.9-14.3 31.9-31.9-14.3-31.9-31.9-31.9z" />
                                        </svg>
                                    </i>
                                </label>
                                <div className="col-lg-2">
                                    <div id="ember1433" className="ember-view">
                                        <div className="ac-box ">
                                            <span className="ac-selected form-control " tabIndex={0}>
                                                <span data-integrity-label>SKU</span>

                                                <div>

                                                    <i className="zf-ac-toggler">
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x={0}
                                                            y={0}
                                                            viewBox="0 0 512 512"
                                                            xmlSpace="preserve"
                                                        >
                                                            <path d="M493.9 227.8c-21.4-21-56.1-21.1-77.6-.3L256 382.9 95.7 227.6c-21.5-20.8-56.3-20.7-77.6.3-10.6 10.4-15.9 24-15.9 37.7 0 13.8 5.4 27.5 16.2 38l199 192.9c21.4 20.7 55.9 20.7 77.3 0l199-192.9c21.4-20.9 21.6-54.8.2-75.8z" />
                                                        </svg>
                                                    </i>
                                                </div>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="form-group">
                                <label className="font-medium">Duplicate Item Name</label>
                                <div className="form-check">

                                    <input
                                        id="a5f64d457"
                                        className="ember-checkbox ember-view form-check-input"
                                        type="checkbox"
                                    />
                                    <label className="form-check-label" htmlFor="a5f64d457">

                                        Allow duplicate item names
                                    </label>
                                </div>
                                <p className="text-muted">
                                    If you allow duplicate item names, all imports involving items
                                    will use SKU as the primary field for mapping.
                                </p>
                                <div className="w-75 alert alert-warning pl-4 p-3">

                                    <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x={0}
                                        y={0}
                                        viewBox="0 0 512 512"
                                        xmlSpace="preserve"
                                        className="icon icon-sm align-text-top fill-danger"
                                    >
                                        <path
                                            d="M508.2 461.2c-14-28.3-215-420.6-227.5-444.5-11.3-21.4-38.1-22.7-49.3-.9-17.1 33.3-217.1 425.4-227 444.8C-8 485 7.7 512.5 28.9 512.5h455c23.2 0 34.6-30.6 24.3-51.3zm-251.7-16c-16.5 0-29.8-14.9-29.8-33.2 0-18.3 13.3-33.2 29.8-33.2s29.8 14.9 29.8 33.2c0 18.4-13.4 33.2-29.8 33.2zm39.4-271.9c-2.5 22.4-21.1 132.2-27.2 168-1.1 6.6-6.3 12.2-12.4 12.2h-.2c-6.3 0-11.6-5.7-12.8-12.6-6.2-36.4-24.7-145.8-27.2-167.9-3-26.5 15.6-50.2 39.6-50.2 24.4 0 43.2 23.6 40.2 50.5z"
                                            id="Layer_2_2_"
                                        />
                                    </svg>
                                    Before you enable this option, make
                                    <a
                                        id="ember1434"
                                        className="ember-view"
                                        href="#/settings/preferences/items/customfields?highlightContent=sku"
                                    >
                                        the SKU field active and mandatory.
                                    </a>


                                </div>
                            </div>
                            <hr />


                            <div className="form-group row">
                                <div className="col-lg-7">
                                    <label className="font-large">Price Lists</label>
                                    <a
                                        href="https://zoho.com/in/inventory/help/items/price-list.html"
                                        target="_blank"
                                        rel="nofollow noreferrer noopener"
                                    >
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x={0}
                                            y={0}
                                            viewBox="0 0 512 512"
                                            xmlSpace="preserve"
                                            className="icon cursor-pointer icon-lg align-text-bottom"
                                        >
                                            <path d="M317.1 147.5c-15.1-13.8-35.5-20.8-60.5-20.8-23.7 0-43.1 6.5-57.7 19.4-14.6 12.9-23.5 31.5-26.4 55.5l-.6 4.9 40.4 4.8.7-4.6c2.5-15.8 7.7-27.5 15.4-34.7 7.7-7.2 17.1-10.7 28.7-10.7 12 0 21.9 3.9 30.1 11.9 8.2 8 12.2 16.9 12.2 27.3 0 5.6-1.3 10.7-4 15.4-2.8 4.9-9.3 11.9-19.3 20.7-10.7 9.4-17.9 16.5-22.1 21.5-5.8 7-10 14-12.6 20.8-3.5 9.1-5.3 19.9-5.3 32.3 0 2.1.1 5.1.2 9l.1 4.7h38.4l.1-4.8c.3-14.3 1.4-21.4 2.3-24.7 1.3-4.7 3.2-8.8 5.9-12.5 2.8-3.8 9-10 18.5-18.4 15.1-13.4 25.1-24.6 30.4-34.2 5.4-9.7 8.1-20.4 8.1-31.9 0-19.9-7.7-37-23-50.9zM256.3 385.3c12.1 0 22-9.8 22-22 0-12.1-9.8-22-22-22-12.1 0-22 9.8-22 22s9.8 22 22 22z" />
                                            <path d="M437.4 74.6C388.9 26.1 324.5-.5 256-.5S123.1 26.2 74.6 74.6C26.1 123.1-.5 187.5-.5 256s26.7 132.9 75.1 181.4c48.5 48.5 112.9 75.1 181.4 75.1s132.9-26.7 181.4-75.1c48.5-48.5 75.1-112.9 75.1-181.4s-26.6-132.9-75.1-181.4zm-22.6 340.2c-42.4 42.4-98.8 65.8-158.8 65.8s-116.4-23.4-158.8-65.8C54.8 372.4 31.5 316 31.5 256S54.8 139.6 97.2 97.2C139.6 54.8 196 31.5 256 31.5s116.4 23.4 158.8 65.8c42.4 42.4 65.8 98.8 65.8 158.8s-23.4 116.3-65.8 158.7z" />
                                        </svg>
                                    </a>
                                    <div className="form-check">

                                        <input
                                            id="a7982d120"
                                            className="ember-checkbox ember-view form-check-input"
                                            type="checkbox"
                                        />
                                        <label className="form-check-label" htmlFor="a7982d120">

                                            Enable Price Lists
                                        </label>
                                    </div>
                                    <div className="form-check">

                                        <input
                                            id="a7ee0bad9"
                                            className="ember-checkbox ember-view form-check-input"
                                            type="checkbox"
                                        />
                                        <label className="form-check-label" htmlFor="a7ee0bad9">

                                            Apply price list on each item in a transaction
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="form-group">
                                <label className="font-large">Composite Items</label>
                                <div className="form-check">

                                    <input
                                        id="aa15ad9dd"
                                        className="ember-checkbox ember-view form-check-input"
                                        type="checkbox"
                                    />
                                    <label className="form-check-label" htmlFor="aa15ad9dd">

                                        Enable Composite Items
                                    </label>
                                </div>
                            </div>
                            <hr />
                            <div className="form-group row">
                                <div className="col-lg-10">



                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <label className="col-form-label">
                                                <span>
                                                    <span className="required">Inventory Start Date</span>
                                                    &nbsp;
                                                    <i
                                                        id="ember1435"
                                                        className="tooltip-container ember-view"
                                                    >

                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x={0}
                                                            y={0}
                                                            viewBox="0 0 512 512"
                                                            xmlSpace="preserve"
                                                            className="icon icon-sm icon-info-circled align-text-top"
                                                        >
                                                            <path d="M255.4 31.9c30.2 0 59.4 5.9 87 17.5 26.6 11.2 50.4 27.4 71 47.9 20.5 20.5 36.6 44.3 47.9 71 11.6 27.6 17.5 56.7 17.5 87s-5.9 59.4-17.5 87c-11.2 26.6-27.4 50.4-47.9 71-20.5 20.5-44.3 36.6-71 47.9-27.6 11.6-56.7 17.5-87 17.5s-59.4-5.9-87-17.5c-26.6-11.2-50.4-27.4-71-47.9-20.5-20.5-36.6-44.3-47.9-71-11.6-27.6-17.5-56.7-17.5-87s5.9-59.4 17.5-87c11.2-26.6 27.4-50.4 47.9-71s44.3-36.6 71-47.9c27.5-11.6 56.7-17.5 87-17.5m0-31.9C114.3 0 0 114.3 0 255.4s114.3 255.4 255.4 255.4 255.4-114.3 255.4-255.4S396.4 0 255.4 0z" />
                                                            <path d="M303.4 351.1h-8.2c-4.4 0-8-3.6-8-8v-94.4c0-15.3-11.4-28-26.6-29.7-2.5-.3-4.8-.5-6.7-.5-23.6 0-44.4 11.9-56.8 30l-.1.1v-.1c-1 2-1.7 5.2.7 6.5.6.3 1.2.5 1.8.5h15.9c4.4 0 8 3.6 8 8v79.8c0 4.4-3.6 8-8 8h-8.1c-8.7 0-15.8 7.1-15.8 15.8v.3c0 8.7 7.1 15.8 15.8 15.8h96.1c8.7 0 15.8-7.1 15.8-15.8v-.3c0-8.9-7.1-16-15.8-16zM255.4 127.7c-17.6 0-31.9 14.3-31.9 31.9s14.3 31.9 31.9 31.9 31.9-14.3 31.9-31.9-14.3-31.9-31.9-31.9z" />
                                                        </svg>
                                                    </i>
                                                </span>

                                            </label>
                                        </div>
                                        <div className="col-lg-5">
                                            <input
                                                placeholder="dd/MM/yyyy"
                                                id="ember1436"
                                                className="ember-text-field zf-date-picker date-picker ember-view form-control"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <label className="font-large pb-3">
                                        Advanced Inventory Tracking
                                    </label>
                                    <div className="form-check">

                                        <input
                                            id="a410a88fc"
                                            className="ember-checkbox ember-view form-check-input"
                                            type="checkbox"
                                        />
                                        <label className="form-check-label" htmlFor="a410a88fc">

                                            Enable Serial Number Tracking
                                        </label>
                                    </div>
                                    <div className="form-check">

                                        <input
                                            id="aedbe1b58"
                                            className="ember-checkbox ember-view form-check-input"
                                            type="checkbox"
                                        />
                                        <label className="form-check-label" htmlFor="aedbe1b58">

                                            Enable Batch Tracking
                                        </label>
                                    </div>
                                    <hr />
                                    <div className="form-check">

                                        <input
                                            id="aaa9c0f3d"
                                            className="ember-checkbox ember-view form-check-input"
                                            type="checkbox"
                                        />
                                        <label className="form-check-label" htmlFor="aaa9c0f3d">

                                            Prevent stock from going below zero
                                            <i id="ember1437" className="tooltip-container ember-view">

                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x={0}
                                                    y={0}
                                                    viewBox="0 0 512 512"
                                                    xmlSpace="preserve"
                                                    className="icon icon-sm align-text-top icon-help-circled"
                                                >
                                                    <path d="M317.1 147.5c-15.1-13.8-35.5-20.8-60.5-20.8-23.7 0-43.1 6.5-57.7 19.4-14.6 12.9-23.5 31.5-26.4 55.5l-.6 4.9 40.4 4.8.7-4.6c2.5-15.8 7.7-27.5 15.4-34.7 7.7-7.2 17.1-10.7 28.7-10.7 12 0 21.9 3.9 30.1 11.9 8.2 8 12.2 16.9 12.2 27.3 0 5.6-1.3 10.7-4 15.4-2.8 4.9-9.3 11.9-19.3 20.7-10.7 9.4-17.9 16.5-22.1 21.5-5.8 7-10 14-12.6 20.8-3.5 9.1-5.3 19.9-5.3 32.3 0 2.1.1 5.1.2 9l.1 4.7h38.4l.1-4.8c.3-14.3 1.4-21.4 2.3-24.7 1.3-4.7 3.2-8.8 5.9-12.5 2.8-3.8 9-10 18.5-18.4 15.1-13.4 25.1-24.6 30.4-34.2 5.4-9.7 8.1-20.4 8.1-31.9 0-19.9-7.7-37-23-50.9zM256.3 385.3c12.1 0 22-9.8 22-22 0-12.1-9.8-22-22-22-12.1 0-22 9.8-22 22s9.8 22 22 22z" />
                                                    <path d="M437.4 74.6C388.9 26.1 324.5-.5 256-.5S123.1 26.2 74.6 74.6C26.1 123.1-.5 187.5-.5 256s26.7 132.9 75.1 181.4c48.5 48.5 112.9 75.1 181.4 75.1s132.9-26.7 181.4-75.1c48.5-48.5 75.1-112.9 75.1-181.4s-26.6-132.9-75.1-181.4zm-22.6 340.2c-42.4 42.4-98.8 65.8-158.8 65.8s-116.4-23.4-158.8-65.8C54.8 372.4 31.5 316 31.5 256S54.8 139.6 97.2 97.2C139.6 54.8 196 31.5 256 31.5s116.4 23.4 158.8 65.8c42.4 42.4 65.8 98.8 65.8 158.8s-23.4 116.3-65.8 158.7z" />
                                                </svg>
                                            </i>
                                        </label>
                                    </div>
                                    <div className="form-check">

                                        <input
                                            id="a6d87e5a3"
                                            className="ember-checkbox ember-view form-check-input"
                                            type="checkbox"
                                        />
                                        <label className="form-check-label" htmlFor="a6d87e5a3">

                                            Show an Out of Stock warning when an item's stock drops below
                                            zero
                                            <i id="ember1438" className="tooltip-container ember-view">

                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x={0}
                                                    y={0}
                                                    viewBox="0 0 512 512"
                                                    xmlSpace="preserve"
                                                    className="icon icon-sm align-text-top icon-help-circled"
                                                >
                                                    <path d="M317.1 147.5c-15.1-13.8-35.5-20.8-60.5-20.8-23.7 0-43.1 6.5-57.7 19.4-14.6 12.9-23.5 31.5-26.4 55.5l-.6 4.9 40.4 4.8.7-4.6c2.5-15.8 7.7-27.5 15.4-34.7 7.7-7.2 17.1-10.7 28.7-10.7 12 0 21.9 3.9 30.1 11.9 8.2 8 12.2 16.9 12.2 27.3 0 5.6-1.3 10.7-4 15.4-2.8 4.9-9.3 11.9-19.3 20.7-10.7 9.4-17.9 16.5-22.1 21.5-5.8 7-10 14-12.6 20.8-3.5 9.1-5.3 19.9-5.3 32.3 0 2.1.1 5.1.2 9l.1 4.7h38.4l.1-4.8c.3-14.3 1.4-21.4 2.3-24.7 1.3-4.7 3.2-8.8 5.9-12.5 2.8-3.8 9-10 18.5-18.4 15.1-13.4 25.1-24.6 30.4-34.2 5.4-9.7 8.1-20.4 8.1-31.9 0-19.9-7.7-37-23-50.9zM256.3 385.3c12.1 0 22-9.8 22-22 0-12.1-9.8-22-22-22-12.1 0-22 9.8-22 22s9.8 22 22 22z" />
                                                    <path d="M437.4 74.6C388.9 26.1 324.5-.5 256-.5S123.1 26.2 74.6 74.6C26.1 123.1-.5 187.5-.5 256s26.7 132.9 75.1 181.4c48.5 48.5 112.9 75.1 181.4 75.1s132.9-26.7 181.4-75.1c48.5-48.5 75.1-112.9 75.1-181.4s-26.6-132.9-75.1-181.4zm-22.6 340.2c-42.4 42.4-98.8 65.8-158.8 65.8s-116.4-23.4-158.8-65.8C54.8 372.4 31.5 316 31.5 256S54.8 139.6 97.2 97.2C139.6 54.8 196 31.5 256 31.5s116.4 23.4 158.8 65.8c42.4 42.4 65.8 98.8 65.8 158.8s-23.4 116.3-65.8 158.7z" />
                                                </svg>
                                            </i>
                                        </label>
                                    </div>
                                    <div className="form-check" id="reorder-level">

                                        <input
                                            id="ad7e898a8"
                                            className="ember-checkbox ember-view form-check-input"
                                            type="checkbox"
                                        />
                                        <label className="form-check-label" htmlFor="ad7e898a8">

                                            Notify me if an item's quantity reaches the reorder point
                                        </label>
                                    </div>

                                    <div className="form-check">

                                        <input
                                            className="form-check-input"
                                            id="a8f73dd13"
                                            type="checkbox"
                                        />
                                        <label className="form-check-label" htmlFor="a8f73dd13">

                                            Track landed cost on items
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="mt-7 ">
                            <a
                                id="ember1439"
                                className="btn btn-primary ember-view"
                                type="submit"
                            >
                                Save
                            </a>
                        </div>
                    </form>

                </div>

            </div>
        </>
    )
}

export default ItemsGeneral