import { PAYMENT_MODE_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
    // 'Authorization': url.Auth_Token
}

export const getPaymentModeKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PAYMENT_MODE_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPaymentModeKeyValueList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addPaymentMode = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PAYMENT_MODE_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addPaymentMode axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
