import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import $ from "jquery";
import Tooltip from "@mui/material/Tooltip";
import { toast, ToastContainer } from "react-toastify";
import { jsPDF } from "jspdf";

import { useHistory, Link } from "react-router-dom";
import {
  getSalesOrderDetail,
  getSalesOrderList,
  logSalesOrderHistory,
} from "../../services/index";

import ViewPdf from "./ViewPdf";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import pyramidLogo from "../../image-gallery/pyramidLogo.png";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EditIcon from "@mui/icons-material/Edit";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const SalesView = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [keys, setKeys] = useState("");
  const [values, setValues] = useState("");

  const [hsnKey, setHsnKey] = useState("");
  const [hsnVal1, setHsnVal1] = useState("");
  const [hsnVal2, setHsnVal2] = useState("");

  const [taxType, setTaxType] = useState("");
  const [orgDetail, setOrgDetail] = useState("");
  const [logData, setLogData] = useState({});
  const [salesOrdersList, setSalesOrdersList] = useState([]);
  const [salesOrderDetail, setSalesOrderDetail] = useState(null);

  let history = useHistory();

  const handleEdit = (orderUuid) => {
    history.push("/inventory/salesorders/" + orderUuid + "/edit");
  };

  const [rows, setRows] = useState([]);
  const getAllSalesOrders = () => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getSalesOrderList(list_data).then((res) => {
      if (res.errorCode == 0) {
        res.responsePacket.data.reverse();
        setSalesOrdersList(res.responsePacket.data);
        setRows(res.responsePacket.data);
      } else {
        toast(res.message);
      }
    });
  };

  useEffect(() => {
    console.log(salesOrderDetail, "salesOrderDetail");
  }, [salesOrderDetail]);

  const pushTaxElements = (orderDetailTax, orderDetailPdfTax) => {
    // setSalesOrderDetailTaxesHtml(orderDetailTax);
    // setSalesOrderDetailPdfTaxesHtml(orderDetailPdfTax);
  };

  const getSalesOrderDetails = (id) => {
    getSalesOrderDetail(id).then((res) => {
      if (res.errorCode == 0) {
        let abc = res.responsePacket.customerAddresses;
        let d = res.responsePacket.items;

        var tot = JSON.parse(JSON.stringify(res.responsePacket.totalAmount));
        let t1 = tot.toFixed(0);
        let t2 = tot.toFixed(1).toString()?.split(".")[1];

        if (t2 > 4) {
          res.responsePacket.totalAmount += 1;
          res.responsePacket.rounding = "+0." + t2+"0";
        } else {
          res.responsePacket.rounding = "-0." + t2+"0";
        }

        console.log(t1, t2, "t1 t2");

        d.map((a) => {
          a.taxBean.newTax = a.taxBean.taxType + a.taxBean.rate.toString();
        });

        let obj = {};
        for (let i = 0; i < d.length; i++) {
          if (obj[d[i].taxBean.newTax]) {
            obj[d[i].taxBean.newTax] += d[i].taxBean.amount;
          } else {
            obj[d[i].taxBean.newTax] = d[i].taxBean.amount;
          }
        }

        setKeys(Object.keys(obj));
        setValues(Object.values(obj));

        let z = JSON.parse(JSON.stringify(d));

        z.map((a) => {
          a.newHsn = a.hsnSac.toString() + a.taxBean.newTax;
        });

        let obj2 = {};
        for (let i = 0; i < z.length; i++) {
          if (obj2[z[i].newHsn]) {
            obj2[z[i].newHsn] += z[i].taxBean.amount;
          } else {
            obj2[z[i].newHsn] = z[i].taxBean.amount;
          }
        }

        let obj3 = {};
        for (let i = 0; i < z.length; i++) {
          if (obj3[z[i].newHsn]) {
            obj3[z[i].newHsn] += z[i].amount;
          } else {
            obj3[z[i].newHsn] = z[i].amount;
          }
        }

        setHsnKey(Object.keys(obj2));
        setHsnVal1(Object.values(obj2));
        setHsnVal2(Object.values(obj3));

        logSalesOrderHistory(res.responsePacket.orderNo).then((res) => {
          if (res.errorCode == 0) {
            const d = res.responsePacket;
            setLogData(d);
          } else {
            toast(res.message);
          }
        });

        let netTax = 0;
        d.map((a) => {
          netTax += a.taxBean.amount;
        });

        res.responsePacket.netTax = netTax;

        let localDate = new Date(res.responsePacket.salesOrderDate)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");

        res.responsePacket.salesOrderDate = localDate;
        setSalesOrderDetail(res.responsePacket);

        let org = JSON.parse(localStorage.getItem("orgDetail"));
        setOrgDetail(org);
        if (org?.beanAddress?.state == undefined) {
          alert("logged In user's organization addres not found ");
        }

        let gst12cust = res.responsePacket.customerGstNumber.substring(0, 2);
        let gst12org = org.gstNumber.substring(0, 2);
        if (gst12cust == gst12org) {
          setTaxType("intra");
        } else {
          setTaxType("inter");
        }

        var taxArray = [];
        var salesOrderTaxesArrayHtml = [];
        var salesOrderPdfTaxesArrayHtml = [];
        res.responsePacket.items &&
          res.responsePacket.items.map((item, index) => {
            if (item.taxUuid != null) {
              if (item.taxUuid.trim() != "") {
                var taxDict = {
                  key: item.taxTitle,
                  value: item.taxUuid,
                  rate: item.taxRate,
                  itemAmount: item.amount,
                };
                taxArray.push(taxDict);
              }
            }
          });
        const uniqueIds = [];
        const uniqueTax = taxArray.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.value);
          if (!isDuplicate) {
            uniqueIds.push(element.value);
            return true;
          }
        });
        const uniqueTaxItemsIds = [];
        const uniqueTaxItemAmounts = [];
        const uniqueTaxItemsAmount = taxArray.filter((element) => {
          const isDuplicate = uniqueTaxItemsIds.includes(element.value);
          if (!isDuplicate) {
            uniqueTaxItemsIds.push(element.value);
            uniqueTaxItemAmounts.push(element.itemAmount);
            return true;
          } else {
            var duplicateIndex = uniqueTaxItemsIds.indexOf(element.value);
            uniqueTaxItemAmounts[duplicateIndex] =
              uniqueTaxItemAmounts[duplicateIndex] + element.itemAmount;
          }
        });
        uniqueTax.map((i, index) => {
          var amount = uniqueTaxItemAmounts[index];
          var taxAmount = (amount * i.rate) / 100;
          var html = (
            <div className="row text-muted">
              <div className="col-lg-6 col-md-6">{i.key}</div>
              <div className="col-lg-6 col-md-6 over-flow">
                ₹ {taxAmount.toFixed(2)}
              </div>
            </div>
          );
          var htmlPdf = (
            <tr style={{ height: "10px" }}>
              <td valign="middle" align="right">
                {i.key}
              </td>
              <td valign="middle" style={{ width: "110px" }}>
                ₹ {taxAmount.toFixed(2)}
              </td>
            </tr>
          );
          salesOrderTaxesArrayHtml.push(html);
          salesOrderPdfTaxesArrayHtml.push(htmlPdf);
          if (index + 1 == uniqueTax.length) {
            pushTaxElements(
              salesOrderTaxesArrayHtml,
              salesOrderPdfTaxesArrayHtml
            );
          }
        });
      } else {
        toast(res.message);
      }
    });
  };

  useEffect(() => {
    getAllSalesOrders();
    getSalesOrderDetails(props.match.params.id);
  }, [props.match.params.id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSearch = (e) => {
    const result = rows.filter((a) => {
      return (
        a.customerTitle?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.orderNo?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.warehouseTitle?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.salesOrderDate?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.salespersonTitle?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.priceListTitle?.toLowerCase().match(e.target.value.toLowerCase())
      );
    });
    setSalesOrdersList(result);
  };

  const filterOptions = [
    { value: "isInvoiced", label: "isInvoiced" },
    { value: "isPaid", label: "isPaid" },
    { value: "isPacked", label: "isPacked" },
    { value: "allOrders", label: "allOrders" },
  ];

  const handleFilter = (e) => {
    if (e.value == "isPaid") {
      var arr1 = rows.filter((data) => data.isPaid);
      setSalesOrdersList(arr1);
    }
    if (e.value == "isInvoiced") {
      var arr2 = rows.filter((data) => data.isInvoiced);
      setSalesOrdersList(arr2);
    }
    if (e.value == "isPacked") {
      var arr3 = rows.filter((data) => data.isPacked);
      setSalesOrdersList(arr3);
    }
    if (e.value == "allOrders") {
      var arr3 = rows.filter((data) => data.orderNo);
      setSalesOrdersList(arr3);
    }
  };

  const handlePrint1 = () => {
    // setPrintDiv(true);
    var doc = new jsPDF("p", "pt", "a4"); //potrait
    // var doc = new jspdf('l', 'pt', "a4");  // For landscape
    doc.html(document.getElementById("pdf"), {
      callback: function (doc) {
        doc.save(salesOrderDetail?.orderNo);
      },
    });
  };

  return (
    <>
      <div
        className="list-column column d-print-none"
        style={{ width: "330px" }}
      >
        <div className="fill list-header topgrad d-print-none">
          <div className="list-filter ">
            <div className="btn-toolbar float-right">
              <div
                id="ember1958"
                className="ember-view btn btn-primary d-flex align-items-center"
              >
                <Link
                  to={"/inventory/salesorders/new"}
                  style={{ color: "#fff" }}
                >
                  <AddIcon />
                  &nbsp;&nbsp;New
                </Link>
              </div>
            </div>

            <div
              id="ember635"
              className="dropdown ember-view clearfix list-title"
            >
              <a
                id="ember636"
                className="dropdown-toggle no-caret ember-view  "
              >
                <span className="filter-title over-flow ">All Sales Order</span>
                &nbsp;
              </a>
            </div>
          </div>
        </div>

        <div className="scroll-y fill body scrollbox list-body">
          <div className="table-responsive overflow-initial ">
            <table
              id="ember7912"
              className="table zi-table ember-view header-fixed"
            >
              <tbody>
                {salesOrdersList &&
                  salesOrdersList.map((d, i) => (
                    <Link to={`/inventory/salesorders/${d.recordId}`}>
                      <div>
                        <tr>
                          <td>{d.customerTitle}</td>
                        </tr>
                        <tr
                          style={{
                            padding: "8px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>{d.orderNo}</div>
                          <div>₹ {d.totalAmount.toFixed(2)}</div>
                        </tr>
                      </div>
                    </Link>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="column content-column" style={{ left: "330px" }}>
        <div className="fill1 header d-flex align-items-center p-4 justify-content-between">
          <div className="btn-toolbar over-flow text-muted font-xlarge">
            <span className="page-header-title">
              {salesOrderDetail?.orderNo}
            </span>{" "}
            &nbsp; &nbsp;
            <span style={{ cursor: "pointer" }} onClick={() => handleShow()}>
              Log History
            </span>
          </div>
          <div className="btn-toolbar d-flex justify-content-end align-items-center">
            <SearchRoundedIcon />
            <input
              className="ember-text-field ember-view form-control col-2"
              type="text"
              placeholder="Search Here"
              onChange={handleSearch}
              style={{ minWidth: "150px" }}
            />
            <Select
              placeholder="Filter Orders"
              options={filterOptions}
              onChange={handleFilter}
              style={{ minWidth: "150px" }}
            />
            <div className="btn-group">
              <button
                className="tooltip-container ember-view btn btn-primary print-btn"
                onClick={handlePrint}
              >
                <LocalPrintshopOutlinedIcon />
              </button>
              <button
                className="tooltip-container btn print-btn"
                onClick={handlePrint1}
              >
                <LocalPrintshopOutlinedIcon />
              </button>
              {/* handlePrint1 */}
            </div>
            {salesOrderDetail?.isInvoiced ? (
              <></>
            ) : (
              <>
                <button
                  className="tooltip-container ember-view btn btn-primary "
                  onClick={() =>
                    handleEdit(
                      salesOrderDetail != null ? (
                        salesOrderDetail.recordId
                      ) : (
                        <></>
                      )
                    )
                  }
                >
                  <EditIcon />
                </button>
                <button
                  className="tooltip-container ember-view btn btn-primary"
                  onClick={() =>
                    history.push({
                      pathname: `/inventory/invoices/new`,
                      state: {
                        invoiceId: salesOrderDetail.recordId,
                        invoiceOrder: true,
                      },
                    })
                  }
                  type="button"
                >
                  <Tooltip title="Create Invoice">
                    <ReceiptIcon />
                  </Tooltip>
                </button>
              </>
            )}
          </div>
        </div>
        <br />
        {salesOrderDetail && salesOrderDetail && orgDetail && orgDetail ? (
          <ViewPdf
            orderDetail={salesOrderDetail}
            itemList={salesOrderDetail.items}
            address={salesOrderDetail.customerAddresses}
            orgDetail={orgDetail}
            taxType={taxType}
            componentRef={componentRef}
            pdfType={"sales"}
            keys={keys}
            values={values}
            handlePrint={handlePrint}
            hsnKey={hsnKey}
            hsnVal1={hsnVal1}
            hsnVal2={hsnVal2}
          />
        ) : (
          <></>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <table>
            {logData.length == 0 ? (
              "No Changes Found"
            ) : (
              <tr>
                <td>Date</td>
                <td>Changes</td>
              </tr>
            )}
            {logData.length >= 1 &&
              logData?.map((history) => {
                return (
                  <>
                    <tr>
                      <td style={{ verticalAlign: "top" }}>
                        {history?.transactionDate?.substring(0, 10)}
                        :&nbsp;&nbsp;
                      </td>

                      <td>
                        {history?.message.split("\n").map((item, i) => (
                          <tr>{item}</tr>
                        ))}
                      </td>
                    </tr>
                    <hr />
                  </>
                );
              })}
          </table>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default SalesView;
