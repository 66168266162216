
import './App.css';
import LoginComponent from './components/LoginComponent';
import Cookies from 'universal-cookie';
import NavigationComponent from './components/SharedComponents/NavigationComponent';
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";
function App() {
  const cookies = new Cookies();

  var loginData1 = cookies.get('loginData1'); 

  if (loginData1 == undefined) {
    return (
      <div>
        <LoginComponent />
      </div>

    );
  }
  else {
    return (
      <div className='h-100'>
        <div className="page-loader-wrapper">
          <div className="loader">
            <div className="page-loader-wrapper" style={{ display: "none" }}>
              <div className="loader">
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
                <div className="bar4"></div>
                <div className="bar5"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="wrapper">
          <nav
            className="navbar navbar-fixed-top"
            style={{ padding: "0" }}
          ></nav>
          <div id="megamenu" className="megamenu vivify driveInTop"></div>
          <div id="rightbar" className="rightbar"></div>
          <div id="left-sidebar" className="sidebar">
            <div className="sidebar-scroll">
              <div className="user-account"></div>
              <nav id="left-sidebar-nav" className="sidebar-nav"></nav>
            </div>
          </div>
        </div>
      
          <NavigationComponent />
      
        {/* <SearchComponent />
        <RightbarComponent />
        <NavigationComponent />
        <ContentWrapperComponent /> */}
      </div>

    );
  }
}

export default App;
