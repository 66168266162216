import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Button from "@mui/material/Button";

import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";

import PreferencesNav from "../Preferences/PreferencesNav";
import { unitGetAllRec, addUnit, updateUnit } from "../../../services/index";

const UnitList = () => {
  const [unitInfo, setUnitInfo] = useState({
    name: "",
    unit: "",
    convFactor: 0,
  });

  const unitOptions = [
    {
      value: "Weight",
      label: "Weight",
    },
    {
      value: "Measure",
      label: "Measure",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const [filteredData, setFilteredData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setUnitInfo("");
    setShow(false);
    setEdit(false);
  };
  const handleShow = () => setShow(true);

  const columns = [
    {
      name: "Name",
      cell: (row) => row.unitName,
    },
    {
      name: "Measuring Unit",
      cell: (row) => row.measuringUnit,
    },
  ];

  useEffect(() => {
    getUnitRecords();
  }, []);

  const getUnitRecords = () => {
    let data = {
      page: 0,
      search: "",
      size: -1,
      uuid: "",
    };
    unitGetAllRec(data).then((res) => {
      if (res.errorCode == 0) {
        setFilteredData(res.responsePacket.data);
      }
    });
  };

  const handleClicked = (data) => {
    setShow(true);
    setEdit(true);
    setUnitInfo({
      name: data.unitName,
      unit: { label: data.measuringUnit, value: data.measuringUnit },
      convFactor: data.unitConversionFactor,
      id: data.recordId,
    });
  };

  const handleSave = () => {
    let data = {
      unitName: unitInfo.name,
      unitConversionFactor: unitInfo.convFactor,
      measuringUnit: unitInfo.unit.value,
    };

    if (edit) {
      updateUnit(unitInfo.id, data).then((res) => {
        if (res.errorCode == 0) {
          toast("👍" + res.message);
          handleClose();
          getUnitRecords();
        } else {
          toast("🙁" + res.message);
        }
      });
    } else {
      addUnit(data).then((res) => {
        if (res.errorCode == 0) {
          toast("👍" + res.message);
          handleClose();
          getUnitRecords();
        } else {
          toast("🙁" + res.message);
        }
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          Unit Info
          <hr />
          <table>
            <tr>
              <td style={{ width: "150px" }}>Name:</td>
              <td>
                <input
                  className="form-control"
                  value={unitInfo.name}
                  onChange={(e) => {
                    setUnitInfo({ ...unitInfo, name: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Unit:</td>
              <td>
                <Select
                  value={unitInfo.unit}
                  options={unitOptions}
                  onChange={(e) => {
                    setUnitInfo({ ...unitInfo, unit: e });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Conversion Factor:</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  value={unitInfo.convFactor}
                  onChange={(e) => {
                    setUnitInfo({ ...unitInfo, convFactor: e.target.value });
                  }}
                />
              </td>
              <Tooltip
                title={
                  <h4>
                    Default units are Kg, Litre, Metre
                    <br />
                    Put corresponding conversion value to these units
                  </h4>
                }
              >
                <InfoIcon />
              </Tooltip>
            </tr>
          </table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="column list-column d-print-none column-small">
        <PreferencesNav />
      </div>
      <div className="column content-column">
        <div className="header clearfix" style={{ borderBottom: "none" }}>
          <span className="float-left" style={{ fontSize: "35px" }}>
            Unit List
          </span>
        </div>

        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            columns={columns}
            data={filteredData}
            fixedHeader
            subHeader
            subHeaderAlign="right"
            pointerOnHover={true}
            onRowClicked={(data) => {
              handleClicked(data);
            }}
            subHeaderComponent={
              <>
                <Button variant="contained" onClick={handleShow}>
                  <AddIcon />
                  New
                </Button>
              </>
            }
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UnitList;
