import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import Tooltip from "@mui/material/Tooltip";
import { toast, ToastContainer } from "react-toastify";

import { useHistory, Link } from "react-router-dom";
import {
  getPurchaseDetails,
  getAllRecords, 
  logPurchaseOrderHistory,
} from "../../services/index";

import ViewPdf from "../Sales/ViewPdf";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EditIcon from "@mui/icons-material/Edit";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const PurchaseView = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [keys, setKeys] = useState("");
  const [values, setValues] = useState("");

  const [hsnKey, setHsnKey] = useState("");
  const [hsnVal1, setHsnVal1] = useState("");
  const [hsnVal2, setHsnVal2] = useState("");
  const [taxType, setTaxType] = useState("");
  const [orgDetail, setOrgDetail] = useState("");
  const [logData, setLogData] = useState({});
  const [purchaseOrdersList, setPurchaseOrdersList] = useState([]);
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState(null);

  let history = useHistory();

  const handleEdit = (orderUuid) => {
    history.push("/inventory/purchaseorders/" + orderUuid + "/edit");
  };

  const [rows, setRows] = useState([]);
  const getAllPurchaseOrders = () => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getAllRecords(list_data).then((res) => {
      if (res.errorCode == 0) {
        res.responsePacket.data.reverse();
        setPurchaseOrdersList(res.responsePacket.data);
        setRows(res.responsePacket.data);
      } else {
        toast(res.message);
      }
    });
  };

  useEffect(() => {
    console.log(purchaseOrderDetail, "purchaseOrderDetail");
    console.log(purchaseOrdersList, "purchaseOrdersList");
  }, [purchaseOrderDetail,purchaseOrdersList]);

  const pushTaxElements = (orderDetailTax, orderDetailPdfTax) => {
    // setPurchaseOrderDetailtaxBeanHtml(orderDetailTax);
    // setPurchaseOrderDetailPdftaxBeanHtml(orderDetailPdfTax);
  };

  const getPurchase = (id) => {
    getPurchaseDetails(id).then((res) => {
      if (res.errorCode == 0) {
        let localDate= new Date(res.responsePacket.purchaseOrderDate).toLocaleDateString();
        res.responsePacket.purchaseOrderDate=localDate

        
        let abc = res.responsePacket.address;
        let d = res.responsePacket.items;

        d.map((a) => {
          a.taxBean=a.taxes
          a.taxBean.newTax = a.taxes?.taxType + a.taxes?.rate?.toString();
        });

        let obj = {};
        for (let i = 0; i < d.length; i++) {
          if (obj[d[i].taxBean.newTax]) {
            obj[d[i].taxBean.newTax] += d[i].taxBean.amount;
          } else {
            obj[d[i].taxBean.newTax] = d[i].taxBean.amount;
          }
        }

        console.log(obj, "obj");
   
        setKeys(Object.keys(obj));
        setValues(Object.values(obj));  

        let z = JSON.parse(JSON.stringify(d));

        z.map((a) => {
          a.newHsn = a.hsnSac.toString() + a.taxBean.newTax;
        });

        let obj2 = {};
        for (let i = 0; i < z.length; i++) {
          if (obj2[z[i].newHsn]) {
            obj2[z[i].newHsn] += z[i].taxBean.amount;
          } else {
            obj2[z[i].newHsn] = z[i].taxBean.amount;
          }
        }

        let obj3 = {};
        for (let i = 0; i < z.length; i++) {
          if (obj3[z[i].newHsn]) {
            obj3[z[i].newHsn] += z[i].amount;
          } else {
            obj3[z[i].newHsn] = z[i].amount;
          }
        }

        setHsnKey(Object.keys(obj2));
        setHsnVal1(Object.values(obj2));
        setHsnVal2(Object.values(obj3));




        logPurchaseOrderHistory(res.responsePacket.purchaseOrderNo).then((res) => {
          if (res.errorCode == 0) {
            const d = res.responsePacket;
            setLogData(d);
          } else {
            toast(res.message);
          }
        });

        let netTax = 0;
        d.map((a) => {
          netTax += a.taxBean.amount;
        });

        console.log(netTax, "netTax");
        res.responsePacket.netTax = netTax;
       

        let org = JSON.parse(localStorage.getItem("orgDetail"));
        setOrgDetail(org);
        if (org?.beanAddress?.state == undefined) {
          alert("logged In user's organization addres not found ");
        }
        let orgShipAdd = org?.beanAddress?.state;
        let shipAdd = abc?.filter((a) => a.addressTypeEnum == "Shipping");
        console.log(shipAdd[0].state, orgShipAdd, " shipAdd orgShipAdd");

        if (shipAdd[0].state == orgShipAdd) {
          setTaxType("intra");
        } else {
          setTaxType("inter");
        }


        

        var taxArray = [];
        var purchaseOrdertaxBeanArrayHtml = [];
        var purchaseOrderPdftaxBeanArrayHtml = [];
        res.responsePacket.items &&
          res.responsePacket.items.map((item, index) => {
            if (item.taxUuid != null) {
              if (item.taxUuid.trim() != "") {
                var taxDict = {
                  key: item.taxTitle,
                  value: item.taxUuid,
                  rate: item.taxRate,
                  itemAmount: item.amount,
                };
                taxArray.push(taxDict);
              }
            }
          });
        const uniqueIds = [];
        const uniqueTax = taxArray.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.value);
          if (!isDuplicate) {
            uniqueIds.push(element.value);
            return true;
          }
        });
        const uniqueTaxItemsIds = [];
        const uniqueTaxItemAmounts = [];
        const uniqueTaxItemsAmount = taxArray.filter((element) => {
          const isDuplicate = uniqueTaxItemsIds.includes(element.value);
          if (!isDuplicate) {
            uniqueTaxItemsIds.push(element.value);
            uniqueTaxItemAmounts.push(element.itemAmount);
            return true;
          } else {
            var duplicateIndex = uniqueTaxItemsIds.indexOf(element.value);
            uniqueTaxItemAmounts[duplicateIndex] =
              uniqueTaxItemAmounts[duplicateIndex] + element.itemAmount;
          }
        });
        uniqueTax.map((i, index) => {
          var amount = uniqueTaxItemAmounts[index];
          var taxAmount = (amount * i.rate) / 100;
          var html = (
            <div className="row text-muted">
              <div className="col-lg-6 col-md-6">{i.key}</div>
              <div className="col-lg-6 col-md-6 over-flow">
                ₹ {taxAmount.toFixed(2)}
              </div>
            </div>
          );
          var htmlPdf = (
            <tr style={{ height: "10px" }}>
              <td valign="middle" align="right">
                {i.key}
              </td>
              <td valign="middle" style={{ width: "110px" }}>
                ₹ {taxAmount.toFixed(2)}
              </td>
            </tr>
          );
          purchaseOrdertaxBeanArrayHtml.push(html);
          purchaseOrderPdftaxBeanArrayHtml.push(htmlPdf);
          if (index + 1 == uniqueTax.length) {
            pushTaxElements(
              purchaseOrdertaxBeanArrayHtml,
              purchaseOrderPdftaxBeanArrayHtml
            );
          }
        });



        setPurchaseOrderDetail(res.responsePacket);
      } else {
        toast(res.message);
      }
    });
  };

  useEffect(() => {
    getAllPurchaseOrders();
    getPurchase(props.match.params.id);
  }, [props.match.params.id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSearch = (e) => {
    const result = rows.filter((a) => {
      return (
        a.vendorTitle?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.purchaseOrderNo?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.warehouseTitle?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.purchaseOrderDate?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.priceListTitle?.toLowerCase().match(e.target.value.toLowerCase())
      );
    });
    setPurchaseOrdersList(result);
  };

  const filterOptions = [
    { value: "isBilled", label: "isBilled" },
    { value: "isPaid", label: "isPaid" },
    { value: "isPacked", label: "isPacked" },
    { value: "allOrders", label: "allOrders" },
  ];

  const handleFilter = (e) => {
    if (e.value == "isPaid") {
      var arr1 = rows.filter((data) => data.isPaid);
      setPurchaseOrdersList(arr1);
    }
    if (e.value == "isBilled") {
      var arr2 = rows.filter((data) => data.isBilled);
      setPurchaseOrdersList(arr2);
    }
    if (e.value == "isPacked") {
      var arr3 = rows.filter((data) => data.isPacked);
      setPurchaseOrdersList(arr3);
    }
    if (e.value == "allOrders") {
      var arr3 = rows.filter((data) => data.orderNo);
      setPurchaseOrdersList(arr3);
    }
  };

  return (
    <>
      <div
        className="list-column column d-print-none"
        style={{ width: "300px" }}
      >
        <div className="fill list-header topgrad d-print-none">
          <div className="list-filter ">
            <div className="btn-toolbar float-right">
              <div
                id="ember1958"
                className="ember-view btn btn-primary d-flex align-items-center"
              >
                <Link
                  to={"/inventory/purchase/new"}
                  style={{ color: "#fff" }}
                >
                  <AddIcon />
                  &nbsp;&nbsp;New
                </Link>
              </div>
            </div>

            <div
              id="ember635"
              className="dropdown ember-view clearfix list-title"
            >
              <a
                id="ember636"
                className="dropdown-toggle no-caret ember-view  "
              >
                <span className="filter-title over-flow ">Purchase Orders</span>
                &nbsp;
              </a>
            </div>
          </div>
        </div>

        <div className="scroll-y fill body scrollbox list-body">
          <div className="table-responsive overflow-initial ">
            <table
              id="ember7912"
              className="table zi-table table-hover ember-view header-fixed"
            >
              <tbody>
                {purchaseOrdersList &&
                  purchaseOrdersList.map((d, i) => (
                    <tr
                      key={i}
                      tabIndex="-1"
                      id="ember7931"
                      className="ember-view"
                    >
                      <td>
                        <Link to={`/inventory/purchase/${d.recordId}`}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <table>
                              <tr>{d.vendorTitle}</tr>
                              <tr>{d.purchaseOrderNo}</tr>
                            </table>
                            <table>
                              <tr>₹ {d.totalAmount.toFixed(2)}</tr>
                            </table>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="column content-column" style={{ left: "301px" }}>
        <div className="fill1 header d-flex align-items-center p-4 justify-content-between">
          <div className="btn-toolbar over-flow text-muted font-xlarge">
            <span className="page-header-title">
              {purchaseOrderDetail?.purchaseOrderNo}
            </span>{" "}
            &nbsp; &nbsp;
            <span style={{ cursor: "pointer" }} onClick={() => handleShow()}>
              Log History
            </span>
          </div>
          <div className="btn-toolbar d-flex justify-content-end align-items-center">
            <SearchRoundedIcon />
            <input
              className="ember-text-field ember-view form-control col-2"
              type="text"
              placeholder="Search Here"
              onChange={handleSearch}
              style={{ minWidth: "150px" }}
            />
            {/* <Select
              placeholder="Filter Orders"
              options={filterOptions}
              onChange={handleFilter}
              style={{ minWidth: "150px" }}
            /> */}
            <div className="btn-group">
              <button
                className="tooltip-container ember-view btn btn-primary print-btn"
                onClick={handlePrint}
              >
                <LocalPrintshopOutlinedIcon />
              </button>
            </div>
            {purchaseOrderDetail?.isBilled ? (
              <></>
            ) : (
              <>
                <button
                  className="tooltip-container ember-view btn btn-primary "
                  onClick={() =>
                    handleEdit(
                      purchaseOrderDetail != null ? (
                        purchaseOrderDetail.recordId
                      ) : (
                        <></>
                      )
                    )
                  }
                >
                  <EditIcon />
                </button>
                <button
                  className="tooltip-container ember-view btn btn-primary"
                  onClick={() =>
                    history.push({
                      pathname: `/inventory/convertbill/${purchaseOrderDetail.recordId}`,
                      state: "bill",
                    })
                  }
                  type="button"
                >
                  <Tooltip title="Create Bill">
                    <ReceiptIcon />
                  </Tooltip>
                </button>
              </>
            )}

         
          </div>
        </div>
        <br />
        {purchaseOrderDetail && purchaseOrderDetail && orgDetail && orgDetail ? (
          <ViewPdf
            orderDetail={purchaseOrderDetail}
            itemList={purchaseOrderDetail.items}
            address={purchaseOrderDetail.address}
            orgDetail={orgDetail}
            taxType={taxType}
            componentRef={componentRef}
            pdfType={"purchase"}
            keys={keys}
            values={values}
            handlePrint={handlePrint}
            hsnKey={hsnKey}
            hsnVal1={hsnVal1}
            hsnVal2={hsnVal2}
          />
        ) : (
          <></>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <table>
            {logData.length == 0 ? (
              "No Changes Found"
            ) : (
              <tr>
                <td>Date</td>
                <td>Changes</td>
              </tr>
            )}
            {logData.length >= 1 &&
              logData?.map((history) => {
                return (
                  <>
                    <tr>
                      <td style={{ verticalAlign: "top" }}>
                        {history?.transactionDate?.substring(0, 10)}
                        :&nbsp;&nbsp;
                      </td>

                      <td>
                        {history?.message.split("\n").map((item, i) => (
                          <tr>{item}</tr>
                        ))}
                      </td>
                    </tr>
                    <hr />
                  </>
                );
              })}
          </table>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default PurchaseView;
