// prod :ingredient
// rest :allitem

import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  getItemsForRecipe,
  addRecipeIngredients,
  getRecipeDetailsByUUID,
  updateRecipeIngradients,
  getNotIngredientItemList1,
  getIsIngredientItemList1,
  getAllActiveWareRecords,
  getActiveItemRecords,
} from "../../services/index";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const RecipeNew = (props) => {
  console.log(props, "props");
  const recipeId = props?.match?.params?.id;
  const history = useHistory();
  const [heading, setHeading] = useState(null);
  const [wareOptions, setWareOptions] = useState("");
  const [ware, setWare] = useState("true");

  const [itemOptions, setItemOptions] = useState("");
  const [itemOptions2, setItemOptions2] = useState("");
  const [requestData, setRequestData] = useState({
    title: "",
    quantity: 0,
    batchno: "",
  });
  const [notIngredientOptions, setNotIngredientOptions] = useState("");
  const [notIngredientItem, setNotIngredientItem] = useState("");
  const [itemUnit1, setItemUnit1] = useState("");

  useEffect(() => {
    const data = {
      page: 0,
      search: "",
      size: -1,
      uuid: "",
    };

    getAllActiveWareRecords(data).then((res) => {
      console.log(res, "res");
      if (res.errorCode == 0) {
        const options = res.responsePacket.data.map((d) => ({
          value: d,
          ware: { label: d.title, value: d.recordId },
          label: (
            <>
              <div>{d.title}</div>
              <div>{d.isRestaurent ? "Rest" : "not Rest"}</div>
            </>
          ),
        }));
        setWareOptions(options);
      }
    });
  }, []);

  const tempRows2 = {
    step: 1,
    item: "",
    qty: 1,
    unit: "",
    rate: 0,
    yield: 0,
    note: "",
    amount: 0,
  };

  const [rows2, setRows2] = useState([tempRows2]);
  const [total2, setTotal2] = useState(0);

  const handlAddRow2 = () => {
    setRows2([...rows2, tempRows2]);
  };

  const handleClear2 = (id, i) => {
    const obj = rows2.slice();
    obj.splice(i, 1);
    setRows2(obj);
    let sum = 0;
    obj.forEach((item) => {
      sum += item.amount;
    });
    setTotal2(sum);

    let filterOptions = [...itemOptions];
    itemOptions2 &&
      itemOptions2.map((a) => {
        if (a.value === id) {
          filterOptions.push(a);
        }
      });
    setItemOptions(filterOptions);
  };

  // const handleClear2 = (i) => {
  //   let obj = [...rows2];
  //   obj.splice(i, 1);
  //   setRows2(obj);
  //   let sum = 0;
  //   rows2 &&
  //     rows2.map((data) => {
  //       sum += data.amount;
  //       setTotal2(sum);
  //     });
  // };

  const handleStep = (e, i) => {
    const obj = [...rows2];
    obj[i].step = Number(e.target.value);
    setRows2(obj);
  };

  const handleItem = (e, i) => {
    console.log(e, "e");
    const obj = [...rows2];
    obj[i].item = e;
    obj[i].unit = e.weightUnitTitle;
    obj[i].rate = e.price;
    obj[i].amount = obj[i].rate * obj[i].qty;
    setRows2(obj);
    handleTotal2();

    console.log(itemOptions2, "itemOptions2");
    var setSend = false;
    var filterOptions = [];
    itemOptions2 &&
      itemOptions2.map((a) => {
        rows2 &&
          rows2.map((b) => {
            console.log(b, "b");
            if (a.value === b?.item?.value) {
              setSend = true;
            }
          });
        if (!setSend) {
          filterOptions.push(a);
        }
        setSend = false;
      });
    setItemOptions(filterOptions);
  };

  const handleQty = (e, i) => {
    const obj = [...rows2];
    obj[i].qty = Number(e.target.value);
    obj[i].amount = obj[i].rate * obj[i].qty;
    setRows2(obj);
    handleTotal2();
  };

  const handleYield = (e, i) => {
    const obj = [...rows2];
    obj[i].yield = Number(e.target.value);
    setRows2(obj);
  };

  const handleNote = (e, i) => {
    const obj = [...rows2];
    obj[i].note = e.target.value;
    setRows2(obj);
  };

  const handleTotal2 = () => {
    let sum = 0;
    rows2 &&
      rows2.map((data) => {
        sum += data.amount;
        setTotal2(sum);
      });
  };

  useEffect(() => {
    getNotIngredientItemList1().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.recordId,
          label: d.title,
          packUnitId: d.packUnitId,
          packUnitTitle: d.packUnitTitle,
          sellingPriceValue: d.sellingPriceValue,
        }));
        setNotIngredientOptions(options);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    if (recipeId != undefined || recipeId != null) {
      if (recipeId) {
        setHeading("Edit Recipe ");
      } else {
        setHeading("New Recipe ");
      }
    }

    if (recipeId != null) {
      getRecipeDetails(recipeId);
    }
  }, []);

  const getRecipeDetails = (id) => {
    getRecipeDetailsByUUID(id).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;
        let tempRows2 = [];
        d.ingredientsBeanList &&
          d.ingredientsBeanList.map((c) => {
            let obj = {
              step: c.stepOrder,
              item: {
                label: c.itemTitle,
                value: c.itemId,
                weightUnitId: c.quantityUnitId,
                weightUnitTitle: c.quantityUnitTitle,
                price: c.itemRate,
              },
              qty: c.quantity,
              unit: c.quantityUnitTitle,
              rate: c.itemRate,
              yield: c.yield,
              note: c.instruction,
              amount: c.itemWeightValue,
            };
            tempRows2.push(obj);
          });
        setRows2(tempRows2);
        setTotal2(d.totalAmount);

        setNotIngredientItem({
          label: res.responsePacket.itemTitle,
          value: res.responsePacket.itemId,
          packUnitId: res.responsePacket.quantityUnitId,
          packUnitTitle: res.responsePacket.quantityUnitTitle,
        });
        setItemUnit1(res.responsePacket.quantityUnitTitle);
        setRequestData(res.responsePacket);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const handleQuantity = (e) => {
    setRequestData({
      ...requestData,
      quantity: e.target.value,
    });
  };

  const handleItem1 = (e) => {
    setNotIngredientItem(e);
    setItemUnit1(e.packUnitTitle);
  };

  useEffect(() => {
    console.log(rows2, "rows2");
    console.log(total2, "total2");
  }, [rows2, total2]);

  const handleSave = () => {
    let arr1 = [];
    let filteredList = rows2.filter((data) => data.item.value);

    filteredList.map((d) => {
      let data = {
        itemId: d.item.value,
        itemTitle: d.item.label,
        quantityUnitId: d.item.weightUnitId,
        instruction: d.note,
        quantity: d.qty,
        stepOrder: d.step,
        itemWeightValue: d.qty * d.rate, //amount
        itemRate: d.rate,
        yield: d.yield,
      };
      arr1.push(data);
    });

    let request = {
      batchNo: "BA",
      itemId: notIngredientItem.value,
      quantityUnitId: notIngredientItem.packUnitId,
      quantityUnitTitle: notIngredientItem.packUnitTitle,
      ingredientsBeanList: arr1,
      totalAmount: total2,
      quantity: parseInt(requestData.quantity),
      isProduction: ware == "true" ? true : false,
    };

    console.log(request, "request");

    if (recipeId != null) {
      updateRecipeIngradients(recipeId, request).then((res) => {
        if (res.errorCode === 0) {
          toast(res.message, { autoClose: 2000 });

          history.push("/inventory/recipe");
        } else {
          toast(res.message, { autoClose: 2000 });
          return;
        }
      });
    } else {
      addRecipeIngredients(request).then((res) => {
        if (res.errorCode === 0) {
          toast(res.message, { autoClose: 2000 });

          history.push("/inventory/recipe");
        } else {
          toast(res.message, { autoClose: 2000 });
          return;
        }
      });
    }
  };

  const handleWare = (e) => {
    // prod :ingredient
    // rest :allitem
    console.log(e.target.value, "e");
    setItemOptions("");
    setItemOptions2("");
    setWare(e.target.value);
    let data2 = {
      page: 0,
      search: "",
      size: -1,
      uuid: "",
    };
    if (e.target.value == "false") {
      getActiveItemRecords(data2).then((res) => {
        if (res.errorCode === 0) {
          const options = res.responsePacket.data.map((c) => ({
            label: c.title,
            value: c.recordId,
            weightUnitId: c.weightUnitId,
            weightUnitTitle: c.weightUnitTitle,
            price: c.sellingPriceValue,
          }));
          setItemOptions(options);
          setItemOptions2(options);
        }
      });
    } else {
      handleProductionItem();
    }
  };
  const handleProductionItem = () => {
    getItemsForRecipe().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.data.map((c) => ({
          label: c.title,
          value: c.recordId,
          weightUnitId: c.weightUnitId,
          weightUnitTitle: c.weightUnitTitle,
          price: c.price,
        }));
        setItemOptions(options);
        setItemOptions2(options);
      }
    });
  };

  useEffect(() => {
    if (ware == "true") {
      handleProductionItem();
    }
  }, []);

  return (
    <>
      <div className="fill list-header d-flex  align-items-center bg-white p-3 border-bottom">
        <SoupKitchenIcon /> {recipeId ? "Edit" : "New"} Recipie
      </div>

      <div
        className="scroll-y noscroll-x fill body scrollbox"
        style={{ marginLeft: "20px" }}
      >
        <div className="item-creation" autoComplete="off">
          <div className="primary-info white-bg">
            <div className="item-new">
              <div className="row">
                <div className="col-lg-8">
                  <RadioGroup
                    row
                    style={{ paddingLeft: "15px" }}
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    value={ware}
                    onChange={(e) => handleWare(e)}
                  >
                    <FormControlLabel
                      disabled={recipeId ? "true" : false}
                      value={true}
                      control={<Radio size="small" />}
                      label="Production"
                    />
                    <FormControlLabel
                      disabled={recipeId ? "true" : false}
                      value={false}
                      control={<Radio size="small" />}
                      label="Restaurent"
                    />
                  </RadioGroup>

                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Select Recipe
                    </label>
                    <div className="col-lg-5">
                      <Select
                        onChange={(e) => handleItem1(e)}
                        options={notIngredientOptions}
                        value={notIngredientItem}
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Batch Size
                    </label>
                    <div className="col-lg-5">
                      <input
                        value={requestData.quantity}
                        className="form-control"
                        name="quantity"
                        onChange={(e) => {
                          handleQuantity(e);
                        }}
                        type="number"
                        min="0"
                      />
                    </div>
                  </div>

                  <div className="row ">
                    <label className="col-lg-3  required">Unit</label>

                    <div className="col-lg-5">
                      <input
                        className="form-control"
                        disabled="true"
                        value={itemUnit1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <hr />

            <div className="customTable">
              <table>
                <tr>
                  <td>Step</td>
                  <td>Item</td>
                  <td>Qty</td>
                  <td>Unit</td>
                  <td>Rate</td>
                  <td>Yield%</td>
                  <td>Instruction</td>
                  <td>Amount</td>
                  <td></td>
                </tr>
                {rows2.map((data, i) => {
                  return (
                    <tr>
                      <td>
                        <input
                          type="number"
                          min={1}
                          value={data.step}
                          onChange={(e) => handleStep(e, i)}
                        />
                      </td>
                      <td style={{ width: "250px" }}>
                        <Select
                          style={{ zIndex: "20" }}
                          placeholder="Ingredients"
                          options={itemOptions}
                          value={data.item}
                          onChange={(e) => handleItem(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          min={1}
                          value={data.qty}
                          onChange={(e) => handleQty(e, i)}
                        />
                      </td>
                      <td>
                        <input type="text" disabled="true" value={data.unit} />
                      </td>
                      <td>
                        <input
                          type="number"
                          disabled="true"
                          value={data.rate}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={data.yield}
                          onChange={(e) => handleYield(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="textarea"
                          value={data.note}
                          onChange={(e) => handleNote(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          disabled="true"
                          value={data.amount}
                        />
                      </td>

                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClear2(data.item.value, i)}
                      >
                        {rows2.length > 1 && <i className="fa fa-times"></i>}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="font-large text-uppercase font-xs text-open">
                    Total: ₹ {total2}
                  </td>
                </tr>
              </table>
            </div>

            <button
              id="ember1426"
              className="btn btn-default addbtn mt-3"
              onClick={handlAddRow2}
            >
              <AddIcon style={{ color: "blue" }} /> New Row
            </button>

            <div className="text-left mt-3 mb-4">
              <button
                className="btn bg-primary text-white mr-2"
                onClick={(e) => handleSave(e)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default RecipeNew;
