import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import Cookies from "universal-cookie";
import toast, { Toaster } from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SecurityIcon from "@mui/icons-material/Security";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyIcon from "@mui/icons-material/Key";
import UserModel from "./UserModel";
import AssignRoles from "./AssignRoles";
import {
  setAdminPassword,
  getAdminRole,
  getAdminList,
  deleteAdmin,
  deactivateAdmin,
  activateAdmin,
  getOrgList,
  uploadImage,
  addNewAdmin,
  getAdminDetails,
  updateAdmin,
  getWarehouseKeyValueList,
} from "../../../services/index";

const UserAndRoles = (props) => {
  console.log(props, "props");

  const cookies = new Cookies();
  const history = useHistory();
  const [edit, setEdit] = useState(false);

  const [userModel, setUserModel] = useState(false);

  const [isPass, setIsPass] = useState(true);
  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [show3, setShow3] = useState(false);
  const handleShow3 = () => setShow3(true);
  const handleClose3 = () => {
    setShow3(false);
  };

  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => {
    setShow2(false);
  };
  const [ware, setWare] = useState("");
  const [wareOptions, setWareOptions] = useState("");
  const [org, setOrg] = useState("");
  const [orgOptions, setOrgOptions] = useState("");

  const [role, setRole] = useState("");
  const [roleOptions, setRoleOptions] = useState("");

  const [userType, setUserType] = useState([]);

  const [user, setUser] = useState({
    email: "",
    fullName: "",
    mobile: 0,
    organisationUuid: "",
    organisationTitle: "",
    password: "",
    photoImageUrl: "",
    userType: "ROLE_SUPER_ADMIN",
    username: "",
  });

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setEdit(false);
    setWare("");
    setUser({
      email: "",
      fullName: "",
      mobile: 0,
      password: "",
      photoImageUrl: "",
      role: "ROLE_SUPER_ADMIN",
      username: "",
    });
  };

  useEffect(() => {
    getAdminRole().then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        const options = d.map((a) => ({
          label: a,
          value: a,
        }));
        console.log(options, "opt");
        setRoleOptions(options);
      } else {
        toast(res.message);
      }
    });

    getOrgList().then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        const options = d.map((a) => ({
          label: a.organisationTitle,
          value: a.recordId,
          isDefault: a.isDefault,
        }));

        setOrgOptions(options);

        // isDefault
        let abc = options.filter((a) => a.isDefault);
        console.log(abc, "abc");

        setOrg({
          value: abc[0].value,
          label: abc[0].label,
        });
      } else {
        toast(res.message);
      }
    });

    getWarehouseKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        const options = d.map((a) => ({
          label: a.value,
          value: a.key,
        }));
        setWareOptions(options);
      } else {
        toast(res.message);
      }
    });
    getAllRecords();
  }, []);

  const getAllRecords = () => {
    getAdminList().then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        d.reverse();
        setRows(d);
        setFilteredData(d);
      } else {
        toast(res.message, { autoClose: 1000 });
      }
    });
  };

  const handleUpload = (e) => {
    let image = e.target.files[0];
    const formData = new FormData();
    formData.append("file", image);

    uploadImage(formData).then((res) => {
      if (res.errorCode === 0) {
        setUser({
          ...user,
          photoImageUrl: res.responsePacket.fileUrl,
        });
      }
    });
  };

  const columns = [
    {
      name: "FULL NAME",
      selector: (data) => (
        <span className="text-uppercase">{data.fullName}</span>
      ),
    },
    {
      name: "USER NAME",
      selector: (data) => data.username,
    },
    {
      name: "USER TYPE",
      selector: (data) => data.userType,
    },
    {
      name: "MOBILE",
      selector: (data) => data.mobile,
    },
    {
      name: "EMAIL",
      selector: (data) => data.email,
    },

    {
      name: "ACTION",
      selector: (data) => (
        <>
          <Tooltip title="Edit">
            <EditIcon
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => handleEdit(data)}
            />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          {data.active ? (
            <CheckCircleIcon
              style={{
                fontSize: "15px",
                marginLeft: "5px",
                cursor: "pointer",
                color: "#2fae5d",
              }}
              onClick={() => handleDeactivateAdmin(data.recordId)}
            />
          ) : (
            <CancelIcon
              style={{
                fontSize: "15px",
                marginLeft: "5px",
                cursor: "pointer",
                color: "tomato",
              }}
              onClick={() => handleActivateAdmin(data.recordId)}
            />
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {data.userType == "ROLE_SUPER_ADMIN" ? (
            <Tooltip title="SuperAdmin Roles cant be assigned">
              <SecurityIcon
                style={{ fontSize: "15px", cursor: "pointer", color: "red" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Assign Roles">
              <SecurityIcon
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                  color: "greenyellow",
                }}
                onClick={() => {
                  handleUserModel(data);
                }}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;&nbsp;
          <DeleteIcon
            style={{
              fontSize: "15px",
              marginLeft: "5px",
              cursor: "pointer",
              color: "tomato",
            }}
            onClick={() => handleDeleteAdmin(data.recordId)}
          />
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Edit Password">
            <KeyIcon
              onClick={() => handlePass(data.recordId)}
              style={{
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const handleUserModel = (a) => {
    setShow3(true);
    setUser({
      ...user,
      userId: a.recordId,
      fullName: a.fullName,
      username: a.username,
      userType: a.userType,
    });
    console.log(a, "userInfo");
  };

  const handlePass = (uuid) => {
    setUser({ ...user, id: uuid });
    setShow2(true);
  };

  const handleNewRecord = () => {
    setShow(true);
    setEdit(false);
  };
  console.log(orgOptions, "orggg");

  const handleEdit = (data) => {
    setShow(true);
    setEdit(true);
    console.log(data, "data");
    getAdminDetails(data.recordId).then((res) => {
      if (res.errorCode === 0) {
        let d = res.responsePacket;
        if (d.beanUserWarehouseList) {
          d.beanUserWarehouseList.map((a) => {
            a.value = a.warehouseId;
            a.label = a.warehouseTitle;
          });
          setWare(d.beanUserWarehouseList);
        }

        setOrg({
          value: d.organisationUuid,
          label: d.organisationTitle,
        });

        setRole({
          value: d.userType,
          label: d.userType,
        });

        setUser({
          recordId: d.recordId,
          email: d.email,
          fullName: d.fullName,
          mobile: d.mobile,
          organisationUuid: d.organisationUuid,
          organisationTitle: d.organisationTitle,
          password: "",
          photoImageUrl: "",
          role: d.userType,
          // username: d.username,
        });

        let dict = {
          label: d.userType,
          value: d.userType,
        };
        setUserType(dict);
      }
    });
  };

  const handleDeactivateAdmin = (id) => {
    deactivateAdmin(id).then((res) => {
      if (res.errorCode === 0) {
        toast(res.message, { autoClose: 1000 });
        getAllRecords();
      } else {
        toast(res.message, { autoClose: 1000 });
      }
    });
  };

  const handleActivateAdmin = (id) => {
    activateAdmin(id).then((res) => {
      if (res.errorCode === 0) {
        getAllRecords();
        toast(res.message, { autoClose: 1000 });
      } else {
        toast(res.message, { autoClose: 1000 });
      }
    });
  };

  const handleDeleteAdmin = (id) => {
    Swal.fire({
      title: "Delete this record ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes ",
      cancelButtonText: " No",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAdmin(id).then((res) => {
          if (res.errorCode === 0) {
            toast(res.message, { autoClose: 1000 });
            getAllRecords();
          } else {
            toast(res.message, { autoClose: 1000 });
          }
        });
      }
    });
  };

  const handleRowClick = (e) => {
    history.push("/settings/userRole/AssignRoles/" + e);
  };

  const handleSearch = (e) => {
    const result = filteredData.filter((a) => {
      return (
        a.fullName.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.username.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.mobile.toLowerCase().match(e.target.value.toLowerCase())
      );
    });
    setRows(result);
  };

  const handleWare = (e) => {
    console.log(e, "e");
    setWare(e);
  };
  console.log(ware, "ware");

  const handleNewUser = () => {
    if (String(user.mobile).length != 10 || user.mobile == "") {
      toast("Please Enter 10 digit Phone no.", {
        autoClose: 2000,
      });
      return;
    }

    if (user.fullName == "") {
      toast("Please Enter User Name", {
        autoClose: 2000,
      });
      return;
    }

    if (user.email == "") {
      toast("Please Enter User email", {
        autoClose: 2000,
      });
      return;
    }

    if (!edit && user.password == "") {
      toast("Please Enter password", {
        autoClose: 2000,
      });
      return;
    }
    ware.map((a) => {
      a.warehouseTitle = a.label;
      a.warehouseId = a.value;
    });

    let dict = {
      email: user.email,
      fullName: user.fullName,
      mobile: user.mobile,

      organisationId: org.value,
      organisationTitle: org.label,
      beanUserWarehouseList: ware,

      photoImageUrl: user.photoImageUrl,
      userType: role.value,
    };
    if (!edit) {
      dict.password = user.password;
    }

    console.log(dict, "dict");

    if (edit) {
      updateAdmin(user.recordId, dict).then((res) => {
        if (res.errorCode == 0) {
          toast("👏" + res.message, {
            autoClose: 1000,
            pauseOnHover: true,
            closeOnClick: true,
          });
          getAllRecords();
          handleClose();
        } else {
          toast("🙁" + res.message, {
            autoClose: 1000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          return;
        }
      });
    } else {
      addNewAdmin(dict).then((res) => {
        if (res.errorCode == 0) {
          toast("👏" + res.message, {
            autoClose: 1000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          getAllRecords();
          handleClose();
        } else {
          toast("🙁" + res.message, {
            autoClose: 1000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          return;
        }
      });
    }
  };
  console.log(isPass, "isPass");
  console.log(role, "role");

  const handleSavePassword = () => {
    const data = { password: user.password };
    setAdminPassword(user.id, data).then((res) => {
      if (res.errorCode == 0) {
        toast("updated");
        handleClose2();
      } else {
        toast("update failed");
      }
    });
  };
  return (
    <>
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Body>
          <AssignRoles
            userId={user.userId}
            handleClose3={handleClose3}
            username={user.username}
            userType={user.userType}
          />
        </Modal.Body>
      </Modal>

      <Modal size="xs" show={show2} onHide={handleClose2}>
        <Modal.Body>
          <table>
            <tr>
              <td>Password: </td>
              <td>
                <input
                  className="form-control"
                  placeholder="Enter password"
                  value={user.password}
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                />
              </td>
            </tr>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleSavePassword()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Body>
          <Modal.Header>{edit ? "Edit User" : "Add User"}</Modal.Header>
          <br />

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label " htmlFor="mobile">
                  Full Name:
                </label>
                <div className="">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter fullname"
                    value={user.fullName}
                    onChange={(e) =>
                      setUser({ ...user, fullName: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label " htmlFor="username">
                  Email (UserName):
                </label>
                <div className="">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter email"
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label " htmlFor="email">
                  Mobile:
                </label>
                <div className="">
                  <input
                    type="number"
                    min={0}
                    className="form-control"
                    placeholder="Enter mobile"
                    value={user.mobile}
                    onChange={(e) =>
                      setUser({ ...user, mobile: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            {edit ? (
              <></>
            ) : (
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="control-label " htmlFor="email">
                    Password:{" "}
                  </label>
                  <div className="">
                    <input
                      className="form-control"
                      placeholder="Enter password"
                      value={user.password}
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label " htmlFor="email">
                  User Type:
                </label>
                <div className="">
                  <Select
                    options={roleOptions}
                    value={role}
                    onChange={(e) => setRole(e)}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label " htmlFor="email">
                  Organization:
                </label>
                <div className="">
                  <Select
                    style={{ zIndex: "6" }}
                    options={orgOptions}
                    onChange={(e) => setOrg(e)}
                    value={org}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label " htmlFor="email">
                  Warehouse:
                </label>
                <div className="">
                  <Select
                    isMulti
                    style={{ zIndex: "6" }}
                    options={wareOptions}
                    onChange={(e) => handleWare(e)}
                    value={ware}
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label " htmlFor="email">
                  Upload Image:
                </label>
                <div className="customfileupload">
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    name="profileImageUrl"
                    // value={user.photoImageUrl}
                    onChange={(e) => handleUpload(e)}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleNewUser()}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <DataTable
        title="All Users"
        columns={columns}
        data={rows}
        theme="light"
        fixedHeader
        subHeader
        subHeaderAlign="right"
        subHeaderComponent={
          <>
            {" "}
            <SearchRoundedIcon />
            <input
              className="ember-text-field ember-view form-control col-2"
              type="text"
              placeholder="Search Here"
              onChange={handleSearch}
              style={{ minWidth: "150px" }}
            />
            &nbsp;
            <button
              className="btn btn-primary "
              onClick={() => handleNewRecord()}
            >
              <AddIcon style={{ color: "white" }} />
              New
            </button>
          </>
        }
      />

      <Toaster />
    </>
  );
};

export default UserAndRoles;
