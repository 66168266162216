import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { getInvoicesList } from "../../services/Sales/InvoiceServices";
import { Redirect, Link, useHistory } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import AddIcon from "@mui/icons-material/Add";

const InvoiceList = (props) => {
  let history = useHistory();
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date1 = curr.toISOString().substring(0, 10);
  console.log(date1);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [dataId, setDataId] = useState(false);
  const [redirectState, setRedirectState] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;

  const columns = [
    {
      name: "INVOICE No.",
      selector: (data) => data.invoiceNo,
      sortable: true,
    },
    {
      name: "SALES No.",
      selector: (data) => data.salesOrderNo,
      sortable: true,
    },
    {
      name: "CUSTOMER NAME",
      selector: (data) => data.customerTitle,
      sortable: true,
    },

    {
      name: "PAY || SHIP",
      selector: (data) => (
        <div className="text-center">
          {data.status == "Unpaid" ? (
            <>
              {" "}
              <PaymentIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.push({
                    pathname: "/inventory/SalesPayment/" + data.recordId,
                    state: { id: data.recordId },
                  })
                }
              />
            </>
          ) : (
            <Tooltip title="Paid" arrow placement="top">
              <PaymentIcon style={{ color: "greenyellow" }} />
            </Tooltip>
          )} 
          &nbsp;&nbsp;&nbsp;&nbsp;
          {data.isShipped ? (
            <Tooltip title="Shipped" arrow placement="top">
              <LocalShippingRoundedIcon style={{ color: "greenyellow" }} />
            </Tooltip>
          ) : (
            <>
              {" "}
              <LocalShippingRoundedIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.push({
                    pathname: "/inventory/newshipping/" + data.recordId,
                    state: { id: data.recordId },
                  })
                }
              />
            </>
          )}
        </div>
      ),
    },

    {
      name: "AMOUNT",
      selector: (data) => parseFloat(data.amount).toFixed(2),
    },
    {
      name: "BALANCE DUE",
      selector: (data) => parseFloat(data.balanceDue).toFixed(2),
    },
    {
      name: "DUE DATE",
      selector: (data) => data.dueDate,
    },
    {
      name: "OVER DUE",
      selector: (data) => (
        <>
          {data.status == "Paid" ? (
            <div>Paid</div>
          ) : (
            <div style={{ color: data.diff < 0 ? "tomato" : "greenYellow" }}>
              {data?.diff}
            </div>
          )}
        </>
      ),
    },
  ];

  const InvoicesList = () => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getInvoicesList(list_data).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket.data;

        d.map((a) => {
          let dateDue = new Date(a.dueDate);
          let today = new Date(date1);
          var Diff_In_Time = dateDue.getTime() - today.getTime();
          var Diff_In_Days = Diff_In_Time / (1000 * 3600 * 24);
          a.diff = Diff_In_Days;
        });
        console.log(d, "dddddd");
        setData(d);
        setFilteredData(d);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  useEffect(() => {
    console.log(data, "setData111");
  }, [data]);

  let redirecting = redirectState ? (
    <Redirect push to={`/inventory/invoices/${dataId}`} />
  ) : (
    ""
  );

  useEffect(() => {
    InvoicesList();
  }, [page]);

  useEffect(() => {
    const result = data.filter((data) => {
      return (
        data.customerTitle.toLowerCase().match(search.toLowerCase()) ||
        data.status.toLowerCase().match(search.toLowerCase()) ||
        data.invoiceNo.toLowerCase().match(search.toLowerCase()) ||
        data.salesOrderNo.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  console.log(props, "props");
  return (
    <>
      {console.log(filteredData, "filteredData123")}
      <div style={{ height: 500, width: "100%" }}>
        {filteredData && filteredData ? (
          <DataTable
            title="All Invoices"
            columns={columns}
            // data={data}
            data={filteredData}
            theme="light"
            pagination
            fixedHeader
            defaultSortAsc={false}
            defaultSortFieldId={1}
            // pointerOnHover={true}
            highlightOnHover={true}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
            onRowClicked={(data) => {
              setRedirectState(true);
              setDataId(data.recordId);
            }}
            subHeader
            subHeaderComponent={
              <>
                {" "}
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder={"Search here"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ margin: "10px" }}
                />
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  onClick={() =>
                    history.push({
                      pathname: `/inventory/directInvoices`,
                      state: { directInvoice: true },
                    })
                  }
                >
                  <AddIcon />
                  New
                </button>
              </>
            }
            subHeaderAlign="right"
          />
        ) : (
          <></>
        )}

        {redirecting}
      </div>
      <ToastContainer />
    </>
  );
};

export default InvoiceList;
