import { PURCHASE_URL, PURCHASE_PAYMENT_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}



export const getPurchaseOrderByVendorId1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PURCHASE_URL() + "getPurchaseOrderByVendorId", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getPurchaseOrderByItemId1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PURCHASE_URL() + "getPurchaseOrderByItemId", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const PurchasePayment1 = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PURCHASE_PAYMENT_URL() + "purchaseRecordPayment/" + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const PurchasePaymentList1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PURCHASE_PAYMENT_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};



export const PurchasePaymentUUID1 = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PURCHASE_PAYMENT_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPurchaseDetails axios!");
                });
        } catch (error) {
            console.error("in LoginService > getPurchaseDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

// --------------------------

export const receivePackage = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PURCHASE_URL() + "receivePackage", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getAllRecords = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PURCHASE_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const addPurchaseOrder = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PURCHASE_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const updatePurchaseOrder = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PURCHASE_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};




export const getPurchaseDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PURCHASE_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPurchaseDetails axios!");
                });
        } catch (error) {
            console.error("in LoginService > getPurchaseDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};



// getPurchaseOrderNoByVendorUUID
export const getPurchaseDetailsByUUID = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PURCHASE_URL() + 'getKeyValueListByVendor/' + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPurchaseDetailsByUUID axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const getPurchaseKeyValuePair = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PURCHASE_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPurchaseKeyValuePair axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};