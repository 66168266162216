import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { getBatchesList1 } from "../../services/index";
import { toast } from "react-toastify";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import EditIcon from "@mui/icons-material/Edit";

const BatchList = (props) => {
  const history = useHistory();
  const [manageRecipeModal, setManageRecipeModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const columns = [
    {
      name: "BATCH No.",
      selector: (row) => row.batchNo,
      sortable: true,
    },
    {
      name: "BATCH NAME.",
      selector: (row) => row.batchName,
    },

    {
      name: "ITEM",
      selector: (row) => row.itemTitle,
    },

    {
      name: "QUANTITY",
      selector: (row) => row.quantity + " " + row.quantityUnitTitle,
    },
    {
      name: "EDIT",
      selector: (row) => (
        <div>
          <Link>
            <EditIcon
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => {
                history.push("/inventory/batches/" + row.recordId + "/edit");
              }}
            />
          </Link>
        </div>
      ),
    },
  ];

  const handleRecipeView = (id) => {
    // /inventory/batches/:id
    history.push({
      pathname: `/inventory/batches/` + id,
      state: { data: rows },
    });
  };

  useEffect(() => {
    if (rows.length == 0) {
      var request = {
        page: 0,
        search: "",
        size: -1,
      };
      getBatchesList1(request)
        .then((res) => {
          if (res.errorCode == 0) {
            setRows(res.responsePacket.data);
            setFilteredData(res.responsePacket.data);
          } else {
            toast("🙁" + res.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          toast("🙁" + err, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }, []);

  useEffect(() => {
    const result = rows.filter((data) => {
      return (
        data.itemTitle.toLowerCase().match(search.toLowerCase()) ||
        data.batchNo.toLowerCase().match(search.toLowerCase()) ||
        data.batchName.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  return (
    <>
      <div style={{ height: 500, width: "100%" }}>
        <DataTable
          title="All Batches"
          defaultSortAsc={false}
          defaultSortFieldId={1}
          columns={columns}
          data={filteredData}
          theme="light"
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          pointerOnHover={true}
          highlightOnHover={true}
          onRowClicked={(data) => {
            handleRecipeView(data.recordId);
          }}
          subHeader
          subHeaderComponent={
            <>
              {" "}
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control col-2"
                type="text"
                placeholder={"Search here"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ margin: "10px" }}
              />
              <Link to="/inventory/batches/new">
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                >
                  <AddIcon />
                  New
                </button>
              </Link>
            </>
          }
          subHeaderAlign="right"
        />
      </div>

      <ToastContainer />
    </>
  );
};

export default BatchList;
