import React from 'react'
import DataTable, {
  createTheme,
  FilterComponent,
} from "react-data-table-component";

const ItemsFieldCustomization = () => {

  createTheme(
    "light",
    {
      text: {
        primary: "#268bd2",
        secondary: "#2aa198",
      },
      background: {
        default: "#fff",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#e6e6e6",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "light"
  );

  const columns = [
    {
      name: "FIELD NAME",
      selector: (data) => data.salesOrderDate,
    },
    {
      name: "DATA TYPE",
      selector: (data) => data.orderNo,
    },
    {
      name: "	MANDATORY",
      selector: (data) => data.referenceNo,
    },
    {
      name: "SHOW IN ALL PDF",
      selector: (data) => data.customerTitle,
    },
    {
      name: "STATUS",
      selector: (data) => data.salesOrderStatusEnum,
    },
  ]

  return (
    <>
      <div style={{ height: 500, width: "100%" }}>
        <DataTable
          columns={columns}
          data={''}
          theme="light"
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          pointerOnHover={true}
          highlightOnHover={true}
        // onRowClicked={(data) => {
        //   setState(true);
        //   setDataId(data.recordId);
        // }}
        />
      </div>
    </>
  )
}

export default ItemsFieldCustomization