import { ISSUANCE_URL,WAREHOUSEITEM_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
    // 'Authorization': url.Auth_Token
}

 


export const getRecordByDepartmentId1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ISSUANCE_URL()+"getRecordByDepartnmentId" ,data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getRecordByDepartnmentId axios!");
                });
        } catch (error) {
            console.error("in LoginService > getRecordByDepartnmentId, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};



export const getIssuanceDetail = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(ISSUANCE_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getIssuanceDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getIssuanceDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

// for issuance no and uuid
export const getIssuanceKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(ISSUANCE_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getIssuanceKeyValueList axios!");
                });
        } catch (error) {
            console.error("in LoginService > getIssuanceKeyValueList, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getIssuanceRecords = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ISSUANCE_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getIssuanceRecords axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const addIssuance = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(ISSUANCE_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addIssuance axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const updateIssuance = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(ISSUANCE_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    }); 
};


export const deleteIssuance = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(ISSUANCE_URL(),+id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};