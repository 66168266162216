import { PRICE_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}


export const activeDeactivePrice = (id) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(PRICE_URL() + "activeOrDeActiveItemPriseList/"+id ,id, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in activeDeactivePrice axios!");
          });
      } catch (error) {
        console.error("in LoginService > activeDeactivePrice, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };



export const getPriceList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PRICE_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPriceList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getPriceDetails = (id) => { 
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PRICE_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPriceDetails axios!");
                });
        } catch (error) {
            console.error("in LoginService > getPriceDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const addPrice = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PRICE_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addPrice axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updatePrice = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PRICE_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePrice axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const getPriceKeyValuePair = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PRICE_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    }); 
};

export const getSalesPriceKeyValuePair = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PRICE_URL() + "sales/getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getPurchasePriceKeyValuePair = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PRICE_URL() + "purchase/getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

// http://192.168.1.3:8001/itemPriceList/purchase/getKeyValueList


// http://192.168.1.3:8001/itemPriceList/sales/getKeyValueList