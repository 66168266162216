import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";

import { getRequisitionList } from "../../services/index";
import { toast, ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const RequisitionList = (props) => {
  const history = useHistory();
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [manageRecipeModal, setManageRecipeModal] = useState(false);

  const columns = [
    {
      name: "REQ No.",
      selector: (row) => row.requisitionCode,
      sortable: true,
    },

    {
      name: "Dept",
      selector: (row) => row.requisitionDepartmentTitle,
    },
    {
      name: "User",
      selector: (row) => row.requisitionByUserTitle,
    },
    {
      name: "DATE",
      selector: (row) => row.requisitionDate,
    },
    {
      name: "ISSUED",
      selector: (row) => row.isIssued?"true":"false"
    },

  ];

  const handleRecipeView = (id) => {
    history.push({
      pathname: `/inventory/recipe/` + id,
      state: { data: rows },
    });
  };

  useEffect(() => {
    if (rows.length == 0) {
      var request = {
        page: 0,
        search: "",
        size: -1,
      };
      getRequisitionList(request).then((res) => {
        if (res.errorCode == 0) {
          res.responsePacket.data.reverse();
          setRows(res.responsePacket.data);
          setFilteredData(res.responsePacket.data);
        } else {
          toast("🙁" + res.message);
        }
      });
    }
  }, []);
  useEffect(() => {
    const result = rows.filter((data) => {
      return data?.requisitionDate.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  const handleConvert = (data) => {
    if (!data.isIssued) {
      history.push({
        pathname: "/inventory/issueitems/new",
        state: { reqId: data.recordId },
      });
    }
  };

  return (
    <>
      <div style={{ height: 500, width: "100%" }}>
        <DataTable
          title="All Requisition"
          columns={columns}
          theme="light"
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover={true}
          onRowClicked={(data) => {
            handleConvert(data);
          }}
          subHeader
          subHeaderComponent={
            <>
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control col-2"
                type="text"
                placeholder={"Search here"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ margin: "10px" }}
              />
              <Link to="/inventory/requisition/new">
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                >
                  <AddIcon />
                  New
                </button>
              </Link>
            </>
          }
        />
      </div>

      <ToastContainer />
    </>
  );
};

export default RequisitionList;
