import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import DataTable, {
  createTheme,
  FilterComponent,
} from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { getPriceList, activeDeactivePrice } from "../../services/index";
import AddIcon from "@mui/icons-material/Add";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const PriceList = () => {
  //setting up variables
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [rows, setRows] = useState([]);

  let history = useHistory();

  const columns = [
    {
      name: "NAME",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "PRICING TYPE",
      selector: (row) => row.itemPriceListTypeEnum,
    },
    {
      name: "PRICING SCHEME",
      selector: (row) =>
        row.isUnitPrice
          ? "Unit Pricing"
          : row.isVolumePrice
          ? "Volume Pricing"
          : "-",
    },
    {
      name: "DETAILS",
      selector: (row) =>
        row.isIndividual ? (
          "Per Item Rate"
        ) : row.isMarkDown ? (
          row.percentage + "% Markdown"
        ) : row.isMarkUp ? (
          row.percentage + "% Markdown"
        ) : (
          <></>
        ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <Link>
            <EditIcon
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => {
                history.push("/inventory/pricelists/" + row.recordId + "/edit");
              }}
            />
          </Link>&nbsp;&nbsp;
          {  row.active ? (
          <CheckCircleIcon
            style={{
              fontSize: "15px",
              cursor: "pointer",
              color: "greenyellow",
            }}
            onClick={() => handleActiveInactive(row)}
          />
        ) : (
          <CancelIcon
            style={{ fontSize: "15px", cursor: "pointer", color: "tomato" }}
            onClick={() => handleActiveInactive(row)}
          />
        )}
        </div>
      ),
    },

  ];

  useEffect(() => {
    getPriceRecords();
  }, []);

  const getPriceRecords = () => {
    var request = {
      page: 0,
      search: "",
      size: -1,
    };
    getPriceList(request).then((res) => {
      if (res.errorCode == 0) {
        let d=res.responsePacket.data
        d.reverse()
        setRows(d);
        setFilteredData(d);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  useEffect(() => {
    const result = rows.filter((data) => {
      return (
        data.title.toLowerCase().match(search.toLowerCase()) ||
        data.itemPriceListTypeEnum.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  const handlePriceListDetails = (id) => {
    history.push("/inventory/pricelists/" + id);
  };

  const handleActiveInactive = (row) => {
    console.log(row, "row");
    activeDeactivePrice(row.recordId).then((res) => {
      if (res.errorCode == 0) {
        getPriceRecords();
        toast(res.message, { autoClose: 2000 });
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };
  return (
    <>
      <div style={{ height: 500, width: "100%" }}>
        <DataTable
          title="All Price Lists"
          columns={columns}
          data={filteredData}
          // defaultSortAsc={false}
          // defaultSortFieldId={1}
          theme="light"
          pagination
          fixedHeader
          pointerOnHover={true}
          highlightOnHover={true}
          onRowClicked={(data) => {
            handlePriceListDetails(data.recordId);
          }}
          subHeader
          subHeaderComponent={
            <>
              {" "}
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control col-2"
                type="text"
                placeholder={"Search here"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ margin: "10px" }}
              />
              <Link to="/inventory/pricelists/new">
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                >
                  <AddIcon />
                  New
                </button>
              </Link>
            </>
          }
          subHeaderAlign="right"
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default PriceList;
