import React, { Component, useEffect, useState } from "react";
import $ from "jquery";
import Cookies from "universal-cookie";
import { Route, Link, Switch, Routes, useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import HeaderComponent from "./HeaderComponent";
import SettingsIcon from "@mui/icons-material/Settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimelineIcon from "@mui/icons-material/Timeline";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Routing from "./Routing";
import PreferencesNav from "../Settings/Preferences/PreferencesNav";
import {
  urlMappingUUID1,
  getOrgById,
  getAdminDetails,
} from "../../services/index";

const NavigationComponent = (props) => {
  const [userData, setUserData] = useState("");
  const [urlList, setUrlList] = useState("");
  const [userRights, setUserRights] = useState("");

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [headerLogout, setHeaderLogout] = useState(false);

  let history = useHistory();
  let show = window.localStorage.getItem("showSettingNav");
  let prefNav = window.localStorage.getItem("showPreferencesNav");
  const [loggedInUserName, setLoggedInUserName] = useState("");
  const [showSettingNav, setShowSettingNav] = useState(show);
  const [showPreferencesNav, setShowPreferencesNav] = useState(prefNav);
  const [activeOption, setActiveOption] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [orgDetails, setOrgDetails] = useState("");

  useEffect(() => {
    let loginData1 = new Cookies().get("loginData1");
    setUserData(new Cookies().get("loginData1"));
    let id = loginData1.recordId;
    let orgId = loginData1.organisationId;

    getAdminDetails(id).then((res) => {
      if (res.errorCode === 0) {
        let d = res.responsePacket;
        setUserDetails(d);
        localStorage.setItem("userDetail", JSON.stringify(d));
      }
    });

    urlMappingUUID1(id).then((res) => {
      if (res.errorCode == 0) {
        setUrlList(res.responsePacket.urlList);
        setUserRights(res.responsePacket);
        console.log(res.responsePacket.urlList, "urlList logged in user");
      } else {
        if (res.message == "Unauthorised Access") {
          setHeaderLogout(true);
        }
        toast("🙁" + res.message);
      }
    });

    getOrgById(orgId).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;
        console.log(d, "d");
        localStorage.setItem("orgDetail", JSON.stringify(d));
        setOrgDetails(d);
      } else {
        toast(res.message);
      }
    });
  }, []);

  const handleParentCallback = (data) => {
    if (data != null) {
      let show = window.localStorage.getItem("showSettingNav");
      setShowSettingNav(show);
      setActiveOption(data.option);
      if (data.option == "orgProfile") {
        history.push("/settings/orgProfile");
      } else if (data.option == "userRole") {
        history.push("/settings/userRole");
      } else if (data.option == "warehouse") {
        history.push("/settings/warehouse");
      } else if (data.option == "taxes") {
        history.push("/settings/taxes/taxrates");
      } else if (data.option == "preferences") {
        let prefNav = window.localStorage.getItem("showPreferencesNav");
        setShowPreferencesNav(prefNav);
        history.push("/settings/preferences/DepartmentList");
      }
    }
  };

  const handleSettingBack = (e) => {
    window.localStorage.removeItem("showSettingNav");
    window.localStorage.removeItem("showPreferencesNav");
    setShowSettingNav(false);
    setShowPreferencesNav(false);
    setActiveOption(null);
  };

  const handlePreferences = (e) => {
    localStorage.setItem("showPreferencesNav", true);
    let prefNav = window.localStorage.getItem("showPreferencesNav");
    setShowPreferencesNav(prefNav);
  };

  const handleOnChange = (data) => {
    if (data != "preferences") {
      window.localStorage.removeItem("showPreferencesNav");
      setShowPreferencesNav(false);
      setActiveOption(data);
    }
  };

  useEffect(() => {
    document.querySelectorAll("a[href^='#']").forEach((node) => {
      node.addEventListener("click", (e) => {
        e.preventDefault();
      });
    });
    var cook = new Cookies();
    if (cook.get("loginData1") == null) {
      const cookies = new Cookies();
      cookies.remove("loginData1");
      window.location.reload(1);
    } else {
      setLoggedInUserName(cook.get("loginData1").fullName);
    }
    var currentURI = window.location.pathname;
    if (currentURI.split("/")[3] == "taxrates") {
    }
  }, []);

  console.log(userDetails, "UserDetails");

  return (
    <>
      {/* <Router> */}
      <HeaderComponent
        parentCallBack={handleParentCallback}
        headerLogout={headerLogout}
      />

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Body>
          <h1>
            <b>User Details</b>
          </h1>
          <table>
            <tr>
              <td style={{ width: "100px" }}>Name:</td>
              <td>{userDetails.fullName}</td>
            </tr>
            <tr>
              <td>UserName:</td>
              <td>{userDetails.username}</td>
            </tr>
            <tr>
              <td>Phone:</td>
              <td>{userDetails.mobile}</td>
            </tr>
            <tr>
              <td>User Type:</td>
              <td>{userDetails.userType}</td>
            </tr>
            <tr>
              <td>Org Name:</td>
              <td>{userDetails?.organisationTitle}</td>
            </tr>

            <tr>
              <td>Warehouse:</td>
              {userDetails &&
                userDetails?.beanUserWarehouseList?.map((a) => {
                  return <td>{a.warehouseTitle},</td>;
                })}
            </tr>
          </table>
        </Modal.Body>
      </Modal>
      <div id="left-sidebar" className="sidebar mini_sidebar_on">
        <div
          className="navbar-brand"
          style={{
            padding: "10px 2px 8px",
            margin: "0",
            textAlign: "center",
          }}
        >
          <Link to="/">
            <img
              src="https://etouch.s3.ap-south-1.amazonaws.com/primary-image/logo.png"
              alt="Logo"
              style={{ width: "36%" }}
            ></img>
          </Link>
          <button
            type="button"
            className="btn-toggle-offcanvas btn btn-sm float-right"
          >
            <i className="lnr lnr-menu icon-close"></i>
          </button>
        </div>
        <div
          className="slimScrollDiv"
          style={{
            position: "relative",
            overflow: "auto",
            width: "auto",
            height: "calc(100vh - 65px)",
          }}
        >
          <div
            className="sidebar-scroll"
            style={{
              overflow: "auto",
              width: "auto",
              height: "calc(100vh - 65px)",
            }}
          >
            {showSettingNav ? (
              <div className="column tab-column nav-column d-print-none">
                <div className="fill header">
                  <div className="title">
                    <div className="back-link">
                      <Link
                        to="/"
                        id="ember7969"
                        onClick={(e) => handleSettingBack(e)}
                      >
                        <svg
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 507.84 426.92"
                          className="icon icon-sm text-black"
                        >
                          <path d="M507.84 188.44H95.73L248.82 35.35 213.46 0 0 213.46l213.46 213.46 35.36-35.36L95.69 238.44h412.15v-50z" />
                        </svg>
                        &nbsp;Back
                      </Link>
                    </div>
                    <p>Settings</p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div
                  className="user-account"
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={() => setShow1(true)}
                >
                  <div className="user_div">
                    <img
                      src="https://etouch.s3.ap-south-1.amazonaws.com/primary-image/user.png"
                      className="user-photo"
                      alt="User Profile Picture"
                    />
                  </div>
                  <div>
                    {" "}
                    <div> Welcome</div>
                    <div>{userDetails && userDetails.fullName}</div>
                  </div>
                  {/* <div className="dropdown">
                    <span>Welcome,</span>
                    <a
                      className="dropdown-toggle user-name"
                      data-toggle="dropdown"
                    >
                      <strong>{loggedInUserName}</strong>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-right account vivify flipInY">
                      <li>
                        <a style={{ cursor: "pointer" }} onClick={viewProfile}>
                          <i className="icon-user"></i>My Profile
                        </a>
                      </li>
                      <li>
                        <a href="app-inbox.html">
                          <i className="icon-envelope-open"></i>Messages
                        </a>
                      </li>

                      <li className="divider"></li>
                      <li>
                        <a style={{ cursor: "pointer" }} >
                          <i className="icon-power"></i>Logout
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <nav id="left-sidebar-nav" className="sidebar-nav">
                  <ul id="main-menu" className="metismenu main-div">
                    <li
                      className="navLi"
                      onClick={() => $(this).addClass("active")}
                    >
                      <Link to="/">
                        <i className="icon-home"></i>
                        <span>Home</span>
                      </Link>
                    </li>
                    <li className="">
                      <a
                        className="has-arrow"
                        aria-expanded="false"
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-list-ul"></i>
                        <span>Inventory</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        className="collapse"
                        style={{ height: "0px" }}
                      >
                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/items"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Items
                            </Link>

                            <Link
                              to="/inventory/items/new"
                              className="ember-view p-0"
                              id="ember171"
                            >
                              <AddIcon />
                            </Link>
                          </a>
                        </li>

                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/pricelists"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Price Lists
                            </Link>
                            <Link
                              to="/inventory/pricelists/new"
                              className="ember-view p-0"
                              id="ember171"
                            >
                              <AddIcon />
                            </Link>
                          </a>
                        </li>

                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/issueitems"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Issue Items
                            </Link>
                            <Link
                              to="/inventory/issueitems/new"
                              className="ember-view p-0"
                              id="ember171"
                            >
                              <AddIcon />
                            </Link>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <a
                        className="has-arrow"
                        aria-expanded="false"
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-shopping-cart"></i>
                        <span>Sales</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        className="collapse"
                        style={{ height: "0px" }}
                      >
                        <li className="navLi">
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/contacts"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Customers
                            </Link>
                            <Link
                              to="/inventory/contacts/new"
                              className="ember-view p-0"
                              id="ember171"
                            >
                              <AddIcon />
                            </Link>
                          </a>
                        </li>
                        <li className="navLi">
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/salesorders"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Sales Orders
                            </Link>
                            <Link
                              to="/inventory/salesorders/new"
                              className="ember-view p-0"
                              id="ember171"
                            >
                              <AddIcon />
                            </Link>
                          </a>
                        </li>

                        <li className="navLi">
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/invoices"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Invoices
                            </Link>
                            <AddIcon
                              onClick={() =>
                                history.push({
                                  pathname: `/inventory/directInvoices`,
                                  state: { directInvoice: true },
                                })
                              }
                            />
                          </a>
                        </li>
                        <li className="navLi">
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/paymentlist"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Payments
                            </Link>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="">
                      <a
                        className="has-arrow"
                        aria-expanded="false"
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-shopping-bag"></i>
                        <span>Purchase</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        className="collapse"
                        style={{ height: "0px" }}
                      >
                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/vendors"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Vendors
                            </Link>

                            <Link
                              to="/inventory/vendors/new"
                              className="ember-view p-0"
                              id="ember171"
                            >
                              <AddIcon />
                            </Link>
                          </a>
                        </li>
                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/purchase/"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Purchase
                            </Link>
                            <Link
                              to="/inventory/purchase/new"
                              className="ember-view p-0"
                              id="ember171"
                            >
                              <AddIcon />
                            </Link>
                          </a>
                        </li>

                        {/*  */}
                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to={"/inventory/bills"}
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Bills
                            </Link>

                            <AddIcon
                              onClick={() =>
                                history.push({
                                  pathname: `/inventory/DirectBill`,
                                  state: { directBill: true },
                                })
                              }
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to={"/inventory/PurchasePayment"}
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Payments
                            </Link>
                          </a>
                        </li>
                      </ul>
                    </li>
                    {/* production */}
                    <li className="">
                      <a
                        className="has-arrow"
                        aria-expanded="false"
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-industry"></i>
                        <span>Production</span>
                      </a>
                      <ul
                        aria-expanded="false"
                        className="collapse"
                        style={{ height: "0px" }}
                      >
                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/recipe"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Recipe
                            </Link>
                            <Link
                              to="/inventory/recipe/new"
                              className="ember-view p-0"
                              id="ember171"
                            >
                              <AddIcon />
                            </Link>
                          </a>
                        </li>

                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/batches"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Batches
                            </Link>
                            <Link
                              to="/inventory/batches/new"
                              className="ember-view p-0"
                              id="ember171"
                            >
                              <AddIcon />
                            </Link>
                          </a>
                        </li>

                        <li>
                          <a
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <Link
                              to="/inventory/requisition"
                              className="ember-view p-0"
                              id="ember170"
                            >
                              Requisition
                            </Link>

                            <AddIcon
                              onClick={() =>
                                history.push({
                                  pathname: `/inventory/requisition/new`,
                                  state: { reqAdd: true },
                                })
                              }
                            />
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li className="">
                      <Link
                        to="/inventory/reports"
                        className="ember-view p-0"
                        id="ember171"
                      >
                        <a
                          className="has-arrow"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          <i class="fa fa-bar-chart"></i>
                          <span>Reports</span>
                        </a>
                      </Link>
                    </li>
                    {/* a settings here */}

                    <div style={{ border: 0, paddingLeft: 0 }}>
                      <li>
                        <a
                          className="has-arrow"
                          aria-expanded="false"
                          style={{
                            cursor: "pointer",
                            display:
                              userRights.adminRoleEnum == "ROLE_SUPER_ADMIN"
                                ? "block"
                                : "none",
                          }}
                        >
                          &nbsp;
                          <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x={0}
                            y={0}
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                            className="icon cursor-pointer icon-lg align-text-bottom"
                          >
                            <path d="M258.3 149.8c-57.3 0-103.9 46.6-103.9 103.9 0 57.3 46.6 103.9 103.9 103.9s104-46.6 104-103.9c0-57.3-46.6-103.9-104-103.9zm0 175.9c-39.7 0-71.9-32.3-71.9-71.9 0-39.7 32.3-71.9 71.9-71.9 39.7 0 71.9 32.3 71.9 71.9.1 39.6-32.2 71.9-71.9 71.9z" />
                            <path d="M491.4 202.9l-38.7-14c-3-9.5-6.6-18.6-10.7-27.1l18.3-38.3c5.6-11.8 3.2-26-6-35.2l-30.5-30.6c-9.3-9.3-23.4-11.7-35.2-6.1l-38.2 18.1c-8.1-3.8-16.7-7.1-27.1-10.3l-14-38.7C304.9 8.5 293.2.4 280.2.4h-43.6c-12.9 0-24.6 8.2-29.1 20.4l-14.1 38.8c-9.7 3.1-18.8 6.7-27.1 10.7l-38.2-18.4c-11.8-5.6-26-3.2-35.2 6L62.1 88.3c-9.3 9.3-11.7 23.5-6 35.3l18 37.8c-5.2 9.8-9.2 18.7-12.2 27l-40.8 14.2C8.6 207 .2 218.7.2 232v43.3c0 13.2 8.4 25 20.7 29.2l40.9 14.3c3.1 8.8 6.9 17.7 11.7 27.1L55.6 384c-5.6 11.9-3.2 26 6.2 35.2L92.6 450c9.2 9.2 23.4 11.7 35.2 6l38.1-18.1c9.7 5.2 18.6 9.2 27.1 12.2l14.2 40.8c4.3 12.5 16 20.8 29.3 20.8h43.3c13.2 0 25-8.4 29.3-20.8l14.2-40.9c8.9-3.1 18-7 27.1-11.7l38.1 17.9c11.8 5.6 26 3.1 35.3-6.2l30.7-30.9c9.2-9.2 11.6-23.3 6-35.2l-18.2-38.4c3.8-8 7.1-16.6 10.3-27.1l38.6-14c12.3-4.3 20.6-16 20.6-29V232c0-13-8.2-24.7-20.4-29.1zm-11.6 71.8l-38.5 14c-9.2 3.3-16.1 10.6-19 19.9v.1c-2.8 9.3-5.7 16.8-8.9 23.5-4.1 8.5-4.2 18.3-.1 26.7l18.2 38.3-29.7 29.9-38-17.9c-8.9-4.1-18.8-4-27.3.5-8 4.1-16 7.6-23.7 10.3-8.9 2.9-16.1 10.1-19.3 19.1l-14.2 40.7h-41.8L223.2 439c-3.1-8.9-10-15.9-19-19-7.3-2.6-15-6.1-23.7-10.7s-19.1-4.8-27.9-.6l-37.9 18-29.9-29.8 17.9-37.9c4.1-8.9 4-18.9-.5-27.3-4.3-8.3-7.7-16.1-10.3-23.9-3.1-8.9-10.2-16-19.1-19.1l-40.6-14.2v-41.8l40.7-14.2c8.9-3.1 15.9-10 19-18.9 2.5-7 6-14.7 10.7-23.5 4.6-8.6 4.9-19 .6-27.9l-18-37.8L115 80.9l38 18.3c8.5 4.1 18.6 4 27.1-.2 7.1-3.5 15.1-6.6 23.6-9.3 9-2.9 16.3-9.9 19.6-19l14-38.5h42.1l13.9 38.4c3.3 9.2 10.6 16.1 19.9 19h.1c9.2 2.8 16.6 5.6 23.4 8.9 8.6 4.1 18.3 4.1 26.8.1l38-18 29.5 29.6-18.2 38-.1.2c-4 8.7-4 18.5.2 26.9 3.6 7.4 6.7 15.3 9.3 23.5 2.7 9 9.8 16.4 18.9 19.8l38.5 14v42.1z" />
                          </svg>
                          &nbsp;&nbsp;
                          <span> Settings</span>
                        </a>

                        <ul
                          aria-expanded="false"
                          className="collapse"
                          style={{ height: "0px" }}
                        >
                          <li>
                            <Link
                              to="/settings/orgList"
                              id="ember761"
                              className={
                                activeOption == "orgProfile"
                                  ? "nav-link text-nowrap ember-view active"
                                  : "nav-link text-nowrap ember-view"
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="icon icon-sm lpanel text-black"
                              >
                                <path d="M455.2 97.6H304.4v-41c0-31.3-25.4-56.7-56.7-56.7H56.6C25.3-.1-.1 25.4-.1 56.6v398.7c0 31.3 25.4 56.7 56.7 56.7h398.6c31.3 0 56.7-25.4 56.7-56.7V154.2c0-31.2-25.4-56.6-56.7-56.6zM31.9 455.3V56.6C31.9 43 43 31.9 56.6 31.9h191.1c13.6 0 24.7 11.1 24.7 24.7V480H216v-95.9c0-8.8-7.2-16-16-16h-88.6c-8.8 0-16 7.2-16 16V480H56.6c-13.6 0-24.7-11.1-24.7-24.7zm152 24.7h-56.6v-79.9h56.6V480zm296-24.7c0 13.6-11.1 24.7-24.7 24.7H304.4V129.6h150.9c13.6 0 24.7 11.1 24.7 24.7v301z" />
                                <path d="M220.3 272c8.8 0 16-7.2 16-16s-7.2-16-16-16H91.9c-8.8 0-16 7.2-16 16s7.2 16 16 16h128.4zM91.9 160.4h128.5c8.8 0 16-7.2 16-16s-7.2-16-16-16H91.9c-8.8 0-16 7.2-16 16s7.1 16 16 16zm339.5 175.7h-79.7c-8.8 0-16 7.2-16 16s7.2 16 16 16h79.7c8.8 0 16-7.2 16-16s-7.1-16-16-16zm0-96.1h-79.7c-8.8 0-16 7.2-16 16s7.2 16 16 16h79.7c8.8 0 16-7.2 16-16 0-8.9-7.1-16-16-16z" />
                              </svg>
                              &nbsp;&nbsp;Organization
                            </Link>
                          </li>

                          <li
                            id="ember762"
                            onClick={() => handleOnChange("userRole")}
                            className={
                              activeOption == "userRole"
                                ? "nav-item active ember-view"
                                : "nav-item ember-view"
                            }
                          >
                            <Link
                              to="/settings/userRole"
                              className={
                                activeOption == "userRole"
                                  ? "nav-link active ember-view"
                                  : "nav-link ember-view"
                              }
                            >
                              <svg
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x={0}
                                y={0}
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                                className="icon icon-sm lpanel text-black"
                              >
                                <path d="M487.1 336.8c-15.8-19.2-38-30.2-66-32.5-20.9-5.7-43.2-16.3-47-22.3-1.5-6.5 5.7-26.2 15.4-41.9 7.2-7.4 26.7-29.5 25-52.9-.5-7.2-2.9-13.9-7-19.5 2.3-17.1 3.5-50.7-16.5-76.5-16.7-21.5-43.2-32.5-78.8-32.5-19.9 0-37 3.4-51.1 10.1 11.2 5.4 20.5 12.8 27.9 22.3 1.9 2.4 3.5 4.9 5 7.4 5.5-.9 11.5-1.4 18-1.4 23 0 39.1 5.8 48.2 17.3 16 20.5 7.8 54.3 7.7 54.5l-3.2 12.8 10.7 7.7c.2.2.5.3.6.5.3 4.8-7.8 16.9-15.1 23.9l-1.6 1.6-1.2 2c-.2.5-7 11.1-13 24.7-9.3 20.9-11.8 37.6-7.8 51.1 1.9 6.3 5.5 12.1 11.1 17.6 11.5 4.3 21.5 10.7 30.1 19.1 1 .5 2.1.9 3.2 1.4 15.9 6.7 30.8 10.7 31.4 10.8l1.8.5 1.8.2c18 1.2 31.3 7.4 40.7 18.7 12.2 14.8 15.5 35.7 16.2 50.1h-63.5c.2 4.8.1 9.1 0 12.5l-.9 26.1h102l.9-26.1c.9-18.1-.7-57.9-25-87.3z" />
                                <path d="M399.6 450H2.8l-2-16.9C.5 430.6-6 374.8 25.2 337c16-19.4 38.3-30.3 66.3-32.6 20.9-5.6 43.4-16 47.1-22 1.6-7.1-6.6-27.7-15.5-42.1-7.2-7.4-26.7-29.6-25-52.9.5-7.2 2.9-13.9 7-19.5-2.4-17.2-3.5-50.7 16.5-76.5 16.7-21.6 43.3-32.5 79-32.5 35.6 0 62.1 11 78.8 32.5 20 25.9 18.7 59.4 16.5 76.5 4.1 5.6 6.5 12.3 7 19.5 1.6 23.4-17.8 45.5-25 52.9-9.6 15.7-16.9 35.4-15.4 41.9 3.8 6 26 16.6 47 22.3 27.9 2.3 50.1 13.3 66 32.5 24.3 29.4 25.9 69.2 25.2 87.1l-1.1 25.9zM38.8 411.3h323.3c-.7-14.4-4.1-35.3-16.2-50.1-9.4-11.3-22.7-17.5-40.7-18.7l-1.8-.2-1.8-.5c-.6-.2-15.5-4.1-31.4-10.8-25.5-10.8-40-23.2-44.4-38-4-13.5-1.4-30.1 7.8-51.1 6-13.6 12.8-24.2 13-24.7l1.2-2 1.6-1.6c7.2-7 15.4-19.1 15.1-23.9-.2-.2-.4-.2-.6-.5l-10.7-7.7 3.2-12.8c.2-.2 8.3-34-7.7-54.5-9-11.5-25.2-17.3-48.2-17.3-23.2 0-39.6 5.9-48.5 17.6-15.8 20.5-7.7 54-7.6 54.4l3.4 12.8-10.7 7.7c-.2.2-.5.4-.6.5-.2 4.1 6.5 15.7 15.1 24.1l1.6 1.6 1.2 2c.2.5 7.1 11.2 13.1 24.8 9.3 21.1 11.8 37.8 7.8 51.1-4.3 14.8-18.9 27.2-44.5 37.8-15.9 6.6-30.9 10.5-31.5 10.6l-1.6.5-1.6.2c-18.2 1.2-31.5 7.4-40.9 18.7-12.4 14.8-15.8 35.6-16.4 50z" />
                              </svg>
                              &nbsp;&nbsp;User Roles
                            </Link>
                          </li>

                          <li
                            id="ember764"
                            onClick={() => handleOnChange("warehouse")}
                            className={
                              activeOption == "warehouse"
                                ? "nav-item active ember-view"
                                : "nav-item ember-view"
                            }
                          >
                            <Link
                              to="/settings/warehouse"
                              id="ember765"
                              className={
                                activeOption == "warehouse"
                                  ? "nav-link active ember-view"
                                  : "nav-link ember-view"
                              }
                            >
                              <svg
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="icon icon-sm lpanel text-black"
                              >
                                <path d="M496.2 147c-2.3 0-4.7-.5-6.9-1.6l-233-111.7-233 111.7c-8 3.8-17.5.5-21.3-7.5-3.8-8-.5-17.5 7.5-21.3l239.9-115c4.4-2.1 9.5-2.1 13.8 0l239.9 115c8 3.8 11.3 13.4 7.5 21.3-2.7 5.8-8.5 9.1-14.4 9.1zM436.2 456c-5.9-7.1-11.8-13.6-17.3-20.5-14.7-18.2-28.5-36.9-40.5-57.1-5.1-8.7-9.6-17.7-13.4-27.1-6.5-16-6.2-32.1 0-48 10.8-27.5 31-45 60.3-49.7 3.7-.6 6.9-1.2 10.5-1.2 35 0 67.3 26.1 74.8 61.1 3.3 15.6.7 30.1-5.8 44.3-6.6 14.6-15 28.2-24.1 41.4-13 19-27.2 37-42.4 54.5-.6.7-1.2 1.2-2.1 2.3zm38-132.8c0-20.9-17.1-37.9-38-37.9s-38 17.1-37.9 37.9c.1 21.2 16.9 38 37.9 38.1 21-.1 38-17.1 38-38.1zM496 511H16c-8.8 0-16-7.2-16-16s7.2-16 16-16h480c8.8 0 16 7.2 16 16s-7.2 16-16 16z" />
                                <path d="M70 492c-8.8 0-16-7.2-16-16V117c0-8.8 7.2-16 16-16s16 7.2 16 16v359c0 8.8-7.2 16-16 16zm371-278c-8.8 0-16-7.2-16-16v-81c0-8.8 7.2-16 16-16s16 7.2 16 16v81c0 8.8-7.2 16-16 16zm-189 27H146c-8.8 0-16-7.2-16-16s7.2-16 16-16h106c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 87H146c-8.8 0-16-7.2-16-16s7.2-16 16-16h106c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 86H146c-8.8 0-16-7.2-16-16s7.2-16 16-16h106c8.8 0 16 7.2 16 16s-7.2 16-16 16z" />
                              </svg>
                              &nbsp;&nbsp;Warehouses
                            </Link>
                          </li>

                          <li
                            id="ember768"
                            className="nav-item ember-view is-shown disabled"
                            onClick={(e) => handlePreferences(e)}
                          >
                            <Link
                              to="/settings/preferences/DepartmentList"
                              id="ember769"
                              className="nav-link ember-view"
                            >
                              <svg
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x={0}
                                y={0}
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                                className="icon icon-sm lpanel text-black"
                              >
                                <path d="M487 239.7H213.3c-7.4-33.7-37.4-59.1-73.4-59.1S74 206 66.6 239.7H21c-8.8 0-16 7.2-16 16s7.2 16 16 16h45.6c7.4 33.7 37.4 59.1 73.4 59.1s66-25.3 73.4-59.1H487c8.8 0 16-7.2 16-16s-7.2-16-16-16zm-347 59.1c-23.8 0-43.1-19.3-43.1-43.1s19.3-43.1 43.1-43.1 43.1 19.3 43.1 43.1-19.4 43.1-43.1 43.1zM487 418.1h-62c-7.4-33.7-37.4-59.1-73.4-59.1s-66 25.3-73.4 59.1H21c-8.8 0-16 7.2-16 16s7.2 16 16 16h257.2c7.4 33.7 37.4 59.1 73.4 59.1s66-25.3 73.4-59.1h62c8.8 0 16-7.2 16-16s-7.2-16-16-16zm-92.3 16c0 23.8-19.3 43.1-43.1 43.1s-43.1-19.3-43.1-43.1 19.3-43.1 43.1-43.1 43.1 19.3 43.1 43.1zM21 93.4h257.2c7.4 33.7 37.4 59.1 73.4 59.1s66-25.3 73.4-59.1h62c8.8 0 16-7.2 16-16s-7.2-16-16-16h-62c-7.4-33.7-37.4-59.1-73.4-59.1s-66 25.3-73.4 59.1H21c-8.8 0-16 7.2-16 16s7.2 16 16 16zm330.6-59.1c23.8 0 43.1 19.3 43.1 43.1s-19.3 43.1-43.1 43.1-43.1-19.3-43.1-43.1 19.3-43.1 43.1-43.1z" />
                              </svg>
                              &nbsp;&nbsp;Preferences
                            </Link>
                          </li>

                          <li id="ember772">
                            <Link to="/settings/taxes/taxList" id="ember773">
                              <svg
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x={0}
                                y={0}
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                                className="icon icon-sm lpanel text-black"
                              >
                                <path d="M147.9 358.4c-6.2-6.3-6.2-16.4 0-22.6l196.9-196.2c6.3-6.2 16.4-6.2 22.6 0 6.2 6.3 6.2 16.4 0 22.6L170.6 358.4c-6.3 6.2-16.4 6.2-22.7 0z" />
                                <circle cx="188.5" cy="179.5" r="39.5" />
                                <circle cx="326.9" cy="318.4" r="39.5" />
                                <path d="M254.6 511.6c-68.5 0-132.9-26.7-181.4-75.1-48.4-48.4-75-112.8-75-181.4S24.8 122.2 73.3 73.8C121.7 25.3 186.1-1.3 254.6-1.3c68.5 0 132.9 26.7 181.4 75.1s75.1 112.9 75.1 181.4-26.6 132.9-75.1 181.3-112.8 75.1-181.4 75.1zm0-480.9c-60 0-116.3 23.4-158.7 65.8-42.4 42.4-65.8 98.8-65.8 158.7s23.4 116.3 65.8 158.7c42.4 42.4 98.8 65.8 158.7 65.8s116.3-23.4 158.7-65.8c42.4-42.4 65.8-98.8 65.8-158.7 0-60-23.4-116.3-65.8-158.7C371 54 314.6 30.7 254.6 30.7z" />
                              </svg>
                              &nbsp;&nbsp;Taxes
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </div>

                    {/* /a  settings end here*/}
                  </ul>
                </nav>
              </>
            )}
          </div>
          <div
            className="slimScrollBar"
            style={{
              background: "rgb(28, 34, 44)",
              width: "2px",
              position: "absolute",
              top: "0px",
              opacity: "0.4",
              display: "block",
              borderRadius: "3px",
              zIndex: "99",
              right: "1px",
              height: "255.162px",
            }}
          ></div>
          <div
            className="slimScrollRail"
            style={{
              width: "2px",
              height: "100%",
              position: "absolute",
              top: "0px",
              display: "none",
              borderRadius: "7px",
              background: "rgb(51, 51, 51)",
              opacity: "0.2",
              zIndex: "90",
              right: "1px",
            }}
          ></div>
        </div>
        {showPreferencesNav ? <PreferencesNav /> : <></>}
      </div>

      <Routing />
      {/* </Router> */}
    </>
  );
};

export default NavigationComponent;
