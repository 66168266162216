import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { useHistory, useParams, Link, Redirect } from "react-router-dom";
import InsertChartIcon from "@mui/icons-material/InsertChart";

const Reports = () => {

  return (
    <>
  
      <div className="reports-content  content-column">
      
        <div className="header clearfix" style={{ borderBottom: "none" }}>
          <span className="float-left" style={{ fontSize: "20px",paddingTop:"20px" }}>
          All Reports
          </span>
        </div>
    <br/>
        <div
          className="scroll-y noscroll-x body scrollbox reports-home-container pt-7"
          style={{ marginTop: "40px", paddingLeft: "10px" }}
        >
          <table>
            <tr>SALES</tr>
            <tr>
              <Link to={"/inventory/reports/" + "SalesbyCustomer"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Sales by Customer</span>
              </Link>
            </tr>
            <tr>
              <Link to={"/inventory/reports/" + "SalesbyItem"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Sales by Item</span>
              </Link>
            </tr>
            <tr>
              <Link to={"/inventory/reports/" + "SalesbyInvoice"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Sales by Invoice</span>
              </Link>
            </tr>
            <tr>
              <Link to={"/inventory/reports/" + "SalesbySalesPerson"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Sales by SalesPerson</span>
              </Link>
            </tr>
          </table>
          <br />
          <table>
            <tr>PURCHASE AND EXPENSE</tr>
            <tr>
              <Link to={"/inventory/reports/" + "PurchasebyVendor"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Purchase by Vendor</span>
              </Link>
            </tr>
            <tr>
              <Link to={"/inventory/reports/" + "PurchasesbyItem"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Purchase by Item</span>
              </Link>
            </tr>
          </table>
          <br />
          <table>
            <tr>PAYABLES</tr>
            <tr>
              <Link to={"/inventory/reports/" + "BillsByVendorId"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Bills By VendorId</span>
              </Link>
            </tr>
          </table>
          <br />
          <table>
            <tr>OTHERS</tr>
            
            <tr>
              <Link to={"/inventory/reports/" + "BelowOrderLevelItemReport"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Below Order Level Item </span>
              </Link>
            </tr>
            <tr>
              <Link to={"/inventory/reports/" + "RecordByDepartment"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Record By Departnment</span>
              </Link>
            </tr>
            <tr>
              <Link to={"/inventory/reports/" + "InventorySummary"}>
                <InsertChartIcon />{" "}
                <span style={{ color: "green" }}>Inventory Summary</span>
              </Link>
            </tr>
        
          </table>
        </div>
      </div>
    </>
  );
};

export default Reports;
