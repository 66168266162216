import React, { useState } from 'react'
import Box from "@mui/material/Box";
import { TabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import SalesGeneral from './SalesGeneral';
import SalesCustomization from './SalesCustomization';
import SalesValidation from './SalesValidation';
import SalesCustom from './SalesCustom';
import PreferencesNav from '../PreferencesNav'

const SalesOrder = () => {
    const [value, setValue] = useState("1");

    const handleChange = (e, newValue) => {
        setValue(newValue)
    }

    return (
        <>
            <div className='column list-column d-print-none column-small'>

            <PreferencesNav/>

            </div>
            <div class="column content-column">
                <div className="header d-flex align-items-center p-4 justify-content-between" style={{ borderBottom: 'none' }}>
                    <span className="float-left" style={{ fontSize: '35px' }}> Sales Orders </span>
                    <div className="float-right" id="preferences-top-menu" />
                </div>
                <div className="ember-view scroll-y noscroll-x fill body scrollbox">
                <div className="" style={{ marginTop: '-60px' }} >
                    <div className="fill-container">
                   
                        <div class="sub-tab">
                            <Box
                                className="custom-tabs mt-65"
                                sx={{ width: "100%", typography: "body1" }}
                            >
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <TabList onChange={handleChange}>
                                            <Tab label="General" value="1"  />
                                            <Tab disabled={true} label="Field Customization" value="2"/>
                                            <Tab  disabled={true} label="Validation Rules" value="3" />
                                            <Tab disabled={true} label="Custom Status " value="4" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <SalesGeneral />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <SalesCustomization />
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <SalesValidation />
                                    </TabPanel>
                                    <TabPanel value="4">
                                        <SalesCustom />
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default SalesOrder