import React, { useState } from 'react'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';


const GSTSettings = () => {
    const [radio, setRadio] = useState('no');
    const [showCompScheme, setShowCompScheme] = useState(false);
    const [showCustomOption, setShowCustomOption] = useState(false);
    const [showDigitalService, setShowDigitalService] = useState(false);

    const handleGSTRadio = (e) => {
        setRadio(e.target.value)
    }

    const handleCustomDuty = (e) => {
        e.target.checked ? setShowCustomOption(true) : setShowCustomOption(false)
    }

    const handleCompositionScheme = (e) => {
        e.target.checked ? setShowCompScheme(true) : setShowCompScheme(false)
    }

    const handleDigitalService = (e) => {
        e.target.checked ? setShowDigitalService(true) : setShowDigitalService(false)
    }
    return (
        <>

            <div className="column content-column">

                <div className="fill list-header">
                    <div className="list-filter">
                        <span className="font-xxlarge mx-4">GST Settings</span>
                    </div>
                </div>
                <div className="scroll-y noscroll-x fill body scrollbox pt-5">
                    <div className="row">
                        <div className="col-lg-9">
                            <br />
                            <form className="vatsettings">

                                <div tabIndex={-1} id="ember982" className="no-outline ember-view">

                                </div>

                                <fieldset className="form-group well">
                                    <div className="row">
                                        <legend className="col-form-label  col-lg-5">
                                            <h3> Is your business registered for GST?</h3>
                                        </legend>
                                        <RadioGroup className='d-block' name="row-radio-buttons-group" onChange={(e) => handleGSTRadio(e)} value={radio} >
                                            <FormControlLabel control={<Radio value='yes' />} label="Yes" />
                                            <FormControlLabel control={<Radio value="no" />} label="No" />
                                        </RadioGroup>
                                    </div>
                                </fieldset>

                                {radio == 'yes' ?
                                    <div>
                                        <div className="form-group row">
                                            <div className="col-form-label col-lg-5">
                                                <span className="required">GSTIN</span>
                                                <span id="ember992" className="popover-container ember-view">
                                                    <span id="ember993" className="ember-view">
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x={0}
                                                            y={0}
                                                            viewBox="0 0 512 512"
                                                            xmlSpace="preserve"
                                                            className="icon icon-sm align-text-bottom text-muted cursor-pointer"
                                                        >
                                                            <path d="M317.1 147.5c-15.1-13.8-35.5-20.8-60.5-20.8-23.7 0-43.1 6.5-57.7 19.4-14.6 12.9-23.5 31.5-26.4 55.5l-.6 4.9 40.4 4.8.7-4.6c2.5-15.8 7.7-27.5 15.4-34.7 7.7-7.2 17.1-10.7 28.7-10.7 12 0 21.9 3.9 30.1 11.9 8.2 8 12.2 16.9 12.2 27.3 0 5.6-1.3 10.7-4 15.4-2.8 4.9-9.3 11.9-19.3 20.7-10.7 9.4-17.9 16.5-22.1 21.5-5.8 7-10 14-12.6 20.8-3.5 9.1-5.3 19.9-5.3 32.3 0 2.1.1 5.1.2 9l.1 4.7h38.4l.1-4.8c.3-14.3 1.4-21.4 2.3-24.7 1.3-4.7 3.2-8.8 5.9-12.5 2.8-3.8 9-10 18.5-18.4 15.1-13.4 25.1-24.6 30.4-34.2 5.4-9.7 8.1-20.4 8.1-31.9 0-19.9-7.7-37-23-50.9zM256.3 385.3c12.1 0 22-9.8 22-22 0-12.1-9.8-22-22-22-12.1 0-22 9.8-22 22s9.8 22 22 22z" />
                                                            <path d="M437.4 74.6C388.9 26.1 324.5-.5 256-.5S123.1 26.2 74.6 74.6C26.1 123.1-.5 187.5-.5 256s26.7 132.9 75.1 181.4c48.5 48.5 112.9 75.1 181.4 75.1s132.9-26.7 181.4-75.1c48.5-48.5 75.1-112.9 75.1-181.4s-26.6-132.9-75.1-181.4zm-22.6 340.2c-42.4 42.4-98.8 65.8-158.8 65.8s-116.4-23.4-158.8-65.8C54.8 372.4 31.5 316 31.5 256S54.8 139.6 97.2 97.2C139.6 54.8 196 31.5 256 31.5s116.4 23.4 158.8 65.8c42.4 42.4 65.8 98.8 65.8 158.8s-23.4 116.3-65.8 158.7z" />
                                                        </svg>
                                                    </span>
                                                    <div
                                                        id="ember994"
                                                        className="d-none popover bs-popover-right ember-view"
                                                    >
                                                    </div>
                                                </span>
                                                <div className="text-muted">(Maximum 15 digits)</div>
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    id="ember995"
                                                    className="ember-text-field ember-view form-control"
                                                    type="text"
                                                />
                                                <span
                                                    id="ember996"
                                                    className="cursor-pointer validate-gstin text-blue ember-view btn-link cursor-pointer"
                                                >
                                                    Get Taxpayer details
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-form-label col-lg-5"> GST Registered On </div>
                                            <div className="col-lg-4">
                                            </div>
                                        </div>
                                        <fieldset className="form-group">
                                            <div className="row">
                                                <legend
                                                    className="col-form-label col-lg-5 pt-0"
                                                    style={{ lineHeight: "15px" }}
                                                >
                                                    Composition Scheme <br />
                                                    <a
                                                        href="https://zoho.com/in/books/gst/gst-composition-scheme.html"
                                                        rel="noopener noreferrer"
                                                        className="font-xs"
                                                        target="_blank"
                                                    >
                                                        Know more
                                                    </a>
                                                </legend>
                                                <div className="col-lg-7">
                                                    <div className="form-check">
                                                        <input
                                                            id="acbfb33bf"
                                                            className="ember-checkbox ember-view form-check-input"
                                                            type="checkbox"
                                                            onChange={(e) => handleCompositionScheme(e)}
                                                        />
                                                        <label className="form-check-label" htmlFor="acbfb33bf">
                                                            My business is registered for Composition Scheme.
                                                        </label>
                                                        &nbsp; &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        {showCompScheme ?
                                            <fieldset className="form-group">
                                                <div className="row">
                                                    <legend className="col-form-label col-lg-5 required pt-0">
                                                        Composition Scheme Percentage
                                                    </legend>
                                                    <div className="col-lg-6">
                                                        <div className="form-check">

                                                            <input
                                                                id="215cd144"
                                                                className="form-check-input ember-view"
                                                                type="radio"
                                                                defaultValue={1}
                                                            />
                                                            <label className="form-check-label" htmlFor="215cd144">

                                                                1% <span className="text-muted">
                                                                    (For Traders and Manufacturers)
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="form-check">

                                                            <input
                                                                disabled
                                                                id="2383453c"
                                                                className="form-check-input ember-view"
                                                                type="radio"
                                                                defaultValue={2}
                                                            />
                                                            <label className="form-check-label" htmlFor="2383453c">

                                                                <label
                                                                    id="ember341"
                                                                    className="d-inline-block tooltip-container ember-view"
                                                                >

                                                                    2%
                                                                    <span className="text-muted">
                                                                        (For Manufacturers - GSTN has lowered the rate for manufacturers
                                                                        to 1%.)
                                                                    </span>
                                                                </label>
                                                            </label>
                                                        </div>
                                                        <div className="form-check">

                                                            <input
                                                                id="ab7b6848"
                                                                className="form-check-input ember-view"
                                                                type="radio"
                                                                defaultValue={5}
                                                            />
                                                            <label className="form-check-label" htmlFor="ab7b6848">

                                                                5% <span className="text-muted">(For Restaurant sector)</span>
                                                            </label>
                                                        </div>
                                                        <div className="form-check">

                                                            <input
                                                                id="52b579a9"
                                                                className="form-check-input ember-view"
                                                                type="radio"
                                                                defaultValue={6}
                                                            />
                                                            <label className="fon-check-label" htmlFor="52b579a9">

                                                                6%
                                                                <span className="text-muted">
                                                                    (For Suppliers of Services or Mixed Suppliers)
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            : <></>}
                                        <fieldset className="form-group">
                                            <div className="row">
                                                <legend className="col-form-label col-lg-5 pt-0">
                                                    Reverse Charge <br />
                                                    <a
                                                        href="https://zoho.com/in/books/gst/reverse-charge-mechanism-gst.html"
                                                        rel="noopener noreferrer"
                                                        className="font-xs"
                                                        target="_blank"
                                                    >
                                                        Know more
                                                    </a>
                                                </legend>
                                                <div className="col-lg-7">
                                                    <div className="form-check">
                                                        <input
                                                            id="a59cfad33"
                                                            className="ember-checkbox ember-view form-check-input"
                                                            type="checkbox"
                                                        />
                                                        <label className="form-check-label" htmlFor="a59cfad33">
                                                            Enable Reverse Charge in Sales transactions
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset className="form-group">
                                            <div className="row">
                                                <legend className="col-form-label col-lg-5 pt-0">
                                                    <span
                                                        id="ember998"
                                                        className="tooltip-container text-dashed-underline ember-view"
                                                    >
                                                        Import / Export
                                                    </span>
                                                </legend>
                                                <div className="col-lg-7">
                                                    <div className="form-check">
                                                        <input
                                                            id="a0ad33560"
                                                            className="ember-checkbox ember-view form-check-input"
                                                            type="checkbox"
                                                            onChange={(e) => handleCustomDuty(e)}
                                                        />
                                                        <label className="form-check-label" htmlFor="a0ad33560">

                                                            My business is involved in SEZ / Overseas Trading
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        {showCustomOption ?
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-5 required">
                                                    Custom Duty Tracking Account
                                                </label>
                                                <div className="col-lg-4">

                                                    <div id="ember356" className="accounts-select ember-view">
                                                        <div id="ember357" className="ember-view">
                                                            <div className="ac-box ">
                                                                <span className="ac-selected form-control " tabIndex={0}>
                                                                    <span className="text-muted"> Select Account</span>
                                                                    <div>

                                                                        <i className="zf-ac-toggler">
                                                                            <svg
                                                                                version="1.1"
                                                                                id="Layer_1"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                x={0}
                                                                                y={0}
                                                                                viewBox="0 0 512 512"
                                                                                xmlSpace="preserve"
                                                                            >
                                                                                <path d="M493.9 227.8c-21.4-21-56.1-21.1-77.6-.3L256 382.9 95.7 227.6c-21.5-20.8-56.3-20.7-77.6.3-10.6 10.4-15.9 24-15.9 37.7 0 13.8 5.4 27.5 16.2 38l199 192.9c21.4 20.7 55.9 20.7 77.3 0l199-192.9c21.4-20.9 21.6-54.8.2-75.8z" />
                                                                            </svg>
                                                                        </i>
                                                                    </div>
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="form-text font-xs">

                                                        You can create a new account with type as Expense or Other Expense.
                                                    </div>
                                                </div>
                                            </div>
                                            : <></>}

                                        <fieldset className="form-group">
                                            <div className="row">
                                                <legend className="col-form-label col-lg-5 pt-0">
                                                    Digital Services
                                                </legend>
                                                <div className="col-lg-6">
                                                    <div className="form-check">
                                                        <input
                                                            id="a499dee51"
                                                            className="ember-checkbox ember-view form-check-input"
                                                            type="checkbox"
                                                            onChange={(e) => handleDigitalService(e)}
                                                        />
                                                        <label className="form-check-label" htmlFor="a499dee51">

                                                            Track sale of digital services to overseas customers
                                                        </label>
                                                        <small className="d-block form-text">
                                                            {showDigitalService ?
                                                                'If you disable this option, any digital service created by you will be considered as a service.'
                                                                : 'Enabling this option will let you record and track export of digital services to individuals.'}
                                                        </small>

                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <hr />
                                        <div className="form-group row">
                                            <div className="col-lg-7 offset-lg-5">
                                                <button
                                                    id="ember999"
                                                    className="btn btn-primary ember-view"
                                                    type="submit"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>}
                            </form>
                        </div>
                    </div>
                </div>
                <div id="ember983" className="d-none ember-view">
                    <div className="modal-backdrop show" />
                    <div className="loader bank-loader">
                        <h4>Updating GST Settings</h4>
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default GSTSettings