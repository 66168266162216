import { SALE_ORDER_URL } from "../CONSTANTS";
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData1");

const headersdata = {
  "content-type": "application/json",
  Accept: "application/json",
  Authorization: cook != undefined ? cook.accessToken : "",
  // 'Authorization': url.Auth_Token
};



export const getSalesOrderBySalesPersonId1 = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(SALE_ORDER_URL() + "getSalesOrderBySalesPersonId",data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getSalesOrderBySalesPersonId axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getSalesByInvoiceNo1 = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SALE_ORDER_URL() + "getSalesByInvoiceNo/"+id, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getSalesByInvoiceNo axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getSalesOrderByItemId1 = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(SALE_ORDER_URL() + "getSalesOrderByItemId", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};


export const getSalesOrderByCustomerId1 = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(SALE_ORDER_URL() + "getSalesOrderByCustomerId", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getSalesOrderList = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(SALE_ORDER_URL() + "getAllRecords", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const addSalesOrder = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(SALE_ORDER_URL(), data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getSalesOrderDetail = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SALE_ORDER_URL() + id, { headers: headersdata })
        .then((res) => {
          console.log("getItemDetail > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getItemDetail > axios err=", err);
          reject("Error in getItemDetail axios!");
        });
    } catch (error) {
      console.error("in LoginService > getItemDetail, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getSalesOrderNoByCustomerUUID = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SALE_ORDER_URL() + "getKeyValueListByCustomer/" + id, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getSalesOrderNoByCustomerUUID axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const updateSalesOrder = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(SALE_ORDER_URL() + id, data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const convertToInvoice = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(SALE_ORDER_URL() + id, data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getItemList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};