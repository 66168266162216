import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link, Redirect } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import {
  logIssuanceHistory,
  getIssuanceRecords,
  getIssuanceDetail,
  getIssuanceKeyValueList,
} from "../../services/index";

const ViewIssuance = (props) => {
  let { id } = useParams();

  const [logData, setLogData] = useState({});
  const [loading, setLoading] = useState(false);
  const [rId, setRId] = useState([]);
  const [unit, setUnit] = useState([]);

  const [issuanceKeyOptions, setIssuanceKeyOptions] = useState([]);

  const [issuanceKeyValueList, setIssuanceKeyValueList] = useState([]);

  const [issuanceRec, setIssuanceRec] = useState([]);
  const [rows, setRows] = useState([]);
  const [issuanceDetails, setIssuanceDetails] = useState([]);

  const [redirState, setRedirState] = useState(false);
  const [dataId, setDataId] = useState();
  const [value, setValue] = useState("1");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const editItems = () => {
    setRedirState(true);
    setDataId(issuanceDetails.recordId);
  };

  useEffect(() => {
    logIssuanceHistory(issuanceDetails.issuanceCode).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        console.log(d, "d");
        setLogData(d);
      } else {
        toast(res.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  }, [issuanceDetails.issuanceCode]);
  const getIssueDetails = (id) => {
    getIssuanceDetail(id)
      .then((res) => {
        if (res.errorCode == 0) {
          setIssuanceDetails(res.responsePacket);
          setLoading(true);
        } else {
          toast("👎" + res.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("👎" + err, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    let customer_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getIssuanceRecords(customer_data).then((res) => {
      if (res.errorCode == 0) {
        setIssuanceRec(res.responsePacket.data);
        const d = res.responsePacket.data;
        setRows(d);

        const data = res.responsePacket.data;
        const options = data.map((d) => ({
          value: d.recordId,
        }));

        setRId(options);
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    getIssueDetails(id);

    getIssuanceKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        setIssuanceKeyValueList(res.responsePacket);
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setIssuanceKeyOptions(options);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }, []);

  return (
    <>
      <div
        className="list-column column d-print-none"
        style={{ width: "300px" }}
      >
        <div className="fill list-header topgrad d-print-none">
          <div className="list-filter">
            <div className="btn-toolbar float-right">
              <Link to="/inventory/issueitems/new">
                <button
                  id="ember3047"
                  className="btn btn-primary tooltip-container ember-view"
                >
                  <span className="d-flex align-items-center">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      viewBox="0 0 512 512"
                      xmlSpace="preserve"
                      className="icon icon-xsmall mr-2"
                    >
                      <path d="M484 227H284V28c0-15.5-12.5-28-28-28s-28 12.5-28 28v199H28c-15.5 0-28 12.5-28 28v1c0 15.5 12.5 28 28 28h200v200c0 15.5 12.5 28 28 28s28-12.5 28-28V284h200c15.5 0 28-12.5 28-28v-1c0-15.5-12.5-28-28-28z"></path>
                    </svg>
                    New
                  </span>
                </button>
              </Link>
            </div>
            <div
              id="ember3068"
              className="clearfix list-title dropdown ember-view"
            >
              <a id="ember3069" className="dropdown-toggle no-caret ember-view">
                <span className="filter-title over-flow">All Issuance</span>
              </a>
            </div>
          </div>
        </div>
        <div className="scroll-y fill body scrollbox list-body">
          <div className="table-responsive overflow-initial">
            <table
              id="ember7912"
              className="table zi-table table-hover ember-view header-fixed"
            >
              <tbody>
                {rows.map((row, index) => (
                  <tr
                    key={index}
                    tabIndex="-1"
                    id="ember3096"
                    className="table-active ember-view"
                    onClick={() => getIssueDetails(row.recordId)}
                  >
                    <td>
                      <Link to={`/inventory/issueitems/${row.recordId}`}>
                        <div className="list-primary">
                          <div className="text-uppercase  text-open">
                            {row.issuanceCode}
                          </div>

                          <div className="text-muted">
                            {row?.issuedDepartmentTitle}
                          </div>
                          <div className="text-muted">
                            By: {row?.issuedByUserTitle}
                          </div>
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="column content-column " style={{ left: "301px" }}>
        <div className="header no-border d-flex align-items-center p-4">
          <h3 className="over-flow header-title">
            {/* {issuanceDetails.issuanceCode} */}
            &nbsp;
          </h3>
          <div>
            <div className="btn-toolbar">
              <div className="btn-group">{/* edit btn */}</div>
              {redirState && (
                <Redirect
                  push
                  to={{
                    pathname: `/inventory/issueitems/${dataId}/edit`,
                  }}
                />
              )}

              {/* Cross btn */}
              <Link to={"/inventory/issueitems"}>
                <span
                  className="close-details pl-4"
                  data-ember-action=""
                  data-ember-action-3373="3373"
                >
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    className="icon icon-sm align-text-top text-black"
                  >
                    <path d="M455.2 9.2L256 208.4 56.8 9.2C44.5-3.1 24.6-3.1 12.2 9.2l-2.9 2.9C-3 24.4-3 44.4 9.3 56.7L208.4 256 9.2 455.2c-12.3 12.3-12.3 32.3 0 44.6l2.9 2.9c12.3 12.3 32.3 12.3 44.6 0L256 303.6l199.2 199.2c12.3 12.3 32.3 12.3 44.6 0l2.9-2.9c12.3-12.3 12.3-32.3 0-44.6L303.6 256 502.8 56.8c12.3-12.3 12.3-32.3 0-44.6l-2.9-2.9c-12.5-12.4-32.4-12.4-44.7-.1z"></path>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Primary Details</Tab>
            <Tab>Log History</Tab>
          </TabList>
          <TabPanel>
            <div className="ember-view scroll-y noscroll-x fill body scrollbox">
              <br />
              <div className="customTable" id="scroll">
                <table>
                  <tr>
                    <td className="font-large text-uppercase font-xs text-open">
                      {issuanceDetails.issuanceCode}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-large text-uppercase font-xs text-open">
                      {issuanceDetails?.requisitionCode}
                    </td>
                  </tr>
                  {issuanceDetails.issuedToUserTitle ? (
                    <tr>
                      <td>Issued To:</td>
                      <td>{issuanceDetails.issuedToUserTitle}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td>Issued By:</td>
                    <td>{issuanceDetails.issuedByUserTitle}</td>
                  </tr>
                  <tr>
                    <td>Issuance Date:</td>
                    <td>{issuanceDetails.issuanceDate}</td>
                  </tr>
                  <tr>
                    <td>Department:</td>
                    <td>{issuanceDetails.issuedDepartmentTitle}</td>
                  </tr>
                  <tr>
                    <td>Warehouse:</td>
                    <td>{issuanceDetails.warehouseTitle}</td>
                  </tr>
                </table>
              </div>
              <hr />

              <div className="customTable" id="scroll">
                {!loading
                  ? "loading"
                  : issuanceDetails.issuedItemsList.length !== 0 && (
                      <div>
                        ISSUED ITEMS
                        <hr />
                        <table>
                          <thead>
                            <tr>
                              <td>Item</td>
                              <td>Quantity</td>
                            </tr>
                          </thead>
                          <tbody>
                            {issuanceDetails.issuedItemsList.map((item, i) => (
                              <tr key={i}>
                                <td>{item.itemTitle}</td>

                                <td>
                                  {item.issuedQuantity}&nbsp;
                                  {item.issuedQuantityUnitTitle}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                <div id="ember3382" className="ember-view"></div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <table>
              {logData.length == 0 ? (
                "No Changes Found"
              ) : (
                <tr>
                  <td>Date</td>
                  <td>Changes</td>
                </tr>
              )}
              {logData.length >= 1 &&
                logData?.map((history) => {
                  return (
                    <>
                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          {history?.transactionDate?.substring(0, 10)}
                          :&nbsp;&nbsp;
                        </td>

                        <td>
                          {history?.message.split("\n").map((item, i) => (
                            <tr>{item}</tr>
                          ))}
                        </td>
                      </tr>
                      <hr />
                    </>
                  );
                })}
            </table>
          </TabPanel>
        </Tabs>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewIssuance;
