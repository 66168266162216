import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";

import { toast,ToastContainer } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Header } from "antd/lib/layout/layout";
import PreferencesNav from "../Preferences/PreferencesNav";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AddIcon from "@mui/icons-material/Add";

import {
  termsConditionList1,
  addTermsCondition,
  updateTermsCondition,
  termsConditionUUID1,
} from "../../../services/index";

const TermsConditionList = () => {
  let history = useHistory();

  const [termsTitle, setTermsTitle] = useState("");
  const [termsDesc, setTermsDesc] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setTermsTitle("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [edit, setEdit] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);

  const [dataId, setDataId] = useState(false);

  const columns = [
    {
      name: "TITLE",
      cell: (row) => row.title,
    },
    {
      name: "DESC.",
      cell: (row) => row.detailedConditions,
    },
  ];

  const handleSave = () => {
    if (termsTitle == "") {
      toast("Please fill termsTitle ", {
        autoClose: 2000,
      });
      return;
    }

    const data1 = {
      title: termsTitle,
      detailedConditions:termsDesc
    };

    edit
      ? updateTermsCondition(dataId, data1).then((res) => {
          if (res.success) {
            toast(res.message, {
              autoClose: 2000,
            });
            setTermsTitle("");
            handleClose();
            handleRefresh();
          } else {
            toast(res.message, {
              autoClose: 2000,
            });
            return;
          }
        })
      : addTermsCondition(data1).then((res) => {
          if (res.errorCode == 0) {
            toast(res.message, {
              autoClose: 2000,
            });
            setTermsTitle("");
            handleClose();
            handleRefresh();
          } else {
            toast(res.message, {
              autoClose: 2000,
            });
            return;
          }
        });
  };

  const handleClicked = (id) => {
    termsConditionUUID1(id).then((res) => {
      if (res.errorCode == 0) {
        setTermsTitle(res.responsePacket.title);
        setTermsDesc(res.responsePacket.detailedConditions);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    handleShow();
    setEdit(true);
  };

  const handleRefresh = () => {
    var data = {
      page: 0,
      search: "",
      size: -1,
    };

    termsConditionList1(data).then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        setRows(temp);
        setFilteredData(temp);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  useEffect(() => {
    var data = {
      page: 0,
      search: "",
      size: -1,
    };

    termsConditionList1(data).then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        setRows(temp);
        setFilteredData(temp);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }, []);

  useEffect(() => {
    const result = rows.filter((data) => {
      return data.title.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  return (
    <>
      <div className="column list-column d-print-none column-small">
        <PreferencesNav />
      </div>
      <div className="column content-column">
        <div className="header clearfix" style={{ borderBottom: "none" }}>
          <span className="float-left" style={{ fontSize: "35px" }}>
            Terms and Condition List
          </span>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <Header>Terms and Condition Info</Header>
            <br />
            <hr />

            <table>
              <tr>
                <td>Terms:</td>
                <td>
                  {" "}
                  <div>
                    <input
                      rows="4"
                      cols="50"
                      className="form-control ember-text-area ember-view"
                      onChange={(e) => {
                        setTermsTitle(e.target.value);
                      }}
                      value={termsTitle}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Desc:</td>
                <td> <textarea
                rows="4"
                cols="50"
                      className="form-control"
                      onChange={(e) => {
                        setTermsDesc(e.target.value);
                      }}
                      value={termsDesc}
                    /></td>
              </tr>
            </table>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            columns={columns}
            data={filteredData}
            fixedHeader
            pointerOnHover={true}
            highlightOnHover={true}
            onRowClicked={(data) => {
              setDataId(data.recordId);
              handleClicked(data.recordId);
            }}
            subHeader
            subHeaderComponent={
              <>
                {" "}
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder={"Search here"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ margin: "10px" }}
                />
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                  onClick={handleShow}
                >
                  <AddIcon />
                  New
                </button>
              </>
            }
            subHeaderAlign="right"
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default TermsConditionList;
