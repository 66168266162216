import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "react-select";
import AddIcon from "@mui/icons-material/Add";
import PriceChangeIcon from '@mui/icons-material/PriceChange';

import {
  getPriceDetails,
  getActiveItemRecords,
  getRoundOffKeyValuePair,
  addPrice,
  updatePrice,
} from "../../services/index";

const PriceListNew = (props) => {
  console.log(props, "props123");

  let priceId = props.match.params.id;
  let history = useHistory();
  const [itemOptions, setItemOptions] = useState("");
  const [delItem, setDelItem] = useState("");
  const [itemOptions2, setItemOptions2] = useState("");
  const [priceInfo, setPriceInfo] = useState({
    name: "",
    type: "Sales",
    rate: "markUp",
    scheme: "unit",
    percent: 1,
    note: "",
  });

  const tempRow = {
    item: "",
    start: 0,
    end: 0,
    custom: 0,
  };

  const [rows, setRows] = useState([tempRow]);

  const handleAddRow = () => {
    setRows([...rows, tempRow]);
    handleFilterItems();
  };

  const handleClear = (id, i) => {
    const obj = rows.slice();
    obj.splice(i, 1);
    setRows(obj);
  };

  useEffect(() => {
    var request = {
      page: 0,
      search: "",
      size: -1,
    };
    getActiveItemRecords(request).then((res) => {
      if (res.errorCode == 0) {
        const options = res?.responsePacket?.data.map((d) => ({
          value: d.recordId,
          label: d.title,
          sPrice: d?.sellingPriceValue,
          pPrice: d?.purchasePriceValue,
        }));
        setItemOptions(options);
        setItemOptions2(options);
      } else {
        toast(res.message);
      }
    });
    if (priceId) {
      getPriceDetails1();
    }
  }, []);

  const getPriceDetails1 = () => {
    getPriceDetails(priceId).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;
        let d2;
        if (d.isVolumePrice) {
          d2 = d?.itemCustomVolumePriceBeansList;
        } else if (d.isUnitPrice) {
          d2 = d?.itemCustomUnitPriceBeanList;
        }

        let rate;
        if (d.isIndividual) {
          rate = "individual";
        } else if (d.isMarkDown) {
          rate = "markDown";
        } else {
          rate = "markUp";
        }

        setPriceInfo({
          name: d.title,
          type: d.itemPriceListTypeEnum,
          rate: rate,
          scheme: d.isVolumePrice ? "volume" : "unit",
          percent: d?.percentage,
          note: d?.description,
        });

        let abc =
          d2 &&
          d2.map((a) => {
            return {
              item: {
                value: a.itemId,
                label: a.itemTitle,
                sPrice: a.price,
                priceListId: a.priceListId,
              },
              custom: a.customPrice,
              start: a.startQuantity,
              end: a.endQuantity,
            };
          });
        setRows(abc);
      } else {
        toast(res.message);
      }
    });
  };

  const handleItem = (e, i) => {
    const obj = [...rows];
    obj[i].item = e;
    setRows(obj);
    handleFilterItems();
  };

  const handleFilterItems = () => {
    var send = false;
    var filterOptions = [];
    itemOptions2 &&
      itemOptions2.map((a) => {
        rows &&
          rows.map((b) => {
            if (a.value === b?.item?.value) {
              send = true;
            }
          });
        if (!send) {
          filterOptions.push(a);
        }
        send = false;
      });
    setItemOptions(filterOptions);
  };

  const handleStart = (e, i) => {
    const obj = [...rows];
    obj[i].start = Number(e.target.value);
    setRows(obj);
  };
  const handleEnd = (e, i) => {
    const obj = [...rows];
    obj[i].end = Number(e.target.value);
    setRows(obj);
  };
  const handleCustom = (e, i) => {
    const obj = [...rows];
    obj[i].custom = Number(e.target.value);
    setRows(obj);
  };

  useEffect(() => {
    console.log(priceInfo, "priceInfo");
    console.log(rows, "rows");
  }, [rows, priceInfo]);

  const handleSave = () => {
    if (priceInfo.name == "") {
      toast("Please enter PriceList name");
      return;
    }

    if (priceInfo.rate != "individual" && priceInfo.percent == "") {
      toast("Please enter Percentage");
      return;
    }

    const data = {
      currencyRecordCode: "string",
      currencyRecordId: "dad7fa51-f498-42fb-a2d9-8efe52269ffc",
      currencyRecordTitle: "INR - Indian Rupee",
      currencyRecordSymbol: "string",

      title: priceInfo.name,
      description: priceInfo.note,
      itemPriceListTypeEnum: priceInfo.type,
      isMarkUp: priceInfo.rate == "markUp" ? true : false,
      isMarkDown: priceInfo.rate == "markDown" ? true : false,
      isIndividual: priceInfo.rate == "individual" ? true : false,

      isUnitPrice:
        priceInfo.rate == "individual" && priceInfo.scheme == "unit"
          ? true
          : false,
      isVolumePrice:
        priceInfo.rate == "individual" && priceInfo.scheme == "volume"
          ? true
          : false,

      itemCustomUnitPriceBeanList: [],
      itemCustomVolumePriceBeansList: [],

      percentage: priceInfo.rate != "individual" ? priceInfo.percent : "",
      roundOffRecordId: priceInfo.rate == "individual" ? "" : "1",
      roundOffRecordTitle:
        priceInfo.rate == "individual" ? "" : "Round off to nearest zero",
      roundOffRecordValue: 0,
    };
    let rows2;
    if (priceInfo.rate == "individual" && priceInfo.scheme == "unit") {
      rows.map((a) => {
        a.itemTitle = a.item.label;
        a.itemId = a.item.value;
        a.salesPrice = a.item.sPrice;
        a.customPrice = a.custom;
      });
      rows2 = rows.filter((a) => a.item && a.custom > 0);
      data.itemCustomUnitPriceBeanList = rows2;
    }

    if (priceInfo.rate == "individual" && priceInfo.scheme == "volume") {
      rows.map((a) => {
        a.itemTitle = a.item.label;
        a.itemId = a.item.value;
        a.salesPrice = a.item.sPrice;
        a.startQuantity = a.start;
        a.endQuantity = a.end;
        a.customPrice = a.custom;
      });
      rows2 = rows.filter((a) => a.item && a.custom > 0);
      data.itemCustomVolumePriceBeansList = rows2;
    }
    if (priceInfo.rate == "individual" && rows2.length == 0) {
      toast("please add Item");
      return;
    }
    console.log(data, "data");

    if (priceId) {
      updatePrice(priceId, data).then((res) => {
        if (res.errorCode == 0) {
          toast(res.message);
          history.push("/inventory/pricelists");
        } else {
          toast(res.message);
        }
      });
    } else {
      addPrice(data).then((res) => {
        if (res.errorCode == 0) {
          toast(res.message);
          history.push("/inventory/pricelists");
        } else {
          toast(res.message);
        }
      });
    }
  };

  return (
    <>
      <div style={{ paddingLeft: "10px" }}>
        <div className="fill list-header  bg-white p-3 border-bottom">
         <PriceChangeIcon/> {priceId ? "EDIT" : "NEW"} PRICELIST
        </div>

        <table className="customTable">
          <tr>
            <td style={{ width: "100px" }}>Name:</td>
            <td>
              <input
                placeholder="Ex: 20 sales unit "
                className="form-control"
                value={priceInfo.name}
                onChange={(e) =>
                  setPriceInfo({ ...priceInfo, name: e.target.value })
                }
              />
            </td>
          </tr>

          <tr>
            <td>Type:</td>
            <td>
              <RadioGroup
                row
                value={priceInfo.type}
                onChange={(e) =>
                  setPriceInfo({ ...priceInfo, type: e.target.value })
                }
              >
                <FormControlLabel
                  disabled={priceId ? true : false}
                  value="Sales"
                  control={<Radio size="small" />}
                  label="Sales"
                />
                <FormControlLabel
                  disabled={priceId ? true : false}
                  value="Purchase"
                  control={<Radio size="small" />}
                  label="Purchase"
                />
              </RadioGroup>
            </td>
          </tr>
          <tr>
            <td>Rate:</td>
            <td>
              <RadioGroup
                row
                value={priceInfo.rate}
                onChange={(e) =>
                  setPriceInfo({ ...priceInfo, rate: e.target.value })
                }
              >
                <FormControlLabel
                  disabled={priceId ? true : false}
                  value="markUp"
                  control={<Radio size="small" />}
                  label="markUp"
                />
                <FormControlLabel
                  disabled={priceId ? true : false}
                  value="markDown"
                  control={<Radio size="small" />}
                  label="markDown"
                />
                <FormControlLabel
                  disabled={priceId ? true : false}
                  value="individual"
                  control={<Radio size="small" />}
                  label="individual"
                />
              </RadioGroup>
            </td>
          </tr>
          {priceInfo.rate == "individual" ? (
            <tr>
              <td>Scheme:</td>
              <td>
                <RadioGroup
                  row
                  value={priceInfo.scheme}
                  onChange={(e) =>
                    setPriceInfo({ ...priceInfo, scheme: e.target.value })
                  }
                >
                  <FormControlLabel
                    disabled={priceId ? true : false}
                    value="unit"
                    control={<Radio size="small" />}
                    label="unit"
                  />
                  <FormControlLabel
                    disabled={priceId ? true : false}
                    value="volume"
                    control={<Radio size="small" />}
                    label="volume"
                  />
                </RadioGroup>
              </td>
            </tr>
          ) : (
            <tr>
              <td style={{ width: "100px" }}>Percent:</td>
              <td>
                <input
                  className="form-control"
                  type="number"
                  value={priceInfo.percent}
                  onChange={(e) =>
                    setPriceInfo({ ...priceInfo, percent: e.target.value })
                  }
                />
              </td>
            </tr>
          )}
          <tr>
            <td style={{ width: "100px" }}>Note:</td>
            <td>
              <input
                className="form-control"
                value={priceInfo.note}
                onChange={(e) =>
                  setPriceInfo({ ...priceInfo, note: e.target.value })
                }
              />
            </td>
          </tr>
        </table>
        <br />
        {priceInfo.rate == "individual" ? (
          <div>
            <table className="customTable">
              <tr>
                <td>Item</td>
                {priceInfo.scheme == "volume" ? (
                  <>
                    <td>Start Qty</td>
                    <td>End Qty</td>
                  </>
                ) : (
                  <></>
                )}

                <td>Custom Qty</td>
                <td></td>
              </tr>
              {rows &&
                rows.map((a, i) => {
                  return (
                    <tr>
                      <td style={{ width: "300px" }}>
                        <Select
                          options={itemOptions}
                          value={a.item}
                          onChange={(e) => handleItem(e, i)}
                        />
                      </td>

                      {priceInfo.scheme == "volume" ? (
                        <>
                          {" "}
                          <td>
                            <input
                              type="number"
                              min={0}
                              value={a.start}
                              onChange={(e) => handleStart(e, i)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              min={0}
                              value={a.end}
                              onChange={(e) => handleEnd(e, i)}
                            />
                          </td>
                        </>
                      ) : (
                        <></>
                      )}

                      <td>
                        <input
                          type="number"
                          min={0}
                          value={a.custom}
                          onChange={(e) => handleCustom(e, i)}
                        />
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClear(a.item.value, i)}
                      >
                        {rows.length > 1 && <i className="fa fa-times"></i>}
                      </td>
                    </tr>
                  );
                })}
            </table>

            <button
              id="ember1426"
              className="btn btn-default addbtn mt-3"
              onClick={handleAddRow}
            >
              <AddIcon style={{ color: "blue" }} /> New Row
            </button>
          </div>
        ) : (
          <></>
        )}
        <br />
        <div>
          {" "}
          <button
            className="btn bg-primary text-white mr-2"
            onClick={(e) => handleSave(e)}
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PriceListNew;
