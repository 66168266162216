import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import {
  getInvoicesList,
  getAllRecordsPayment,
} from "../../services/Sales/InvoiceServices";
import Modal from "react-bootstrap/Modal";

import { logSalesPaymentHistory } from "../../services/index";
import { Redirect, Link } from "react-router-dom";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
const PaymentList = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [logData, setLogData] = useState({});

  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState([]);
  const [dataId, setDataId] = useState(false);
  const [redirectState, setRedirectState] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;

  const columns = [
    {
      name: "PAYMENT DATE",
      selector: (data) => data.paymentDate,
      sortable: true,
    },
    {
      name: "PAYMENT NO.",
      selector: (data) => data.paymentNo,
      sortable: true,
    },
    {
      name: "INVOICE NO.",
      selector: (data) => data.invoiceNo,
      sortable: true,
    },
    {
      name: "CUSTOMER NAME",
      selector: (data) => data.customerTitle,
      sortable: true,
    },
    {
      name: "AMOUNT RECEIVED",
      selector: (data) => data.amountReceived?.toFixed(2),
    },
  ];

  const handleModel = (id) => {
    handleShow();
    logSalesPaymentHistory(id).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        console.log(d, "d");
        setLogData(d);
      } else {
        toast(res.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  };

  const PaymentList = () => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getAllRecordsPayment(list_data)
      .then((res) => {
        if (res.errorCode == 0) {
          setRecords(res.responsePacket);
          const d = res.responsePacket.data;
          setData(d);
          setFilteredData(d);
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("🙁" + err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  let redirecting = redirectState ? (
    <Redirect push to={`/inventory/SalesPayment/view/${dataId}`} />
  ) : (
    ""
  );

  useEffect(() => {
    PaymentList();
  }, [page]);

  useEffect(() => {
    const result = data.filter((data) => {
      return (
        data.customerTitle.toLowerCase().match(search.toLowerCase()) ||
        data.invoiceNo.toLowerCase().match(search.toLowerCase()) ||
        data.paymentDate.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          Log History
          <hr/>
          <table>
            {logData.length == 0 ? (
              "No Changes Found"
            ) : (
              <tr>
                <td>Date</td>
                <td>Changes</td>
              </tr>
            )}
            {logData.length >= 1 &&
              logData?.map((history) => {
                return (
                  <>
                    <tr>
                      <td style={{ alignItems: "start" }}>
                        {history?.transactionDate?.substring(0, 10)}:&nbsp;&nbsp;
                      </td>

                      <td>
                        {history?.message.split("\n").map((item, i) => (
                          <tr>{item}</tr>
                        ))}
                      </td>
                    </tr>
                    <hr />
                  </>
                );
              })}
          </table>
        </Modal.Body>
      </Modal>

      <div className="table-responsive overflow-initial customviews-table  ">
        {/* ======= */}
        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            title={"All Payments"}
            columns={columns}
            // data={data}
            data={filteredData}
            theme="light"
            pagination
            fixedHeader
            pointerOnHover={true}
            highlightOnHover={true}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
            onRowClicked={(data) => {
              handleModel(data.recordId);
            }}
            subHeader
            subHeaderComponent={
              <>
                {" "}
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder={"Search here"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </>
            }
            subHeaderAlign="right"
          />
          {redirecting}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PaymentList;
