
import { BILL_URL,DELIVERY_URL, TERMSCONDITION_URL,DEPARTMENT_URL,BASE_URL_INVENTORY,HSN_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook !== undefined ? cook.accessToken : ''
}


// /hsn/uploadHsnExcel


export const uploadHsnExcel = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(HSN_URL() + "uploadHsnExcel",data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in upload axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const downloadHsnExcel = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(BASE_URL_INVENTORY() + "hsn/downloadHsnExcel", {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in upload axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const activeInactiveDept = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(DEPARTMENT_URL()+"activeOrDeActiveRecord/"+id ,id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in activeOrDeActiveRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const activeInactiveDelivery = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(DELIVERY_URL()+"activeOrDeActiveRecord/"+id ,id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in activeOrDeActiveRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const departmentKeyValue1 = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(DEPARTMENT_URL()+"getKeyValueList" , { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DEPARTMENT_URL axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const addDepartment1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(DEPARTMENT_URL() , data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DEPARTMENT_URL axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateDepartment1 = (id, data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(DEPARTMENT_URL() + id, data, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getItemList axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };



export const deptKeyValueList1 = () => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(DEPARTMENT_URL() + "getKeyValueList", { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getKeyValueList axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };
  export const departmentList1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(DEPARTMENT_URL() +"getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DELIVERY_URL axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const departmentUUID1 = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(DEPARTMENT_URL() +id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DELIVERY_URL axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};



export const addTermsCondition = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(TERMSCONDITION_URL() , data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in termsCondition axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};



export const getTermsConditionKeyValue = () => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(TERMSCONDITION_URL() + "getKeyValueList", { headers: headersdata })
          .then((res) => {
            console.log("getItemDetail > axios res=", res);
            resolve(res.data);
          })
          .catch((err) => {
            console.log("getItemDetail > axios err=", err);
            reject("Error in getItemDetail axios!");
          });
      } catch (error) {
        console.error("in LoginService > getItemDetail, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };



  export const updateTermsCondition = (id, data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(TERMSCONDITION_URL() + id, data, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getItemList axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };

  export const termsConditionList1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(TERMSCONDITION_URL() +"getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DELIVERY_URL axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const termsConditionUUID1 = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TERMSCONDITION_URL() +id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DELIVERY_URL axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
  

  export const editDeliveryPerson1 = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(DELIVERY_URL() +id,data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DELIVERY_URL axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

  export const deliveryPersonUUID1 = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(DELIVERY_URL() +id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DELIVERY_URL axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};



export const deliveryPersonList1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(DELIVERY_URL() +"getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DELIVERY_URL axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};





export const addDeliveryPerson = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(DELIVERY_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addDeliveryPerson axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
}; 

export const KeyValueDeliveryPersonList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(DELIVERY_URL() + "getKeyValueDetailsList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in KeyValueDeliveryPersonList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};




export const PurchasePaymentBillOrder = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(BILL_URL(),+id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};



// DELETE http://192.168.1.3:8001/bills/94731496-5cf0-4b19-a360-545b3e99cd9d
export const deleteBillOrder = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(BILL_URL(),+id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

// POST http://192.168.1.3:8001/bills/ add new



// PUT http://192.168.1.3:8001/bills/http%3A%2F%2F192.168.1.3%3A8001%2Fbill%2F94731496-5cf0-4b19-a360-545b3e99cd9d update existing ,matching input
export const updateBillOrder = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(BILL_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    }); 
};


// POST http://192.168.1.3:8001/bills/getAllRecords fetch all
export const getAllBillRecords = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(BILL_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

// GET http://192.168.1.3:8001/bills/94731496-5cf0-4b19-a360-545b3e99cd9d fetch specific
export const getBillDetailsByUUID = (id) => { 
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(BILL_URL() + id, { headers: headersdata })
                .then((res) => {                   
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getBillDetails axios!");
                    
                });
        } catch (error) {
            console.error("in LoginService > getBillDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


// GET http://192.168.1.3:8001/bills/getKeyValueList fetch key value list
export const getBillKeyValuePair = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(BILL_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getBillKeyValuePair axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};