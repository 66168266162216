import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SyncIcon from '@mui/icons-material/Sync';


import {
  getVendorList,
  uploadVendorExcel,
  urlMappingUUID1,
  activeDeactiveVendor,
  getWarehouseKeyValueList,
  addVendorByWarehouseId1, //sync
  getVendorNotInWarehouseId1, //sync
  getVendorByWare1,
  removeVendorByWarehouseId1,

} from "../../services/index";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";

import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import {
  BASE_URL_INVENTORY,
  DOWNLOAD_VENDOR_URL,
} from "../../services/CONSTANTS";
import { Tooltip } from "@material-ui/core";

const VendorList = () => {
  const [error, setError] = useState({});

  const [item1, setItem1] = useState("");
  const [item2, setItem2] = useState("");

  const [itemSync1, setItemSync1] = useState("");
  const [itemSync2, setItemSync2] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [file1, setFile1] = useState({
    fileBase64: "",
    fileName: "",
  });
  const [issuanceOptions, setIssuanceOptions] = useState([]);
  const [ware, setWare] = useState([]);
  const [wareOptions, setWareOptions] = useState([]);

  const [downloadVendorExcel1, setDownloadVendorExcel1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [rows, setRows] = useState([]);
  let history = useHistory();

  useEffect(() => {
    vendorAllRecords();
    getWarehouseKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        let data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));

        setWareOptions(options);
      }
    });
  }, []);

  const vendorAllRecords = () => {
    var request = {
      page: 0,
      search: "",
      size: -1,
    };
    getVendorList(request).then((res) => {
      if (res.errorCode == 0) {
        res.responsePacket.data.reverse();
        setRows(res.responsePacket.data);
        setFilteredData(res.responsePacket.data);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  useEffect(() => {
    const result = rows.filter((data) => {
      return (
        data.title.toLowerCase().match(search.toLowerCase()) ||
        data.companyTitle.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  const handleCustomSelect = (e) => {
    history.push("/inventory/vendors/" + e);
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(filteredData[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  const handleActiveInactive = (row) => {
    console.log(row, "row");
    activeDeactiveVendor(row.recordId).then((res) => {
      if (res.errorCode == 0) {
        vendorAllRecords();
        toast(res.message, { autoClose: 2000 });
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };

  const columns = [
    {
      name: "NAME",
      selector: (row) => row.title,
    },

    {
      name: "EMAIL",
      selector: (row) => row.email,
    },
    {
      name: "WORK PHONE",
      selector: (row) => row.phone,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <EditIcon
            style={{ fontSize: "15px", cursor: "pointer" }}
            onClick={() => {
              history.push("/inventory/vendors/" + row.recordId + "/edit");
            }}
          />
          &nbsp;&nbsp;
          {row.active ? (
            <CheckCircleIcon
              style={{
                fontSize: "15px",
                cursor: "pointer",
                color: "greenyellow",
              }}
              onClick={() => handleActiveInactive(row)}
            />
          ) : (
            <CancelIcon
              style={{ fontSize: "15px", cursor: "pointer", color: "tomato" }}
              onClick={() => handleActiveInactive(row)}
            />
          )}
        </div>
      ),
    },
  ];

  //const baseUrl = `${BASE_URL_INVENTORY}` + `${DownloadItemExcel_URL}` + warehouseId;

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    const base = base64.split(",")[1];
    setFile1({
      fileBase64: base,
      fileName: file.name,
    });
  };

  const handleUpload = () => {
    uploadVendorExcel(file1).then((res) => {
      if (res.errorCode == 0) {
        vendorAllRecords();
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setError(res);
        setShow2(true);
      }
    });
    setTimeout(handleClose(), 6000);
  };

  const [userData, setUserData] = useState("");
  const [urlList, setUrlList] = useState("");
  const [userRights, setUserRights] = useState("");

  useEffect(() => {
    let loginData1 = new Cookies().get("loginData1");
    setUserData(new Cookies().get("loginData1"));
    let id = loginData1.recordId;

    urlMappingUUID1(id).then((res) => {
      if (res.errorCode == 0) {
        setUrlList(res.responsePacket.urlList);
        setUserRights(res.responsePacket);
      } else {
        toast("🙁" + res.message);
      }
    });
  }, []);

  const handleWarn = () => {
    alert("Access Denied!");
    return;
  };

  const handleWareChange = (e) => {
    setWare(e);
    getVendorNotInWarehouseId1(e.value).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;
        setItem2(d);
      }
    });
    
    getVendorByWare1(e.value).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;
        setItem1(d);
      }
    });

  };

  const handleItem1 = (row) => {
    console.log(row.selectedRows, "row.selectedRows");
    let abc = row.selectedRows.map((a) => {
      return {
        vendorId: a.vendorId,
        vendorTitle: a.vendorTitle,
        warehouseId: ware.value,
        warehouseTitle: ware.label,
      };
    });
    setItemSync1(abc);


  };

  const handleItem2 = (row) => {
    console.log(row.selectedRows, "row.selectedRows");
    let abc = row.selectedRows.map((a) => {
      return {
        vendorId: a.recordId,
        vendorTitle: a.title,
        warehouseId: ware.value,
        warehouseTitle: ware.label,
      };
    });
    setItemSync2(abc);
  };

  const existColumn = [
    {
      name: "Existing",
      selector: (row) => row.vendorTitle,
    },
  ];

  const notExistColumn = [
    {
      name: "not Existing",
      selector: (row) => row.title,
    },
  ];

  const handleAddSync = () => {

    addVendorByWarehouseId1(ware.value,itemSync2).then((res) => {
      if (res.errorCode == 0) {
        toast(res.message)
        handleWareChange(ware)
      }else{
        toast(res.message)
      }
    });
  };

  const handleRemoveSync = () => {

    removeVendorByWarehouseId1(ware.value,itemSync1).then((res) => {
      if (res.errorCode == 0) {
        toast(res.message)
        handleWareChange(ware)
      }else{
        toast(res.message)
      }
    });
  };

  return (
    <>
    {/* size="lg" */}
      <Modal show={show3} onHide={handleClose3} size="lg" >
        <div
          className="row"
          style={{ padding: "5px", justifyContent: "space-between" }}
        >
          <div className="col-4" style={{ zIndex: 6 }}>
            {" "}
            <Select
              options={wareOptions}
              onChange={(e) => handleWareChange(e)}
              value={ware}
              maxMenuHeight={220}
            />
          </div>
          <div>
            {/* <Tooltip title="ADD"> */}
            <ArrowBackIosIcon
              style={{
                color: "#00a2ca",
                fontSize: "30px",
                cursor: "pointer",
              }}
              onClick={handleAddSync}
            /> 
            {/* </Tooltip> */}
            {/* <Tooltip title="REMOVE"> */}
            <ArrowForwardIosIcon
                style={{
                  color: "#00a2ca",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
                onClick={handleRemoveSync}
              /> 
               {/* </Tooltip> */}
          </div>
          <div></div>
          {/* <div className="col-4 d-flex align-items-center">
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control "
                type="text"
                placeholder="Search item "
                onChange={(e) => handleSearch(e)}
              />
            </div> */}
        </div>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <DataTable
              columns={existColumn}
              data={item1}
              fixedHeader
              selectableRows
              onSelectedRowsChange={(rows) => {
                handleItem1(rows);
              }}
            />

            <DataTable
              columns={notExistColumn}
              data={item2}
              fixedHeader
              selectableRows
              onSelectedRowsChange={(rows) => {
                handleItem2(rows);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Body>
          <div> {error.message}</div>
          <br />
          {error.listOfErrors &&
            error.listOfErrors?.map((item) => {
              return <div>{item}</div>;
            })}
        </Modal.Body>
      </Modal>
      <div className="fill list-header d-print-none">
        <Modal show={show} onHide={handleClose} size="sm">
          <Modal.Body>
            <input
              className="ember-text-field ember-view form-control "
              id="originalFileName"
              type="file"
              inputProps={{
                accept: "image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt",
              }}
              required
              label="Document"
              name="originalFileName"
              onChange={(e) => handleFileRead(e)}
              size="small"
              variant="standard"
            />
          </Modal.Body>

          <Modal.Footer>
            {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
            (urlList &&
              urlList.some(
                (data) => data.url === "/vendor/downloadVendorExcel"
              )) ? (
              <a
                href={`${BASE_URL_INVENTORY}` + `${DOWNLOAD_VENDOR_URL}`}
                target="_blank"
                download
              >
                <Button variant="primary">
                  <DownloadIcon />
                  Download
                </Button>
              </a>
            ) : (
              <Button variant="secondary" onClick={handleWarn}>
                <DownloadIcon /> Download
              </Button>
            )}

            {/* <a
              href={`${BASE_URL_INVENTORY}` + `${DOWNLOAD_VENDOR_URL}`}
              target="_blank"
              download
            >
              <DownloadIcon /> Download
            </a> */}

            {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
            (urlList &&
              urlList.some(
                (data) => data.url === "/vendor/uploadPriceExcel"
              )) ? (
              <Button variant="primary" onClick={handleUpload}>
                <UploadIcon />
                Upload
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleWarn}>
                Upload
              </Button>
            )}
            {/* <Button variant="primary" onClick={handleUpload}>
              Upload
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
      <div style={{ height: 500, width: "100%" }}>
        <DataTable
          title="All Vendors"
          columns={columns}
          data={filteredData}
          theme="light"
          // defaultSortAsc={true}
          // defaultSortDesc={false}
          // defaultSortFieldId={1}
          pagination
          fixedHeader
          pointerOnHover={true}
          highlightOnHover={true}
          onRowClicked={(data) => {
            handleCustomSelect(data.recordId);
          }}
          subHeader
          subHeaderComponent={
            <>
              {" "}
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control col-2"
                type="text"
                placeholder={"Search here"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ margin: "10px" }}
              />
              <Button variant="primary" onClick={handleShow3}>
               <SyncIcon/> Sync
              </Button>
              <Button
                variant="primary"
                onClick={handleShow}
                className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
              >
                <ImportExportIcon /> Excel
              </Button>
              <Link to="/inventory/vendors/new">
                <Button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                >
                  <AddIcon />
                  New
                </Button>
              </Link>
            </>
          }
          subHeaderAlign="right"
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default VendorList;
