import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import AddIcon from "@mui/icons-material/Add";
import { useHistory, Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import ArticleIcon from "@mui/icons-material/Article";
import Cookies from "universal-cookie";

import {
  getVendorKeyValuePair,
  getTermsConditionKeyValue,
  getWarehouseKeyValueDetailsList,
  getInterStateTaxKeyValueList,
  getIntraStateTaxKeyValueList,
  ItemListByWarePrice1,
  getVendorDetails,
  getPurchaseDetails,
  updatePurchaseOrder,
  addPurchaseOrder,
  addDirectBillRecord1,
  addBillOrder,
  getOrgById,
  getVendorByWare,
} from "../../services/index";

const PurchaseNew = (props) => {
  console.log(props, "props");
  let directBill = props?.location?.state?.directBill;
  let purchaseId = props?.match?.params?.id;
  let billId;
  if (props.location.state == "bill") {
    billId = props?.match?.params?.id;
  }

  console.log(directBill, "directBill");
  console.log(purchaseId, "purchaseId");
  console.log(billId, "billId");

  let history = useHistory();
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date1 = curr.toISOString().substring(0, 10);

  const [orgDetail, setOrgDetail] = useState("");
  const [purchaseInfo, setPurchaseInfo] = useState({
    vendor: "",
    warehouse: "",
    taxType: "",
    reference: "",
    orderDate: date1,
    shipDate: date1,
    notes: "",
    terms: "",
    subTotal: 0.0,
    total: 0.0,
    shipCharge: "",
    netShip: 0.0,
    netTax: 0,
    shipTax: "",
  });

  const tempRows = {
    item: {
      value: "",
      label: "",
    },
    quantity: 1,
    taxes: "",
    rate: 0,
    amount: 0,
    isFloating: false,
    itemTitle: "",
    itemUuid: "",
    deleted: false,
  };

  const [rows, setRows] = useState([tempRows]);

  const [wareOptions, setWareOptions] = useState("");
  const [vendorOptions, setVendorOptions] = useState("");
  const [termsOptions, setTermsOptions] = useState("");
  const [itemOptions, setItemOptions] = useState("");
  const [itemOptions2, setItemOptions2] = useState("");
  const [taxOptions, setTaxOptions] = useState("");

  useEffect(() => {
    callBasicAPI();
    if (purchaseId) {
      getPurchaseDetails1(purchaseId);
    }
    if (billId) {
      getPurchaseDetails1(billId);
    }

    let loginData1 = new Cookies().get("loginData1");
    let orgId = loginData1.organisationId;
    getOrgById(orgId).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;
        console.log(d, "d");
        setOrgDetail(d);
        if (d.beanAddress == undefined) {
          alert("logged In user's organization address not found ");
        }
      } else {
        toast(res.message);
      }
    });
  }, []);

  const getPurchaseDetails1 = (Id) => {
    getPurchaseDetails(Id).then((res) => {
      if (res.errorCode === 0) {
        console.log(res, "res");
        let d = res.responsePacket;
        let itemList = res.responsePacket.items;
        let a = res.responsePacket.address;
        let wId = d.warehouseId;
        let pId = d.priceListId;
        handleGetItems(wId, pId);

        let vendor1 = {
          recordId: d.vendorId,
          title: d.vendorTitle,
          value: d.vendorId,
          label: d.vendorTitle,
          addressList: a,
          phone: d.vendorMobileNumber,
          itemPriceListTitle: d.priceListTitle,
          itemPriceListRecordId: d.priceListId,

          paymentTermRecordId: d.paymentTermsId,
          paymentTermTitle: d.paymentTermsTitle,
        };
        console.log(vendor1, "vendor");
        let warehouse = { value: d.warehouseId, label: d.warehouseTitle };
        console.log(warehouse, "w");
        let terms = { value: d.termsConditionId, label: d.termsCondition };
        let shipTax = {
          value: d.shippingChargeTaxId,
          label: d.shippingChargeTaxTitle,
          rate: d.shippingChargeTaxRate,
        };
        let netTax = 0;
        itemList.map((c) => {
          netTax += c.taxes.amount;
        });

        console.log(netTax, "netTax");
        // note:  d.shippingCharges=netShip
        // so we need to calculate shipCharge i.e without tax

        let shipCharge =
          d.shippingCharges / (1 + d.shippingChargeTaxRate * 0.01);

        setPurchaseInfo({
          ...purchaseInfo,
          vendor: vendor1,
          warehouse: warehouse,
          terms: terms,
          shipTax: shipTax,
          shipCharge: Number(shipCharge.toFixed(2)),
          netTax: netTax,

          reference: d.referenceNo,
          orderDate: d.purchaseOrderDate,
          shipDate: d.expectedShipmentDate,
          notes: d.vendorNote,
          subTotal: d.subTotalAmount,
          total: d.totalAmount,
          netShip: d.shippingCharges,

          purchaseOrderNo: d?.purchaseOrderNo,
          purchaseOrderId: d?.recordId,
          taxType: "",
        });
        let tempRows = [];

        itemList.map((c) => {
          let obj = {
            step: c.stepOrder,
            item: {
              label: c.itemTitle,
              value: c.itemUuid,
            },
            itemUuid: c.itemUuid,
            itemTitle: c.itemTitle,
            isFloating: c?.isFloating,
            amount: c.amount,
            quantity: c.quantity,
            taxes: c.taxes,
            rate: c.rate,
            hsnSac: c.hsnSac,
            hsnUuid: c.hsnUuid,
          };
          tempRows.push(obj);
        });
        setRows(tempRows);
      } else {
        handleToast(res);
      }
    });
  };

  const handleAddNewRow = () => {
    setRows([...rows, tempRows]);
    handleFilterItems();
  };

  const handleClear = (id, i) => {
    const obj = rows.slice();
    obj.splice(i, 1);
    setRows(obj);

    let sum2 = 0;
    let netTax = 0;
    let netShip = purchaseInfo.netShip;
    obj &&
      obj.map((data) => {
        netTax += data.taxes.amount;
        sum2 += data.amount;
        setPurchaseInfo({
          ...purchaseInfo,
          netTax: netTax,
          subTotal: sum2.toFixed(2),
          total: (sum2 + netShip + netTax).toFixed(2),
        });
      });

    let filterOptions = [...itemOptions];
    itemOptions2 &&
      itemOptions2.map((a) => {
        if (a.value === id) {
          filterOptions.push(a);
        }
      });
    setItemOptions(filterOptions);
  };

  const handleToast = (res) => {
    toast(res.message);
  };

  const callBasicAPI = () => {
    getWarehouseKeyValueDetailsList().then((res) => {
      if (res.errorCode === 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setWareOptions(options);
      } else {
        handleToast(res);
      }
    });

    getTermsConditionKeyValue().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        options.map((a) => {
          setPurchaseInfo({
            ...purchaseInfo,
            terms: a,
          });
        });
        setTermsOptions(options);
      } else {
        handleToast(res);
      }
    });
  };

  const freshVendor = (aa, d1, taxType) => {
    // setItemOptions("");
    // setItemOptions2("");
    // setRows([tempRows]);
    setPurchaseInfo({
      ...purchaseInfo,
      taxType: taxType,
      vendor: d1,
      // warehouse: "",
      shipTax: aa[0],
      netTax: 0,
      subTotal: 0,
      netShip: 0,
      shipCharge: 0,
      total: 0,
    });
  };

  const freshWare = (e) => {
    setItemOptions("");
    setItemOptions2("");
    setRows([tempRows]);
    setPurchaseInfo({
      ...purchaseInfo,
      // taxType: taxType,
      vendor: "",
      warehouse: e,
      netTax: 0,
      subTotal: 0,
      netShip: 0,
      shipCharge: 0,
      total: 0,
    });
  };

  const handleVendor = (e) => {
    getVendorDetails(e.value).then((res) => {
      if (res.errorCode == 0) {
        let d1 = res.responsePacket;
        setPurchaseInfo({ ...purchaseInfo, vendor: d1 });

        d1.value = d1.recordId;
        d1.label = d1.title;
        let orgShipAdd = orgDetail?.beanAddress?.state;
        let shipAdd = d1.addressList.filter(
          (a) => a.addressTypeEnum == "Shipping"
        );
        let wId = purchaseInfo.warehouse.value;
        let pId = d1.itemPriceListRecordId;
        handleGetItems(wId, pId);
        console.log(shipAdd[0].state, orgShipAdd, "shipAdd  orgShipAdd");

        if (shipAdd[0].state == orgShipAdd) {
          getIntraStateTaxKeyValueList().then((res) => {
            let d = res.responsePacket;
            const options = d.map((a) => ({
              label: a.title,
              value: a.recordId,
              rate: a.rate,
              taxType: a.taxType,
            }));
            const aa = options.filter(
              (c) => c.taxType == "GST" && c.rate == 18.0
            );
            console.log(aa, "aa1");
            setTaxOptions(options);
            freshVendor(aa, d1, "intra");
          });
        } else {
          getInterStateTaxKeyValueList().then((res) => {
            let d = res.responsePacket;
            const options = d.map((a) => ({
              label: a.title,
              value: a.recordId,
              rate: a.rate,
              taxType: a.taxType,
            }));
            const aa = options.filter(
              (c) => c.taxType == "IGST" && c.rate == 18.0
            );
            console.log(aa, "aa2");
            setTaxOptions(options);
            freshVendor(aa, d1, "inter");
          });
        }
      } else {
        handleToast(res);
      }
    });

    // handleGetItems(wId, pId);
  };

  const handleWarehouse = (e) => {
    setPurchaseInfo({ ...purchaseInfo, warehouse: e });
    let pId = purchaseInfo.vendor.itemPriceListRecordId;
    let wId = e.value;
    handleGetItems(wId, pId);
  };

  const handleWarehouse2 = (e) => {
    // setPurchaseInfo({ ...purchaseInfo, warehouse: e });
    let wId = e.value;
    handleGetVendor(wId);
    freshWare(e);
  };

  const handleGetVendor = (wId) => {
    getVendorByWare(wId).then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.recordId,
          label: d.title,
        }));
        setVendorOptions(options);
      } else {
        toast(res.message);
      }
    });
  };

  const handleGetItems = (wId, pId) => {
    ItemListByWarePrice1(wId, pId).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;
        const options = d.map((a) => ({
          value: a.recordId,
          label:a.title,
          // label: (
          //   <>
          //     <div>{a.title}</div>
          //     <div>
          //       stock: {a.currentStockQuantity} rate: {a.purchasePriceValue}
          //     </div>
          //   </>
          // ),
          itemId: a.recordId,
          title: a.title,
          taxes:
            purchaseInfo.taxType == "inter" ? a.interstateTax : a.intrastateTax,
          isFloating: a.isFloating,
          rate: a.purchasePriceValue,
        }));

        setItemOptions(options);
        setItemOptions2(options);
      } else {
        handleToast(res);
      }
    });
  };

  console.log(itemOptions, "setItemOptions");
  const handleData = (e, key) => {
    setPurchaseInfo({
      ...purchaseInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleTerms = (e) => {
    setPurchaseInfo({
      ...purchaseInfo,
      terms: e,
    });
  };

  useEffect(() => {
    console.log(purchaseInfo, "purchaseInfo");
    console.log(rows, "rows");
  }, [purchaseInfo, rows]);

  const handleItem = (e, i) => {
    console.log(e, "e");
    const obj = [...rows];
    obj[i].item.value = e.value;
    obj[i].item.label = e.title;
    obj[i].rate = e.rate;
    obj[i].isFloating = e.isFloating;
    obj[i].itemUuid = e.value;
    obj[i].itemTitle = e.title;
    obj[i].taxes = e.taxes;

    let netRate = e.rate + e.rate * e.taxes.rate * 0.01;

    obj[i].amount = e.rate * obj[i].quantity;

    obj[i].taxes.amount = e.rate * e.taxes.rate * 0.01;

    setRows(obj);
    handleSubTotal();
    handleFilterItems();
  };

  const handleFilterItems = () => {
    var send = false;
    var filterOptions = [];
    itemOptions2 &&
      itemOptions2.map((a) => {
        rows &&
          rows.map((b) => {
            if (a.value === b?.item?.value) {
              send = true;
            }
          });
        if (!send) {
          filterOptions.push(a);
        }
        send = false;
      });
    setItemOptions(filterOptions);
  };

  const handlequantity = (e, i) => {
    console.log(e, "e");
    const obj = [...rows];
    let netRate = obj[i].rate + obj[i].rate * obj[i].taxes.rate * 0.01;

    obj[i].quantity = e.target.value;
    obj[i].amount = obj[i].rate * e.target.value;
    obj[i].taxes.amount = obj[i].amount * obj[i].taxes.rate * 0.01;

    setRows(obj);
    handleSubTotal();
  };

  const handleSubTotal = () => {
    let sum2 = 0;
    let netTax = 0;
    let netShip = purchaseInfo.netShip;
    rows &&
      rows.map((data) => {
        netTax += data.taxes.amount;
        sum2 += data.amount;
        setPurchaseInfo({
          ...purchaseInfo,
          netTax: netTax,
          subTotal: sum2.toFixed(2),
          total: (sum2 + netShip + netTax).toFixed(2),
        });
      });
  };

  const handleRate = (e, i) => {
    console.log(e, "e");

    const obj = [...rows];
    let netRate = obj[i].rate + obj[i].rate * obj[i].taxes.rate * 0.01;

    obj[i].rate = e.target.value;
    obj[i].amount = obj[i].quantity * e.target.value;
    obj[i].taxes.amount = obj[i].amount * obj[i].taxes.rate * 0.01;

    setRows(obj);
    handleSubTotal();
  };

  const handleShipCharge = (e) => {
    let ShipCharge = Number(e.target.value);
    let netShip = ShipCharge + ShipCharge * purchaseInfo?.shipTax?.rate * 0.01;
    let netTotal =
      Number(purchaseInfo.subTotal) + netShip + purchaseInfo.netTax;

    setPurchaseInfo({
      ...purchaseInfo,
      netShip: netShip,
      shipCharge: ShipCharge,
      total: netTotal,
    });
  };
  const handleShipTax = (e) => {
    let netShip =
      purchaseInfo.shipCharge + purchaseInfo.shipCharge * e.rate * 0.01;
    let netTotal =
      Number(purchaseInfo.subTotal) + netShip + purchaseInfo.netTax;

    setPurchaseInfo({
      ...purchaseInfo,
      shipTax: e,
      netShip: netShip,
      total: netTotal,
    });
  };

  const handleSave = (e) => {
    if (purchaseInfo.vendor == "") {
      toast("Select vendor");
      return;
    }

    if (purchaseInfo.warehouse == "") {
      toast("Select warehouse");
      return;
    }
    if (purchaseInfo.terms == "") {
      toast("Select terms");
      return;
    }
    rows.map((a) => {
      if (purchaseId) {
        a.deleted = false;
      }
    });

    let rows2 = rows.filter((a) => a.item.value != "");

    if (rows2.length == 0) {
      toast("Select item");
      return;
    }

    let purchaseRequest = {
      items: rows2,
      address: purchaseInfo.vendor.addressList,
      vendorId: purchaseInfo.vendor.recordId,
      vendorTitle: purchaseInfo.vendor.title,
      vendorMobileNumber: purchaseInfo.vendor.phone,
      vendorNote: purchaseInfo.notes,
      purchaseOrderStatusEnum: "Confirmed",

      isDiscountAtTransaction: true,
      isPaid: true,
      isReceived: true,
      isTcs: true,
      expectedShipmentDate: purchaseInfo.shipDate,
      purchaseOrderDate: purchaseInfo.orderDate,
      paymentTermsId: purchaseInfo.vendor.paymentTermRecordId,
      paymentTermsTitle: purchaseInfo.vendor.paymentTermTitle,

      priceListId: purchaseInfo.vendor.itemPriceListRecordId,
      priceListTitle: purchaseInfo.vendor.itemPriceListTitle,

      subTotalAmount: Number(purchaseInfo?.subTotal).toFixed(2),
      termsCondition: purchaseInfo.terms.label,
      termsConditionId: purchaseInfo.terms.value,

      totalAmount: Number(purchaseInfo?.total).toFixed(2),
      warehouseId: purchaseInfo.warehouse.value,
      warehouseTitle: purchaseInfo.warehouse.label,
      referenceNo: purchaseInfo.reference,

      shippingCharges: purchaseInfo?.netShip,
      shippingChargeTaxRate: purchaseInfo?.shipTax?.rate,
      shippingChargeTaxId: purchaseInfo?.shipTax?.value,
      shippingChargeTaxTitle: purchaseInfo?.shipTax?.label,

      hsnId: null,
      purchaseOrderNo: "SO",
      purchaseOrderStatusEnum: "Confirmed",
      isInvoiced: false,
      isDelivered: false,
      isPacked: false,
      isPaid: false,
      isShipped: false,
    };
    if (billId) {
      purchaseRequest.motorVehicleNo = purchaseInfo.motorVehicleNo;
      purchaseRequest.billNo = "BO";
      purchaseRequest.orderNo = "SO";
      purchaseRequest.purchaseOrderNo = purchaseInfo?.purchaseOrderNo;
      purchaseRequest.purchaseOrderId = purchaseInfo?.purchaseOrderId;
      purchaseRequest.billDate = purchaseInfo?.orderDate;
      purchaseRequest.itemsList = rows2;
    }

    console.log(purchaseRequest, "purchaseRequest 123");

    if (directBill) {
      addDirectBillRecord1(purchaseRequest).then((res) => {
        if (res.errorCode == 0) {
          history.push("/inventory/bills");
          handleToast(res);
        } else {
          handleToast(res);
        }
      });
    } else {
      if (billId) {
        addBillOrder(purchaseRequest).then((res) => {
          if (res.errorCode == 0) {
            history.push("/inventory/bills");
            handleToast(res);
          } else {
            handleToast(res);
          }
        });
      } else {
        if (purchaseId) {
          updatePurchaseOrder(purchaseId, purchaseRequest).then((res) => {
            if (res.errorCode == 0) {
              history.push("/inventory/purchase");
              handleToast(res);
            } else {
              handleToast(res);
            }
          });
        } else {
          addPurchaseOrder(purchaseRequest).then((res) => {
            if (res.errorCode == 0) {
              history.push("/inventory/purchase");
              handleToast(res);
            } else {
              handleToast(res);
            }
          });
        }
      }
    }
  };

  return (
    <>
      <div className="fill list-header d-flex justify-content-between align-items-center bg-white p-3 border-bottom">
        <h3 className="float-left">
          <ArticleIcon />
          &nbsp;&nbsp;
          {directBill
            ? "DIRECT BILL"
            : billId
            ? "NEW BILL"
            : purchaseId
            ? "EDIT PURCHASE ORDER"
            : "NEW PURCHASE ORDER"}
             &nbsp;&nbsp;{purchaseInfo?.purchaseOrderNo}
        </h3>
      
      </div>

      <div
        className="scroll-y noscroll-x fill body scrollbox"
        style={{ marginLeft: "15px" }}
      >
        <div className="item-creation" autoComplete="off">
          <div className="primary-info white-bg">
            <div className="item-new">
              <div className="row">
                <div className="col-lg-8">
                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Warehouse
                    </label>
                    <div className="col-lg-5">
                      <Select
                      isDisabled={billId?true:false}
                        onChange={(e) => handleWarehouse2(e)}
                        options={wareOptions}
                        value={purchaseInfo.warehouse}
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Vendor
                    </label>

                    <div className="col-lg-5">
                      <Select
                       isDisabled={billId?true:false}
                        onChange={(e) => handleVendor(e)}
                        options={vendorOptions}
                        value={purchaseInfo.vendor}
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Order Date
                    </label>
                    <div className="col-lg-5">
                      <input
                       disabled={billId?true:false}
                        className="form-control"
                        type="date"
                        name="orderDate"
                        onChange={(e) => handleData(e)}
                        value={purchaseInfo.orderDate}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Shipment Date
                    </label>
                    <div className="col-lg-5">
                      <input
                       disabled={billId?true:false}
                        className="form-control"
                        type="date"
                        name="shipDate"
                        onChange={(e) => handleData(e)}
                        value={purchaseInfo.shipDate}
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Terms
                    </label>

                    <div className="col-lg-5">
                      <Select
                       isDisabled={billId?true:false}
                        onChange={(e) => handleTerms(e)}
                        options={termsOptions}
                        value={purchaseInfo.terms}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {purchaseInfo?.vendor?.addressList ? (
                    <table>
                      <tr>
                        <td style={{ minWidth: "100px" }}>Billing:</td>
                        <td>{purchaseInfo?.vendor?.addressList[0]?.state}</td>
                      </tr>
                      <tr>
                        <td>Shipping:</td>
                        <td>{purchaseInfo?.vendor?.addressList[1]?.state}</td>
                      </tr>
                      <tr>
                        <td>PriceList:</td>
                        {purchaseInfo?.vendor?.itemPriceListTitle}
                      </tr>
                      <tr>
                        <td>Pay Terms:</td>
                        {purchaseInfo?.vendor?.paymentTermTitle}
                      </tr>
                      {/* <tr>
                        <td>Org State:</td>
                        {orgDetail?.beanAddress?.state}
                      </tr> */}
                      <tr>
                        <td>TaxType:</td>
                        {purchaseInfo?.taxType}
                      </tr>
                    </table>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <br />
            <hr />

            <div className="customTable">
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ minWidth: "100px" }}>Item</td>
                  <td>quantity</td>
                  <td>Tax</td>
                  <td>Rate</td>
                  <td>Amount</td>
                  <td></td>
                </tr>
                {rows &&
                  rows.map((data, i) => {
                    return (
                      <tr>
                        <td style={{ minWidth: "250px" }}>
                          <Select
                            isDisabled={billId ? true : false}
                            style={{ zIndex: "20" }}
                            placeholder="Item"
                            options={itemOptions}
                            value={data.item}
                            onChange={(e) => handleItem(e, i)}
                          />
                        </td>
                        <td>
                          <input
                            disabled={billId ? true : false}
                            type="number"
                            min={1}
                            value={data.quantity}
                            onChange={(e) => handlequantity(e, i)}
                          />
                        </td>
                        <td>{data.taxes?.title}</td>

                        <td>
                          <input
                            type="number"
                            disabled={data.isFloating && !billId ? false : true}
                            value={data.rate}
                            onChange={(e) => handleRate(e, i)}
                          />
                        </td>
                        <td>{data.amount?.toFixed(2)}</td>
                        {billId ? (
                          <></>
                        ) : (
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear(data.item.value, i)}
                          >
                            {rows.length > 1 && <i className="fa fa-times"></i>}
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </table>
            </div>

            {billId ? (
              <></>
            ) : (
              <button
                id="ember1426"
                className="btn btn-default addbtn mt-3"
                onClick={handleAddNewRow}
              >
                <AddIcon style={{ color: "blue" }} /> New Row
              </button>
            )}

            <br />
            <br />
            <div className="row" style={{ justifyContent: "space-between" }}>
              <table className="customTable">
                <tr>
                  <td>Notes</td>
                  <td>
                    {" "}
                    <textarea
                      className="form-control"
                      name="notes"
                      onChange={(e) => handleData(e)}
                      value={purchaseInfo.notes}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Reference</td>
                  <td>
                    {" "}
                    <input
                      className="form-control"
                      name="reference"
                      onChange={(e) => handleData(e)}
                      value={purchaseInfo.reference}
                    />
                  </td>
                </tr>
              </table>

              <table className="customTable" style={{ paddingRight: "20px" }}>
                <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td></td>
                  <td>{Number(purchaseInfo?.subTotal).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Shipping</td>
                  <td>
                    <input
                      disabled={billId?true:false}
                      type="number"
                      value={purchaseInfo.shipCharge}
                      onChange={(e) => handleShipCharge(e)}
                    />
                  </td>
                  <td>
                    <Select
                      isDisabled={true}
                      onChange={(e) => handleShipTax(e)}
                      options={taxOptions}
                      value={purchaseInfo.shipTax}
                    />
                  </td>
                  <td>{Number(purchaseInfo?.netShip).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Total Tax</td>
                  <td></td>
                  <td></td>
                  <td>{Number(purchaseInfo?.netTax).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>{Number(purchaseInfo?.total).toFixed(2)}</td>
                </tr>
              </table>
            </div>

            <div className="text-left mt-3 mb-4">
              <button
                className="btn bg-primary text-white mr-2"
                onClick={(e) => handleSave(e)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default PurchaseNew;
