import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Header } from "antd/lib/layout/layout";
import PreferencesNav from "../Preferences/PreferencesNav";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AddIcon from "@mui/icons-material/Add";

import {
  getTaxExemptionKeyValuePair,
  addTaxExemption,
} from "../../../services/index";

const TaxExempt = () => {
  let history = useHistory();
  const [taxInfo, setTaxInfo] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setTaxInfo("");
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const columns = [
    {
      name: "Reasons",
      cell: (row) => row.value,
      sortable: true,
    },
  ];

  const handleSave = () => {
    if (taxInfo == "") {
      toast("Please fill Reason");
      return;
    }

    const data = {
      description: taxInfo,
      reason: taxInfo,
      taxExemptForEnum: "Customer",
    };
    addTaxExemption(data).then((res) => {
      if (res.errorCode == 0) {
        toast(res.message);
        handleClose();
        getTaxExRecords();
      } else {
        toast(res.message);
        return;
      }
    });
  };

  useEffect(() => {
    getTaxExRecords();
  }, []);

  const getTaxExRecords = () => {
    getTaxExemptionKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket;
        setFilteredData(temp);
      } else {
        toast(res.message, {
          autoClose: 2000,
        });
        return;
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Header>TaxExempt Info</Header>
          <hr />

          <table>
            <tr>
              <td>Reason:</td>
              <td>
                {" "}
                <input
                  className="ember-text-field ember-view form-control"
                  type="text"
                  onChange={(e) => {
                    setTaxInfo(e.target.value);
                  }}
                  value={taxInfo}
                />
              </td>
            </tr>
          </table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="column list-column d-print-none column-small">
        <PreferencesNav />
      </div>
      <div className="column content-column">
        <div className="header clearfix" style={{ borderBottom: "none" }}>
          <span className="float-left" style={{ fontSize: "35px" }}>
            Tax Exempt List
          </span>
        </div>

        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            columns={columns}
            data={filteredData}
            fixedHeader
            subHeader
            subHeaderComponent={
              <>
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                  onClick={handleShow}
                >
                  <AddIcon />
                  New
                </button>
              </>
            }
            subHeaderAlign="right"
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default TaxExempt;
