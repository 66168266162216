import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  getItemList,
  getItemDetail,
  getTransactionStatusKeyValueListByType,
  logItemHistory,
} from "../../services/index";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";

const ViewItem = (props) => {
  const [logData, setLogData] = useState({});

  const [itemDetails1, setItemDetails1] = useState("");
  const [valueLoading, setValueLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState([]);

  const [itemDetails, setItemDetails] = useState([]);
  const [value, setValue] = useState("1");
  const [redirState, setRedirState] = useState(false);
  const [dataId, setDataId] = useState();
  const [itemHistoryRowHtml, setItemHistoryRowHtml] = useState([]);
  const [logItemTransactionHtml, setLogItemTransactionHtml] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("SalesOrder");
  const [filterByMenuHtml, setFilterByMenuHtml] = useState([]);
  const [statusBy, setStatusBy] = useState("All");
  const [statusByMenuHtml, setStatusByMenuHtml] = useState([]);
  const [warehouseId, setWarehouseId] = useState(null);

  const [transactiondata, setTransactiondata] = useState([]);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const columns = [
    {
      name: "DATE",
      selector: (row) => row.transactionDate.replace(/T/, "-"),
    },
    {
      name: "DETAILS",
      cell: (row) => (
        <>
          <div className="d-flex align-items-center">
            <p>{row?.message.split("\n")[0]}</p>
          </div>
          &nbsp;
          <div className="d-flex align-items-center">
            <BootstrapTooltip
              title={row?.message.split("\n").map((line, i) => (
                <p key={i}>{line}</p>
              ))}
            >
              <i
                className="fa fa-question-circle tooltip-container ember-view"
                aria-hidden="true"
              ></i>
            </BootstrapTooltip>
          </div>
        </>
      ),
    },
  ];

  const handleFilterByClick = (filter) => {
    setFilterBy(filter);
    getTransactionStatusKeyValueListByTypeCall(filter);
  };
  const handleStatusByClick = (status) => {
    setStatusBy(status);
  };

  const getTransactionStatusKeyValueListByTypeCall = (type) => {
    getTransactionStatusKeyValueListByType(type)
      .then((res) => {
        if (res.errorCode == 0) {
          var temp = [];
          res.responsePacket.map((item) => {
            temp.push(
              <Dropdown.Item onClick={() => handleStatusByClick(item.value)}>
                {item.value}
              </Dropdown.Item>
            );
          });
          setStatusByMenuHtml(temp);
        } else {
        }
      })
      .catch((err) => {});
  };

  const getRecordsID = (id) => {
    getItemDetail(id).then((res) => {
      if (res.errorCode == 0) {
        setItemDetails(res.responsePacket);
        setItemDetails1(res.responsePacket.warehouseItemsBeanList);
        let warehouseId = res.responsePacket.warehouseId;

        setValueLoading(true);
      }
    });
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getRecordsID(props.match.params.id);
  }, [props.match.params.id]);

  useEffect(() => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };

    getItemList(list_data).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket.data;
        setData(d);
        setRows(d);
      }
    });
  }, []);

  const editItems = () => {
    setRedirState(true);
    setDataId(itemDetails.recordId);
  };
  console.log(props);
  useEffect(() => {
    logItemHistory(props.match.params.id).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        console.log(d, "d");
        setLogData(d);
      } else {
        toast(res.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  }, [props.match.params.id]);

  const handleSearch = (e) => {
    const result = rows.filter((a) => {
      return (
        a.title.toLowerCase().match(e.target.value.toLowerCase()) ||
        a.brandTitle.toLowerCase().match(e.target.value.toLowerCase())
      );
    });
    setData(result);
  };

  const searchOptions = [
    { value: "isIngredient", label: "Ingredient" },
    { value: "isPurchase", label: "Purchase" },
    { value: "isSales", label: "Sales" },
    { value: "isTaxable", label: "Taxable" },
    { value: "nonTaxable", label: "NonTaxable" },
  ];

  const handleSearchOption = (e) => {
    setSearch(e);

    var arr1 = rows.filter((data) => data.isIngredient);
    var arr2 = rows.filter((data) => data.isPurchase);
    var arr3 = rows.filter((data) => data.isSales);
    var arr4 = rows.filter((data) => data.isTaxable);
    var arr5 = rows.filter((data) => !data.isTaxable);

    if (e.value == "isIngredient") {
      setData(arr1);
    }
    if (e.value == "isPurchase") {
      setData(arr2);
    }
    if (e.value == "isSales") {
      setData(arr3);
    }
    if (e.value == "isTaxable") {
      setData(arr4);
    }
    if (e.value == "nonTaxable") {
      setData(arr5);
    }
  };

  console.log(itemDetails?.isFloating);
  return (
    <>
      <div
        className="list-column column d-print-none "
        style={{ width: "300px" }}
      >
        <div className="fill list-header topgrad d-print-none">
          <div className="list-filter">
            <div className="btn-toolbar float-right">
              <button
                className="btn btn-primary"
                data-ember-action=""
                data-ember-action-651="651"
              >
                <Link
                  to={"/inventory/items/new"}
                  className="align-items-center d-flex"
                  style={{ color: "#fff" }}
                >
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    className="icon icon-xsmall align-text-bottom"
                  >
                    <path d="M484 227H284V28c0-15.5-12.5-28-28-28s-28 12.5-28 28v199H28c-15.5 0-28 12.5-28 28v1c0 15.5 12.5 28 28 28h200v200c0 15.5 12.5 28 28 28s28-12.5 28-28V284h200c15.5 0 28-12.5 28-28v-1c0-15.5-12.5-28-28-28z"></path>
                  </svg>
                  &nbsp; New
                </Link>
              </button>
            </div>
            <div
              id="ember681"
              className="dropdown ember-view clearfix list-title"
            >
              <a
                id="ember682"
                className="dropdown-toggle no-caret ember-view  "
              >
                <span className="filter-title over-flow ">All Items</span>&nbsp;
              </a>
            </div>
          </div>
        </div>
        <div id="scrolled_div" className="scroll-y fill body scrollbox">
          <div className="table-responsive overflow-initial  ">
            <table
              id="ember721"
              className="table zi-table table-hover ember-view header-fixed"
            >
              <tbody>
                {data &&
                  data.map((d) => (
                    <>
                      <tr
                        key={d.recordId}
                        tabIndex="-1"
                        id="ember737"
                        className="ember-view table-active"
                      >
                        <td
                          data-ember-action=""
                          data-ember-action-7650="7650"
                          className="p-0"
                        >
                          <Link
                            to={`/inventory/items/${d.recordId}`}
                            id="ember7651"
                            className="active ember-view text-break d-block px-3 py-2 text-black"
                          >
                            <div className="text-uppercase text-open">
                              {d.title}
                            </div>
                          </Link>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="column content-column" style={{ left: "301px" }}>
        <div className="fill1 header d-flex align-items-center p-4 justify-content-between">
          <a
            className="over-flow header-title text-uppercase"
            style={{ fontSize: "x-large" }}
          >
            {/* {itemDetails.title} */}
            &nbsp;
          </a>
          <div className="btn-toolbar d-flex justify-content-end align-items-center">
            <Select
              placeholder="Filter Items"
              value={search}
              options={searchOptions}
              onChange={handleSearchOption}
              style={{ minWidth: "150px" }}
            />
            <SearchRoundedIcon />
            <input
              className="ember-text-field ember-view form-control col-2"
              type="text"
              placeholder="Search item"
              onChange={handleSearch}
              style={{ minWidth: "150px" }}
            />
            <div className="btn-group">
              <button
                id="ember7700"
                className="tooltip-container ember-view btn btn-primary mr-0"
                type="button"
                onClick={editItems}
              >
                {redirState && (
                  <Redirect
                    push
                    to={{
                      pathname: `/inventory/items/${dataId}/edit`,
                    }}
                  />
                )}
                <EditIcon />
              </button>
            </div>
          </div>
        </div>
        <br />
        <Tabs>
          <TabList>
            <Tab>Primary Details</Tab>
            <Tab>Log History</Tab>
          </TabList>
          {itemDetails && itemDetails ? (
            <TabPanel>
              <div className="ember-view scroll-y noscroll-x fill body scrollbox">
                <br />
                <div className="group">
                  <div className="row">
                    <label className="col-lg-3 items-label text-muted">
                      Item:
                    </label>{" "}
                    <label className="col-lg-7">{itemDetails?.title}</label>
                  </div>
                  <div className="row">
                    <label className="col-lg-3 items-label text-muted">
                      Ingredient:
                    </label>{" "}
                    <label className="col-lg-7">
                      {itemDetails?.isIngredient ? "True" : "False"}
                    </label>
                  </div>

                  <div className="row">
                    <label className="col-lg-3 items-label text-muted">
                      Floating:
                    </label>{" "}
                    <label className="col-lg-7">
                      {itemDetails?.isFloating ? "True" : "False"}
                    </label>
                  </div>

                  <div className="row">
                    <label className="col-lg-3 items-label text-muted">
                      Pack Unit
                    </label>{" "}
                    <label className="col-lg-7">
                      {itemDetails?.packUnitTitle}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-lg-3 items-label text-muted">
                      Box Weight
                    </label>{" "}
                    <label className="col-lg-7">
                      {itemDetails?.weightValue} {itemDetails?.weightUnitTitle}
                    </label>
                  </div>

                  <div className="row">
                    <label className="col-lg-3 items-label text-muted">
                      SKU
                    </label>{" "}
                    <label className="col-lg-6">{itemDetails?.sku}</label>
                  </div>
                  <div className="row">
                    <label className="col-lg-3 items-label text-muted">
                      HSN Code
                    </label>{" "}
                    <label className="col-lg-7">{itemDetails?.hsnSac}</label>
                  </div>
                  <div className="row">
                    {" "}
                    <label className="col-lg-3 items-label text-muted">
                      Tax Preference
                    </label>{" "}
                    <label className="col-lg-6 col-md-6 col-sm-6 col-6">
                      {itemDetails?.isTaxable ? "Taxable" : "Non-Taxable"}
                    </label>{" "}
                  </div>

                  {itemDetails.openingStock ? (
                    <div className="row">
                      {" "}
                      <label className="col-lg-3 items-label text-muted">
                        {" "}
                        Opening Stock
                      </label>{" "}
                      <label className="col-lg-6 col-md-6 col-sm-6 col-6">
                        {itemDetails.openingStock}
                      </label>{" "}
                    </div>
                  ) : (
                    <></>
                  )}

                  {itemDetails.openingStockValue ? (
                    <div className="row">
                      {" "}
                      <label className="col-lg-3 items-label text-muted">
                        {" "}
                        Opening Stock Value
                      </label>{" "}
                      <label className="col-lg-6 col-md-6 col-sm-6 col-6">
                        {itemDetails.openingStockValue}
                      </label>{" "}
                    </div>
                  ) : (
                    <></>
                  )}

                  {itemDetails.brandTitle ? (
                    <div className="row">
                      {" "}
                      <label className="col-lg-3 items-label text-muted">
                        {" "}
                        Category
                      </label>
                      <label className="col-lg-6 col-md-6 col-sm-6 col-6">
                        {itemDetails.brandTitle}
                      </label>{" "}
                    </div>
                  ) : (
                    <></>
                  )}

                  {itemDetails.isTaxable ? (
                    <>
                      <div className="row">
                        <label className="col-lg-3 items-label text-muted">
                          {" "}
                          Intra State Tax
                        </label>
                        <label className="col-lg-6">
                          {itemDetails != null
                            ? itemDetails.taxTitleIntrastate
                            : "-"}
                        </label>
                      </div>
                      <div className="row">
                        <label className="col-lg-3 items-label text-muted">
                          {" "}
                          Inter State Tax
                        </label>{" "}
                        <label className="col-lg-6">
                          {itemDetails != null
                            ? itemDetails.taxTitleInterstate
                            : "-"}
                        </label>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {itemDetails.reorderPoint ? (
                    <div className="row">
                      {" "}
                      <label className="col-lg-3 items-label text-muted">
                        {" "}
                        Reorder Point
                      </label>{" "}
                      <label className="col-lg-6 col-md-6 col-sm-6 col-6">
                        {itemDetails.reorderPoint}
                      </label>{" "}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <br />
                <div className="row">
                  {itemDetails.sellingPriceValue ? (
                    <div className="col-6">
                      {" "}
                      <label className="pb-3 font-medium">
                        Sales Information
                      </label>
                      <div className="group">
                        <div className="row">
                          <label className="col-lg-4 items-label text-muted">
                            {" "}
                            Selling Price
                          </label>{" "}
                          <label className="col-lg-5">
                            {" "}
                            {itemDetails.sellingCurrencySymbol}{" "}
                            {itemDetails.sellingPriceValue}{" "}
                          </label>
                        </div>
                        <div className="row">
                          <label className="col-lg-4 items-label text-muted">
                            {" "}
                            Account
                          </label>{" "}
                          <label className="col-lg-5">
                            {itemDetails.sellingAccountTitle}
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {itemDetails.purchasePriceValue ? (
                    <div className="col-6">
                      <label className="pb-3 font-medium">
                        Purchase Information
                      </label>
                      <div className="group">
                        <div className="row">
                          <label className="col-lg-4 items-label text-muted">
                            Purchase Price
                          </label>{" "}
                          <label className="col-lg-5">
                            {" "}
                            {itemDetails.purchaseCurrencySymbol}{" "}
                            {itemDetails.purchasePriceValue}{" "}
                          </label>
                        </div>
                        <div className="row">
                          <label className="col-lg-4 items-label text-muted">
                            Account
                          </label>{" "}
                          <label className="col-lg-5">
                            {itemDetails.purchaseAccountTitle}
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <br />

                <div>
                  <label className="pb-3 font-medium">Warehouse Info</label>{" "}
                  <table
                    id="ember7912"
                    className="table zi-table table-hover ember-view header-fixed"
                  >
                    <tr>
                      <td>Warehouse Title</td>
                      <td>Current Stock</td>
                      <td>Opening Stock Quantity</td>
                      <td>Opening Stock Value</td>
                    </tr>

                    <tbody>
                      {valueLoading
                        ? itemDetails1.map((item, i) => {
                            return (
                              <>
                                <tr>
                                  <td>{item.warehouseTitle}</td>
                                  <td>{item.currentStockQuantity}</td>
                                  <td>{item.openingStockQuantity}</td>
                                  <td>{item.openingStockValue}</td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
          ) : (
            "..loading"
          )}

          <TabPanel>
            <table>
              {logData.length == 0 ? (
                "No Changes Found"
              ) : (
                <tr>
                  <td>Date</td>
                  <td>Changes</td>
                </tr>
              )}
              {logData.length >= 1 &&
                logData?.map((history) => {
                  return (
                    <>
                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          {history?.transactionDate?.substring(0, 10)}
                          :&nbsp;&nbsp;
                        </td>

                        <td>
                          {history?.message.split("\n").map((item, i) => (
                            <tr>{item}</tr>
                          ))}
                        </td>
                      </tr>
                      <hr />
                    </>
                  );
                })}
            </table>
          </TabPanel>
        </Tabs>
      </div>
      <ToastContainer />
    </>
  );
};
export default ViewItem;
