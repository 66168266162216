import React, { Component, useState, useEffect } from 'react'
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from 'react-select';
import { addOrganisationSetting } from '../../../../services/Settings/OrganizationSettingServices'
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';

const InvoicesGeneral = (props) => {
    const cookies = new Cookies();
    const [invoicePrefix, setInvoicePrefix] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);


    const optionKeyValue = [
        {
            label: 'Paneer Butter Masala',
            value: 'PNB',
        },
        {
            label: 'Paneer Bhurji',
            value: 'bhurji',
        },
        {
            label: 'Malai Kofta',
            value: 'kofta',
        }
    ];

    const [invoice, setInvoice] = useState({
        edit: false,
        QR: false,
        zeroValueLineItems: false,
        termsAndConditions: '',
        customerNotes: '',
        QRDescription: '',
        receiptwithNote: false,
        automateReceiptWithNote: false,
        payOnline: false,
        selectedValue: optionKeyValue.value,
        invoiceOrderNo: 'orderNumber'
    })


    const handleInvoiceOrder = (e) => {
        setInvoice({
            ...invoice,
            invoiceOrderNo: e.target.value,
        });
        var dict;
        if (e.target.value == 'orderNumber') {
            dict = {
                orderNumber: true,
                orderRefNo: false,
                edit: invoice.edit,
                QR: invoice.QR,
                zeroValueLineItems: invoice.zeroValueLineItems,
                termsAndConditions: invoice.termsAndConditions,
                customerNotes: invoice.customerNotes,
                QRDescription: invoice.QRDescription,
                receiptwithNote: invoice.receiptwithNote,
                automateReceiptWithNote: invoice.automateReceiptWithNote,
                payOnline: invoice.payOnline,
                selectedValue: invoice.selectedValue,
                selectedKeyValue: invoice.selectedKeyValue,
                invoiceOrderNo: 'orderNumber',

            }
        } else {
            dict = {
                orderNumber: false,
                orderRefNo: true,
                edit: invoice.edit,
                QR: invoice.QR,
                zeroValueLineItems: invoice.zeroValueLineItems,
                termsAndConditions: invoice.termsAndConditions,
                customerNotes: invoice.customerNotes,
                QRDescription: invoice.QRDescription,
                receiptwithNote: invoice.receiptwithNote,
                automateReceiptWithNote: invoice.automateReceiptWithNote,
                payOnline: invoice.payOnline,
                selectedValue: invoice.selectedValue,
                selectedKeyValue: invoice.selectedKeyValue,
                invoiceOrderNo: 'orderRefNumber',
            }
        }
        props.parentCallback(dict);
    }

    const handleToggal = (e) => {
        setInvoice({
            ...invoice,
            QR: e.target.checked,
        })
        var dict = { ...invoice }
        dict['QR'] = e.target.checked
        props.parentCallback(dict)
    }

    const handlePaymentChange = (e) => {
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.checked,
        });
        var dict = { ...invoice }
        dict[e.target.name] = e.target.checked;
        props.parentCallback(dict);
    };

    const handleEditInvoice = (e) => {
        setInvoice({
            ...invoice,
            edit: e.target.checked,
        });
        var dict = { ...invoice }
        dict["edit"] = e.target.checked;
        props.parentCallback(dict);
    }

    const handleZeroValue = (e) => {
        setInvoice({
            ...invoice,
            zeroValueLineItems: e.target.checked
        })
        var dict = { ...invoice }
        dict["zeroValueLineItems"] = e.target.checked;
        props.parentCallback(dict);
    }

    const handleQRDescription = (e) => {
        setInvoice({
            ...invoice,
            QRDescription: e.target.value
        })
        var dict = { ...invoice }
        dict["QRDescription"] = e.target.value;
        props.parentCallback(dict);
    }

    const handleTermsAndConditions = (e) => {
        setInvoice({
            ...invoice,
            termsAndConditions: e.target.value,
        })
        var dict = { ...invoice }
        dict["termsAndConditions"] = e.target.value;
        props.parentCallback(dict);

    }

    const handleCustomerNotes = (e) => {
        setInvoice({
            ...invoice,
            customerNotes: e.target.value
        })
        var dict = { ...invoice }
        dict["customerNotes"] = e.target.value;
        props.parentCallback(dict);
    }
    const handleChangeOption = (e) => {
        // setSelectedValue(e);
        setInvoice({
            ...invoice,
            selectedValue: e,
        })
        var dict = { ...invoice }
        dict["selectedValue"] = e;
        props.parentCallback(dict);
    }

    const handleSaveInvoice = () => {
        let organisationId = cookies.get('loginData1').organisationId
        var dict = [
            {
                key: 'INVOICE_TERMS_CONDITIONS',
                value: invoice.termsAndConditions
            },
            {
                key: 'INVOICE_CUSTOMER_NOTE',
                value: invoice.customerNotes
            }
        ]
        var data = {
            "invoicePrefix": invoicePrefix,
            "organisationId": organisationId,
            "settingsItem": dict
        }
        addOrganisationSetting(data).then((res) => {
            if (res.errorCode === 0) {
                toast('👏' + res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        setInvoice({
            termsAndConditions: '',
            customerNotes: '',
        })


    }



    useEffect(() => {
        if (props.data != null) {
        
            const invoice = props.data
            if (props.data.orderNumber == true) {
                setInvoice({
                    edit: invoice.edit,
                    QR: invoice.QR,
                    zeroValueLineItems: invoice.zeroValueLineItems,
                    termsAndConditions: invoice.termsAndConditions,
                    customerNotes: invoice.customerNotes,
                    QRDescription: invoice.QRDescription,
                    receiptwithNote: invoice.receiptwithNote,
                    automateReceiptWithNote: invoice.automateReceiptWithNote,
                    payOnline: invoice.payOnline,
                    selectedValue: invoice.selectedValue,
                    invoiceOrderNo: 'orderNumber',
                })
            } else {
                setInvoice({
                    edit: invoice.edit,
                    QR: invoice.QR,
                    zeroValueLineItems: invoice.zeroValueLineItems,
                    termsAndConditions: invoice.termsAndConditions,
                    customerNotes: invoice.customerNotes,
                    QRDescription: invoice.QRDescription,
                    receiptwithNote: invoice.receiptwithNote,
                    automateReceiptWithNote: invoice.automateReceiptWithNote,
                    payOnline: invoice.payOnline,
                    selectedValue: invoice.selectedValue,
                    invoiceOrderNo: 'orderRefNumber'
                })
            }
        }

    }, []);




    return (
        <>
            <div className="scroll-y noscroll-x  body scrollbox">
                <div id="ember2346" className="ember-view">
                    <form className="preferences-body ">
                        {/* <div tabIndex={-1} id="ember2355" className="no-outline ember-view"></div>
                        <div>
                            <FormGroup onChange={handleEditInvoice}  >
                                <FormControlLabel checked={invoice.edit} control={<Checkbox />} label="Allow editing of Sent Invoice?" />
                            </FormGroup>
                        </div>
                        <hr />
                        <div  >
                            <label className="font-medium">Invoice Order Number</label>
                            <RadioGroup name="row-radio-buttons-group" onChange={handleInvoiceOrder} value={invoice.invoiceOrderNo} >
                                <FormControlLabel control={<Radio value='orderNumber' />} label="Use Sales Order Number" />
                                <FormControlLabel control={<Radio value="orderRefNumber" />} label="Use Sales Order Reference Number" />
                            </RadioGroup>
                        </div>
                        <div>
                            <label className="font-medium">Payments</label>
                            <FormGroup >
                                <FormControlLabel control={<Checkbox name="payOnline" onChange={handlePaymentChange} checked={invoice.payOnline} />} label="Get notified when customers pay online" />
                                <FormControlLabel control={<Checkbox name="receiptwithNote" onChange={handlePaymentChange} checked={invoice.receiptwithNote} />} label="Do you want to include the payment receipt along with the Thank You Note?" />
                                <FormControlLabel control={<Checkbox name="automateReceiptWithNote" onChange={handlePaymentChange} checked={invoice.automateReceiptWithNote} />} label="Automate thank you note to customer on receipt of online payment." />
                            </FormGroup>
                        </div>
                        <div className="option">
                            <label >Option</label>
                            <Select
                                isClearable
                                onChange={handleChangeOption}
                                value={invoice.selectedValue}
                                options={optionKeyValue}
                            />
                        </div>
                        <hr />
                        <div id="ember2356" className="ember-view">
                            <div>
                                <div className="form-group row">
                                    <label className="col-lg-9">
                                        <span className="font-medium">Invoice QR Code</span>
                                        <div className="text-void font-xs mt-2">
                                            Enable and configure the QR code you want to display on the PDF copy of an invoice. Your customers can scan the QR code using their device to access the URL or other information that you configure.
                                        </div>
                                    </label>
                                    <div className="ml-auto col-lg-auto">
                                        <div className="form-check">
                                            <label className="form-check-label mr-2" >
                                            </label>
                                            <FormGroup
                                                onChange={handleToggal} > <FormControlLabel checked={invoice.QR} control={<Switch />} label="Enabled" />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                {invoice.QR == true ?
                                    <div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">QR Code Type</label>
                                            <div className="col-lg-5">
                                                <div
                                                    id="ember2357"
                                                    className="project-lineitem-option ember-view"
                                                >
                                                    <div className="ac-box ">
                                                        <span className="ac-selected form-control " tabIndex={0}>
                                                            <span data-integrity-label>Invoice URL</span>
                                                            <div>
                                                                <i className="zf-ac-toggler">
                                                                    <svg
                                                                        version="1.1"
                                                                        id="Layer_1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        x={0}
                                                                        y={0}
                                                                        viewBox="0 0 512 512"
                                                                        xmlSpace="preserve" >
                                                                        <path d="M493.9 227.8c-21.4-21-56.1-21.1-77.6-.3L256 382.9 95.7 227.6c-21.5-20.8-56.3-20.7-77.6.3-10.6 10.4-15.9 24-15.9 37.7 0 13.8 5.4 27.5 16.2 38l199 192.9c21.4 20.7 55.9 20.7 77.3 0l199-192.9c21.4-20.9 21.6-54.8.2-75.8z" />
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">
                                                QR Code Description
                                            </label>
                                            <div className="col-lg-5">
                                                <textarea
                                                    rows={4}
                                                    placeholder="Enter a description to be displayed with the QR code"
                                                    value={invoice.QRDescription}
                                                    className="ember-text-area ember-view form-control"
                                                    onChange={handleQRDescription}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    : <></>}
                                <div className="form-group row">
                                    <div className="text-muted font-xs col-lg-9">
                                        <span className="text-semibold">Note: </span> To enable the QR
                                        code in an invoice template, go to the invoice template &gt; Edit
                                        &gt; Footer &gt; enable Invoice QR Code.
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr />
                        <div>
                            <label className="font-medium">Zero-value line items</label>
                            <FormGroup onChange={handleZeroValue}>
                                <FormControlLabel
                                    control={<Checkbox checked={invoice.zeroValueLineItems} name="zeroValueLineItems" />} label="Hide zero-value line items" />

                            </FormGroup>
                            <p className="text-muted">
                                Choose whether you want to hide zero-value line items in an invoice's
                                PDF and the Client Portal. They will still be visible while editing an
                                invoice. This setting will not apply to invoices whose total is zero.
                            </p>
                        </div>
                        <hr /> */}
                        <div className="form-group row">
                            <div className="col-lg-11">
                                <label className="font-medium">Invoice PreFix.</label>
                                <input
                                    placeholder="Enter the invoice Pre-Fix"
                                    id="ember1697"
                                    className="ember-text-area ember-view form-control"
                                    name='invoicePrefix'
                                    value={invoicePrefix}
                                    onChange={(e) => setInvoicePrefix(e.target.value)}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="form-group row">
                            <div className="col-lg-11">
                                <label className="font-medium">Terms &amp; Conditions</label>
                                <textarea
                                    row={9}
                                    className="ember-text-area ember-view form-control"
                                    value={invoice.termsAndConditions}
                                    onChange={handleTermsAndConditions}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="form-group row">
                            <div className="col-lg-11">
                                <label className="font-medium">Customer Notes</label>
                                <textarea
                                    row={9}
                                    className="ember-text-area ember-view form-control"
                                    value={invoice.customerNotes}
                                    onChange={handleCustomerNotes}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="mt-7">
                            <a
                                className="btn btn-primary ember-view"
                                type="submit"
                                onClick={handleSaveInvoice}
                            >
                                Save
                            </a>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default InvoicesGeneral