import { VENDOR_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}

// /vendor/getRecordByWarehouseUuid/{uuid}
export const getVendorByWare = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(VENDOR_URL() + "getRecordByWarehouseUuid/" + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getVendorByWare axios!");
                });
        } catch (error) {
            console.error("in LoginService > getVendorByWare, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const activeDeactiveVendor = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(VENDOR_URL() + "activeOrDeActiveVendor/" + id, id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in activeDeactiveVendor axios!");
                });
        } catch (error) {
            console.error("in LoginService > activeDeactiveVendor, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const uploadVendorExcel = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(VENDOR_URL() + "uploadVendorExcel", data, {
                    headers: headersdata,
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in upload axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};







// http://192.168.1.3:8001/vendor/downloadVendorExcel

export const getDownloadVendorExcel = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(VENDOR_URL() + "downloadVendorExcel", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in downloadVendorExcel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getVendorList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(VENDOR_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getVendorDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(VENDOR_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getVendorDetails axios!");
                });
        } catch (error) {
            console.error("in LoginService > getVendorDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const addVendor = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(VENDOR_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getVendorDetailsByUUID = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(VENDOR_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateVendorDetailsByUUID = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(VENDOR_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


export const getVendorKeyValuePair = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(VENDOR_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getVendorKeyValuePair axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};