import React, {
  Component,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import Swal from "sweetalert2";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <div className="px-3">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Dashboard</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inventory</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6">
                <div className="card-wrapper flip-left">
                  <div className="card s-widget-top">
                    <div className="front p-3 px-4">
                      <div className="text-light">Income status</div>
                      <div className="py-4 m-0 text-center h2 text-info">
                        $2,258
                      </div>
                      <div className="d-flex">
                        <small className="text-muted">New income</small>
                        <div className="ml-auto">0%</div>
                      </div>
                    </div>
                    <div className="back p-3 px-4 bg-info text-center">
                      <p className="text-light">This Week</p>
                      <span id="minibar-chart2" className="mini-bar-chart">
                        <canvas
                          width="154"
                          height="80"
                          style={{
                            display: "inline-block",
                            width: "154px",
                            height: "80px",
                            verticalAlign: "top",
                          }}
                        ></canvas>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="card-wrapper flip-left">
                  <div className="card s-widget-top">
                    <div className="front p-3 px-4 bg-danger text-light">
                      <div>Order status</div>
                      <div className="py-4 m-0 text-center h2">428</div>
                      <div className="d-flex">
                        <small>New order</small>
                        <div className="ml-auto">
                          <i className="fa fa-caret-down"></i>10%
                        </div>
                      </div>
                    </div>
                    <div className="back p-3 px-4 text-center">
                      <p>This Week</p>
                      <span id="minibar-chart4" className="mini-bar-chart">
                        <canvas
                          width="154"
                          height="80"
                          style={{
                            display: "inline-block",
                            width: "154px",
                            height: "80px",
                            verticalAlign: "top",
                          }}
                        ></canvas>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="card-wrapper flip-left">
                  <div className="card s-widget-top">
                    <div className="front p-3 px-4 bg-warning text-light">
                      <div>Customer status</div>
                      <div className="py-4 m-0 text-center h2">232</div>
                      <div className="d-flex">
                        <small>New users</small>
                        <div className="ml-auto">
                          <i className="fa fa-caret-up"></i>3%
                        </div>
                      </div>
                    </div>
                    <div className="back p-3 px-4 text-center">
                      <p>This Week</p>
                      <span id="minibar-chart3" className="mini-bar-chart">
                        <canvas
                          width="154"
                          height="80"
                          style={{
                            display: "inline-block",
                            width: "154px",
                            height: "80px",
                            verticalAlign: "top",
                          }}
                        ></canvas>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="card-wrapper flip-left">
                  <div className="card s-widget-top">
                    <div className="front p-3 px-4">
                      <div className="text-light">Total revenue</div>
                      <div className="py-4 m-0 text-center h2 text-success">
                        $9,653
                      </div>
                      <div className="d-flex">
                        <small className="text-muted">Income</small>
                        <div className="ml-auto">
                          <i className="fa fa-caret-up text-success"></i>4%
                        </div>
                      </div>
                    </div>
                    <div className="back p-3 px-4 bg-success text-center">
                      <p className="text-light">This Week</p>
                      <span id="minibar-chart1" className="mini-bar-chart">
                        <canvas
                          width="154"
                          height="80"
                          style={{
                            display: "inline-block",
                            width: "154px",
                            height: "80px",
                            verticalAlign: "top",
                          }}
                        ></canvas>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="body">
                <div className="form-group mb-4">
                  <label className="d-block">
                    Financials{" "}
                    <span className="float-right">
                      77% <i className="fa fa-long-arrow-up"></i>
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar bg-azura"
                      role="progressbar"
                      aria-valuenow="77"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "77%" }}
                    ></div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="d-block">
                    Time to Market{" "}
                    <span className="float-right">
                      50% <i className="fa fa-long-arrow-up"></i>
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "50%" }}
                    ></div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="d-block">
                    Engagement{" "}
                    <span className="float-right">
                      23% <i className="fa fa-long-arrow-up"></i>
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar bg-green"
                      role="progressbar"
                      aria-valuenow="23"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "23%" }}
                    ></div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="d-block">
                    Customers{" "}
                    <span className="float-right">
                      78% <i className="fa fa-long-arrow-up"></i>
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar bg-indigo"
                      role="progressbar"
                      aria-valuenow="78"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "78%" }}
                    ></div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="d-block">
                    Competitors{" "}
                    <span className="float-right">
                      {" "}
                      33% <i className="fa fa-long-arrow-up"></i>
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar bg-orange"
                      role="progressbar"
                      aria-valuenow="33"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "33%" }}
                    ></div>
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label className="d-block">
                    Exit Strategy
                    <span className="float-right">
                      88% <i className="fa fa-long-arrow-up"></i>
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar bg-red"
                      role="progressbar"
                      aria-valuenow="88"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "88%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="body">
                <div className="row text-center">
                  <div className="col-lg-12 col-md-5">
                    <div className="text-center">
                      <div
                        style={{
                          display: "inline",
                          width: "68px",
                          height: "68px",
                        }}
                      >
                        <canvas
                          width="85"
                          height="85"
                          style={{ width: "68px", height: "68px" }}
                        ></canvas>
                        <input
                          type="text"
                          className="knob"
                          data-width="68"
                          data-height="68"
                          data-thickness="0.1"
                          data-bgcolor="#383b40"
                          data-fgcolor="#17C2D7"
                          style={{
                            width: "38px",
                            height: "22px",
                            position: "absolute",
                            verticalAlign: "middle",
                            marginTop: "22px",
                            marginLeft: "-53px",
                            border: "0px",
                            background: "none",
                            font: "bold 13px Arial",
                            textAlign: "center",
                            color: "rgb(23, 194, 215)",
                            padding: "0px",
                            appearance: "none",
                          }}
                        />
                      </div>
                    </div>
                    <label className="mb-0 mt-2">New Users</label>
                    <h4 className="h4 mb-0 font-weight-bold text-cyan">225</h4>
                  </div>
                  <div className="col-12 col-md-2 col-lg-12">
                    <hr className="mt-4 mb-4" />
                  </div>
                  <div className="col-lg-12 col-md-5">
                    <div className="text-center">
                      <div
                        style={{
                          display: "inline",
                          width: "68px",
                          height: "68px",
                        }}
                      >
                        <canvas
                          width="85"
                          height="85"
                          style={{ width: "68px", height: "68px" }}
                        ></canvas>
                        <input
                          type="text"
                          className="knob"
                          data-width="68"
                          data-height="68"
                          data-thickness="0.1"
                          data-bgcolor="#383b40"
                          data-fgcolor="#dc3545"
                          style={{
                            width: "38px",
                            height: "22px",
                            position: "absolute",
                            verticalAlign: "middle",
                            marginTop: "22px",
                            marginLeft: "-53px",
                            border: "0px",
                            background: "none",
                            font: "bold 13px Arial",
                            textAlign: "center",
                            color: "rgb(220, 53, 69)",
                            padding: "0px",
                            appearance: "none",
                          }}
                        />
                      </div>
                    </div>
                    <label className="mb-0 mt-2">Return Visitors</label>
                    <h4 className="h4 mb-0 font-weight-bold text-info">124</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
