import React, { useState, useEffect } from "react";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useHistory, Link } from "react-router-dom";
import Address from "../SharedComponents/Address";
import PersonIcon from "@mui/icons-material/Person";
import {
  getCustomerList,
  addCustomer,
  updateCustomer,
  getCustomerDetails,
  getTaxExemptionKeyValuePair,
  getPaymentTermKeyValuePair,
  getStateRegionKeyValuePair,
  getSalesPriceKeyValuePair,
} from "../../services/index";

const CustomerNew = (props) => {
  let customerId = props?.match?.params?.id;
  let history = useHistory();

  const [gstNumber, setGstNumber] = useState("18AABCU9603R1ZM");

  const [taxPreference, setTaxPreference] = useState([]);
  const [exemptionOptions, setExemptionOptions] = useState([]);
  const [exemption, setExemption] = useState("");

  const [fssai, setFssai] = useState("12345123451234");
  const [priceListOptions, setPriceListOptions] = useState([]);
  const [priceList, setPriceList] = useState("");

  const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState("");

  const [placeSupply, setPlaceSupply] = useState([]);
  const [placeSupplyOptions, setPlaceSupplyOptions] = useState("");

  const [remarkData, setRemarkData] = useState("");

  const [requestData, setRequestData] = useState({
    recordId: null,
    customerType: "Business",
    fullName: "",
    customerEmail: "",
    customerPhone: "",
  });

  const [child1, setChild1] = useState({
    addressTypeEnum: "Billing",
    country: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const [child2, setChild2] = useState({
    addressTypeEnum: "Shipping",
    country: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const handleCopy = (d) => {
    d.addressTypeEnum = "Shipping";
    setChild2(d);
  };

  const handleChild1 = (e, key) => {
    if (e.value && e.label) {
      setChild1({
        ...child1,
        [key]: e,
      });
    } else if (e.target.value) {
      setChild1({
        ...child1,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChild2 = (e, key) => {
    if (e.value && e.label) {
      setChild2({
        ...child2,
        [key]: e,
      });
    } else if (e.target.value) {
      setChild2({
        ...child2,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    console.log(child1, "child1");
    console.log(child2, "child2");
  }, [child1, child2]);

  const handleToast = (res) => {
    toast(res.message, {
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  useEffect(() => {
    getStateRegionKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setPlaceSupplyOptions(options);
      } else {
        handleToast(res);
      }
    });

    getSalesPriceKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setPriceListOptions(options);
      } else {
        handleToast(res);
      }
    });

    getTaxExemptionKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setExemptionOptions(options);
      } else {
        handleToast(res);
      }
    });

    getPaymentTermKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setPaymentTermsOptions(options);
      } else {
        handleToast(res);
      }
    });
  }, []);

  const handleTypeChange = (e) => {
    setRequestData({
      ...requestData,
      customerType: e.target.value,
    });
  };

  useEffect(() => {
    if (customerId != null) {
      getCustomerDetails(customerId).then((res) => {
        if (res.errorCode == 0) {
          const customerData = res.responsePacket;
setFssai(res.responsePacket.fssaiLicenseNumber)
          let d = res.responsePacket.addressList;

          let child1 = d.filter((a) => a.addressTypeEnum == "Billing");
          child1.map((a) => {
            a.country = { value: "c", label: a.country };
            a.state = { value: "b", label: a.state };
          });

          let child2 = d.filter((a) => a.addressTypeEnum == "Shipping");
          child2.map((a) => {
            a.country = { value: "c", label: a.country };
            a.state = { value: "b", label: a.state };
          });
          let a1 = child1[0];
          let a2 = child2[0];

          if (a1 != undefined) {
            setChild1(a1);
          }
          if (a2 != undefined) {
            setChild2(a2);
          }
          setRemarkData(customerData?.remark);
          setRequestData({
            recordId: customerData.recordId,
            customerType: customerData.customerType,
            fullName: customerData.title,
            customerEmail: customerData.email,
            customerPhone: customerData.phone,
          });
          setPlaceSupply({
            value: customerData.placeOfSupply,
            label: customerData.placeOfSupplyTitle,
          });

          var abc = customerData.taxApplicable ? "Taxable" : "Tax Exempt";
          setTaxPreference(abc);

          setGstNumber(customerData.gstNumber);

          var defautTerm = {
            value: customerData.paymentTermRecordId,
            label: customerData.paymentTermTitle,
          };

          setPaymentTerms(defautTerm);

          var defautPriceList = {
            value: customerData.itemPriceListRecordId,
            label: customerData.itemPriceListTitle,
          };

          setPriceList(defautPriceList);
        } else {
          handleToast(res);
        }
      });
    }
  }, [customerId]);

  const handleSaveCustomer = (e) => {
    console.log(e, "eeeeee");
    let doNotSave = false;
    // if (
    //   !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //     requestData.customerEmail
    //   )
    // ) {
    //   alert("Please provide valid Mail without Spaces");
    //   doNotSave = true;
    //   return;
    // }

    if (requestData.customerPhone.toString().length !== 10) {
      if (requestData.customerPhone.toString().length > 10) {
        alert("Mobile number cannot exceed the limit of 10");
        doNotSave = true;
      } else if (requestData.customerPhone.toString().length < 10) {
        alert("Please provide valid Moble Number");
        doNotSave = true;
      }
    }
    if (requestData.fullName === null || requestData.fullName === "") {
      alert("Please Fill Customer Name First !");
      return;
    }

    if (customerId == null) {
      if (requestData?.customerPhone?.length != 10) {
        alert("please input 10 digit phone number");
        return;
      }
    }
    if (gstNumber == "") {
      alert("please input gstNumber");
      return;
    }

    let ar = [];

    var deepCopy1 = JSON.parse(JSON.stringify(child1));
    var deepCopy2 = JSON.parse(JSON.stringify(child2));

    deepCopy1.state = child1?.state?.label;
    deepCopy1.country = child1?.country?.label;

    deepCopy2.state = child2?.state?.label;
    deepCopy2.country = child2?.country?.label;

    ar.push(deepCopy1);
    ar.push(deepCopy2);

    console.log(child1, child2, "ssss");

    if (child1.country.label == undefined || child1.country.label == "") {
      toast("Please select both country");
      return;
    }
    if (child1.state.label == undefined || child1.state.label == "") {
      toast("Please select both state");
      return;
    }
    if (child1.city == undefined || child1.city == "") {
      toast("Please select both city");
      return;
    }

    if (child1.zipcode == undefined || child1.zipcode == "") {
      toast("Please select both zipcode");
      return;
    }
    if (child1.street1 == undefined || child1.street1 == "") {
      toast("Please select both street1");
      return;
    }

    // for (let x in child1) {
    //   console.log(child1[x], "obj");
    //   if (child1[x] == "" || child1[x] == undefined) {
    //     toast(`Empty field Billing: ${[x]} `, {
    //       autoClose: 4000,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //     });
    //     doNotSave = true;
    //     return;
    //   }
    // }

    // for (let x in child2) {
    //   console.log(child2[x], "obj");
    //   if (child2[x] == "" || child2[x] == undefined) {
    //     toast(`Empty field Shipping: ${[x]} `, {
    //       autoClose: 4000,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //     });
    //     doNotSave = true;
    //     return;
    //   }
    // }
    console.log(ar, "ar");
    var request = {
      addressList: ar,
      companyTitle: requestData.fullName?.trim(),
      fullName: requestData.fullName?.trim(),
      title: requestData.fullName?.trim(),
      currencyRecordId: "dad7fa51-f498-42fb-a2d9-8efe52269ffc",
      currencyRecordTitle: "INR - Indian Rupee",
      customerType: requestData.customerType,
      email: requestData.customerEmail?.trim(),
      phone: requestData.customerPhone,
      salutation: "Mr",
      taxApplicable: true,
      placeOfSupply: placeSupply.value,
      placeOfSupplyTitle: placeSupply.label,
      // taxExemptRecordId: taxPreference == "Tax Exempt" ? exemption.value : "",
      gstNumber: gstNumber,
      gstTreatmentTypeEnum: "GSTINUIN",
      gstTreatmentRecordId: "0a66bb0b-36e1-4ef2-b892-a4e04b623e71",
      itemPriceListRecordId: priceList.value,
      itemPriceListTitle: priceList.label,
      paymentTermRecordId: paymentTerms.value,
      paymentTermTitle: paymentTerms.label,
      remark: remarkData,
      fssaiLicenseNumber: fssai,
    };

    console.log(request, "request");
    if (!doNotSave) {
      if (requestData.recordId != null) {
        updateCustomer(requestData.recordId, request).then((res) => {
          if (res.errorCode == 0) {
            history.push("/inventory/contacts");
            handleToast(res);
          } else {
            handleToast(res);
          }
        });
      } else {
        addCustomer(request).then((res) => {
          if (res.errorCode == 0) {
            history.push("/inventory/contacts");
            handleToast(res);
          } else {
            handleToast(res);
          }
        });
      }
    }
  };

  return (
    <>
      <div className="fill list-header d-flex justify-content-between align-items-center bg-white p-3 border-bottom">
        <h3 className="">
          <PersonIcon /> {customerId ? "Edit" : "New"} Customer
        </h3>
      </div>

      <div className="scroll-y noscroll-x fill body scrollbox pb-100 position-relative">
        <div className="header">
          <div id="ember2534" className="ember-view pagetips-title float-right">
            <div id="ember2536" className="ember-view">
              <div id="ember2537" className="ember-view" />
            </div>
          </div>
        </div>
        <div
          className="fill-container header-separator"
          style={{ paddingTop: "30px" }}
        />

        <div className="row ">
          <div className="col-lg-8">
            {/**/}
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-form-label col-lg-3 pt-0">
                  <span id="ember2541" className="required">
                    {" "}
                    Customer Type{" "}
                  </span>
                </legend>
                <div className="col-lg-9">
                  {/* Put Here */}
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    value={requestData.customerType}
                    onChange={handleTypeChange}
                  >
                    <FormControlLabel
                      defaultChecked
                      value="Business"
                      control={<Radio size="small" />}
                      label="Business"
                      className="required"
                    />
                    <FormControlLabel
                      value="Individual"
                      control={<Radio size="small" />}
                      label="Individual"
                      className="required"
                    />
                  </RadioGroup>
                </div>
              </div>
            </fieldset>

            <div className="row form-group">
              <label className="col-form-label col-lg-3">
                <span
                  id="ember2542"
                  className="tooltip-container text-dashed-underline ember-view required"
                >
                  Company's Name
                </span>
              </label>

              <div className="col-lg-6">
                <input
                  autoFocus
                  id="ember2546"
                  className="ember-text-field ember-view form-control"
                  type="text"
                  value={requestData.fullName}
                  onChange={(e) =>
                    setRequestData({
                      ...requestData,
                      fullName: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row form-group">
              <label className="col-form-label col-lg-3 required">
                Customer Email
              </label>
              <div className="col-lg-6">
                <input
                  id="ember2553"
                  className="ember-text-field ember-view form-control"
                  type="text"
                  value={requestData.customerEmail}
                  onChange={(e) =>
                    setRequestData({
                      ...requestData,
                      customerEmail: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-form-label col-lg-3 required">
                Customer Phone
              </label>
              <div className="col-lg-6">
                <input
                  placeholder="10 digit"
                  id="ember2557"
                  className="ember-text-field ember-view form-control"
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  maxLength={10}
                  minLength={10}
                  value={requestData.customerPhone}
                  onChange={(e) =>
                    setRequestData({
                      ...requestData,
                      customerPhone: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            {/* <div className="row form-group">
              <label className="col-form-label col-lg-3 required">
              FSSAI No.
              </label>
              <div className="col-lg-6">
                <input
                  className="ember-text-field ember-view form-control"
                
                />
              </div>
            </div> */}
          </div>
        </div>
        <br />
        <Tabs>
          <TabList>
            <Tab>Other Details</Tab>
            <Tab>Address</Tab>
            <Tab>Remarks</Tab>
          </TabList>

          <TabPanel>
            <div className="row form-group">
              <label className="col-form-label col-lg-3 ">GST No.</label>
              <div className="col-lg-3">
                <input
                  id="ember682"
                  placeholder="ex.18AABCU9603R1ZM"
                  maxLength="15"
                  className="ember-text-field ember-view form-control"
                  type="text"
                  value={gstNumber}
                  onChange={(e) => setGstNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-form-label col-lg-3 ">FSSAI No.</label>
              <div className="col-lg-3">
                <input
                  id="ember682"
                  placeholder="ex.12345123451234"
                  className="ember-text-field ember-view form-control"
                  type="number"
                  value={fssai}
                  onChange={(e) => setFssai(e.target.value)}
                />
              </div>
            </div>

            <div className="row form-group">
              <label className="col-form-label col-lg-3 ">
                Customer PriceList
              </label>
              <div className="col-lg-3">
                <Select
                  options={priceListOptions}
                  onChange={(e) => setPriceList(e)}
                  value={priceList}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-form-label col-lg-3 ">Payment Terms</label>
              <div className="col-lg-3">
                <Select
                  options={paymentTermsOptions}
                  onChange={(e) => setPaymentTerms(e)}
                  value={paymentTerms}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-form-label col-lg-3 ">
                Place of Supply
              </label>
              <div className="col-lg-3">
                <Select
                  options={placeSupplyOptions}
                  onChange={(e) => setPlaceSupply(e)}
                  value={placeSupply}
                />
              </div>
            </div>

            {/* <div className="row">
              <legend className="col-form-label col-lg-3 required">
                Tax Preference
              </legend>
              <div className="col-lg-3 align-self-center">
                <RadioGroup
                  row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                  value={taxPreference}
                  onChange={(e) => setTaxPreference(e.target.value)}
                >
                  <FormControlLabel
                    defaultChecked
                    value="Taxable"
                    control={<Radio size="small" />}
                    label="Taxable"
                  />
                  <FormControlLabel
                    value="Tax Exempt"
                    control={<Radio size="small" />}
                    label="Tax Exempt"
                  />
                </RadioGroup>
              </div>
            </div>

            {taxPreference == "Tax Exempt" ? (
              <div className="row form-group">
                <label className="col-form-label col-lg-3 ">
                  Exemption Reason
                </label>
                <div className="col-lg-3">
                  <Select
                    options={exemptionOptions}
                    onChange={(e) => setExemption(e)}
                    value={exemption}
                  />
                </div>
              </div>
            ) : (
              <></>
            )} */}
          </TabPanel>

          <TabPanel>
            <Address
              handleChild1={handleChild1}
              child1={child1}
              handleChild2={handleChild2}
              child2={child2}
              handleCopy={handleCopy}
            />
          </TabPanel>
          <TabPanel>
            <div className="col-lg-8">
              <label className="col-form-label">
                Remarks <span className="text-muted">(For Internal Use)</span>
              </label>{" "}
              <textarea
                rows={3}
                id="ember719"
                className="ember-text-area ember-view form-control"
                value={remarkData}
                onChange={(e) => setRemarkData(e.target.value)}
              />
            </div>
          </TabPanel>
        </Tabs>

        <br />
        <hr style={{ size: "10px", color: "black" }} />

        <div className="row" style={{ margin: "20px" }}>
          <button
            id="ember1143"
            className="btn btn-primary ember-view"
            onClick={handleSaveCustomer}
          >
            Save
          </button>
          &nbsp;
          <button
            className="btn btn-secondary"
            onClick={() => history.push("/inventory/contacts")}
          >
            Cancel
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CustomerNew;
