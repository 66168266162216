import { UNIT_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
    // 'Authorization': url.Auth_Token
}
// /units/{unit}/recordTitleList
export const getUnitByUnit = (unit) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(UNIT_URL() +unit+ "/recordTitleList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const getUnitKeyValuePair = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(UNIT_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};
export const getUnitKeyValueListByBaseUnit = (baseUnit) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(UNIT_URL() + "getKeyValueListByBaseUnit/" + baseUnit, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};
export const getUnitKeyValueList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(UNIT_URL() + "getKeyValueList/", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemDetail axios!");
                });
        } catch (error) {
            console.error("in LoginService > getItemDetail, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const unitGetAllRec = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(UNIT_URL()+"getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getAllRecords axios!");
                });
        } catch (error) {
            console.error("in LoginService > getAllRecords, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const addUnit = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(UNIT_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addUnit axios!");
                });
        } catch (error) {
            console.error("in LoginService > addUnit, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateUnit = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(UNIT_URL()+id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateUnit axios!");
                });
        } catch (error) {
            console.error("in LoginService > updateUnit, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

