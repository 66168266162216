import React, { useState, useEffect } from "react";
import Select from "react-select";
import AddIcon from "@mui/icons-material/Add";
import { toast, ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import BallotIcon from "@mui/icons-material/Ballot";

import {
  getKeyValueForBatches1,
  getItemDetailKeyValuePair,
  addBatches1,
  getBatchesUUID1,
  updateBatches1,
  getProductionDetailsKeyValue1,
  getUnitKeyValueList,
  updateItemOnWarehouses1,
  getWarehouseByItemId1,
} from "../../services/index";

const BatchNew = (props) => {
  const batchId = props?.match?.params?.id;
  const history = useHistory();

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date1 = curr.toISOString().substring(0, 10);

  const [edit, setEdit] = useState(true);
  const [total, setTotal] = useState(0);
  const [yield1, setYield1] = useState("");
  const [batchName, setBatchName] = useState("");
  const [batchDetails, setBatchDetails] = useState("");
  const [unitOptions, setUnitOptions] = useState("");
  const [itemOptions, setItemOptions] = useState("");
  const [prodDetailsOptions, setProdDetailsOptions] = useState("");
  const [date, setDate] = useState(date1);

  const [unit, setUnit] = useState("");
  const [item, setItem] = useState("");
  const [prodDetails, setProdDetails] = useState("");
  const [quantity, setQuantity] = useState("");

  const [warehouseOptions, setWarehouseOptions] = useState("");
  const [warehouseOptions2, setWarehouseOptions2] = useState("");

  const [rows, setRows] = useState([
    {
      stockQuantity: "",
      warehouse: "",
    },
  ]);

  useEffect(() => {
    getProductionDetailsKeyValue1().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setProdDetailsOptions(options);
      } else {
        toast(res.message, { autoClose: 2000 });
      }
    });

    getKeyValueForBatches1().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket.data;
        const options = data.map((d) => ({
          value: d,
          label: d.title,
        }));
        setItemOptions(options);
      } else {
        toast(res.message, { autoClose: 2000 });
      }
    });

    getUnitKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setUnitOptions(options);
      } else {
        toast(res.message, { autoClose: 2000 });
      }
    });
  }, []);

  useEffect(() => {
    if (batchId)
      getBatchesUUID1(batchId).then((res) => {
        if (res.errorCode == 0) {
          const data = res.responsePacket;

          if (data.batchesWarehouseBeanList) {
            let arr1 = [];
            data.batchesWarehouseBeanList &&
              data.batchesWarehouseBeanList.map((c) => {
                let obj = {
                  warehouse: { label: c.warehouseTitle, value: c.warehouseId },
                  stockQuantity: c.quantity,
                };
                arr1.push(obj);
              });

            setRows(arr1);
          }

          setBatchDetails(data);
          setUnit(data.quantityUnitTitle);
          setBatchName(data.batchName);

          setItem({
            label: data.itemTitle,
            value: {
              recordId: data.itemId,
              title: data.itemTitle,
              weightUnitId: data.quantityUnitId,
              weightUnitTitle: data.quantityUnitTitle,
            },
          });
          setProdDetails({
            label: data.productionDetailTitle,
            value: data.productionDetailId,
          });

          setQuantity(Number(data.quantity));
          setDate(data.productionDate);

          getWarehouseByItemId1(data.itemId).then((res) => {
            if (res.errorCode == 0) {
              const data = res.responsePacket;
              const options = data.map((d) => ({
                value: d.warehouseId,
                label: d.warehouseTitle,
              }));
              setWarehouseOptions(options);
              setWarehouseOptions2(options);
            } else {
              toast(res.message, { autoClose: 2000 });
            }
          });
          // data?.batchesWarehouseBeanList ? setEdit(false) : setEdit(true);
        } else {
          toast(res.message, { autoClose: 2000 });
        }
      });
  }, []);

  const handlAddRow = () => {
    setRows([
      ...rows,
      {
        stockQuantity: "",
        warehouse: "",
      },
    ]);
  };

  const handleClear = (id, i) => {
    let obj = [...rows];
    obj.splice(i, 1);
    setRows(obj);

    let filterOptions = [...warehouseOptions];
    warehouseOptions2 &&
      warehouseOptions2.map((a) => {
        if (a.value === id) {
          filterOptions.push(a);
        }
      });
    setWarehouseOptions(filterOptions);
  };

  const handleWarehouse = (e, i) => {
    const obj = [...rows];
    obj[i].warehouse = e;
    setRows(obj);

    var setSend = false;
    var filterOptions = [];
    warehouseOptions2 &&
      warehouseOptions2.map((a) => {
        rows.map((b) => {
          if (a.value === b.warehouse.value) {
            setSend = true;
          }
        });
        if (!setSend) {
          filterOptions.push(a);
        }
        setSend = false;
      });
    setWarehouseOptions(filterOptions);
  };

  const handleQuantity = (e) => {
    if (e.target.value > 0) {
      setQuantity(Number(e.target.value));
    }
  };

  const handleStockQuantity = (e, i) => {
    const obj = [...rows];

    if (e.target.value <= quantity && e.target.value >= 0) {
      obj[i].stockQuantity = Number(e.target.value);
    }

    setRows(obj);
    handleTotal();
  };

  const handleTotal = () => {
    let sum = 0;
    rows &&
      rows.map((data) => {
        sum += Number(data.stockQuantity);
        setTotal(sum);
      });
  };

  const handleItemSelect1 = (e) => {
    setItem(e);
    setUnit(e.value.weightUnitTitle);
    getWarehouseByItemId1(e.value.recordId).then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.warehouseId,
          label: d.warehouseTitle,
        }));
        setWarehouseOptions(options);
        setWarehouseOptions2(options);
      } else {
        toast(res.message, { autoClose: 2000 });
      }
    });
  };

  const handleSaveBatch = () => {
    console.log(total, quantity);
    if (total > quantity) {
      alert("Sum of Assigned Qty > Item Qty");
      return;
    }

    let arr1 = [];
    rows.filter((item, i) => {
      // if (item.warehouse.value && item.stockQuantity) {
      if (item.warehouse.value) {
        arr1.push({
          // quantity: item.stockQuantity,
          quantity: quantity,
          warehouseId: item.warehouse.value,
          warehouseTitle: item.warehouse.label,
        });
      }
    });
    console.log(arr1, "arr1");
    const request = {
      batchName: batchName,
      batchNo: "BA",
      itemId: item.value.recordId,
      itemTitle: item.value.title,
      productionDate: date,
      productionDetailId: "3bcce633-69a4-4008-8934-46a2712a73b0",
      productionDetailTitle: "Complete",
      quantity: quantity,
      quantityUnitId: item.value.weightUnitId,
      quantityUnitTitle: item.value.weightUnitTitle,
      batchesWarehouseBeanList: arr1,
    };

    console.log(request, "request");

    if (batchId != null) {
      updateBatches1(batchId, request).then((res) => {
        if (res.errorCode === 0) {
          toast(res.message, { autoClose: 2000 });
          history.push("/inventory/batches");
        } else {
          toast(res.message, { autoClose: 2000 });
          return;
        }
      });
    } else {
      addBatches1(request).then((res) => {
        if (res.errorCode === 0) {
          toast(res.message, { autoClose: 2000 });
          history.push("/inventory/batches");
        } else {
          toast(res.message, { autoClose: 2000 });
          return;
        }
      });
    }
  };

  useEffect(() => {
    console.log(rows, "rows");
    console.log(total, "total");
    console.log(quantity, "quantity");
    console.log(warehouseOptions2, "warehouseOptions2");
    console.log(warehouseOptions, "warehouseOptions");
    console.log(edit, "edit");
  }, [rows, quantity, total, warehouseOptions, warehouseOptions2, edit]);

  const handleBatchName = (e) => {
    setBatchName(e.target.value);
  };

  return (
    <>
      <div className="fill list-header d-flex justify-content-between align-items-center bg-white p-3 border-bottom">
        <h1 className="">
          <BallotIcon />
          {batchId ? "Edit" : "New"} Batch Production
        </h1>
      </div>
      <div style={{ paddingLeft: "15px", paddingTop: "15px" }}>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 required">Batch Name</label>
          <div className="col-lg-3">
            <input
              disabled={edit ? false : true}
              id="ember1413"
              className="ember-text-field ember-view form-control"
              type="text"
              onChange={(e) => handleBatchName(e)}
              value={batchName}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-lg-2 required">Item</label>
          <div className="col-lg-3">
            <Select
              isDisabled={edit ? false : true}
              placeholder={item}
              options={itemOptions}
              onChange={(e) => handleItemSelect1(e)}
              value={item}
            />
          </div>
        </div>

        <div className="form-group row ">
          <label className="col-form-label col-lg-2 required">Quantity</label>
          <div className="col-1">
            <input
              disabled={edit ? false : true}
              id="ember1413"
              className="ember-text-field ember-view form-control"
              type="number"
              min={1}
              onChange={(e) => handleQuantity(e)}
              value={quantity}
            />
          </div>
          <div className="col-2">
            <input
              id="ember1413"
              disabled={true}
              className="ember-text-field ember-view form-control"
              type="text"
              value={item?.value?.weightUnitTitle}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-lg-2 required">Date</label>
          <div className="col-lg-3">
            <input
              disabled={edit ? false : true}
              autoFocus
              id="ember1413"
              className="ember-text-field ember-view form-control"
              type="date"
              onChange={(e) => setDate(e.target.value)}
              value={date}
            />
          </div>
        </div>
        <hr />

        <br />
        <div>
          {rows.map((data, i) => {
            return (
              <div className="row">
                <label className="col-form-label col-lg-2 required">
                  Warehouse
                </label>

                <div className="col-lg-3">
                  <Select
                    options={warehouseOptions}
                    placeholder={"Select WareHouse"}
                    onChange={(e) => handleWarehouse(e, i)}
                    value={data.warehouse}
                  />
                </div>

                <label className="col-form-label col-lg-2 required">Qty</label>
                <div className="col-lg-3">
                  <input
                    autoFocus
                    disabled={true}
                    className="ember-text-field ember-view form-control"
                    type="number"
                    min={0}
                    onChange={(e) => handleStockQuantity(e, i)}
                    // value={data.stockQuantity}
                    value={quantity}
                  />
                </div>

                {/* {rows.length > 1 && (
                  <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-times"
                    onClick={() => handleClear(data.warehouse.value, i)}
                  ></i>
                )} */}
              </div>
            );
          })}
        </div>
        {/* <div>
          <button
            id="ember1426"
            className="btn btn-default addbtn mt-3"
            onClick={handlAddRow}
          >
            <AddIcon style={{ color: "blue" }} /> New Row
          </button>
        </div> */}
        <br />
        <hr />
        <Button id="ember1426" type="button" onClick={handleSaveBatch}>
          Save
        </Button>

        <Link to={"/inventory/batches"}>
          <Button variant="secondary">Cancel</Button>
        </Link>
      </div>
      <ToastContainer />
    </>
  );
};

export default BatchNew;
