import React, { useState } from "react";
import { Link } from "react-router-dom";

const PreferencesNav = () => {
  const [active, setActive] = useState("salesOrder");

  const handleChangeOption = (data) => {
    setActive(data);
  };

  return (
    <>
      <div style={{ border: 0, paddingLeft: 15 }}>
        <h3>PREFERENCES</h3>
      </div>

      <div
        className="scroll-y noscroll-x fill body scrollbox set-list"
        style={{ height: "800px" }}
      >
        <div className="reports-group lpane-grp">
          <ul className="nav nav-pills flex-column">
            <li
              className="nav-item text-open"
              onChange={(e) => handleChangeOption("Department")}
              style={{ border: 0, cursor: "pointer" }}
            >
              <Link
                to={"/settings/preferences/DepartmentList"}
                id="ember699"
                className="ember-view nav-link"
              >
                Department{" "}
              </Link>
              <hr />
            </li>

            <li
              className="nav-item text-open"
              style={{ border: 0, cursor: "pointer" }}
            >
              <Link
                to={"/settings/preferences/DeliveryPersonList"}
                id="ember699"
                className="ember-view nav-link"
              >
                Delivery Person
              </Link>

              <hr />
            </li>

            <li className="text-open" style={{ border: 0, cursor: "pointer" }}>
              <Link
                to={"/settings/preferences/TermsConditionList"}
                id="ember699"
                className="ember-view nav-link"
              >
                Terms Condition
              </Link>
              <hr />
            </li>

            <li className="text-open" style={{ border: 0, cursor: "pointer" }}>
              <Link
                to={"/settings/preferences/HSN"}
                id="ember699"
                className="ember-view nav-link"
              >
                HSN
              </Link>
              <hr />
            </li>

            <li className="text-open" style={{ border: 0, cursor: "pointer" }}>
              <Link
                to={"/settings/preferences/TaxExempt"}
                id="ember699"
                className="ember-view nav-link"
              >
                Tax Exemption
              </Link>
              <hr />
            </li>

            <li className="text-open" style={{ border: 0, cursor: "pointer" }}>
              <Link
                to={"/settings/preferences/PaymentTerms"}
                id="ember699"
                className="ember-view nav-link"
              >
                Payment Terms
              </Link>
              <hr />
            </li>

            <li className="text-open" style={{ border: 0, cursor: "pointer" }}>
              <Link
                to={"/settings/preferences/UnitList"}
                id="ember699"
                className="ember-view nav-link"
              >
                Units
              </Link>
              <hr />
            </li>
            <li className="text-open" style={{ border: 0, cursor: "pointer" }}>
              <Link
                to={"/settings/preferences/DefaultUrlList"}
                id="ember699"
                className="ember-view nav-link"
              >
                Default Urls
              </Link>
              <hr />
            </li>
            

            {/* <li
              className="text-open"
            >
              <Link
                to="/settings/preferences/salesorders"
                id="ember695"
                className="ember-view nav-link"
              >
                Sales Orders
              </Link>
            </li>
            <br />

            <li
              className="text-open"
            >
              <Link
                to="/settings/preferences/invoices"
                id="ember699"
                className="ember-view nav-link"
              >
                Invoices
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PreferencesNav;
