import React, { Fragment, useEffect } from "react";
import Select, { components } from "react-select";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
const Menu = (props) => {
    return (
        <Fragment>
            <components.Menu {...props}>
                <div>
                    {props.selectProps.fetchingData ? (
                        <span className="fetching">Fetching data...</span>
                    ) : (
                        <div>{props.children}</div>
                    )}
                    {props.selectProps.className === 'Brand' ?
                        <button
                            className="btn btn-light btn-sm btn-block cursor"
                            onClick={props.selectProps.addNew}
                        >
                            <AddIcon color="primary" />
                            Add Brand
                        </button>
                        : <></>
                    }
                    {props.selectProps.className === 'Unit' ?
                        <button
                            className="btn btn-light btn-sm btn-block cursor"
                            onClick={props.selectProps.addNew}
                        >
                            <AddIcon color="primary" />
                            Add Unit
                        </button>
                        : <></>
                    }
                    {props.selectProps.className === 'Manufacturer' ?
                        <button
                            className="btn btn-light btn-sm btn-block cursor"
                            onClick={props.selectProps.addNew}
                        >
                            <SettingsIcon color="primary" />
                            Manage Manufacturers
                        </button>
                        : <></>
                    }
                    {props.selectProps.className === 'Hsn' ?
                        <button
                            className="btn btn-light btn-sm btn-block cursor"
                            onClick={props.selectProps.addNew}
                        >
                            <AddIcon color="primary" />
                            Add HSN
                        </button>
                        : <></>
                    }
                    {props.selectProps.className === 'Warehouse' ?
                        <button
                            className="btn btn-light btn-sm btn-block cursor"
                            onClick={props.selectProps.addNew}
                        >
                            <AddIcon color="primary" />
                            Add Warehouse
                        </button>
                        : <></>
                    }
                    {/* {props.selectProps.className === 'OtherDetails' ?
                        <button
                            className="btn btn-light btn-sm btn-block cursor"
                            onClick={props.selectProps.addNew}
                        >
                            <AddIcon color="primary" />
                            Add New
                        </button>
                        : <></>
                    } */}
                    {props.selectProps.className === 'tds' ?
                        <button
                            className="btn btn-light btn-sm btn-block cursor"
                            onClick={props.selectProps.addNew}
                        >
                            <AddIcon color="primary" />
                            Add TDS
                        </button>
                        : <></>
                    }
                    {props.selectProps.className === 'paymentMode' ?
                        <button
                            className="btn btn-light btn-sm btn-block cursor"
                            onClick={props.selectProps.addNew}
                        >
                            <AddIcon color="primary" />
                            Add Payment Mode
                        </button>
                        : <></>
                    }
                    {/* <button
                        className={"change-data"}
                        onClick={props.selectProps.className === 'Brand' ? props.selectProps.changeOptionsData : alert("Another")}
                    >
                        Add {props.selectProps.className}
                    </button> */}
                </div>
            </components.Menu>
        </Fragment>
    );
};

const Option = (props) => {
    return (
        <Fragment>
            <components.Option {...props}>{props.children}</components.Option>
        </Fragment>
    );
};

const CustomSelect = ({
    value,
    options,
    changeOptionsData,
    fetchingData,
    onChange,
    type,
    inputChange
}) => {

    useEffect(() => {
        if (type === "Brand") {

        }
    }, [type]);

    return (
        <div>
            <Select
             maxMenuHeight={200}
             menuPlacement="auto"
                options={options}
                value={value}
                className={type}
                components={{ Menu, Option }}
                fetchingData={fetchingData}
                addNew={changeOptionsData}
                onInputChange={inputChange}
                onChange={onChange}
            />
        </div>
    );
};
export default CustomSelect;