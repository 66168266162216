import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Button from "@mui/material/Button";

import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PreferencesNav from "./PreferencesNav";
import {
  getDefaultUrls,
  addDefaultUrls,
  urlAllRecords1,
  getAdminRole,
} from "../../../services/index";

const DefaultUrlList = () => {
  const [responsePacket, setResponsePacket] = useState([]);

  const [data, setData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [rows, setRows] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [role, setRole] = useState({});

  useEffect(() => {
    getAdminRole().then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        const options = d.map((a) => ({
          label: a,
          value: a,
        }));
        options.shift();
        setRoleOptions(options);
        setRole(options[0]);
      } else {
        toast(res.message);
      }
    });
  }, []);

  useEffect(() => {
    if (role.value) {
      getDefUrls();
    }
  }, [role]);

  const getDefUrls = () => {
    getDefaultUrls(role.value).then((res) => {
      if (res.errorCode == 0) {
        let urlList1 = res.responsePacket;

        urlAllRecords1().then((res) => {
          if (res.errorCode == 0) {
            if (urlList1.length == 0) {
              res.responsePacket.map((a) => {
                a.beanLinksList.map((b) => {
                  b.module = a.title;
                  b.moduleId = a.recordId;
                });
              });

              setResponsePacket(res.responsePacket);
            } else {
              let abc = res.responsePacket.map((a) => {
                a.beanLinksList.map((b) => {
                  b.module = a.title;
                  b.moduleId = a.recordId;
                  b.ans = false;
                  urlList1.map((c) => {
                    if (b.urlId === c.urlId) {
                      b.ans = true;
                    }
                  });
                  return b;
                });
                return a;
              });
              console.log(abc, "res packet abc");
              setResponsePacket(abc);
            }
          } else {
            toast(res.message, {
              autoClose: 1000,
            });
          }
        });
      } else {
        toast(res.message, {
          autoClose: 1000,
        });
      }
    });
  };

  const handleUrlAnswer = (e, i, i2) => {
    const obj = [...responsePacket];
    obj[i].beanLinksList[i2].ans = e.target.checked;
    setResponsePacket(obj);
  };

  const handleUrlRight = (e, i) => {
    let x = e.target.value;
    const obj = [...responsePacket];

    if (x == "READ_ONLY") {
      obj[i].beanLinksList.map((a) =>
        a.methodType == "GET" ? (a.ans = true) : (a.ans = false)
      );
    } else if (x == "WRITE_ONLY") {
      obj[i].beanLinksList.map((a) =>
        a.methodType == "POST" || a.methodType == "PUT" || a.methodType == "GET"
          ? (a.ans = true)
          : (a.ans = false)
      );
    } else {
      obj[i].beanLinksList.map((a) =>
        a.methodType == "POST" ||
        a.methodType == "PUT" ||
        a.methodType == "GET" ||
        a.methodType == "DELETE"
          ? (a.ans = true)
          : (a.ans = false)
      );
    }
    console.log(obj, "resPacket obj");
    setResponsePacket(obj);
  };

  const handleSave = () => {
    let arr = responsePacket.map((a) => a.beanLinksList.filter((b) => b.ans));
    let brr = arr.filter((a) => a.length > 0);
    let array1 = [];
    brr.map((a) => {
      a.map((b) => array1.push(b));
    });

    let data = {
      adminRoleEnum: role.value,
      urlList: array1,
    };

    console.log(data, "data");

    addDefaultUrls(data).then((res) => {
      if (res.errorCode == 0) {
        toast.success(res.message);
        getDefUrls();
      } else {
        toast.error(res.message);
      }
    });
  };
  useEffect(() => {
    console.log(responsePacket, "responsePacket");
  }, [responsePacket]);

  return (
    <>
      <div className="column list-column d-print-none column-small">
        <PreferencesNav />
      </div>
      <div className="column content-column">
        <div style={{ fontSize: "35px" }}>Url List</div>
        <br />
        <table>
          <tr>
            <td style={{ paddingLeft: "30px" }}>Role</td>
            <td>
              <Select
                options={roleOptions}
                value={role}
                onChange={(e) => setRole(e)}
              />
            </td>
          </tr>
        </table>
        <br />

        <div style={{ overflowY: "scroll", maxHeight: "550px", width: "100%" }}>
          <>
            {responsePacket &&
              responsePacket.map((data, i) => {
                return (
                  <>
                    <br />
                    <div className="col-6">
                      <div style={{ fontSize: "20px" }}>{data?.title}</div>
                      <div
                        className="row"
                        style={{ paddingLeft: "30px", width: "100%" }}
                      >
                        <RadioGroup
                          aria-label="RIGHTS:"
                          name="controlled-radio-buttons-group"
                          value={data?.urlRight}
                          onChange={(e) => handleUrlRight(e, i)}
                        >
                          <div>
                            <FormControlLabel
                              value="READ_ONLY"
                              control={<Radio size="small" />}
                              label="Read"
                            />
                            <FormControlLabel
                              value="WRITE_ONLY"
                              control={<Radio size="small" />}
                              label="Read-Write"
                            />
                            <FormControlLabel
                              value="FULL_ACCESS"
                              control={<Radio size="small" />}
                              label="Full Access"
                            />
                          </div>
                          <table>
                            {data.beanLinksList.map((url, i2) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <input
                                        type="checkbox"
                                        checked={
                                          url?.ans == undefined
                                            ? (url.ans = false)
                                            : url.ans
                                        }
                                        onChange={(e) =>
                                          handleUrlAnswer(e, i, i2)
                                        }
                                      />
                                    </td>
                                    <td>{url.title}</td>
                                  </tr>
                                </>
                              );
                            })}
                          </table>
                        </RadioGroup>{" "}
                      </div>
                    </div>
                  </>
                );
              })}
            <br />
            <br />
          </>
        </div>
        <button
          className="btn btn-primary float-left "
          onClick={handleSave}
          style={{ marginLeft: "20px" }}
        >
          Save
        </button>
      </div>

      <Toaster />
    </>
  );
};

export default DefaultUrlList;
