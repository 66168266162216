import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineSetting } from "react-icons/ai";
import CircleIcon from "@mui/icons-material/Circle";
import { useHistory, useParams, Link, Redirect } from "react-router-dom";
import Select from "react-select";
import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import {
  getAllWarehouse,
  deleteWarehouse,
  activeInactiveWarehouse,
  addNewWarehouse,
  getWarehouseDetails,
  updateWarehouseDetails,
  getCountryKeyValuePair,
  getStateKeyValue,
  getTermsConditionKeyValue,
  getSalesPriceKeyValuePair,
  getCustomerKeyValuePair,
  getPaymentTermKeyValuePair,
  getOrgKeyValue,
  addOrganisationProfile,
  getOrgUUID,
} from "../../../services/index";

import { urlMappingUUID1 } from "../../../services/index";
import { toast, ToastContainer } from "react-toastify";
import DataTable from "react-data-table-component";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Cookies from "universal-cookie";
import Modal from "react-bootstrap/Modal";

const Warehouses = () => {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [wareID, setWareID] = useState(null);
  const [edit, setEdit] = useState(null);
  const [view, setView] = useState(null);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setWareID(null);
    setEdit(null);
    setWareInfo({
      gstNumber: "18AABCU9603R1ZM",
      isRestaurent: true,
    });
  };

  const [countryKeyValue, setCountryKeyValue] = useState([]);
  const [stateKeyValue, setStateKeyValue] = useState([]);
  const [termsOptions, setTermsOptions] = useState("");
  const [orgOptions, setOrgOptions] = useState("");
  const [customerOptions, setCustomerOptions] = useState("");
  const [payTermsOptions, setPayTermsOptions] = useState("");
  const [priceListOptions, setPriceListOptions] = useState("");
  const [wareInfo, setWareInfo] = useState({
    title: "",
    street1: "",
    street2: "",
    city: "",
    zipcode: "",
    state: "",
    country: "",
    bankName: "",
    branchName: "",
    gstNumber: "18AABCU9603R1ZM",
    ifscCode: "",
    terms: "",
    isRestaurent: true,
    org: "",
fssai:"12345123451234",
    priceList: "",
    payTerm: "",
    email: "",
    phone: "",
    placeSupply: "",
  });

  useEffect(() => {
    console.log(wareInfo, "wareInfo");
    getOrgKeyValue().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setOrgOptions(options);
      } else {
        handleToast(res);
      }
    });

    getSalesPriceKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setPriceListOptions(options);
      } else {
        handleToast(res);
      }
    });

    getCustomerKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setCustomerOptions(options);
      } else {
        handleToast(res);
      }
    });
    getPaymentTermKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setPayTermsOptions(options);
      } else {
        handleToast(res);
      }
    });
  }, []);

  const WarehouseDetails = (e) => {
    if (wareID != null)
      getWarehouseDetails(wareID).then((res) => {
        if (res.errorCode == 0) {
          let c = res.responsePacket.addressBean;
          let d = res.responsePacket;

          let terms = {
            value: d.termsConditionId,
            label: d.termsConditionTitle,
          };
          let org = {
            value: d.organisationId,
            label: d.organisationTitle,
          };
          let country = { value: c.country, label: c.country };

          let state = { value: c.state, label: c.state };

          let priceList = {
            value: d.itemPriceListRecordId,
            label: d.itemPriceListTitle,
          };

          let payTerm = {
            value: d.paymentTermRecordId,
            label: d.paymentTermTitle,
          };

          let placeSupply = { value: d.placeOfSupply, label: d.placeOfSupplyTitle };

          setWareInfo({
            title: d.title,
            city: c.city,
            country: country,
            state: state,
            street1: c.street1,
            street2: c.street2,
            zipcode: c.zipcode,

            priceList: priceList,
            payTerm: payTerm,
            placeSupply: placeSupply,
            email: d.email,
            phone: d.phone,
            customerId: d?.customerId,
            customerTitle: d?.customerTitle,

            bankName: d.bankName,
            branchName: d.branchName,
            gstNumber: d.gstNumber,
            ifscCode: d.ifscCode,
            terms: terms,
            isRestaurent: d.isRestaurent,
            org: org,
          });
        } else {
          handleToast(res);
        }
      });
  }; 

  const handleData = (e, key) => {
    console.log(e, key, "ssss");
    if (key == "check") {
      setWareInfo({
        ...wareInfo,
        [e.target.name]: e.target.checked,
      });
    } else if (e.value && e.label) {
      setWareInfo({
        ...wareInfo,
        [key]: e,
      });
    } else {
      setWareInfo({
        ...wareInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const warehouseList = () => {
    var data = {
      page: 0,
      search: "",
      size: -1,
    };
    getAllWarehouse(data).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket.data;
        d.reverse();
        setData(d);
      } else {
        toast("👎" + res.message, {
          autoClose: 1000,

          pauseOnHover: true,
          closeOnClick: true,
        });
      }
    });
  };

 

  const RegionKeyValue = (e) => {
    getStateKeyValue().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setStateKeyValue(options);
      } else {
        handleToast(res);
      }
    });
  };

  const GetCountryKeyValueList = (e) => {
    getCountryKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setCountryKeyValue(options);
      } else {
        handleToast(res);
      }
    });
  };

  const getTermsList = () => {
    getTermsConditionKeyValue().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setTermsOptions(options);
      } else {
        handleToast(res);
      }
    });
  };

  useEffect(() => {
    GetCountryKeyValueList();
    RegionKeyValue();
    WarehouseDetails();
    getTermsList();
  }, [wareID]);

  const handleToast = (res) => {
    toast(res.message, {
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const handleNew = () => {
    setShow(true);
  };

  const handleEditWarehouse = (data) => {
    setShow(true);
    setEdit({
      header: "Edit Warehouse",
      button: "Update",
    });
    setWareID(data.recordId);
    // alert(data.recordId)
  };


  const handleView = (data) => {
    console.log(data,"data")
setView(true)
setShow(true);
setEdit({
  header: "View Warehouse",
  button: "Update",
});
setWareID(data.recordId);


    // history.push(`/settings/warehouse/${e.recordId}`);
  };

  const handleDeleteWarehouse = (wareID) => {
    deleteWarehouse(wareID).then((res) => {
      if (res.errorCode == 0) {
        toast(res.message, {
          autoClose: 1000,
          closeOnClick: true,
        });
        warehouseList();
      } else {
        toast("👎" + res.message, {
          autoClose: 1000,
          closeOnClick: true,
        });
      }
    });
  };

  const handleActiveInActive = (wareID) => {
    activeInactiveWarehouse(wareID).then((res) => {
      if (res.errorCode == 0) {
        warehouseList();
        toast(res.message, {
          autoClose: 1000,
          closeOnClick: true,
        });
      } else {
        toast("👎" + res.message, {
          autoClose: 1000,
          closeOnClick: true,
        });
      }
    });
  };

  useEffect(() => {
    warehouseList();
  }, []);

  const columns = [
    {
      name: "WAREHOUSE",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Bank Name",
      selector: (row) => row.bankName,
    },
    {
      name: "Branch Name",
      selector: (row) => row.branchName,
    },
    {
      name: "Restaurant",
      selector: (row) =>
        row.isRestaurent ? (
          <CircleIcon style={{ fontSize: "10px", color: "greenyellow" }} />
        ) : (
          <CircleIcon style={{ fontSize: "10px" }} />
        ),
    },

    {
      name: "ADDRESS",
      selector: (row) => (
        <>
          {" "}
          {row.addressBean.city} , {row.addressBean.state} ,{" "}
          {row.addressBean.country}
        </>
      ),
      sortable: true,
    },
    {
      name: "ACTIONS",
      selector: (row) => (
        <>
          <div className="media-right">
            <EditIcon
              onClick={(e) => handleEditWarehouse(row)}
              style={{ fontSize: "15px", cursor: "pointer" }}
            />
            &nbsp;&nbsp;
            <div className="dropdown ember-view btn-group">
              {row.active ? (
                <CheckCircleIcon
                  style={{
                    cursor: "pointer",
                    color: "greenyellow",
                    fontSize: "15px",
                  }}
                  onClick={() => handleActiveInActive(row.recordId)}
                />
              ) : (
                <CancelIcon
                  style={{
                    cursor: "pointer",
                    color: "tomato",
                    fontSize: "15px",
                  }}
                  onClick={() => handleActiveInActive(row.recordId)}
                />
              )}
            </div>
          </div>
        </>
      ),
      sortable: true,
    },
  ];



  useEffect(() => {
    console.log(wareInfo, "wareInfo");
  }, [wareInfo]);

  const handleSubmit = (e) => {
    console.log(wareInfo.org, "wareInfo.org");
    let newOrgId;

    if (wareInfo.title == "" || wareInfo.title == undefined) {
      toast("Please Select warehouse name");
      return;
    }
    if (wareInfo.state == "" || wareInfo.state == undefined) {
      toast("Please Select state");
      return;
    }
    if (wareInfo.country == "" || wareInfo.country == undefined) {
      toast("Please Select country");
      return;
    }
    if (wareInfo.city == "" || wareInfo.city == undefined) {
      toast("Please Select city");
      return;
    }
    if (wareInfo.zipcode == "" || wareInfo.zipcode == undefined) {
      toast("Please Select zipcode");
      return;
    }
    if (wareInfo.bankName == "" || wareInfo.bankName == undefined) {
      toast("Please Select bankName");
      return;
    }
    if (wareInfo.branchName == "" || wareInfo.branchName == undefined) {
      toast("Please Select branchName");
      return;
    }
    if (wareInfo.gstNumber == "" || wareInfo.gstNumber == undefined) {
      toast("Please Select gstNumber");
      return;
    }
    if (wareInfo.ifscCode == "" || wareInfo.ifscCode == undefined) {
      toast("Please Select ifscCode");
      return;
    }
    if (wareInfo.terms == "" || wareInfo.terms == undefined) {
      toast("Please Select terms");
      return;
    }
    // if (wareInfo.email == "" || wareInfo.email == undefined) {
    //   toast("Please Select email");
    //   return;
    // }
    if (wareInfo.phone == "" || wareInfo.phone == undefined) {
      toast("Please Select phone");
      return;
    }
    if (wareInfo.org == "" || wareInfo.org == undefined) {
      toast("Please Select Organiztion");
      return;
    }

    if (wareInfo.isRestaurent) {
      if (wareInfo.priceList == "" || wareInfo.priceList == undefined) {
        toast("Please Select priceList");
        return;
      }
      if (wareInfo.payTerm == "" || wareInfo.payTerm == undefined) {
        toast("Please Select payTerm");
        return;
      }
      if (wareInfo.placeSupply == "" || wareInfo.placeSupply == undefined) {
        toast("Please Select place of Supply");
        return;
      }

      if (wareInfo.fssai != "" &&wareInfo.fssai.length!=14) {
        toast("Please Select 14 digit fssai");
        return;
      }
    }

    console.log(wareInfo?.org, "org");
    console.log(wareInfo?.org?.label, "orglabel");
    let data = {
      organisationId : wareInfo?.org?.value,
      organisationTitle : wareInfo?.org?.label,
      fssaiLicenseNumber:wareInfo.fssai,
      title: wareInfo.title,
      bankName: wareInfo.bankName,
      branchName: wareInfo.branchName,
      deleted: false,
      gstNumber: wareInfo.gstNumber,
      ifscCode: wareInfo.ifscCode,
      isRestaurent: wareInfo.isRestaurent,
      termsConditionId: wareInfo.terms.value,
      termsConditionTitle: wareInfo.terms.label,
      email: wareInfo.email,
      phone: wareInfo.phone,
      addressBean: {
        addressTypeEnum: "Warehouse",
        attention: wareInfo.attention,
        city: wareInfo.city,
        country: wareInfo.country.label,
        email: wareInfo.email,
        fax: 0,
        phone: wareInfo.phone,
        state: wareInfo.state.label,
        street1: wareInfo.street1,
        street2: wareInfo.street2,
        zipcode: wareInfo.zipcode,
      },
    };
    // "organisationId": "string",
    // "organisationTitle": "string",
    // defaultContactNo: Number(phone),
    // defaultEmail: email,
    // organisationTitle: name,
    if (wareInfo.isRestaurent) {
      data.itemPriceListRecordId = wareInfo.priceList.value;
      data.itemPriceListTitle = wareInfo.priceList.label;
      data.paymentTermRecordId = wareInfo.payTerm.value;
      data.paymentTermTitle = wareInfo.payTerm.label;

      data.placeOfSupply = wareInfo.placeSupply.value;
      data.placeOfSupplyTitle = wareInfo.placeSupply.label;
    }

    if (wareInfo.isRestaurent && wareID&&wareInfo?.customerId) {
      data.customerId = wareInfo?.customerId;
      data.customerTitle = wareInfo?.customerTitle;
    }

    // if (wareInfo.org == "" || wareInfo?.org?.label == undefined) {
    //   const data1 = {
    //     defaultContactNo: wareInfo.phone,
    //     defaultEmail: wareInfo.email,
    //     organisationTitle: wareInfo.title,
        
    //     beanAddress: {
    //       addressLine1: wareInfo.street1,
    //       addressLine2: wareInfo.street2,
    //       city: wareInfo.city,
    //       country: wareInfo.country.label,
    //       pinCode: wareInfo.zipcode,
    //       state: wareInfo.state.label,
    //     },
    //   };
    //   addOrganisationProfile(data1).then((res) => {
    //     if (res.errorCode == 0) {
    //       let orgId = res.responsePacket;
    //       getOrgUUID(orgId).then((res) => {
    //         newOrgId = res.responsePacket.recordId;
    //         data.organisationId = newOrgId;
    //         handleWareNew(data);
    //       });
    //     }
    //   });
    // } else {
    //   data.organisationId = wareInfo?.org?.value;
    //   handleWareNew(data);
    // }
    handleWareNew(data);
    console.log(data, "data");
  };

  const handleWareNew = (data) => {
    if (wareID != null) {
      data.recordId = wareID;

      updateWarehouseDetails(wareID, data).then((res) => {
        if (res.errorCode == 0) {
          warehouseList();
          handleClose();
          handleToast(res);
        } else {
          handleToast(res);
          return;
        }
      });
    } else {
      addNewWarehouse(data).then((res) => {
        if (res.errorCode == 0) {
          warehouseList();
          handleClose();
        } else {
          handleToast(res);
          return;
        }
      });
    }
  };

  return (
    <>
      {" "}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          {edit == null ? "New Warehouse" : edit.header}

          <table>
            <tr style={{ display: "flex", alignItems: "center" }}>
              <td>isRestaurant:</td>
              <td>
                <input
                  type="checkbox"
                  name="isRestaurent"
                  onChange={(e) => handleData(e, "check")}
                  checked={wareInfo.isRestaurent}
                />
              </td>
            </tr>
          </table>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <table className="requiredTable">
              <tr>
                <td style={{ width: "150px" }}>Warehouse Name:</td>
                <td>
                  <input
                    onChange={(e) => {
                      handleData(e);
                    }}
                    name="title"
                    value={wareInfo.title}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>

              <tr>
                <td>Sreet1:</td>
                <td>
                  <input
                    onChange={(e) => {
                      handleData(e);
                    }}
                    name="street1"
                    value={wareInfo.street1}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>Steet2:</td>
                <td>
                  <input
                    onChange={(e) => {
                      handleData(e);
                    }}
                    name="street2"
                    value={wareInfo.street2}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>City:</td>
                <td>
                  <input
                    onChange={(e) => {
                      handleData(e);
                    }}
                    name="city"
                    value={wareInfo.city}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>Zipcode:</td>
                <td>
                  <input
                    onChange={(e) => {
                      handleData(e);
                    }}
                    name="zipcode"
                    value={wareInfo.zipcode}
                    className="ember-text-field ember-view form-control"
                    type="number"
                    min={0}
                  />
                </td>
              </tr>
              <tr>
                <td>State:</td>
                <td>
                  <Select
                    value={wareInfo.state}
                    options={stateKeyValue}
                    onChange={(e) => {
                      handleData(e, "state");
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>
                  <Select
                    value={wareInfo.country}
                    options={countryKeyValue}
                    onChange={(e) => {
                      handleData(e, "country");
                    }}
                  />
                </td>
              </tr>
              {wareInfo.isRestaurent ? (
                <>
                <tr>
                    <td style={{ width: "150px" }}>FSSAI:</td>
                    <td>
                    <input
                    onChange={(e) => handleData(e)}
                    name="fssai"
                    value={wareInfo.fssai}
                    className="ember-text-field ember-view form-control"
                  />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "150px" }}>payTerm:</td>
                    <td>
                      <Select
                        value={wareInfo.payTerm}
                        options={payTermsOptions}
                        onChange={(e) => handleData(e, "payTerm")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>priceList:</td>
                    <td>
                      <Select
                        value={wareInfo.priceList}
                        options={priceListOptions}
                        onChange={(e) => handleData(e, "priceList")}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>placeSupply:</td>
                    <td style={{ width: "170px" }}>
                      <Select
                        value={wareInfo.placeSupply}
                        options={stateKeyValue}
                        onChange={(e) => handleData(e, "placeSupply")}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <></>
              )}
            </table>

            <table className="requiredTable">
              <tr>
                <td style={{ width: "150px" }}>Bank Name:</td>
                <td>
                  <input
                    onChange={(e) => handleData(e)}
                    name="bankName"
                    value={wareInfo.bankName}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>Branch Name:</td>
                <td>
                  <input
                    onChange={(e) => handleData(e)}
                    name="branchName"
                    value={wareInfo.branchName}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>gstNumber:</td>
                <td>
                  <input
                    placeholder="ex.18AABCU9603R1ZM"
                    maxLength="15"
                    name="gstNumber"
                    onChange={(e) => handleData(e)}
                    value={wareInfo.gstNumber}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>ifscCode:</td>
                <td>
                  <input
                    name="ifscCode"
                    onChange={(e) => handleData(e)}
                    value={wareInfo.ifscCode}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>Terms:</td>
                <td style={{ maxWidth: "150px" }}>
                  <Select
                    value={wareInfo.terms}
                    options={termsOptions}
                    onChange={(e) => handleData(e, "terms")}
                  />
                </td>
              </tr>

              <tr>
                <td style={{ width: "150px" }}>Email:</td>
                <td>
                  <input
                    onChange={(e) => handleData(e)}
                    name="email"
                    value={wareInfo.email}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>Phone:</td>
                <td>
                  <input
                    name="phone"
                    onChange={(e) => handleData(e)}
                    value={wareInfo.phone}
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {/* <Tooltip
                    title={
                      <h2>
                        Cant be edited!
                        <br />
                        leave it blank to assign this Warehouse as Organization
                      </h2>
                    }
                  >
                    <WarningIcon />
                  </Tooltip> */}
                  Org:
                </td>
                <td style={{ maxWidth: "150px", cursor: "pointer" }}>
                  <Select
                    isDisabled={wareID ? true : false}
                    value={wareInfo.org}
                    options={orgOptions}
                    onChange={(e) => handleData(e, "org")}
                  />
                </td>
              </tr>
            </table>
          </div>

          <Modal.Footer>
            <button
              onClick={(e) => handleSubmit(e)}
              className="btn btn-primary ember-view mr-2"
            >
              Save
            </button>
            <button
              className="btn btn-secondary"
              onClick={(e) => handleClose(e)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <DataTable
        theme="light"
        title="All Warehouses"
        columns={columns}
        data={data}
        fixedHeader
        // onRowClicked={()=>handleView(data)}

        subHeader
        subHeaderComponent={
          <>
            <button
              className="btn btn-primary align-bottom mr-3"
              onClick={(e) => handleNew(e)}
            >
              {" "}
              <AiOutlinePlus style={{ color: "white" }} /> New{" "}
            </button>
          </>
        }
        subHeaderAlign="right"
      />
      <ToastContainer />
    </>
  );
};

export default Warehouses;
