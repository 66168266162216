import React, { useState, useEffect } from "react";
import { useParams, Link, Redirect } from "react-router-dom";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  getPriceDetails,
  getPriceList,
  logItemPriceListHistory,
} from "../../services/index";

const ViewPriceList = (props) => {
  let { id } = useParams();

  const [logData, setLogData] = useState({});
  const [priceDetails1, setPriceDetails1] = useState([]);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    logItemPriceListHistory(id).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        console.log(d, "d");
        setLogData(d);
      } else {
        toast(res.message, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    });
  }, [id]);

  const getPriceDetails12 = (id) => {
    getPriceDetails(id).then((res) => {
      if (res.errorCode == 0) {
        setPriceDetails1(res.responsePacket);
      } else {
        toast("👎" + res.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  useEffect(() => {
    let customer_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getPriceList(customer_data).then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket.data;
        setRows(d);
        setData(d);
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    getPriceDetails12(id);
  }, []);

  const filterOptions = [
    { value: "isVolumePrice", label: "isVolumePrice" },
    { value: "isUnitPrice", label: "isUnitPrice" },
    { value: "isMarkUp", label: "isMarkUp" },
    { value: "isMarkDown", label: "isMarkDown" },
  ];

  const [data, setData] = useState([]);
  const [search, setSearch] = useState([]);

  const handleFilter = (e) => {
    setSearch(e);
    if (e.value == "isVolumePrice") {
      var arr1 = rows.filter((data) => data.isVolumePrice);
      setData(arr1);
    }
    if (e.value == "isUnitPrice") {
      var arr2 = rows.filter((data) => data.isUnitPrice);
      setData(arr2);
    }
    if (e.value == "isMarkUp") {
      var arr3 = rows.filter((data) => data.isMarkUp);
      setData(arr3);
    }
    if (e.value == "isMarkDown") {
      var arr4 = rows.filter((data) => data.isMarkDown);
      setData(arr4);
    }
  };

  return (
    <>
      <div
        className="list-column column d-print-none"
        style={{ width: "300px" }}
      >
        <div className="fill list-header topgrad d-print-none">
          <div className="list-filter">
            <div className="btn-toolbar float-right">
              <Link
                to="/inventory/pricelists/new"
                id="ember3047"
                className="btn btn-primary tooltip-container ember-view"
              >
                <span className="d-flex align-items-center">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    className="icon icon-xsmall mr-2"
                  >
                    <path d="M484 227H284V28c0-15.5-12.5-28-28-28s-28 12.5-28 28v199H28c-15.5 0-28 12.5-28 28v1c0 15.5 12.5 28 28 28h200v200c0 15.5 12.5 28 28 28s28-12.5 28-28V284h200c15.5 0 28-12.5 28-28v-1c0-15.5-12.5-28-28-28z"></path>
                  </svg>
                  New
                </span>
              </Link>
            </div>
            <div
              id="ember3068"
              className="clearfix list-title dropdown ember-view"
            >
              <a id="ember3069" className="dropdown-toggle no-caret ember-view">
                <span className="filter-title over-flow">All Pricelist</span>
              </a>
            </div>
          </div>
        </div>
        <div className="scroll-y fill body scrollbox list-body">
          <div className="table-responsive overflow-initial">
            <table
              id="ember7912"
              className="table zi-table table-hover ember-view header-fixed"
            >
              <tbody>
                {data &&
                  data.map((row, index) => (
                    <tr
                      key={index}
                      tabIndex="-1"
                      id="ember3096"
                      className="table-active ember-view"
                      onClick={() => getPriceDetails12(row.recordId)}
                    >
                      <td>
                        <Link to={`/inventory/pricelists/${row.recordId}`}>
                          <div className="list-primary text-black">
                            <div>
                              <h6 className="font-xlarge text-uppercase font-xs text-open">
                                {row?.title}
                                &nbsp;
                              </h6>
                            </div>

                            <div className="text-muted">
                              <h6 className="font-xlarge">
                                Type: {row?.itemPriceListTypeEnum}
                              </h6>
                            </div>
                            <div className="text-muted">
                              <h6 className="font-xlarge">
                                {row?.isMarkUp ? "markup" : <></>}
                                {row?.isMarkDown ? "MarkDown" : <></>}
                                {row?.isUnitPrice ? "UnitPrice" : <></>}
                                {row?.isVolumePrice ? "VolumePrice" : <></>}
                              </h6>
                            </div>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="column content-column " style={{ left: "301px" }}>
        <div className="header no-border d-flex align-items-center p-4">
          <h3 className="over-flow header-title text-uppercase">
            {/* {priceDetails1.title} */}
            &nbsp;
          </h3>
          <div className="btn-toolbar d-flex justify-content-end align-items-center">
            <Select
              placeholder="Filter Items"
              value={search}
              options={filterOptions}
              onChange={handleFilter}
              style={{ minWidth: "150px" }}
            />
            <div className="btn-toolbar">
              <div className="btn-group">
                {/* edit btn */}

                <Link
                  to={`/inventory/pricelists/${priceDetails1.recordId}/edit`}
                >
                  <button
                    id="ember7700"
                    className="tooltip-container ember-view btn btn-primary mr-0"
                    type="button"
                  >
                    <EditIcon />
                  </button>
                </Link>
              </div>

            
            </div>
          </div>
        </div>

        <Tabs>
          <TabList>
            <Tab>Primary Details</Tab>
            <Tab>Log History</Tab>
          </TabList>

          <TabPanel>
            <div className="ember-view scroll-y noscroll-x fill body scrollbox">
              <br />
              <div className="customTable" id="scroll">
                <table>
                  <tr>
                    <td className="font-large text-uppercase font-xs text-open">
                      {priceDetails1?.isMarkUp ? "markup" : <></>}
                      {priceDetails1?.isMarkDown ? "MarkDown" : <></>}
                      {priceDetails1?.isUnitPrice ? "UnitPrice" : <></>}
                      {priceDetails1?.isVolumePrice ? "VolumePrice" : <></>}
                    </td>
                  </tr>
                  <tr>
                    <td>Title:</td>
                    <td>{priceDetails1.title}</td>
                  </tr>

                  {priceDetails1?.isMarkDown || priceDetails1?.isMarkUp ? (
                    <tr>
                      <td>Percentage:</td>
                      <td>{priceDetails1.percentage}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <td>Type:</td>
                    <td>{priceDetails1.itemPriceListTypeEnum}</td>
                  </tr>

                  <tr>
                    <td>Description:</td>
                    <td>{priceDetails1.description}</td>
                  </tr>
                </table>
              </div>
              <br />

              {priceDetails1?.isUnitPrice ? (
                <div>
                  {" "}
                  {priceDetails1 &&
                    priceDetails1?.itemCustomUnitPriceBeanList.length !== 0 && (
                      <div className="customTable"  id="scroll">
                          ITEM DETAILS
                          <hr />
                        <table>
                          <thead>
                            <tr>
                              <td>Item Title</td>
                              <td> Price</td>
                              <td>Custom Price</td>
                            </tr>
                          </thead>
                          <tbody>
                            {priceDetails1 &&
                              priceDetails1?.itemCustomUnitPriceBeanList.map(
                                (item, i) => (
                                  <tr key={i}>
                                    <td>{item.itemTitle}</td>

                                    <td>{item.price}</td>
                                    <td>{item.customPrice}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
              ) : (
                <></>
              )}
              {priceDetails1?.isVolumePrice ? (
                <div>
                  {" "}
                  {priceDetails1 &&
                    priceDetails1?.itemCustomVolumePriceBeansList.length !==
                      0 && (
                        <div className="customTable" id="scroll">
                        ITEM DETAILS
                        <hr />
                        {" "}
                        <table>
                          <thead>
                            <tr>
                              <td>Item Title</td>
                              <td> Price</td>
                              <td>Custom Price</td>
                              <td>Start Quantity</td>
                              <td>End Quantity</td>
                            </tr>
                          </thead>
                          <tbody>
                            {priceDetails1 &&
                              priceDetails1?.itemCustomVolumePriceBeansList.map(
                                (item, i) => (
                                  <tr key={i}>
                                    <td>{item.itemTitle}</td>

                                    <td>{item.price}</td>
                                    <td>{item.customPrice}</td>
                                    <td>{item.startQuantity}</td>
                                    <td>{item.endQuantity}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </TabPanel>

          <TabPanel>
            <table>
              {logData.length == 0 ? (
                "No Changes Found"
              ) : (
                <tr>
                  <td>Date</td>
                  <td>Changes</td>
                </tr>
              )}
              {logData.length >= 1 &&
                logData?.map((history) => {
                  return (
                    <>
                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          {history?.transactionDate?.substring(0, 10)}
                          :&nbsp;&nbsp;
                        </td>

                        <td>
                          {history?.message.split("\n").map((item, i) => (
                            <tr>{item}</tr>
                          ))}
                        </td>
                      </tr>
                      <hr />
                    </>
                  );
                })}
            </table>
          </TabPanel>
        </Tabs>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewPriceList;
