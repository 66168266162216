import React, { Component, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Route, Link, Switch, Routes, useHistory } from "react-router-dom";
import { urlMappingUUID1, getOrgById } from "../../services/index";
import $ from "jquery";
import Cookies from "universal-cookie";

// INVENTORY
import IssuanceList from "../Inventory/IssuanceList";
import IssuanceView from "../Inventory/IssuanceView";
import IssuanceNew from "../Inventory/IssuanceNew";

import ItemList from "../Inventory/ItemList";
import ItemView from "../Inventory/ItemView";
import ItemNew from "../Inventory/ItemNew";

import PriceList from "../Inventory/PriceList";
import PriceListNew from "../Inventory/PriceListNew";
import PriceListView from "../Inventory/PriceListView";

// SALES

import CustomerList from "../Sales/CustomerList";
import CustomerNew from "../Sales/CustomerNew";
import CustomerView from "../Sales/CustomerView";

import SalesNew from "../Sales/SalesNew";
import SalesView from "../Sales/SalesView";
import SalesList from "../Sales/SalesList";

import InvoiceList from "../Sales/InvoiceList";
import InvoiceView from "../Sales/InvoiceView";

import PaySales from "../Sales/PaySales";
import PaySalesList from "../Sales/PaySalesList";
import ShipNew from "../Sales/ShipNew";

// PURCHASE

import BillList from "../Purchase/BillList";
import BillView from "../Purchase/BillView";

import VendorNew from "../Purchase/VendorNew";
import VendorView from "../Purchase/VendorView";
import VendorList from "../Purchase/VendorList";

import PurchaseNew from "../Purchase/PurchaseNew";
import PurchaseView from "../Purchase/PurchaseView";
import PurchaseList from "../Purchase/PurchaseList";

import PayPurchase from "../Purchase/PayPurchase";
import PayPurchaseList from "../Purchase/PayPurchaseList";

// PRODUCTION
import RequisitionList from "../Production/RequisitionList";
import RequisitionView from "../Production/RequisitionView";

import RecipeList from "../Production/RecipeList";
import RecipeNew from "../Production/RecipeNew";
import RecipeView from "../Production/RecipeView";

import BatchList from "../Production/BatchList";
import BatchNew from "../Production/BatchNew";
import BatchView from "../Production/BatchView";

// SETTINGS
import AccessDenied from "./AccessDenied";
import AssignRoles from "../Settings/User&Roles/AssignRoles";

import TermsConditionList from "../Settings/Preferences/TermsConditionList";
import DepartmentList from "../Settings/Preferences/DepartmentList";
import DeliveryPersonList from "../Settings/Preferences/DeliveryPersonList";
import HsnList from "../Settings/Preferences/HsnList";
import TaxExempt from "../Settings/Preferences/TaxExempt";
import PaymentTerms from "../Settings/Preferences/PaymentTerms";

import ViewReports from "../Reports/ViewReports";
import Reports from "../Reports/Reports";

import Warehouses from "../Settings/Warehouses/Warehouses";
import OrganizationProfile from "../Settings/Organization Profile/OrganizationProfile";
import UserAndRoles from "../Settings/User&Roles/UserAndRoles";
import GSTSettings from "../Settings/Taxes/GSTSettings";
import ItemsComponent from "../Settings/Preferences/Items/ItemsComponent";
import SalesOrder from "../Settings/Preferences/Sales Order/SalesOrder";
import Invoices from "../Settings/Preferences/Invoices/Invoices";
import TaxList from "../Settings/Taxes/TaxList";
import OrgList from "../Settings/Organization Profile/OrgList";
import ViewWarehouse from "../Settings/Warehouses/ViewWarehouse";
import UnitList from "../Settings/Preferences/UnitList";
import DefaultUrlList from "../Settings/Preferences/DefaultUrlList";

import Dashboard from "../Dashboard";

const Routing = () => {
  let history = useHistory();
  const [userData, setUserData] = useState("");
  const [urlList, setUrlList] = useState("");
  const [userRights, setUserRights] = useState("");

  useEffect(() => {
    let loginData1 = new Cookies().get("loginData1");
    setUserData(new Cookies().get("loginData1"));
    let id = loginData1.recordId;
    let orgId = loginData1.organisationId;

    urlMappingUUID1(id).then((res) => {
      if (res.errorCode == 0) {
        setUrlList(res.responsePacket.urlList);
        setUserRights(res.responsePacket);
        console.log(res.responsePacket.urlList, "urlList logged in user");
      } else {
        toast("🙁" + res.message);
      }
    });

    getOrgById(orgId).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;
        console.log(d, "d");
        localStorage.setItem("orgDetail", JSON.stringify(d));
      } else {
        toast(res.message);
      }
    });
  }, []);

  return (
    <>
      <div id="main-content">
        <Switch>
          <Route path="/" exact component={Dashboard} />
          {/* SETTINGS */}
          <Route
            path="/settings/orgProfile"
            exact
            component={OrganizationProfile}
          />
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ? (
            <Route path="/settings/userRole" exact component={UserAndRoles} />
          ) : (
            <Route path="/settings/userRole" exact component={AccessDenied} />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ? (
            <Route path="/settings/warehouse" exact component={Warehouses} />
          ) : (
            <Route path="/settings/warehouse" exact component={AccessDenied} />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ? (
            <Route path="/settings/taxes/taxList" exact component={TaxList} />
          ) : (
            <Route
              path="/settings/taxes/taxList"
              exact
              component={AccessDenied}
            />
          )}
          <Route
            path="/settings/warehouse/:id"
            exact
            component={ViewWarehouse}
          />
          <Route
            path="/settings/taxes/taxreturnsettings"
            exact
            component={GSTSettings}
          />
          <Route
            path="/settings/preferences/items"
            exact
            component={ItemsComponent}
          />
          <Route
            path="/settings/preferences/salesorders"
            exact
            component={SalesOrder}
          />
          <Route
            path="/settings/preferences/invoices"
            exact
            component={Invoices}
          />
          <Route
            path="/settings/preferences/DeliveryPersonList"
            exact
            component={DeliveryPersonList}
          />
          <Route
            path="/settings/preferences/TermsConditionList"
            exact
            component={TermsConditionList}
          />
          <Route
            path="/settings/preferences/DepartmentList"
            exact
            component={DepartmentList}
          />
          <Route
            path="/settings/preferences/UnitList"
            exact
            component={UnitList}
          />
          <Route
            path="/settings/preferences/DefaultUrlList"
            exact
            component={DefaultUrlList}
          />
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ? (
            <Route
              path="/settings/userRole/AssignRoles/:id"
              exact
              component={AssignRoles}
            />
          ) : (
            <Route
              path="/settings/userRole/AssignRoles/:id"
              exact
              component={AccessDenied}
            />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ? (
            <Route path="/settings/orgList" exact component={OrgList} />
          ) : (
            <Route path="/settings/orgList" exact component={AccessDenied} />
          )}
          {/* VENDORS */}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/vendor/")) ? (
            <Route path="/inventory/vendors/new" exact component={VendorNew} />
          ) : (
            <Route
              path="/inventory/vendors/new"
              exact
              component={AccessDenied}
            />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/vendor/{uuid}")) ? (
            <Route path="/inventory/vendors/:id" exact component={VendorView} />
          ) : (
            <Route
              path="/inventory/vendors/:id"
              exact
              component={AccessDenied}
            />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/vendor/getAllRecords")) ? (
            <Route path="/inventory/vendors" exact component={VendorList} />
          ) : (
            <Route path="/inventory/vendors" exact component={AccessDenied} />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.urlId === "1235383e-bb66-449e-abce-2be0180ee260"
            )) ? (
            <Route
              path="/inventory/vendors/:id/edit"
              exact
              component={VendorNew}
            />
          ) : (
            <Route
              path="/inventory/vendors/:id/edit"
              exact
              component={AccessDenied}
            />
          )}
          {/* Purchase  */}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/purchaseOrder/")) ? (
            <Route
              path="/inventory/purchase/new"
              exact
              component={PurchaseNew}
            />
          ) : (
            <Route
              path="/inventory/purchase/new"
              exact
              component={AccessDenied}
            />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.url === "/purchaseOrder/getAllRecords"
            )) ? (
            <Route path="/inventory/purchase/" exact component={PurchaseList} />
          ) : (
            <Route path="/inventory/purchase/" exact component={AccessDenied} />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.urlId === "bcf25509-49b7-4629-8244-2940073f027f"
            )) ? (
            <Route
              path="/inventory/purchase/:id/edit"
              exact
              component={PurchaseNew}
            />
          ) : (
            <Route
              path="/inventory/purchase/:id/edit"
              exact
              component={AccessDenied}
            />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/purchaseOrder/{uuid}")) ? (
            <Route
              path="/inventory/purchase/:id"
              exact
              component={PurchaseView}
            />
          ) : (
            <Route
              path="/inventory/purchase/:id"
              exact
              component={AccessDenied}
            />
          )}
          {/* <Route
            path="/inventory/purchaserecieve/:id/"
            exact
            component={PurchaseRecieve}
          /> */}
          <Route
            path="/inventory/PurchasePayment/view/:id"
            exact
            component={PurchaseView}
          />
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.url === "/PurchasePayment/PurchasePayment/{uuid}"
            )) ? (
            <Route
              path="/inventory/PurchasePayment/:id"
              exact
              component={PayPurchase}
            />
          ) : (
            <Route
              path="/inventory/PurchasePayment/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.url === "/PurchasePayment/getAllRecords"
            )) ? (
            <Route
              path="/inventory/PurchasePayment"
              exact
              component={PayPurchaseList}
            />
          ) : (
            <Route
              path="/inventory/PurchasePayment"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {/* BILL */}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/bills/getAllRecords")) ? (
            <Route path="/inventory/bills" exact component={BillList} />
          ) : (
            <Route
              path="/inventory/bills"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.url === "/bills/addDirectBillRecord/"
            )) ? (
            <Route path="/inventory/DirectBill" exact component={PurchaseNew} />
          ) : (
            <Route
              path="/inventory/DirectBill"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/bills/{uuid}")) ? (
            <Route path="/inventory/bills/:id" exact component={BillView} />
          ) : (
            <Route
              path="/inventory/bills/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          <Route
            path="/inventory/convertbill/:id/"
            exact
            component={PurchaseNew}
          />
          {/* batches route */}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/batches/getAllRecords")) ? (
            <Route
              path="/inventory/batches"
              exact
              component={BatchList}
            ></Route>
          ) : (
            <Route
              path="/inventory/batches"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/batches/")) ? (
            <Route
              path="/inventory/batches/new"
              exacts
              component={BatchNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/batches/new"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/batches/{uuid}")) ? (
            <Route
              path="/inventory/batches/:id"
              exact
              component={BatchView}
            ></Route>
          ) : (
            <Route
              path="/inventory/batches/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.urlId === "d7cf0517-05fa-49df-a55f-3896be0ceb22"
            )) ? (
            <Route
              path="/inventory/batches/:id/edit"
              exact
              component={BatchNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/batches/:id/edit"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {/* requisition */}
          <Route
            path="/inventory/requisition"
            exact
            component={RequisitionList}
          ></Route>
          <Route
            path="/inventory/requisition/new"
            exact
            component={IssuanceNew}
          ></Route>
          <Route
            path="/inventory/requisition/:id/view"
            exact
            component={RequisitionView}
          ></Route>
          {/* Recipe Routes*/}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/recipe/getAllRecords")) ? (
            <Route
              path="/inventory/recipe"
              exact
              component={RecipeList}
            ></Route>
          ) : (
            <Route
              path="/inventory/recipe"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/recipe/")) ? (
            <Route
              path="/inventory/recipe/new"
              exact
              component={RecipeNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/recipe/new"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/recipe/{uuid}")) ? (
            <Route
              path="/inventory/recipe/:id"
              exact
              component={RecipeView}
            ></Route>
          ) : (
            <Route
              path="/inventory/recipe/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.urlId === "395696ce-6009-411b-863b-0bfe5f2898d0"
            )) ? (
            <Route
              path="/inventory/recipe/:id/edit"
              exact
              component={RecipeNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/recipe/:id/edit"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {/* sales other */}
          {/* <Route
            path="/inventory/shippingdetails/:id"
            exact
            component={ShippingDetails}
          /> */}
          <Route path="/inventory/newshipping/:id" exact component={ShipNew} />
          {/* <Route
            path="/inventory/newpackage/:id"
            exact
            component={NewPackage}
          /> */}
          {/* sales route*/}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/salesOrder/")) ? (
            <Route
              path="/inventory/salesorders/new"
              exact
              component={SalesNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/salesorders/new"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.urlId === "c109bc3a-2147-4880-80f8-8ab54e7774d3"
            )) ? (
            <Route
              path="/inventory/salesorders/:id/edit"
              exact
              component={SalesNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/salesorders/:id/edit"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/salesOrder/{uuid}")) ? (
            <Route
              path="/inventory/salesorders/:id"
              exact
              component={SalesView}
            ></Route>
          ) : (
            <Route
              path="/inventory/salesorders/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.url === "/salesOrder/getAllRecords"
            )) ? (
            <Route
              path="/inventory/salesorders"
              exact
              component={SalesList}
            ></Route>
          ) : (
            <Route
              path="/inventory/salesorders"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {/* <Route
            path="/inventory/SalesPayment/view/:id"
            exact
            component={ViewPayment}
          /> */}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.url === "/recordPayment/recordPayment/{uuid}"
            )) ? (
            <Route
              path="/inventory/SalesPayment/:id"
              exact
              component={PaySales}
            />
          ) : (
            <Route
              path="/inventory/SalesPayment/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.url === "/recordPayment/getAllRecords"
            )) ? (
            <Route
              path="/inventory/paymentlist"
              exact
              component={PaySalesList}
            ></Route>
          ) : (
            <Route
              path="/inventory/paymentlist"
              exact
              component={AccessDenied}
            ></Route>
          )}{" "}
          {/* INVOICE */}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/invoice/")) ? (
            <Route
              path="/inventory/invoices/new"
              exact
              component={SalesNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/invoices/new"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.url === "/invoice/addDirectInvoiceRecord"
            )) ? (
            <Route
              path="/inventory/directInvoices"
              exact
              component={SalesNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/directInvoices"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/invoice/getAllRecords")) ? (
            <Route
              path="/inventory/invoices"
              exact
              component={InvoiceList}
            ></Route>
          ) : (
            <Route
              path="/inventory/invoices"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/invoice/{uuid}")) ? (
            <Route
              path="/inventory/invoices/:id"
              exact
              component={InvoiceView}
            ></Route>
          ) : (
            <Route
              path="/inventory/invoices/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {/* customer route*/}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/customer/")) ? (
            <Route
              path="/inventory/contacts/new"
              exact
              component={CustomerNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/contacts/new"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/customer/{uuid}")) ? (
            <Route
              path="/inventory/contacts/:id"
              exact
              component={CustomerView}
            ></Route>
          ) : (
            <Route
              path="/inventory/contacts/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/customer/getAllRecords")) ? (
            <Route
              path="/inventory/contacts"
              exact
              component={CustomerList}
            ></Route>
          ) : (
            <Route
              path="/inventory/contacts"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.urlId === "0dabfdcb-b89c-4b30-9c0e-e8c72edc785f"
            )) ? (
            <Route
              path="/inventory/contacts/:id/edit"
              exact
              component={CustomerNew}
            ></Route>
          ) : (
            <Route
              path="/inventory/contacts/:id/edit"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {/* pricelist route */}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/itemPriceList/")) ? (
            <Route
              path="/inventory/pricelists/new"
              exact
              component={PriceListNew}
            />
          ) : (
            <Route
              path="/inventory/pricelists/new"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/itemPriceList/{uuid}")) ? (
            <Route
              path="/inventory/pricelists/:id"
              exact
              component={PriceListView}
            />
          ) : (
            <Route
              path="/inventory/pricelists/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.url === "/itemPriceList/getAllRecords"
            )) ? (
            <Route path="/inventory/pricelists" exact component={PriceList} />
          ) : (
            <Route
              path="/inventory/pricelists"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some(
              (data) => data.urlId === "7d5578a1-149e-4592-b886-9f819eb09228"
            )) ? (
            <Route
              path="/inventory/pricelists/:id/edit"
              exact
              component={PriceListNew}
            />
          ) : (
            <Route
              path="/inventory/pricelists/:id/edit"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {/* items route */}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/items/")) ? (
            <Route path="/inventory/items/new" exact component={ItemNew} />
          ) : (
            <Route path="/inventory/items/new" exact component={AccessDenied} />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/items/{uuid}")) ? (
            <Route path="/inventory/items/:id/edit" exact component={ItemNew} />
          ) : (
            <Route
              path="/inventory/items/:id/edit"
              exact
              component={AccessDenied}
            />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/items/UUID/{uuid}")) ? (
            <Route path="/inventory/items/:id" exact component={ItemView} />
          ) : (
            <Route path="/inventory/items/:id" exact component={AccessDenied} />
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/items/getAllRecords")) ? (
            <Route path="/inventory/items" exact component={ItemList} />
          ) : (
            <Route path="/inventory/items" exact component={AccessDenied} />
          )}
          {/* issuance route */}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList && urlList.some((data) => data.url === "/issuance/")) ? (
            <Route
              path="/inventory/issueitems/new"
              exact
              component={IssuanceNew}
            />
          ) : (
            <Route
              path="/inventory/issueitems/new"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/issuance/getAllRecords")) ? (
            <Route
              path="/inventory/issueitems"
              exact
              component={IssuanceList}
            />
          ) : (
            <Route
              path="/inventory/issueitems"
              exact
              component={AccessDenied}
            ></Route>
          )}
          {userRights.adminRoleEnum == "ROLE_SUPER_ADMIN" ||
          (urlList &&
            urlList.some((data) => data.url === "/issuance/{uuid}")) ? (
            <Route
              path="/inventory/issueitems/:id"
              exact
              component={IssuanceView}
            />
          ) : (
            <Route
              path="/inventory/issueitems/:id"
              exact
              component={AccessDenied}
            ></Route>
          )}
          <Route
            path="/inventory/issueitems/:id/edit"
            exact
            component={IssuanceNew}
          />
          <Route path="/inventory/reports" exact component={Reports} />
          <Route path="/inventory/reports/:id" exact component={ViewReports} />
          <Route
            path="/settings/preferences/TaxExempt"
            exact
            component={TaxExempt}
          />
          <Route path="/settings/preferences/HSN" exact component={HsnList} />
          <Route
            path="/settings/preferences/PaymentTerms"
            exact
            component={PaymentTerms}
          />
        </Switch>
      </div>
    </>
  );
};

export default Routing;
