import { formLabelClasses } from '@mui/material';
import React, { useState } from 'react'
import ContactUsComponent from './ContactUsComponent';
import { addOrganisationSetting } from '../../../../services/index'
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';

const SalesGeneral = () => {
    const [orderClosed, setOrderClosed] = useState('invoice_required');
    const [showContactUs, setShowContactUs] = useState(false)
    const [requestData, setRequestData] = useState([]);
    const [customerNote, setCustomerNote] = useState({
        key: "SALES_ORDER_CUSTOMER_NOTE",
        value: ''
    });
    const [terms, setTerms] = useState({
        key: "SALES_ORDER_TERMS_CONDITION",
        value: ''
    });
    const [salesPrefix, setSalesPrefix] = useState(null);

    const cookies = new Cookies();

    const handleSalesOrder = (e) => {
        setOrderClosed(e.target.value)
    
    }

    // const handleContactUs = () => {
    //     setShowContactUs(true)
    // }



    const handleCustomerNote = (e) => {
        setCustomerNote({
            ...customerNote,
            value: e.target.value
        })
    }

    const handleTermsAndConditions = (e) => {
        setTerms({
            ...terms,
            value: e.target.value
        })
    }

    const handleSaveSalesOrderSettings = () => {
        requestData.push(terms, customerNote)
        let organisationId = cookies.get('loginData1').organisationId
        let dict = {
            "salesPrefix":salesPrefix,
            "organisationId": organisationId,
            "settingsItem": requestData
        }

        addOrganisationSetting(dict).then((res) => {
            if (res.errorCode === 0) {
                toast('👏' + res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        setRequestData([])
    }




    return (
        <>
            <div className="scroll-y noscroll-x  body scrollbox">

                <form className="preferences-body ">
                    <div tabIndex={-1} id="ember1695" className="no-outline ember-view">

                    </div>

                    {/* <div className="form-group row">
                        <div className="col-lg-10">
                            <label>
                                Which of the following fields of Sales Orders do you want to update in
                                the respective Invoices?
                            </label>
                            <div className="form-check">

                                <input
                                    id="a8a5efbde"
                                    className="ember-checkbox ember-view form-check-input"
                                    type="checkbox"
                                />
                                <label className="form-check-label" htmlFor="a8a5efbde">
                                    Address
                                </label>
                            </div>
                            <div className="form-check">

                                <input
                                    id="a934fea15"
                                    className="ember-checkbox ember-view form-check-input"
                                    type="checkbox"
                                />
                                <label htmlFor="a934fea15" className="form-check-label">
                                    Customer Notes
                                </label>
                            </div>
                            <div className="form-check">

                                <input
                                    id="a5f8e7ac6"
                                    className="ember-checkbox ember-view form-check-input"
                                    type="checkbox"
                                />
                                <label htmlFor="a5f8e7ac6" className="form-check-label">
                                    Terms &amp; Conditions
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <label>When do you want your Sales Orders to be closed?</label>

                        <fieldset
                            onChange={handleSalesOrder}
                        >
                            <div className="form-check">
                                <input
                                    name='closed'
                                    defaultChecked
                                    className="ember-view form-check-input"
                                    type="radio"
                                    Value="invoice_required"
                                />
                                <label className="form-check-label" htmlFor="a8fdca16a">
                                    When invoice is created
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    name='closed'
                                    className="ember-view form-check-input"
                                    type="radio"
                                    Value="shipment_required"
                                />
                                <label className="form-check-label" htmlFor="ae0a36547">
                                    When shipment is fulfilled
                                </label>
                            </div>
                            <div className="form-check">

                                <input
                                    name='closed'
                                    className="ember-view form-check-input"
                                    type="radio"
                                    Value="invoice_and_shipment_required"
                                />
                                <label className="form-check-label" htmlFor="a54bd2d43">
                                    When shipment is fulfilled and invoice is created
                                </label>
                            </div>
                        </fieldset>
                    </div>
                    <hr />

                    <div className="form-group row mb-0">
                        <label className="col-lg-9">
                            <span className="font-medium">Automate the Sales Order Cycle</span>
                            <span className="beta-label">BETA</span>
                            <div className="text-void mt-2">
                                Transactions like invoices, packages, and shipments can be created
                                automatically as soon as you execute a sales order cycle, which can be
                                done after creating a sales order. You will also be able to override
                                the cycle with different options or skip it entirely for each sales
                                order.
                            </div>
                        </label>
                        <div className="ml-auto col-lg-auto">
                            <a href="#"
                                onClick={handleContactUs}>
                                Request Access
                            </a>
                        </div>
                    </div>
                    <hr /> */}

                    <div className="form-group row">
                        <div className="col-lg-11">
                            <label className="font-medium">Sales PreFix.</label>
                            <input
                                placeholder="Enter the Sales Pre-Fix"
                                id="ember1697"
                                className="ember-text-area ember-view form-control"
                                name='salesPrefix'
                                value={salesPrefix}
                                onChange={(e) => setSalesPrefix(e.target.value)}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                        <div className="col-lg-11">
                            <label className="font-medium">Terms &amp; Conditions</label>
                            <textarea
                                rows={9}
                                placeholder="Enter the terms and conditions of your business to be displayed in your transaction"
                                id="ember1697"
                                className="ember-text-area ember-view form-control"
                                name='SALES_ORDER_TERMS_CONDITION'
                                value={terms.value}
                                onChange={(e) => handleTermsAndConditions(e)}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                        <div className="col-lg-11">
                            <label className="font-medium">Customer Notes</label>
                            <textarea
                                rows={9}
                                placeholder="Enter any notes to be displayed in your transaction"
                                id="ember1698"
                                className="ember-text-area ember-view form-control"
                                name='SALES_ORDER_CUSTOMER_NOTE'
                                value={customerNote.value}
                                onChange={(e) => handleCustomerNote(e)}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="mt-7">
                        <a
                            id="ember1701"
                            className="btn btn-primary ember-view"
                            type="submit"
                            onClick={handleSaveSalesOrderSettings}
                        >
                            Save
                        </a>
                    </div>
                </form>

            </div>
            <ContactUsComponent
                showContactUs={showContactUs}
                setShowContactUs={setShowContactUs}
            />
            <ToastContainer />

        </>
    )
}

export default SalesGeneral