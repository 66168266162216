import {
  ORGANISATION_URL,
  IMAGE_UPLOAD_URL,
  ADMIN_URL,
  URL_MAPPING,
  URL,
  MODULE_URL,
  DEF_URL_MAPPING,
} from "../CONSTANTS";
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData1");

const headersdata = {
  "content-type": "application/json",
  Accept: "application/json",
  Authorization: cook != undefined ? cook.accessToken : "",
};

export const moduleKeyValue1 = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(MODULE_URL() + "keyValueList", { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in moduleKeyValueList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const urlMappingUUID1 = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(URL_MAPPING() + id, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const addUrlMapping1 = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(URL_MAPPING(), data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const addDefaultUrls = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(DEF_URL_MAPPING(), data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in post defaultRoleUrlMapping axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const getDefaultUrls = (role) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(DEF_URL_MAPPING() + "urlListByModuleForRole/" + role, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in defaultRoleUrlMapping axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const urlAllRecords1 = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(URL() + "urlListByModule", { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in urlAllRecords axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const urlKeyValueList1 = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(URL() + "keyValueList", { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getAdminUserDetails = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ADMIN_URL() + "userDetails/" + id, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

// ----------------------
export const updateOrgList = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(ORGANISATION_URL() + id, data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in updateOrgList axios!");
        });
    } catch (error) {
      console.error("in LoginService > updateOrgList, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getOrgList = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ORGANISATION_URL() + "getAllRecords/", data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAllRecords axios!");
        });
    } catch (error) {
      console.error("in LoginService > getAllRecords, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

// /organisation/getRecordById/{id}
export const getOrgById = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ORGANISATION_URL() + "getRecordById/" + id, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in uuid axios!");
        });
    } catch (error) {
      console.error("in LoginService > uuid, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getOrgUUID = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ORGANISATION_URL() + id, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in uuid axios!");
        });
    } catch (error) {
      console.error("in LoginService > uuid, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getOrgKeyValue = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ORGANISATION_URL() + "getKeyValueList", { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getKeyValueList axios!");
        });
    } catch (error) {
      console.error("in LoginService > getKeyValueList, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const addOrganisationProfile = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ORGANISATION_URL(), data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in addOrganisation axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const uploadImage = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(IMAGE_UPLOAD_URL() + "uploadFile", data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in addOrganisation axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getAdminRole = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ADMIN_URL() + "userTypes", { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in userTypes axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const addNewAdmin = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ADMIN_URL(), data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in addNewAdmin axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
// /admin/setResetPassword/{uuid}

export const setAdminPassword = (uuid, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(ADMIN_URL() + "setResetPassword/" + uuid, data, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in setResetPassword axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getAdminList = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ADMIN_URL() + "list", { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminList axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const getAdminDetails = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(ADMIN_URL() + id, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const updateAdmin = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(ADMIN_URL() + id, data, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
// s
export const deleteAdmin = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(ADMIN_URL() + id, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const activateAdmin = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(ADMIN_URL() + "activate/" + id, {}, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const deactivateAdmin = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(ADMIN_URL() + "deactivate/" + id, {}, { headers: headersdata })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in getAdminDetails axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
