import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";

import Tooltip from "@mui/material/Tooltip";
import { toast, ToastContainer } from "react-toastify";

import { useHistory, Link } from "react-router-dom";
import {
  getInvoiceDetailsByUUID,
  getInvoicesList,
  logInvoiceHistory,
} from "../../services/index";

import ViewPdf from "./ViewPdf";
import AddIcon from "@mui/icons-material/Add";
import pyramidLogo from "../../image-gallery/pyramidLogo.png";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";

const InvoiceView = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [keys, setKeys] = useState("");
  const [values, setValues] = useState("");

  const [hsnKey, setHsnKey] = useState("");
  const [hsnVal1, setHsnVal1] = useState("");
  const [hsnVal2, setHsnVal2] = useState("");

  const [taxType, setTaxType] = useState("");
  const [orgDetail, setOrgDetail] = useState("");
  const [logData, setLogData] = useState({});
  const [invoiceOrdersList, setInvoiceOrdersList] = useState([]);
  const [invoiceOrderDetail, setInvoiceOrderDetail] = useState(null);

  let history = useHistory();

  const [rows, setRows] = useState([]);
  const getAllInvoiceOrders = () => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getInvoicesList(list_data).then((res) => {
      if (res.errorCode == 0) {
        res.responsePacket.data.reverse();

        res.responsePacket.data.map((a)=>{
          let localDueDate = new Date(a.dueDate)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");

          a.dueDate=localDueDate;

        })

      

        setInvoiceOrdersList(res.responsePacket.data);
        setRows(res.responsePacket.data);
      } else {
        toast(res.message);
      }
    });
  };

  useEffect(() => {
    console.log(invoiceOrderDetail, "invoiceOrderDetail");
  }, [invoiceOrderDetail]);

  const pushTaxElements = (orderDetailTax, orderDetailPdfTax) => {
    // setInvoiceOrderDetailTaxesHtml(orderDetailTax);
    // setInvoiceOrderDetailPdfTaxesHtml(orderDetailPdfTax);
  };

  const getInvoiceDetailsByUUIDs = (id) => {
    getInvoiceDetailsByUUID(id).then((res) => {
      if (res.errorCode == 0) {
        let abc = res.responsePacket.customerAddresses;
        let d = res.responsePacket.itemsList;

        var tot = JSON.parse(JSON.stringify(res.responsePacket.totalAmount));
        let t1 = tot.toFixed(0);
        let t2 = tot.toFixed(2).toString()?.split(".")[1];

        if (t2 > 49) {
          tot += 1;
          let a=100-t2
          res.responsePacket.rounding = "+0." + a;
          res.responsePacket.totalAmount=tot
        } else {
          res.responsePacket.rounding = "-0." + t2;
        }

        d.map((a) => {
          a.taxBean.newTax = a.taxBean.taxType + a.taxBean.rate.toString();
        });
        let obj = {};
        for (let i = 0; i < d.length; i++) {
          if (obj[d[i].taxBean.newTax]) {
            obj[d[i].taxBean.newTax] += d[i].taxBean.amount;
          } else {
            obj[d[i].taxBean.newTax] = d[i].taxBean.amount;
          }
        }

        setKeys(Object.keys(obj));
        setValues(Object.values(obj));

        let z = JSON.parse(JSON.stringify(d));

        z.map((a) => {
          a.newHsn = a.hsnSac.toString() + a.taxBean.newTax;
        });

        let obj2 = {};
        for (let i = 0; i < z.length; i++) {
          if (obj2[z[i].newHsn]) {
            obj2[z[i].newHsn] += z[i].taxBean.amount;
          } else {
            obj2[z[i].newHsn] = z[i].taxBean.amount;
          }
        }

        let obj3 = {};
        for (let i = 0; i < z.length; i++) {
          if (obj3[z[i].newHsn]) {
            obj3[z[i].newHsn] += z[i].amount;
          } else {
            obj3[z[i].newHsn] = z[i].amount;
          }
        }

        setHsnKey(Object.keys(obj2));
        setHsnVal1(Object.values(obj2));
        setHsnVal2(Object.values(obj3));

        logInvoiceHistory(res.responsePacket.invoiceNo).then((res) => {
          if (res.errorCode == 0) {
            const d = res.responsePacket;
            setLogData(d);
          } else {
            toast(res.message);
          }
        });

        let netTax = 0;
        d.map((a) => {
          netTax += a.taxBean.amount;
        });

        console.log(netTax, "netTax");
        res.responsePacket.netTax = netTax;

        let localDate = new Date(res.responsePacket.salesOrderDate)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");

        let localDate2 = new Date(res.responsePacket.invoiceDate)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");

        let localDate3 = new Date(res.responsePacket.dueDate)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("-");

        res.responsePacket.salesOrderDate = localDate;
        res.responsePacket.invoiceDate = localDate2;
        res.responsePacket.dueDate = localDate3;

        setInvoiceOrderDetail(res.responsePacket);

        let org = JSON.parse(localStorage.getItem("orgDetail"));
        setOrgDetail(org);
        if (org?.beanAddress?.state == undefined) {
          alert("logged In user's organization addres not found ");
        }

        let gst12cust = res.responsePacket.customerGstNumber.substring(0, 2);
        let gst12org = org.gstNumber.substring(0, 2);
        if (gst12cust == gst12org) {
          setTaxType("intra");
        } else {
          setTaxType("inter");
        }

        var taxArray = [];
        var invoiceOrderTaxesArrayHtml = [];
        var invoiceOrderPdfTaxesArrayHtml = [];
        res.responsePacket.itemsList &&
          res.responsePacket.itemsList.map((item, index) => {
            if (item.taxUuid != null) {
              if (item.taxUuid.trim() != "") {
                var taxDict = {
                  key: item.taxTitle,
                  value: item.taxUuid,
                  rate: item.taxRate,
                  itemAmount: item.amount,
                };
                taxArray.push(taxDict);
              }
            }
          });
        const uniqueIds = [];
        const uniqueTax = taxArray.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.value);
          if (!isDuplicate) {
            uniqueIds.push(element.value);
            return true;
          }
        });
        const uniqueTaxItemsIds = [];
        const uniqueTaxItemAmounts = [];
        const uniqueTaxItemsAmount = taxArray.filter((element) => {
          const isDuplicate = uniqueTaxItemsIds.includes(element.value);
          if (!isDuplicate) {
            uniqueTaxItemsIds.push(element.value);
            uniqueTaxItemAmounts.push(element.itemAmount);
            return true;
          } else {
            var duplicateIndex = uniqueTaxItemsIds.indexOf(element.value);
            uniqueTaxItemAmounts[duplicateIndex] =
              uniqueTaxItemAmounts[duplicateIndex] + element.itemAmount;
          }
        });
        uniqueTax.map((i, index) => {
          var amount = uniqueTaxItemAmounts[index];
          var taxAmount = (amount * i.rate) / 100;
          var html = (
            <div className="row text-muted">
              <div className="col-lg-6 col-md-6">{i.key}</div>
              <div className="col-lg-6 col-md-6 over-flow">
                ₹ {taxAmount.toFixed(2)}
              </div>
            </div>
          );
          var htmlPdf = (
            <tr style={{ height: "10px" }}>
              <td valign="middle" align="right">
                {i.key}
              </td>
              <td valign="middle" style={{ width: "110px" }}>
                ₹ {taxAmount.toFixed(2)}
              </td>
            </tr>
          );
          invoiceOrderTaxesArrayHtml.push(html);
          invoiceOrderPdfTaxesArrayHtml.push(htmlPdf);
          if (index + 1 == uniqueTax.length) {
            pushTaxElements(
              invoiceOrderTaxesArrayHtml,
              invoiceOrderPdfTaxesArrayHtml
            );
          }
        });
      } else {
        toast(res.message);
      }
    });
  };

  useEffect(() => {
    getAllInvoiceOrders();
    getInvoiceDetailsByUUIDs(props.match.params.id);
  }, [props.match.params.id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: invoiceOrderDetail?.invoiceNo,
    pageStyle: "print",
  });

  const handleSearch = (e) => {
    const result = rows.filter((a) => {
      return (
        a?.customerTitle?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a?.salesOrderNo?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a?.invoiceNo?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a?.warehouseTitle?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a?.invoiceDate?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a?.dueDate?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a?.motorVehicleNo?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a?.customerPan?.toLowerCase().match(e.target.value.toLowerCase()) ||
        a?.salespersonTitle
          ?.toLowerCase()
          .match(e.target.value.toLowerCase()) ||
        a?.priceListTitle?.toLowerCase().match(e.target.value.toLowerCase())
      );
    });
    setInvoiceOrdersList(result);
  };

  const filterOptions = [
    { value: "isPaid", label: "isPaid" },
    { value: "isPacked", label: "isPacked" },
    { value: "isShipped", label: "isShipped" },
    { value: "allOrders", label: "allOrders" },
  ];

  const handleFilter = (e) => {
    if (e.value == "isPaid") {
      var arr1 = rows.filter((data) => data.isPaid);
      setInvoiceOrdersList(arr1);
    }
    if (e.value == "isShipped") {
      var arr1 = rows.filter((data) => data.isShipped);
      setInvoiceOrdersList(arr1);
    }

    if (e.value == "isPacked") {
      var arr3 = rows.filter((data) => data.isPacked);
      setInvoiceOrdersList(arr3);
    }
    if (e.value == "allOrders") {
      var arr3 = rows.filter((data) => data.salesOrderNo);
      setInvoiceOrdersList(arr3);
    }
  };

  return (
    <>
      <div
        className="list-column column d-print-none"
        style={{ width: "330px" }}
      >
        <div className="fill list-header topgrad d-print-none">
          <div className="list-filter ">
            <div className="btn-toolbar float-right">
              <div
                id="ember1958"
                className="ember-view btn btn-primary d-flex align-items-center"
                onClick={() =>
                  history.push({
                    pathname: `/inventory/directInvoices`,
                    state: { directInvoice: true },
                  })
                }
              >
                <AddIcon />
                &nbsp;&nbsp;New
              </div>
            </div>

            <div
              id="ember635"
              className="dropdown ember-view clearfix list-title"
            >
              <a
                id="ember636"
                className="dropdown-toggle no-caret ember-view  "
              >
                <span className="filter-title over-flow ">All Invoices</span>
                &nbsp;
              </a>
            </div>
          </div>
        </div>

        <div className="scroll-y fill body scrollbox list-body">
          <div className="table-responsive overflow-initial ">
            <table
              id="ember7912"
              className="table zi-table  ember-view header-fixed"
            >
              <tbody>
                {invoiceOrdersList &&
                  invoiceOrdersList.map((d, i) => (
                    <Link to={`/inventory/invoices/${d.recordId}`}>
                      <div>
                        <tr>
                          <td>{d.customerTitle}</td>
                        </tr>
                        <tr
                          style={{
                            padding: "8px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>{d.invoiceNo}</div>
                          <div>₹ {d.amount.toFixed(2)}</div>{" "}
                          <div>due:{d.dueDate}</div>
                        </tr>
                      </div>
                      
                    </Link>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="column content-column" style={{ left: "330px" }}>
        <div className="fill1 header d-flex align-items-center p-4 justify-content-between">
          <div className="btn-toolbar over-flow text-muted font-xlarge">
            <span className="page-header-title">
              {invoiceOrderDetail?.invoiceNo}
            </span>{" "}
            &nbsp; &nbsp;
            <span style={{ cursor: "pointer" }} onClick={() => handleShow()}>
              Log History
            </span>
          </div>
          <div className="btn-toolbar d-flex justify-content-end align-items-center">
            <SearchRoundedIcon />
            <input
              className="ember-text-field ember-view form-control col-2"
              type="text"
              placeholder="Search Here"
              onChange={handleSearch}
              style={{ minWidth: "150px" }}
            />
            <Select
              placeholder="Filter Orders"
              options={filterOptions}
              onChange={handleFilter}
              style={{ minWidth: "150px" }}
            />
            <div className="btn-group">
              <button
                className="tooltip-container ember-view btn btn-primary print-btn"
                onClick={handlePrint}
              >
                <LocalPrintshopOutlinedIcon />
              </button>
            </div>
            {invoiceOrderDetail?.isPaid ? (
              <></>
            ) : (
              <>
                <button
                  className="tooltip-container ember-view btn btn-primary"
                  onClick={() =>
                    history.push({
                      pathname:
                        "/inventory/SalesPayment/" +
                        invoiceOrderDetail.recordId,
                      state: { id: invoiceOrderDetail.recordId },
                    })
                  }
                  type="button"
                >
                  <Tooltip title="Pay">
                    <PaymentIcon />
                  </Tooltip>
                </button>
              </>
            )}
            {invoiceOrderDetail?.isShipped ? (
              <></>
            ) : (
              <>
                <button
                  className="tooltip-container ember-view btn btn-primary"
                  onClick={() =>
                    history.push({
                      pathname:
                        "/inventory/newshipping/" + invoiceOrderDetail.recordId,
                      state: { id: invoiceOrderDetail.recordId },
                    })
                  }
                  type="button"
                >
                  <Tooltip title="Ship">
                    <LocalShippingRoundedIcon />
                  </Tooltip>
                </button>
              </>
            )}
            <Button variant="contained">E-Way</Button>
          </div>
        </div>
        <br />
        {invoiceOrderDetail && invoiceOrderDetail && orgDetail && orgDetail ? (
          <ViewPdf
            orderDetail={invoiceOrderDetail}
            address={invoiceOrderDetail?.customerAddresses}
            itemList={invoiceOrderDetail?.itemsList}
            orgDetail={orgDetail}
            taxType={taxType}
            componentRef={componentRef}
            pdfType={"invoice"}
            keys={keys}
            values={values}
            hsnKey={hsnKey}
            hsnVal1={hsnVal1}
            hsnVal2={hsnVal2}
          />
        ) : (
          <></>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <table>
            {logData.length == 0 ? (
              "No Changes Found"
            ) : (
              <tr>
                <td>Date</td>
                <td>Changes</td>
              </tr>
            )}
            {logData.length >= 1 &&
              logData?.map((history) => {
                return (
                  <>
                    <tr>
                      <td style={{ verticalAlign: "top" }}>
                        {history?.transactionDate?.substring(0, 10)}
                        :&nbsp;&nbsp;
                      </td>

                      <td>
                        {history?.message.split("\n").map((item, i) => (
                          <tr>{item}</tr>
                        ))}
                      </td>
                    </tr>
                    <hr />
                  </>
                );
              })}
          </table>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default InvoiceView;
