import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component'

const SalesCustom = () => {


    const columns = [
        {
            name: "CUSTOM STATUS FOR",
            selector: (data) => data.salesOrderDate,
        },
        {
            name: "STATUS NAME",
            selector: (data) => data.orderNo,
        },
        {
            name: "DESCRIPTION",
            selector: (data) => data.orderNo,
        },
        {
            name: "LABEL COLOR",
            selector: (data) => data.orderNo,
        },

    ];

    useEffect(() => {
        // let list_data = {
        //   page: 0,
        //   size: -1,
        //   search: "",
        // };
        // SalesOrder.getAllSalesOrder(list_data).then((res) => {
        //   if (res.data.errorCode == 0) {
        //     const d = res.data.responsePacket.data;
        //     setData(d);
        //   } else {
        //     alert(res.data.message);
        //   }
        // });
    }, []);

    return (
        <>
            <div className="scroll-y noscroll-x fill body scrollbox ">
                <div className="fill-container">
                    <div style={{ height: 500, width: "100%" }}>
                        <DataTable
                            columns={columns}
                            // data={data}
                            theme="light"
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="500px"
                            pointerOnHover={true}
                            highlightOnHover={true}
                        // onRowClicked={(data) => {
                        //   setState(true);
                        //   setDataId(data.recordId);
                        // }}
                        />
                        {/* {redirecting} */}
                    </div>
                </div>
                <div showpagelengthoption="true" id="ember1102" className="ember-view">
                    <div className="pagination clearfix d-print-none">  </div>
                </div>
            </div>
        </>
    )
}

export default SalesCustom