import { BILL_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook !== undefined ? cook.accessToken : ''
}

// /bills/addDirectBillRecord/

export const addDirectBillRecord1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(BILL_URL()+"addDirectBillRecord/", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
}; 


export const getBillByVendorId1 = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(BILL_URL()+"getBillByVendorId/", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
}; 



// PUT /bills/PurchasePayment/{uuid}  payment update matching input
export const PurchasePaymentBillOrder = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(BILL_URL()+id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};



// DELETE http://192.168.1.3:8001/bills/94731496-5cf0-4b19-a360-545b3e99cd9d
export const deleteBillOrder = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(BILL_URL(),+id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

// POST http://192.168.1.3:8001/bills/ add new
export const addBillOrder = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(BILL_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
}; 


// PUT http://192.168.1.3:8001/bills/http%3A%2F%2F192.168.1.3%3A8001%2Fbill%2F94731496-5cf0-4b19-a360-545b3e99cd9d update existing ,matching input
export const updateBillOrder = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(BILL_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    }); 
};


// POST http://192.168.1.3:8001/bills/getAllRecords fetch all
export const getAllBillRecords = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(BILL_URL() + "getAllRecords", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getItemList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

// GET http://192.168.1.3:8001/bills/94731496-5cf0-4b19-a360-545b3e99cd9d fetch specific
export const getBillDetailsByUUID = (id) => { 
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(BILL_URL() + id, { headers: headersdata })
                .then((res) => {                   
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getBillDetails axios!");
                    
                });
        } catch (error) {
            console.error("in LoginService > getBillDetails, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


// GET http://192.168.1.3:8001/bills/getKeyValueList fetch key value list
export const getBillKeyValuePair = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(BILL_URL() + "getKeyValueList", { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getBillKeyValuePair axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};