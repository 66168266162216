import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast, ToastContainer } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Header } from "antd/lib/layout/layout";
import PreferencesNav from "../Preferences/PreferencesNav";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AddIcon from "@mui/icons-material/Add";
import {
  addDepartment1,
  updateDepartment1,
  departmentList1,
  departmentUUID1,
  deliveryPersonList1,
  addDeliveryPerson,
  deliveryPersonUUID1,
  editDeliveryPerson1,
  activeInactiveDept,
} from "../../../services/index";

const DepartmentList = () => {
  let history = useHistory();
  const [deptName, setDeptName] = useState("");

  const [success, setSuccess] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setDeptName("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [edit, setEdit] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);

  const [dataId, setDataId] = useState(false);

  const columns = [
    {
      name: "TITLE",
      cell: (row) => row.title.toUpperCase(),
      sortable: true,
    },{
      name: "Action",
      selector: (row) => (
        <div>
          {/* <EditIcon
            style={{ fontSize: "15px", cursor: "pointer" }}
            onClick={() => {
              history.push("/inventory/vendors/" + row.recordId + "/edit");
            }}
          />&nbsp;&nbsp; */}
          {row.active ? (
            <CheckCircleIcon
              style={{
                fontSize: "15px",
                cursor: "pointer",
                color: "greenyellow",
              }}
              onClick={() => handleActiveInactive(row)}
            />
          ) : (
            <CancelIcon
              style={{ fontSize: "15px", cursor: "pointer", color: "tomato" }}
              onClick={() => handleActiveInactive(row)}
            />
          )}
        </div>
      ),
    },
  ];



  const handleActiveInactive = (row) => {
    console.log(row, "row");
    activeInactiveDept(row.recordId).then((res) => {
      if (res.errorCode == 0) {
        setSuccess(!success)
        toast(res.message, { autoClose: 2000 });
      } else {
        toast(res.message, { autoClose: 2000 });
        return;
      }
    });
  };

  const handleSave = () => {
    if (deptName == "") {
      toast("Please fill Department name", {
        autoClose: 2000,
      });
      return;
    }

    const data1 = {
      title: deptName,
    };

    edit
      ? updateDepartment1(dataId, data1).then((res) => {
          if (res.errorCode == 0) {
            setSuccess(true);
            setDeptName("");
            handleClose();
            toast(res.message, {
              autoClose: 2000,
            });
          } else {
            toast(res.message, {
              autoClose: 2000,
            });
            return;
          }
        })
      : addDepartment1(data1).then((res) => {
          if (res.errorCode == 0) {
            toast(res.message, {
              autoClose: 2000,
            });
            handleClose();
            setSuccess(true);
          } else {
            toast(res.message, {
              autoClose: 2000,
            });
            return;
          }
        });
  };

  const handleClicked = (id) => {
    departmentUUID1(id).then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        setDeptName(res.responsePacket.title);
      } else {
        toast(res.message, {
          autoClose: 2000,
        });
        return;
      }
    });

    handleShow();
    setEdit(true);
  };

  useEffect(() => {
    var data = {
      page: 0,
      search: "",
      size: -1,
    };

    departmentList1(data).then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        setRows(temp);
        setFilteredData(temp);
      } else {
        toast(res.message, {
          autoClose: 2000,
        });
        return;
      }
    });
  }, [success]);

  useEffect(() => {
    const result = rows.filter((data) => {
      return data.title.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(result);
  }, [search]);

  return (
    <>
    <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                <Header>Department Info</Header>

                <br />
                <hr />

                <table>
                  <tr>
                    <td>Department Name:</td>
                    <td>
                      {" "}
                      <input
                        className="ember-text-field ember-view form-control"
                        type="text"
                        onChange={(e) => {
                          setDeptName(e.target.value);
                        }}
                        value={deptName}
                      />
                    </td>
                  </tr>
                </table>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="primary" onClick={handleSave}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
      <div className="column list-column d-print-none column-small">
        <PreferencesNav />
      </div>
      <div className="column content-column">
        <div className="header clearfix" style={{ borderBottom: "none" }}>
          <span className="float-left" style={{ fontSize: "35px" }}>
            Department List
          </span>
        </div>

        <div className="fill-container">
          <div className="list-filter">
            
            <div
              id="ember1611"
              className="dropdown ember-view clearfix list-title"
            ></div>
          </div>
        </div>

        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            columns={columns}
            data={filteredData}
            fixedHeader
            pointerOnHover={true}
            highlightOnHover={true}
            onRowClicked={(data) => {
              setDataId(data.recordId);
              handleClicked(data.recordId);
            }}
            subHeader
            subHeaderComponent={
              <>
                {" "}
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder={"Search here"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ margin: "10px" }}
                />
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                  onClick={handleShow}
                >
                  <AddIcon />
                  New
                </button>
              </>
            }
            subHeaderAlign="right"
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default DepartmentList;
