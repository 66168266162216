import React from "react";
import { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  // getKeyValueListOfPackageByInvoiceId,
  getInvoiceDetailsByUUID,
  createShipping,
  getCustomerKeyValuePair,
  KeyValueDeliveryPersonList,
  createPackage,
  getInvoicesList,
  getItemKeyValueDetailsList,
  addInvoice,
  updateInvoiceDetailsByUUID,
} from "../../services/index";

// state variables ---------------------------------

const ShipNew = (props) => {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date1 = curr.toISOString().substring(0, 10);

  const ShipOptions = [
    { value: "InTransit", label: "InTransit" },
    { value: "Delivered", label: "Delivered" },
    { value: "Returned", label: "Returned" },
  ];
  const ShipperOptions = [
    { value: "BlueDart", label: "BlueDart" },
    { value: "Zomato", label: "Zomato" },
    { value: "IndiaPost", label: "IndiaPost" },
  ];

  const [deliveryKey, setDeliveryKey] = useState({});
  const [deliveryGuy, setDeliveryGuy] = useState("");
  const [dId, setDId] = useState("");
  const [dPhone, setDPhone] = useState("");

  const [shippingStatusOptions, setShippingStatusOptions] = useState([]);
  const [shipperName, setShipperName] = useState("");
  const [shippingStatus, setShippingStatus] = useState("");
  const [packDetails, setPackDetails] = useState({});
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [packageDetails, setPackageDetails] = useState("");
  const [packageId, setPackageId] = useState("");
  const [newCustomer, setNewCustomer] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [isPacked, setIsPacked] = useState({});
  const [packagingDate, setPackagingDate] = useState(date1);
  const [itemTitle, setItemTitle] = useState("");
  const [itemId, setItemId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [ordered, setOrdered] = useState("");
  const [packedQuantity, setPackedQuantity] = useState({});

  const [warehouseId, setWarehouseId] = useState("");
  const [packageItem, setPackageItem] = useState(null);
  const [requestData, setRequestData] = useState({
    itemList: [],
  });

  const [invoiceDate, setInvoiceDate] = useState("");

  const [customerNameKeyValue, setCustomerNameKeyValue] = useState([]);
  const [dataId, setDataId] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [redirectState, setRedirectState] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [salesOrderDetail, setSalesOrderDetail] = useState(null);

  let history = useHistory();

  // functions defined ---------------------------------

  const handleSubmitPackageData = async (e) => {
    let shippingRequest = {
      isShipped: true,
      shipperName: shipperName.value,
      salesShippingStatus: shippingStatus.value,

      deliveryPersonId: dId,
      deliveryPersonName: deliveryGuy,
      deliveryPersonNumber: dPhone,

      invoiceId: invoiceId,
      invoiceNo: invoiceNo,
      shippingDate: packagingDate,
      shippingItemList: [],
    };

    invoiceDetails.itemsList.map((item, i) => {
      let cart = {
        itemId: item.recordId,
        itemTitle: item.itemTitle,
        quantity: item.quantity,
      };

      shippingRequest.shippingItemList.push(cart);
    });

    createShipping(shippingRequest).then((res) => {
      if (res.errorCode == 0) {
        setPackageDetails(res.responsePacket.data);
        history.push("/inventory/invoices/");
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  //  API defined --------------------

  const GetInvoiceDetails = (id) => {
    if (id != null) {
      getInvoiceDetailsByUUID(id).then((res) => {
        if (res.errorCode == 0) {
          setInvoiceDetails(res.responsePacket);

          setCustomerName(res.responsePacket.customerTitle);
          setInvoiceNo(res.responsePacket.invoiceNo);
          setInvoiceDate(res.responsePacket.invoiceDate);
          setInvoiceId(res.responsePacket.recordId);
          setWarehouseId(res.responsePacket.warehouseId);

          setItemId(res.responsePacket.itemsList[0].recordId);
          setItemTitle(res.responsePacket.itemsList[0].itemTitle);
          setQuantity(res.responsePacket.itemsList[0].quantity);
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    }
  };

  const handleSelectCustomerName = (e) => {
    // setInvoiceNo("");
    setCustomerName(e);
  };

  const KeyValueDeliveryPersonList12 = () => {
    KeyValueDeliveryPersonList().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.recordId,
          label: d.title,
          email: d.email,
          phone: d.phone,
        }));

        setDeliveryKey(options);
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const CustomerKeyValuePair = () => {
    getCustomerKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
          title: d.value2,
        }));

        setNewCustomer(options);
      } else {
        toast(res.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  useEffect(() => {
    KeyValueDeliveryPersonList12();
    CustomerKeyValuePair();
    GetInvoiceDetails(props.match.params.id);
  }, []);

  const handleDGuy = (e) => {
    setDeliveryGuy(e.label);
    setDPhone(e.phone);
    setDId(e.value);
  };

  return (
    <>
      <div style={{ paddingLeft: "20px" }}>
        <div className="fill list-header d-flex  align-items-center bg-white p-3 border-bottom">
          New Shipment
        </div>
        <br />
        <div style={{display:"flex",alignItems:"flex-start"}}>
        <table className="customTable">
          <tr>
            <td>Customer:</td>
            <td>
              <input className="form-control" value={customerName} />
            </td>
          </tr>
          <tr>
            <td>Invoice:</td>
            <td>
              <input className="form-control" value={invoiceNo} />
            </td>
          </tr>
          <tr>
            <td>Shipment Date:</td>
            <td>
              <input
                className="form-control"
                disabled={true}
                value={packagingDate}
                onChange={(e) => setPackagingDate(e.target.value)}
              />
            </td>
          </tr>

          <tr>
            <td>Driver:</td>
            <td>
              <Select
                placeholder={deliveryGuy}
                options={deliveryKey}
                onChange={(e) => handleDGuy(e)}
                value={deliveryGuy}
              />
            </td>
          </tr>
          {deliveryGuy ? (
            <tr>
              <td>Driver Phone</td>
              <td>
                <input 
                className="form-control"
                value={dPhone} />
              </td>
            </tr>
          ) : (
            <></>
          )}

          <tr>
            <td>Note:</td>
            <td>
              <input className="form-control" />
            </td>
          </tr>
        </table>
         &nbsp;
        <table>
          <tr>
            <td>ITEM</td>
            <td>QUANTITY </td>
          </tr>
          <tbody>
            {invoiceDetails?.itemsList?.map((item, i) => (
              <tr key={i}>
                <td>
                  <input
                    className=" form-control"
                    disabled="true"
                    value={item.itemTitle}
                  />
                </td>
                <td>
                  <input
                    className="form-control"
                    disabled="true"
                    onChange={(e) => setQuantity(e.target.value)}
                    value={item.quantity}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        </div>
       


        <br />
        <button
          id="ember1426"
          className="btn btn-primary ember-view"
          type="button"
          onClick={() => handleSubmitPackageData()}
        >
          Save
        </button>
      </div>
      {/* )} */}
    </>
  );
};

export default ShipNew;
