import { LOGIN_URL, REQUEST_PUBLIC_KEY } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'

export const login = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(LOGIN_URL(), data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in signIn axios!");
                });
        } catch (error) {
            console.error("in userServices > signIn, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};
export const getRequestPublicKey = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(REQUEST_PUBLIC_KEY())
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getRequestPublicKey axios!");
                });
        } catch (error) {
            console.error("in LoginService > getRequestPublicKey, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};