import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import DataTable, {
  createTheme,
  FilterComponent,
  Export,
} from "react-data-table-component";

import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import { getItemList, getIssuanceRecords } from "../../services/index";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const IssuanceList = () => {
  let history = useHistory();

  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(10);

  const [redirState, setRedirState] = useState(false);
  const [dataId, setDataId] = useState(false);

  const columns = [
    {
      name: "Issuance No.",

      cell: (row) => row.issuanceCode,
      sortable: true,
    },
    {
      name: "Requisition No.",

      cell: (row) => row?.requisitionCode,
      sortable: true,
    },
    {
      name: "Issuance Date",
      selector: (row) => row.issuanceDate,
      sortable: true,
    },

    {
      name: "Issued By",
      selector: (row) => row.issuedByUserTitle,
      sortable: true,
    },
    // issuedDepartmentTitle
    {
      name: "Department",
      selector: (row) => row.issuedDepartmentTitle,
      sortable: true,
    },
  ];

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  let redirecting = redirState ? (
    <Redirect push to={`/inventory/issueitems/${dataId}`} />
  ) : (
    ""
  );

  useEffect(() => {
    var data = {
      page: 0,
      search: "",
      size: -1,
      // size:page,
    };

    getIssuanceRecords(data).then((res) => {
      if (res.errorCode == 0) {
        var temp = res.responsePacket.data;
        temp.reverse();
        setRows(temp);
        setFilteredData(temp);
      } else {
        toast("🙁" + res.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }, []);

  useEffect(() => {
    const result = rows.filter((data) => {
      return (
        data.issuedToUserTitle.toLowerCase().match(search.toLowerCase()) ||
        data.issuedByUserTitle.toLowerCase().match(search.toLowerCase()) ||
        data.issuedDepartmentTitle.toLowerCase().match(search.toLowerCase()) ||
        data.issuanceDate.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  return (
    <>
      <div style={{ height: 500, width: "100%" }}>
        <DataTable
          title="Issuance List"
          columns={columns}
          data={filteredData}
          theme="light"
          pagination
          defaultSortAsc={true}
          // defaultSortFieldId={"Ino"}
          fixedHeader
          pointerOnHover={true}
          highlightOnHover={true}
          onRowClicked={(data) => {
            setRedirState(true);
            setDataId(data.recordId);
          }}
          subHeader
          subHeaderComponent={
            <>
              {" "}
              <SearchRoundedIcon />
              <input
                className="ember-text-field ember-view form-control col-2"
                type="text"
                placeholder={"Search here"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ margin: "10px" }}
              />
              <Link to="/inventory/issueitems/new">
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  type="button"
                >
                  <AddIcon />
                  New
                </button>
              </Link>
            </>
          }
          subHeaderAlign="right"
        />

        {redirecting}
      </div>
      <ToastContainer />
    </>
  );
};

export default IssuanceList;
