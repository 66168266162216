export const LOADING= "LOADING";
//user constant
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";

//discussion Constant
export const CONTECT_ADD = "CONTECT_ADD";
export const CHECK_RATE = "CHECK_RATE";
export const STATE = "STATE";



