import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import AddIcon from "@mui/icons-material/Add";
import { useHistory, Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import ArticleIcon from "@mui/icons-material/Article";
import Cookies from "universal-cookie";

import {
  getCustomerKeyValuePair,
  getSalePersonDetailKeyValuePair,
  getTermsConditionKeyValue,
  getWarehouseKeyValueDetailsList,
  getInterStateTaxKeyValueList,
  getIntraStateTaxKeyValueList,
  ItemListByWarePrice1,
  getCustomerDetails,
  getSalesOrderDetail,
  addSalePerson,
  updateSalesOrder,
  addSalesOrder,
  addDirectInvoiceRecord1,
  addInvoice,
  getOrgById,
} from "../../services/index";

const SalesNew = (props) => {
  let directInvoice = props?.location?.state?.directInvoice;
  let salesId = props?.match?.params?.id;
  let invoiceId = props?.location?.state?.invoiceId;

  console.log(directInvoice, "directInvoice");
  console.log(salesId, "salesId");
  console.log(invoiceId, "invoiceId");

  let history = useHistory();
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date1 = curr.toISOString().substring(0, 10);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [salesPerson, setSalesPerson] = useState({
    name: "",
    email: "",
  });
  const [orgDetail, setOrgDetail] = useState("");
  const [deletedItem, setDeletdItem] = useState("");
  const [salesInfo, setSalesInfo] = useState({
    customer: "",
    warehouse: "",
    salesPerson: "",
    taxType: "",
    reference: "",
    orderDate: date1,
    orgDetail: "",
    shipDate: date1,
    dueDate: date1,
    notes: "",
    terms: "",
    subTotal: 0.0,
    total: 0.0,
    shipCharge: "",
    netShip: 0.0,
    netTax: 0,
    shipTax: "",
    motorVehicleNo: "",
  });

  const tempRows = {
    item: {
      value: "",
      label: "",
    },
    quantity: 1,
    taxBean: "",
    rate: 0,
    amount: 0,
    isFloating: false,
    itemTitle: "",
    itemUuid: "",
    deleted: false,
  };

  const [rows, setRows] = useState([tempRows]);

  const [wareOptions, setWareOptions] = useState("");
  const [customerOptions, setCustomerOptions] = useState("");
  const [salesPerOptions, setSalesPerOptions] = useState("");
  const [termsOptions, setTermsOptions] = useState("");
  const [itemOptions, setItemOptions] = useState("");
  const [itemOptions2, setItemOptions2] = useState("");
  const [taxOptions, setTaxOptions] = useState("");

  useEffect(() => {
    callBasicAPI();
    if (salesId !== undefined) {
      getSalesOrderDetails(salesId);
    }
    if (invoiceId !== undefined) {
      getSalesOrderDetails(invoiceId);
    }
    let loginData1 = new Cookies().get("loginData1");
    let orgId = loginData1.organisationId;
    getOrgById(orgId).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;
        console.log(d, "d");
        setOrgDetail(d);
        if (d.beanAddress == undefined) {
          alert("logged In user's organization address not found ");
        }
      } else {
        toast(res.message);
      }
    });
  }, []);

  const getSalesOrderDetails = (Id) => {
    getSalesOrderDetail(Id).then((res) => {
      if (res.errorCode === 0) {
        let d = res.responsePacket;
        let itemList = res.responsePacket.items;
        let a = res.responsePacket.customerAddresses;
        let wId = d.warehouseId;
        let pId = d.priceListId;
        let edit = true;
        handleGetItems(wId, pId, edit);

        let customer = {
          recordId: d.customerId,
          title: d.customerTitle,
          value: d.customerId,
          label: d.customerTitle,
          addressList: a,
          phone: d.customerMobileNumber,
          itemPriceListTitle: d.priceListTitle,
          itemPriceListRecordId: d.priceListId,

          paymentTermRecordId: d.paymentTermsId,
          paymentTermTitle: d.paymentTermsTitle,
        };
        let warehouse = { value: d.warehouseId, label: d.warehouseTitle };
        let terms = { value: d.termsConditionId, label: d.termsCondition };
        let salesPerson = { value: d.salespersonId, label: d.salespersonTitle };
        let shipTax = {
          value: d.shippingChargeTaxId,
          label: d.shippingChargeTaxTitle,
          rate: d.shippingChargeTaxRate,
        };
        let netTax = 0;
        itemList.map((c) => {
          netTax += c.taxBean.amount;
        });

        console.log(netTax);
        // note:  d.shippingCharges=netShip
        // so we need to calculate shipCharge i.e without tax

        let shipCharge =
          d.shippingCharges / (1 + d.shippingChargeTaxRate * 0.01);

        setSalesInfo({
          ...salesInfo,
          customer: customer,
          warehouse: warehouse,
          salesPerson: salesPerson,
          terms: terms,
          shipTax: shipTax,
          shipCharge: Number(shipCharge.toFixed(2)),
          netTax: netTax,

          reference: d.referenceNo,
          orderDate: d.salesOrderDate,
          shipDate: d.expectedShipmentDate,
          notes: d.customerNote,
          subTotal: d.subTotalAmount,
          total: d.totalAmount,
          netShip: d.shippingCharges,

          orderNo: d?.orderNo,
          salesOrderId: d?.recordId,
          taxType: "",
        });
        let tempRows = [];

        itemList.map((c) => {
          let obj = {
            step: c.stepOrder,
            item: {
              label: c.itemTitle,
              value: c.itemUuid,
            },
            itemUuid: c.itemUuid,
            itemTitle: c.itemTitle,
            isFloating: c?.isFloating,
            amount: c.amount,
            quantity: c.quantity,
            taxBean: c.taxBean,
            rate: c.rate,
            hsnSac: c.hsnSac,
            hsnUuid: c.hsnUuid,
          };
          tempRows.push(obj);
        });
        setRows(tempRows);
      } else {
        handleToast(res);
      }
    });
  };

  const handleAddNewRow = () => {
    setRows([...rows, tempRows]);
    handleFilterItems();
  };

  const handleClear = (id, i) => {
    //     var deepCopy = JSON.parse(JSON.stringify(rows));
    //     console.log(deepCopy[i],"deepCopy[i]")
    //      deepCopy[i].deleted=true
    //     setDeletdItem({...deletedItem,deepCopy})
    //     console.log(deletedItem,"del")

    const obj = rows.slice();
    obj.splice(i, 1);
    setRows(obj);

    let sum2 = 0;
    let netTax = 0;
    let netShip = salesInfo.netShip;
    obj &&
      obj.map((data) => {
        netTax += data.taxBean.amount;
        sum2 += data.amount;
        setSalesInfo({
          ...salesInfo,
          netTax: netTax,
          subTotal: sum2.toFixed(2),
          total: (sum2 + netShip + netTax).toFixed(2),
        });
      });

    let filterOptions = [...itemOptions];
    itemOptions2 &&
      itemOptions2.map((a) => {
        if (a.value === id) {
          filterOptions.push(a);
        }
      });
    setItemOptions(filterOptions);
  };

  const handleToast = (res) => {
    toast(res.message);
  };

  const callBasicAPI = () => {
    getCustomerKeyValuePair().then((res) => {
      if (res.errorCode === 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setCustomerOptions(options);
      } else {
        handleToast(res);
      }
    });

    getWarehouseKeyValueDetailsList().then((res) => {
      if (res.errorCode === 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setWareOptions(options);
        console.log(options,"op")
        setSalesInfo({...salesInfo,warehouse:options[0]})
      } else {
        handleToast(res);
      }
    });

    getTermsConditionKeyValue().then((res) => {
      if (res.errorCode == 0) {
        const options = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        options.map((a) => {
          setSalesInfo({
            ...salesInfo,
            terms: a,
          });
        });
        setTermsOptions(options);
      } else {
        handleToast(res);
      }
    });
    getSalesKeyValue();
  };

  const getSalesKeyValue = () => {
    getSalePersonDetailKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
          email: d.value2,
        }));
        let button = {
          value: "-1",
          isDisabled: true,
          label: (
            <div onClick={() => setShow(true)}>
              <SettingsIcon color="primary" /> Manage Salespersons
            </div>
          ),
        };
        options.push(button);

        setSalesPerOptions(options);
      } else {
        handleToast(res);
      }
    });
  };

  const freshCustomer = (aa, d1, taxType) => {
    setItemOptions("");
    setItemOptions2("");
    setRows([tempRows]);
    setSalesInfo({
      ...salesInfo,
      taxType: taxType,
      customer: d1,
      warehouse: "",
      shipTax: aa[0],
      netTax: 0,
      subTotal: 0,
      netShip: 0,
      shipCharge: 0,
      total: 0,
    });
  };

  const handleCustomer = (e) => {
    getCustomerDetails(e.value).then((res) => {
      if (res.errorCode == 0) {
        let d1 = res.responsePacket;
        d1.value = d1.recordId;
        d1.label = d1.title;

        let orgShipAdd = orgDetail?.beanAddress?.state;
        let shipAdd = d1.addressList.filter(
          (a) => a.addressTypeEnum == "Shipping"
        );

        console.log(shipAdd[0].state, orgShipAdd, "shipAdd  orgShipAdd");

        if (shipAdd[0].state == orgShipAdd) {
          getIntraStateTaxKeyValueList().then((res) => {
            let d = res.responsePacket;
            const options = d.map((a) => ({
              label: a.title,
              value: a.recordId,
              rate: a.rate,
              taxType: a.taxType,
            }));
            const aa = options.filter(
              (c) => c.taxType == "GST" && c.rate == 18.0
            );
            console.log(aa, "aa1");
            setTaxOptions(options);
            freshCustomer(aa, d1, "intra");
          });
        } else {
          getInterStateTaxKeyValueList().then((res) => {
            let d = res.responsePacket;
            const options = d.map((a) => ({
              label: a.title,
              value: a.recordId,
              rate: a.rate,
              taxType: a.taxType,
            }));
            const aa = options.filter(
              (c) => c.taxType == "IGST" && c.rate == 18.0
            );
            console.log(aa, "aa2");
            setTaxOptions(options);
            freshCustomer(aa, d1, "inter");
          });
        }
      } else {
        handleToast(res);
      }
    });
  };

  const handleWarehouse = (e) => {
    setSalesInfo({ ...salesInfo, warehouse: e });
    let pId = salesInfo.customer.itemPriceListRecordId;
    let wId = e.value;
    handleGetItems(wId, pId);
  };

  const handleGetItems = (wId, pId, edit) => {
    ItemListByWarePrice1(wId, pId).then((res) => {
      if (res.errorCode == 0) {
        let d = res.responsePacket;

        if (invoiceId) {
          d.filter((a) => a.currentStockQuantity > 0);
        }
        const options = d.map((a) => ({
          value: a.recordId,
          label:a.title,
          // label: (
          //   <>
          //     <div>{a.title}</div>
          //     <div>
          //       stock: {a.currentStockQuantity} rate: {a.sellingPriceValue}
          //     </div>
          //   </>
          // ),
          itemId: a.recordId,
          title: a.title,
          taxBean:
            salesInfo.taxType == "inter" ? a.interstateTax : a.intrastateTax,
          isFloating: a.isFloating,
          rate: a.sellingPriceValue,
        }));
        setItemOptions(options);
        setItemOptions2(options);
      } else {
        handleToast(res);
      }
    });
  };

  console.log(itemOptions, "setItemOptions");
  const handleData = (e, key) => {
    setSalesInfo({
      ...salesInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleTerms = (e) => {
    setSalesInfo({
      ...salesInfo,
      terms: e,
    });
  };

  useEffect(() => {
    console.log(salesInfo, "salesInfo");
    console.log(rows, "rows");
  }, [salesInfo, rows]);
  console.log(orgDetail, "setOrgDetail");

  const handleItem = (e, i) => {
    console.log(e, "e");
    const obj = [...rows];
    obj[i].item.value = e.value;
    obj[i].item.label = e.title;
    obj[i].rate = e.rate;
    obj[i].isFloating = e.isFloating;
    obj[i].itemUuid = e.value;
    obj[i].itemTitle = e.title;
    obj[i].taxBean = e.taxBean;
    obj[i].amount = e.rate * obj[i].quantity;
    obj[i].taxBean.amount = e.rate * e.taxBean.rate * 0.01;

    setRows(obj);
    handleSubTotal();
    handleFilterItems();
  };

  const handleFilterItems = () => {
    var send = false;
    var filterOptions = [];
    itemOptions2 &&
      itemOptions2.map((a) => {
        rows &&
          rows.map((b) => {
            if (a.value === b?.item?.value) {
              send = true;
            }
          });
        if (!send) {
          filterOptions.push(a);
        }
        send = false;
      });
    setItemOptions(filterOptions);
  };

  const handleSubTotal = () => {
    let sum2 = 0;
    let netTax = 0;
    let netShip = salesInfo.netShip;
    rows &&
      rows.map((data) => {
        netTax += data.taxBean.amount;
        sum2 += data.amount;
        setSalesInfo({
          ...salesInfo,
          netTax: netTax,
          subTotal: sum2.toFixed(2),
          total: (sum2 + netShip + netTax).toFixed(2),
        });
      });
  };
  const handlequantity = (e, i) => {
    const obj = [...rows];
    obj[i].quantity = e.target.value;
    obj[i].amount = obj[i].rate * e.target.value;
    obj[i].taxBean.amount = obj[i].amount * obj[i].taxBean.rate * 0.01;
    setRows(obj);
    handleSubTotal();
  };

  const handleRate = (e, i) => {
    const obj = [...rows];
    obj[i].rate = e.target.value;
    obj[i].amount = obj[i].quantity * e.target.value;
    obj[i].taxBean.amount = obj[i].amount * obj[i].taxBean.rate * 0.01;
    setRows(obj);
    handleSubTotal();
  };

  const handleShipCharge = (e) => {
    let ShipCharge = Number(e.target.value);
    let netShip = ShipCharge + ShipCharge * salesInfo?.shipTax?.rate * 0.01;
    let netTotal = Number(salesInfo.subTotal) + netShip + salesInfo.netTax;
    setSalesInfo({
      ...salesInfo,
      netShip: netShip,
      shipCharge: ShipCharge,
      total: netTotal,
    });
  };
  const handleShipTax = (e) => {
    let netShip = salesInfo.shipCharge + salesInfo.shipCharge * e.rate * 0.01;
    let netTotal = Number(salesInfo.subTotal) + netShip + salesInfo.netTax;

    setSalesInfo({
      ...salesInfo,
      shipTax: e,
      netShip: netShip,
      total: netTotal,
    });
  };

  const handleSave = (e) => {
    if (salesInfo.customer == "") {
      toast("Select customer");
      return;
    }
    if (salesInfo.salesPerson == "") {
      toast("Select salesPerson");
      return;
    }
    if (salesInfo.warehouse == "") {
      toast("Select warehouse");
      return;
    }
    if (salesInfo.terms == "") {
      toast("Select terms");
      return;
    }

    rows.map((a) => {
      if (salesId) {
        a.deleted = false;
      }
    });

    let rows2 = rows.filter((a) => a.item.value != "");
    if (rows2.length == 0) {
      toast("Select item");
      return;
    }
    if ((directInvoice || invoiceId) && salesInfo.motorVehicleNo == "") {
      toast("Select Vehicle No.");
      return;
    }

    let salesRequest = {
      items: rows2,
      customerId: salesInfo.customer.recordId,
      customerTitle: salesInfo.customer.title,
      customerMobileNumber: salesInfo?.customer?.phone,
      customerNote: salesInfo.notes,
      expectedShipmentDate: salesInfo.shipDate,
      salesOrderDate: salesInfo.orderDate,

      paymentTermsId: salesInfo.customer.paymentTermRecordId,
      paymentTermsTitle: salesInfo.customer.paymentTermTitle,

      priceListId: salesInfo.customer.itemPriceListRecordId,
      priceListTitle: salesInfo.customer.itemPriceListTitle,

      subTotalAmount: Number(salesInfo?.subTotal).toFixed(2),
      termsCondition: salesInfo.terms.label,
      termsConditionId: salesInfo.terms.value,

      totalAmount: Number(salesInfo?.total).toFixed(2),
      warehouseId: salesInfo.warehouse.value,
      warehouseTitle: salesInfo.warehouse.label,
      referenceNo: salesInfo.reference,

      salespersonId: salesInfo.salesPerson.value,
      salespersonTitle: salesInfo.salesPerson.label,
      shippingCharges: salesInfo?.netShip,
      shippingChargeTaxRate: salesInfo?.shipTax?.rate,
      shippingChargeTaxId: salesInfo?.shipTax?.value,
      shippingChargeTaxTitle: salesInfo?.shipTax?.label,

      hsnId: null,
      orderNo: "SO",
      salesOrderStatusEnum: "Confirmed",
      isInvoiced: false,
      isDelivered: false,
      isPacked: false,
      isPaid: false,
      isShipped: false,
    };
    if (invoiceId) {
      salesRequest.motorVehicleNo = salesInfo.motorVehicleNo;
      salesRequest.invoiceNo = "IN";
      salesRequest.orderNo = "SO";
      salesRequest.salesOrderNo = salesInfo?.orderNo;
      salesRequest.salesOrderId = salesInfo?.salesOrderId;
      salesRequest.invoiceDate = salesInfo?.orderDate;
      salesRequest.dueDate = salesInfo?.dueDate;
      salesRequest.itemsList = rows2;
    }

    if (directInvoice) {
      salesRequest.motorVehicleNo = salesInfo.motorVehicleNo;
      salesRequest.dueDate = salesInfo?.dueDate;
    }

    console.log(salesRequest, "salesRequest 123");

    if (directInvoice) {
      addDirectInvoiceRecord1(salesRequest).then((res) => {
        if (res.errorCode == 0) {
          history.push("/inventory/invoices");
          handleToast(res);
        } else {
          handleToast(res);
        }
      });
    } else {
      if (invoiceId) {
        addInvoice(salesRequest).then((res) => {
          if (res.errorCode == 0) {
            history.push("/inventory/invoices");
            handleToast(res);
          } else {
            handleToast(res);
          }
        });
      } else {
        if (salesId) {
          updateSalesOrder(salesId, salesRequest).then((res) => {
            if (res.errorCode == 0) {
              history.push("/inventory/salesorders");
              handleToast(res);
            } else {
              handleToast(res);
            }
          });
        } else {
          addSalesOrder(salesRequest).then((res) => {
            if (res.errorCode == 0) {
              history.push("/inventory/salesorders");
              handleToast(res);
            } else {
              handleToast(res);
            }
          });
        }
      }
    }
  };

  const saveSalesPerson = () => {
    let data = {
      title: salesPerson.name,
      email: salesPerson.email,
    };

    addSalePerson(data).then((res) => {
      if (res.errorCode == 0) {
        toast(res.message);
        handleClose();
        getSalesKeyValue();
      } else {
        toast(res.message);
      }
    });
  };

  return (
    <>
      <div className="fill list-header d-flex justify-content-between align-items-center bg-white p-3 border-bottom">
        <h3 className="float-left">
          <ArticleIcon />
          &nbsp;&nbsp;
          {directInvoice
            ? "DIRECT INVOICE"
            : invoiceId
            ? "NEW INVOICE"
            : salesId
            ? "EDIT SALES ORDER"
            : "NEW SALES ORDER"}
          &nbsp;&nbsp;{salesInfo?.orderNo}
        </h3>
      </div>

      <div
        className="scroll-y noscroll-x fill body scrollbox"
        style={{ marginLeft: "15px" }}
      >
        <div className="item-creation" autoComplete="off">
          <div className="primary-info white-bg">
            <div className="item-new">
              <div className="row">
                <div className="col-lg-8">
                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Customer
                    </label>

                    <div className="col-lg-5">
                      <Select
                        isDisabled={invoiceId ? true : false}
                        onChange={(e) => handleCustomer(e)}
                        options={customerOptions}
                        value={salesInfo.customer}
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Warehouse
                    </label>
                    <div className="col-lg-5">
                      <Select
                        isDisabled={invoiceId ? true : false}
                        onChange={(e) => handleWarehouse(e)}
                        options={wareOptions}
                        value={salesInfo.warehouse}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Order Date
                    </label>
                    <div className="col-lg-5">
                      <input
                        disabled={invoiceId ? true : false}
                        className="form-control"
                        type="date"
                        name="orderDate"
                        onChange={(e) => handleData(e)}
                        value={salesInfo.orderDate}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Shipment Date
                    </label>
                    <div className="col-lg-5">
                      <input
                        disabled={invoiceId ? true : false}
                        className="form-control"
                        type="date"
                        name="shipDate"
                        onChange={(e) => handleData(e)}
                        value={salesInfo.shipDate}
                      />
                    </div>
                  </div>
                  {/* {invoiceId || directInvoice ? (
                    <div className="row form-group">
                      <label className="col-lg-3 col-form-label required">
                        Due Date
                      </label>
                      <div className="col-lg-5">
                        <input
                          disabled={invoiceId?true:false}
                          className="form-control"
                          type="date"
                          name="dueDate"
                          onChange={(e) => handleData(e)}
                          value={salesInfo.dueDate}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )} */}

                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      SalesPerson
                    </label>
                    <div className="col-lg-5">
                      <Select
                        isDisabled={invoiceId ? true : false}
                        onChange={(e) =>
                          setSalesInfo({
                            ...salesInfo,
                            salesPerson: e,
                          })
                        }
                        value={salesInfo.salesPerson}
                        options={salesPerOptions}
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-lg-3 col-form-label required">
                      Terms
                    </label>

                    <div className="col-lg-5">
                      <Select
                        isDisabled={invoiceId ? true : false}
                        onChange={(e) => handleTerms(e)}
                        options={termsOptions}
                        value={salesInfo.terms}
                      />
                    </div>
                  </div>
                  {directInvoice || invoiceId ? (
                    <div className="row form-group">
                      <label className="col-lg-3 col-form-label required ">
                        Vehicle No.
                      </label>
                      <div className="col-lg-5">
                        <input
                          className="form-control"
                          name="motorVehicleNo"
                          onChange={(e) => handleData(e)}
                          value={salesInfo.motorVehicleNo}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  {salesInfo?.customer?.addressList ? (
                    <table>
                      <tr>
                        <td style={{ minWidth: "100px" }}>Billing:</td>
                        <td>{salesInfo?.customer?.addressList[0]?.state}</td>
                      </tr>
                      <tr>
                        <td>Shipping:</td>
                        <td>{salesInfo?.customer?.addressList[1]?.state}</td>
                      </tr>
                      <tr>
                        <td>PriceList:</td>
                        {salesInfo?.customer?.itemPriceListTitle}
                      </tr>
                      <tr>
                        <td>Pay Terms:</td>
                        {salesInfo?.customer?.paymentTermTitle}
                      </tr>
                      {/* <tr>
                        <td>Org State:</td>
                        {orgDetail?.beanAddress?.state}
                      </tr> */}
                      <tr>
                        <td>TaxType:</td>
                        {salesInfo?.taxType}
                      </tr>
                    </table>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <br />
            <hr />

            <div className="customTable">
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ minWidth: "100px" }}>Item</td>
                  <td>quantity</td>
                  <td>Tax</td>
                  <td>Rate</td>
                  <td>Amount</td>
                  <td></td>
                </tr>
                {rows &&
                  rows.map((data, i) => {
                    return (
                      <tr>
                        <td style={{ minWidth: "250px" }}>
                          <Select
                            isDisabled={invoiceId ? true : false}
                            style={{ zIndex: "20" }}
                            placeholder="Item"
                            options={itemOptions}
                            value={data.item}
                            onChange={(e) => handleItem(e, i)}
                          />
                        </td>
                        <td>
                          <input
                            disabled={invoiceId ? true : false}
                            type="number"
                            min={1}
                            value={data.quantity}
                            onChange={(e) => handlequantity(e, i)}
                          />
                        </td>
                        <td>{data.taxBean?.title}</td>

                        <td>
                          <input
                            disabled={
                              data.isFloating && !invoiceId ? false : true
                            }
                            type="number"
                            value={data.rate}
                            onChange={(e) => handleRate(e, i)}
                          />
                        </td>
                        <td>{data.amount?.toFixed(2)}</td>
                        {invoiceId ? (
                          <></>
                        ) : (
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear(data.item.value, i)}
                          >
                            {rows.length > 1 && <i className="fa fa-times"></i>}
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </table>
            </div>
            {invoiceId ? (
              <></>
            ) : (
              <button
                id="ember1426"
                className="btn btn-default addbtn mt-3"
                onClick={handleAddNewRow}
              >
                <AddIcon style={{ color: "blue" }} /> New Row
              </button>
            )}

            <br />
            <br />
            <div className="row" style={{ justifyContent: "space-between" }}>
              <table className="customTable">
                <tr>
                  <td>Notes</td>
                  <td>
                    {" "}
                    <textarea
                      className="form-control"
                      name="notes"
                      onChange={(e) => handleData(e)}
                      value={salesInfo.notes}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Reference</td>
                  <td>
                    {" "}
                    <input
                      className="form-control"
                      name="reference"
                      onChange={(e) => handleData(e)}
                      value={salesInfo.reference}
                    />
                  </td>
                </tr>
              </table>

              <table className="customTable" style={{ paddingRight: "20px" }}>
                <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td></td>
                  <td>{Number(salesInfo?.subTotal).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Shipping</td>
                  <td>
                    <input
                      disabled={invoiceId ? true : false}
                      type="number"
                      value={salesInfo.shipCharge}
                      onChange={(e) => handleShipCharge(e)}
                    />
                  </td>
                  <td>
                    <Select
                      isDisabled={true}
                      onChange={(e) => handleShipTax(e)}
                      options={taxOptions}
                      value={salesInfo.shipTax}
                    />
                  </td>
                  <td>{Number(salesInfo?.netShip).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Total Tax</td>
                  <td></td>
                  <td></td>
                  <td>{Number(salesInfo?.netTax).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>{Number(salesInfo?.total).toFixed(2)}</td>
                </tr>
              </table>
            </div>

            <div className="text-left mt-3 mb-4">
              <button
                className="btn bg-primary text-white mr-2"
                onClick={(e) => handleSave(e)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h4 className="modal-title">Manage Salespersons</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            <tr>
              <td>Name</td>
              <td>
                <input
                  className="ember-text-field ember-view form-control"
                  value={salesPerson.name}
                  onChange={(e) =>
                    setSalesPerson({
                      ...salesPerson,
                      name: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>
                <input
                  className="ember-text-field ember-view form-control"
                  value={salesPerson.email}
                  onChange={(e) =>
                    setSalesPerson({
                      ...salesPerson,
                      email: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              {" "}
              <button
                className="btn btn-info ember-view btn-sm"
                onClick={saveSalesPerson}
              >
                Save
              </button>
            </tr>
          </table>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default SalesNew;
