import React, { useState, useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import DataTable from "react-data-table-component";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { useHistory, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import PreferencesNav from "../Settings/Preferences/PreferencesNav";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import Reports from "./Reports";
import {
  getItemDetailKeyValuePair,
  getSalesOrderByCustomerId1,
  getSalesOrderBySalesPersonId1,
  getSalesOrderByItemId1,
  getPurchaseOrderByVendorId1,
  getPurchaseOrderByItemId1,
  getBillByVendorId1,
  getInvoiceDetailReport1,
  getBelowOrderLevelItemReport1,
  getRecordByDepartmentId1,
  getInventorySummary1,
} from "../../services/index";

const ViewReports = (props) => {
  let history = useHistory();
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date1 = curr.toISOString().substring(0, 10);

  const [item, setItem] = useState("");
  const [itemOptions, setItemOptions] = useState("");

  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [resPacket, setResPacket] = useState([]);
  const [startDate, setStartDate] = useState(date1);
  const [endDate, setEndDate] = useState(date1);
  const [title, setTitle] = useState("");
  const [column, setColumn] = useState([]);

  const [printDiv, setPrintDiv] = useState(false);

  useEffect(() => {
    getItemDetailKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        const options = res.responsePacket.map((d) => ({
          label: d.value,
          value: d.key,
        }));
        setItemOptions(options);
      } else {
        toast(res.message);
      }
    });

    setSearch({ value: props.match.params.id, label: props.match.params.id });
  }, [props.match.params.id]);

  const handleSearchKv = (e) => {
    let arr = [];
    rows.filter((rItem) => {
      let doSend = false;
      rItem.items.map((iData) => {
        if (iData.itemTitle.toLowerCase().includes(e.toLowerCase())) {
          doSend = true;
        }
      });
      if (doSend) {
        arr.push(rItem);
      }
    });
    setResPacket(arr);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint1 = () => {
    setPrintDiv(true);
    var doc = new jsPDF("p", "pt", "a2"); //potrait
    // var doc = new jspdf('l', 'pt', "a4");  // For landscape
    doc.html(document.getElementById("pdf"), {
      callback: function (doc) {
        doc.save(search.value);
      },
    });

    setTimeout(() => {
      setPrintDiv(false);
    }, 2000);
  };

  const handleSearchData = (s) => {
    console.log(s, "eeee");
    let arr1 = rows.filter((a) => {
      return (
        a.billDate?.toLowerCase().match(s.toLowerCase()) ||
        a.salesOrderDate?.toLowerCase().match(s.toLowerCase()) ||
        a.purchaseOrderDate?.toLowerCase().match(s.toLowerCase()) ||
        a.invoiceDate?.toLowerCase().match(s.toLowerCase()) ||
        a.billNo?.toLowerCase().match(s.toLowerCase()) ||
        a.orderNo?.toLowerCase().match(s.toLowerCase()) ||
        a.purchaseOrderNo?.toLowerCase().match(s.toLowerCase()) ||
        a.invoiceNo?.toLowerCase().match(s.toLowerCase()) ||
        a.salesOrderNo?.toLowerCase().match(s.toLowerCase()) ||
        a.customerTitle?.toLowerCase().match(s.toLowerCase()) ||
        a.warehouseTitle?.toLowerCase().match(s.toLowerCase()) ||
        a.itemTitle?.toLowerCase().match(s.toLowerCase()) ||
        a.issuedByUserTitle?.toLowerCase().match(s.toLowerCase()) ||
        a.issuedDepartmentTitle?.toLowerCase().match(s.toLowerCase()) ||
        a.issuedToUserTitle?.toLowerCase().match(s.toLowerCase()) ||
        a.vendorTitle?.toLowerCase().match(s.toLowerCase())
      );
    });
    setResPacket(arr1);
  };

  const handleReportSearch = (e) => {
    var request = {
      startDate: startDate,
      endDate: endDate,
    };
    if (
      (item.value && search.value === "PurchasesbyItem") ||
      (item.value && search.value === "SalesbyItem")
    ) {
      request.itemId = item.value;
    }

    console.log(startDate, "aaa");

    if (search.value === "SalesbyCustomer") {
      getSalesOrderByCustomerId1(request).then((res) => {
        setResPacket(res.responsePacket);
        setRows(res.responsePacket);
        setTitle("Sales by Customer");
        setColumn([
          {
            name: "ORDER DATE",
            selector: (row) => row.salesOrderDate,
            sortable: true,
          },
          {
            name: "CUSTOMER NAME",
            selector: (row) => row.customerTitle,
            sortable: true,
          },
          {
            name: "ORDER NO. ",
            selector: (row) => row.orderNo,
            sortable: true,
          },
          {
            name: "TOTAL AMOUNT ",
            selector: (row) => Number(row.totalAmount)?.toFixed(2),
            sortable: true,
          },
        ]);
      });
    }

    if (search.value === "SalesbyItem") {
      getSalesOrderByItemId1(request).then((res) => {
        if (res.errorCode == 0) {
        } else {
          toast(res.message, { autoClose: 2000 });
          return;
        }
        setResPacket(res.responsePacket);
        setRows(res.responsePacket);
        setTitle("Sales by Item");
        setColumn([
          {
            name: "ORDER NO.",
            selector: (row) => row.salesOrderDate,
            sortable: true,
          },
          {
            name: "CUSTOMER NAME",
            selector: (row) => row.customerTitle,
            sortable: true,
          },
          {
            name: "ORDER NO. ",
            selector: (row) => row.orderNo,
            sortable: true,
          },
          {
            name: "TOTAL AMOUNT ",
            selector: (row) => Number(row.totalAmount)?.toFixed(2),
            sortable: true,
          },
          {
            name: "ITEMS",
            selector: (row) => (
              <>
                {row.items?.map((abc, i) => {
                  return (
                    <>
                      <div>{abc.itemTitle} </div>
                      <br />
                    </>
                  );
                })}
              </>
            ),
            sortable: true,
          },
        ]);
      });
    }

    if (search.value === "SalesbySalesPerson") {
      getSalesOrderBySalesPersonId1(request).then((res) => {
        setResPacket(res.responsePacket);
        setRows(res.responsePacket);
        setTitle("Sales by Sales Person");
        setColumn([
          {
            name: "ORDER DATE",
            selector: (row) => row.salesOrderDate,
            sortable: true,
          },
          {
            name: "CUSTOMER NAME",
            selector: (row) => row.customerTitle,
            sortable: true,
          },
          {
            name: "ORDER NO ",
            selector: (row) => row.orderNo,
            sortable: true,
          },
          {
            name: "TOTAL AMOUNT ",
            selector: (row) => Number(row.totalAmount)?.toFixed(2),
            sortable: true,
          },
        ]);
      });
    }

    if (search.value === "BillsByVendorId") {
      getBillByVendorId1(request).then((res) => {
        setResPacket(res.responsePacket);
        setRows(res.responsePacket);
        setTitle("Bills By Vendor Id");
        setColumn([
          {
            name: "BILL DATE",
            selector: (row) => row.billDate,
            sortable: true,
          },
          {
            name: "VENDOR NAME",
            selector: (row) => row.vendorTitle,
            sortable: true,
          },
          {
            name: "BILL NO. ",
            selector: (row) => row.billNo,
            sortable: true,
          },
          {
            name: "TOTAL AMOUNT ",
            selector: (row) => Number(row.totalAmount)?.toFixed(2),
            sortable: true,
          },
        ]);
      });
    }

    if (search.value === "PurchasesbyItem") {
      getPurchaseOrderByItemId1(request).then((res) => {
        setResPacket(res.responsePacket);
        setRows(res.responsePacket);
        setTitle("Purchases by Item");
        setColumn([
          {
            name: "ORDER DATE",
            selector: (row) => row.purchaseOrderDate,
            sortable: true,
          },
          {
            name: "VENDOR NAME",
            selector: (row) => row.vendorTitle,
            sortable: true,
          },
          {
            name: "ORDER NO. ",
            selector: (row) => row.purchaseOrderNo,
            sortable: true,
          },
          {
            name: "TOTAL AMOUNT ",
            selector: (row) => Number(row.totalAmount)?.toFixed(2),
            sortable: true,
          },
        ]);
      });
    }

    if (search.value === "PurchasebyVendor") {
      getPurchaseOrderByVendorId1(request).then((res) => {
        setResPacket(res.responsePacket);
        setRows(res.responsePacket);
        setTitle("Purchase by Vendor");
        setColumn([
          {
            name: "ORDER DATE",
            selector: (row) => row.purchaseOrderDate,
            sortable: true,
          },
          {
            name: "VENDOR NAME",
            selector: (row) => row.vendorTitle,
            sortable: true,
          },
          {
            name: "ORDER NO. ",
            selector: (row) => row.purchaseOrderNo,
            sortable: true,
          },
          {
            name: "TOTAL AMOUNT ",
            selector: (row) => Number(row.totalAmount)?.toFixed(2),
            sortable: true,
          },
        ]);
      });
    }

    if (search.value === "SalesbyInvoice") {
      getInvoiceDetailReport1(request).then((res) => {
        setResPacket(res.responsePacket);
        setRows(res.responsePacket);
        setTitle("Sales by Invoice");
        setColumn([
          {
            name: "ORDER DATE",
            selector: (row) => row.salesOrderDate,
            sortable: true,
          },
          {
            name: "CUSTOMER NAME",
            selector: (row) => row.customerTitle,
            sortable: true,
          },

          {
            name: "SALES NO. ",
            selector: (row) => row.salesOrderNo,
            sortable: true,
          },
          {
            name: "INVOICE NO. ",
            selector: (row) => row.invoiceNo,
            sortable: true,
          },
          {
            name: "TOTAL AMOUNT ",
            selector: (row) => Number(row.totalAmount)?.toFixed(2),
            sortable: true,
          },
        ]);
      });
    }

    let data = {
      page: 0,
      search: "",
      size: -1,
    };

    if (search.value === "BelowOrderLevelItemReport") {
      getBelowOrderLevelItemReport1(data).then((res) => {
        setResPacket(res.responsePacket.data);
        setRows(res.responsePacket.data);
        setTitle("Below Order Level Item Report");
        setColumn([
          {
            name: "WAREHOUSE",
            selector: (row) => row.warehouseTitle,
            sortable: true,
          },
          {
            name: "ITEM",
            selector: (row) => row.itemTitle,
            sortable: true,
          },
          {
            name: "OPENING STOCK VALUE",
            selector: (row) => Number(row.openingStockValue)?.toFixed(2),
            sortable: true,
          },
          {
            name: "OPENING STOCK",
            selector: (row) => Number(row.openingStockQuantity)?.toFixed(2),
            sortable: true,
          },
          {
            name: "CURRENT STOCK",
            selector: (row) => Number(row.currentStockQuantity)?.toFixed(2),
            sortable: true,
          },
        ]);
      });
    }

    if (search.value === "RecordByDepartment") {
      getRecordByDepartmentId1(request).then((res) => {
        setResPacket(res.responsePacket);
        setRows(res.responsePacket);
        setTitle("Record By Department");
        setColumn([
          {
            name: "DEPARTMENT",
            selector: (row) => row.issuedDepartmentTitle,
            sortable: true,
          },
          {
            name: "ISSUED BY",
            selector: (row) => row.issuedByUserTitle,
            sortable: true,
          },
          {
            name: "ISSUED TO",
            selector: (row) => row.issuedToUserTitle,
            sortable: true,
          },
          {
            name: "ISSUANCE DATE",
            selector: (row) => row.issuanceDate,
            sortable: true,
          },
        ]);
      });
    }

    if (search.value === "InventorySummary") {
      getInventorySummary1(request).then((res) => {
        setResPacket(res.responsePacket.data);
        setRows(res.responsePacket.data);
        setTitle("Inventory Summary");
        setColumn([
          {
            name: "WAREHOUSE",
            selector: (row) => row.warehouseTitle,
            sortable: true,
          },
          {
            name: "ITEM",
            selector: (row) => row.itemTitle,
            sortable: true,
          },
          {
            name: "OPENING STOCK VALUE",
            selector: (row) => Number(row.openingStockValue)?.toFixed(2),
            sortable: true,
          },
          {
            name: "OPENING STOCK",
            selector: (row) => Number(row.openingStockQuantity)?.toFixed(2),
            sortable: true,
          },
          {
            name: "CURRENT STOCK",
            selector: (row) => Number(row.currentStockQuantity)?.toFixed(2),
            sortable: true,
          },
        ]);
      });
    }
  };
  console.log(componentRef);
  console.log(item, "item");
  return (
    <>
      {" "}
      <div className="column list-column d-print-none column-small">
        <Reports />
      </div>
      <div style={{ margin: "15px" }} className="column content-column">
        <div
          className="row"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", zIndex: "6" }}>
            {" "}
            <div style={{ display: "flex", alignItems: "center", zIndex: "6" }}>
              &nbsp;
              <input
                className="ember-text-field ember-view form-control"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <input
                className="ember-text-field ember-view form-control"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", zIndex: "6" }}>
              {search.value === "SalesbyItem" ||
              search.value === "PurchasesbyItem" ? (
                <Select
                  id="reportSelect"
                  placeholder="Select Item"
                  value={item}
                  onChange={(e) => setItem(e)}
                  options={itemOptions}
                />
              ) : (
                ""
              )}

              <Button onClick={handleReportSearch}>Search</Button>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <SearchRoundedIcon />
            {search.value === "SalesbyItem" ? (
              <input
                className="ember-text-field ember-view form-control"
                type="text"
                placeholder="Search here."
                onChange={(e) => handleSearchKv(e.target.value)}
              />
            ) : (
              <input
                className="ember-text-field ember-view form-control"
                type="text"
                placeholder="Search here"
                onChange={(e) => handleSearchData(e.target.value)}
              />
            )}
            <button
              className="tooltip-container ember-view btn btn-primary float-right"
              onClick={handlePrint1}
            >
              <LocalPrintshopOutlinedIcon />
            </button>
          </div>
        </div>
        <br /> <br />
        {/* onChange={(e) => handleSearchKv(e)} */}
        <div ref={componentRef} id="pdf">
          <br />
          <DataTable
            title={printDiv ? `${title} (${startDate} -- ${endDate})` : title}
            columns={column}
            data={resPacket}
            fixedHeader={printDiv ? false : true}
            selectableRowsHighlight
          />
          <br />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewReports;
