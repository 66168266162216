import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { JSEncrypt } from "jsencrypt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import { login, getRequestPublicKey } from "../services/index";

const LoginComponent = () => {
  const [loading, setLoading] = useState(false);

  let LC = localStorage.getItem("rememberUserLogin");

  let rememberUserLogin;
  if (LC) {
    rememberUserLogin = JSON.parse(localStorage.getItem("rememberUserLogin"));
  }

  const [username, setUsername] = useState(rememberUserLogin?.username);
  const [password, setPassword] = useState(rememberUserLogin?.password);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };
  const handleKeyDown = (event) => {
    console.log(event.key);
  };
  const handleUserChange = (e) => {
    setUsername(e.target.value);
  };
  const handlePassChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClick = (event) => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const timeout = setTimeout(() => {
      source.cancel();
      setLoading(false);
      toast("🙁 Server is not responding kindly try again later!!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }, 10000);
    // login()
    getRequestPublicKey()
      .then((res) => {
        clearTimeout(timeout);
        if (res.errorCode == 0) {
          var publicKey = res.responsePacket;
          const encrypt = new JSEncrypt();
          encrypt.setPublicKey(publicKey);
          let encryptedPassword = encrypt.encrypt(password);
          var payload = {
            username: username,
            password: encryptedPassword,
          };
          console.log(payload);

          login(payload).then((res) => {
            if (res.errorCode == 0) {
              var loginData1 = res.responsePacket;
              // set cookie object here
              const cookies = new Cookies();
              let d = new Date();

              d.setTime(d.getTime() + 9 * 60 * 60 * 1000);
              cookies.set("loginData1", loginData1, { path: "/", expires: d });
              toast("👏" + "Login Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              setTimeout(function () {
                window.location.reload(24);
              }, 1000);
            } else {
              var errorMsg = res.message;
              toast("🙁" + errorMsg, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setLoading(false);
            }
          });
        } else {
          var errorMsg = res.message;
          toast("🙁" + errorMsg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setLoading(false)
        }
      })
      .catch((err) => {
        toast("🙁" + err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleForgotPassword = () => {};

  const handleRemember = () => {
    const rememberUserLogin = {
      username: username,
      password: password,
    };

    localStorage.setItem(
      "rememberUserLogin",
      JSON.stringify(rememberUserLogin)
    );
  };



  return (
    <>
      <div className="auth-main2 particles_js">
        <div className="auth_div vivify fadeInTop">
          <div className="card">
            <div className="body">
              <div className="login-img">
                <img
                  className="img-fluid"
                  src="https://s3.ap-south-1.amazonaws.com/crypto-pro-bucket/client-bucket-bww/primary-image/1626166429329_loginimg.png"
                />
              </div>
              <div className="form-auth-small">
                <div className="mb-3">
                  <p className="lead">Login to your account</p>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="signin-email"
                    className="control-label sr-only"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control round"
                    id="signin-email"
                    disabled={loading}
                    value={username}
                    placeholder="User Name"
                    onKeyDown={handleKeyDown}
                    onKeyPress={handleKeyPress}
                    onChange={handleUserChange}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="signin-password"
                    className="control-label sr-only"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control round"
                    id="signin-password"
                    disabled={loading}
                    value={password}
                    placeholder="Password"
                    onKeyPress={handleKeyPress}
                    onChange={handlePassChange}
                  />
                </div>
                <div className="form-group clearfix">
                  <label className="fancy-checkbox element-left">
                    <input type="checkbox" onClick={handleRemember} />
                    <span>Remember me</span>
                  </label>
                </div>
                <button
                  type="submit"
                  onClick={handleClick}
                  className="btn btn-primary btn-round btn-block"
                >
                  {loading && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {loading && <span>LOGIN....</span>}
                  {!loading && <span>LOGIN</span>}
                </button>
                <div className="mt-4">
                  <span className="helper-text m-b-10">
                    <i className="fa fa-lock"></i>{" "}
                    <a
                      onClick={handleForgotPassword}
                      style={{ cursor: "pointer" }}
                    >
                      Forgot password?
                    </a>
                  </span>
                  {/* <span>Don't have an account? <a href="page-register.html">Register</a></span> */}
                </div>
              </div>
              <div className="pattern">
                <span className="red"></span>
                <span className="indigo"></span>
                <span className="blue"></span>
                <span className="green"></span>
                <span className="orange"></span>
              </div>
            </div>
          </div>
        </div>
        <div id="particles-js">
          <canvas
            className="particles-js-canvas-el"
            width="1898"
            height="463"
            style={{ width: "100%", height: "100%" }}
          ></canvas>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

// class LoginComponent extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             username: '',
//             password: '',
//             loading: false
//         }

//         this.handleUserChange = this.handleUserChange.bind(this);
//         this.handlePassChange = this.handlePassChange.bind(this);
//         this.handleForgotPassword = this.handleForgotPassword.bind(this);
//         this.handleKeyPress = this.handleKeyPress.bind(this);
//     }

//     handleKeyPress = (event) => {
//         if (event.key === 'Enter') {
//             this.handleClick()
//         }
//     }

//     handleUserChange(evt) {
//         this.setState({
//             username: evt.target.value,
//         });
//         //console.log(this.state.username);
//     };
//     handleForgotPassword() {
//         let d = new Date();
//         d.setTime(d.getTime() + (30 * 60 * 1000));
//         const cookies = new Cookies();

//         cookies.set('forgotPassword', "redirectToForgot", { path: '/', expires: d });
//         window.location.href = "/forgot-password";
//     };

//     handlePassChange(evt) {
//         this.setState({
//             password: evt.target.value,
//         });
//     }

//     handleClick(event) {
//         this.setState({
//             loading: true
//         })
//         console.log(event);
//         console.log(this.state);
//         // var apiBaseUrl = url.BASE_URL + '/adminLogin/';
//         const CancelToken = axios.CancelToken;
//         const source = CancelToken.source();
//         const timeout = setTimeout(() => {
//             source.cancel();
//             this.setState({
//                 loading: false
//             })
//             toast("Server is not responding kindly try again later!!",
//                 {
//                     icon: '🙁',
//                     style: {
//                         borderRadius: '10px',
//                         background: '#333',
//                         color: '#fff',
//                     },
//                 }
//             );
//         }, 10000);
//         LoginService.getRequestPublicKey().then((res) => {
//             clearTimeout(timeout);
//             if (res.data.errorCode == 0) {
//                 var publicKey = res.data.responsePacket;
//                 const encrypt = new JSEncrypt()
//                 encrypt.setPublicKey(publicKey)
//                 let encryptedPassword = encrypt.encrypt(this.state.password);
//                 var payload = {
//                     "username": this.state.username,
//                     "password": encryptedPassword
//                 }

//                 LoginService.loginUser(payload).then((res) => {
//                     if (res.data.errorCode == 0) {
//                         var loginData1 = res.data.responsePacket;
//                         // set cookie object here
//                         const cookies = new Cookies();
//                         let d = new Date();
//                         d.setTime(d.getTime() + (9 * 60 * 60 * 1000));
//                         cookies.set('loginData1', loginData1, { path: '/', expires: d });
//                         console.log(cookies.get('loginData1')); // Pacman
//                         //$('.toastrDefaultSuccess').show();
//                         toast("Login Successfully",
//                             {
//                                 icon: '👏',
//                                 style: {
//                                     borderRadius: '10px',
//                                     background: '#333',
//                                     color: '#fff',
//                                 },
//                             }
//                         );
//                         // toast.success(successMsg)
//                         // toastr.success(successMsg);
//                         setTimeout(function () {
//                             window.location.reload(24);
//                         }, 1000);
//                     } else {
//                         var errorMsg = res.data.message;
//                         toast(errorMsg,
//                             {
//                                 icon: '🙁',
//                                 style: {
//                                     borderRadius: '10px',
//                                     background: '#333',
//                                     color: '#fff',
//                                 },
//                             }
//                         );
//                         this.setState({
//                             loading: false
//                         })

//                     }
//                 });
//             } else {
//                 toast(res.data.message,
//                     {
//                         icon: '🙁',
//                         style: {
//                             borderRadius: '10px',
//                             background: '#333',
//                             color: '#fff',
//                         },
//                     }
//                 );
//             }
//         })

//     }

//     render() {
//         return (

//             <div className="auth-main2 particles_js">
//                 <div className="auth_div vivify fadeInTop">
//                     <div className="card">
//                         <div className="body">
//                             <div className="login-img">
//                                 <img className="img-fluid" src="https://s3.ap-south-1.amazonaws.com/crypto-pro-bucket/client-bucket-bww/primary-image/1626166429329_loginimg.png" />
//                             </div>
//                             <div className="form-auth-small">
//                                 <div className="mb-3">
//                                     <p className="lead">Login to your account</p>
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="signin-email" className="control-label sr-only">Email</label>
//                                     <input type="email" className="form-control round" id="signin-email" disabled={this.state.loading} value={this.state.username} placeholder="Email" onKeyPress={this.handleKeyPress} onChange={this.handleUserChange} />
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="signin-password" className="control-label sr-only">Password</label>
//                                     <input type="password" className="form-control round" id="signin-password" disabled={this.state.loading} value={this.state.password} placeholder="Password" onKeyPress={this.handleKeyPress} onChange={this.handlePassChange} />
//                                 </div>
//                                 <div className="form-group clearfix">
//                                     <label className="fancy-checkbox element-left">
//                                         <input type="checkbox" />
//                                         <span>Remember me</span>
//                                     </label>
//                                 </div>
//                                 <button type="submit" onClick={(event) => this.handleClick(event)} className="btn btn-primary btn-round btn-block">
//                                     {this.state.loading && (
//                                         <i
//                                             className="fa fa-refresh fa-spin"
//                                             style={{ marginRight: "5px" }}
//                                         />
//                                     )}
//                                     {this.state.loading && <span>LOGIN....</span>}
//                                     {!this.state.loading && <span>LOGIN</span>}
//                                 </button>
//                                 <div className="mt-4">
//                                     <span className="helper-text m-b-10"><i className="fa fa-lock"></i> <a onClick={() => this.handleForgotPassword()} style={{ cursor: "pointer" }}>Forgot password?</a></span>
//                                     {/* <span>Don't have an account? <a href="page-register.html">Register</a></span> */}
//                                 </div>
//                             </div>
//                             <div className="pattern">
//                                 <span className="red"></span>
//                                 <span className="indigo"></span>
//                                 <span className="blue"></span>
//                                 <span className="green"></span>
//                                 <span className="orange"></span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div id="particles-js"><canvas className="particles-js-canvas-el" width="1898" height="463" style={{ width: '100%', height: '100%' }}></canvas></div>
//                 <Toaster />
//             </div >
//         )
//     }

// }

export default LoginComponent;
