import { 
  LOADING,
  CONTECT_ADD,
  CHECK_RATE,
  STATE,
} from "../common/constant";
//  type  = {
//   loading:boolean,
//   contectAdd,
//  }
const initialState  = {
    loading:false,
    contectAdd: {},
    checkRate: {},
    mukesh:{},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload};
    case CONTECT_ADD:
      return { ...state, contectAdd: action.payload};
      case CHECK_RATE:
      return { ...state, checkRate: action.payload};
      case STATE:
      return { ...state, mukesh: action.payload};
    default:
      return state;
  }
};