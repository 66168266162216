import React, { Component, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useHistory, Link } from "react-router-dom";

const HeaderComponent = (props) => {
  console.log(props, "props");
  const history = useHistory();
  const [showSettingSideMenu, setShowSettingSideMenu] = useState(false);
  const logout = () => {
    confirmAlert({
      title: "Logout",
      message: "Are you sure to logout ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleLog()
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleLog = () => {
    const clearCookies = document.cookie
      .split(";")
      .forEach(
        (cookie) =>
          (document.cookie = cookie
            .replace(/^ +/, "")
            .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`))
      );
    localStorage.clear();
    window.location.reload(1);
  };
  useEffect(() => {
    if (props.headerLogout) {
      handleLog();
    }
  }, [props]);

  const handleSettingOptionClick = (tempOption) => {
    setShowSettingSideMenu(false);
    localStorage.setItem("showSettingNav", true);
    if (tempOption === "preferences") {
      localStorage.setItem("showPreferencesNav", true);
    }
    var dict = {
      show: true,
      option: tempOption,
    };
    props.parentCallBack(dict);
  };

  return (
    <>
      <div>
        <nav className="navbar top-navbar">
          <div className="container-fluid">
            <div className="navbar-left">
              <div className="navbar-btn">
                <a href="index.html">
                  <img
                    // src="%PUBLIC_URL%/dist/assets/images/icon.svg"
                    src="https://etouch.s3.ap-south-1.amazonaws.com/primary-image/logo.png"
                    alt="Oculux Logo"
                    className="img-fluid logo"
                  />
                </a>
                <button type="button" className="btn-toggle-offcanvas">
                  <i className="lnr lnr-menu fa fa-bars"></i>
                </button>
              </div>
              <ul className="nav navbar-nav pl-2">
                <div className="search-field">
                  <div className="input-group btn-group">
                    <div className="input-group-prepend">
                      {/* <span className="input-group-text">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                          className="icon icon-xs align-text-bottom"
                        >
                          <path d="M500.7 431.3l-94.2-94.1c21.4-33.9 33.9-74.1 33.9-117.2 0-121.5-98.6-220-220.2-220S0 98.5 0 220s98.6 220 220.2 220c42.9 0 82.9-12.3 116.7-33.5l94.3 94.2c15.1 15 39.5 15 54.5 0l15-15c15.1-15 15.1-39.4 0-54.4zm-280.5-52.2c-88 0-159.3-71.2-159.3-159.1 0-87.9 71.3-159.1 159.3-159.1 88 0 159.2 71.2 159.2 159.1 0 87.9-71.3 159.1-159.2 159.1z"></path>
                        </svg>
                      </span> */}
                      {/* <div
                        className="auto-select ac-selected w-100"
                        tabIndex="-1"
                      >
                        <input
                          aria-label="Search"
                          placeholder="Search1"
                          id="ember45"
                          className="form-control app-quick-search-field ember-text-field ember-view"
                          type="text"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </ul>
            </div>

            <div className="navbar-right">
              <div id="navbar-menu">
                <ul className="nav navbar-nav">
                  {/* <li className=" dropdown top-item user-notification ember-view">
                                    <a className="cursor-pointer icon-menu" data-ember-action="" data-ember-action-101="101">
                                        <div id="ember102" className="tooltip-container ember-view">
                                            <span className="top-icon">
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-lg align-text-bottom">
                                                    <path d="M485.9 409.5l-4.9-4.9c-30.4-30.3-47.8-72.4-47.8-115.4v-75.3c0-77.3-50.2-145.4-123.2-168.5C305.7 19.6 283.3 0 256.4 0c-26.9 0-49.3 19.6-53.5 45.3-73 22.9-123.2 91-123.2 168.4v69.4c0 47.4-18.5 92.1-52 125.8l-.6.6c-5.8 5.8-7.5 14.3-4.4 21.9 3.1 7.5 10.4 12.3 18.5 12.3h128.7C180 483 215.5 512 256.4 512s76.4-29 86.5-68.4h128.7c8.2 0 15.5-4.9 18.6-12.4 3.1-7.5 1.4-16-4.3-21.7zm-229.5 70.4c-23.4 0-44.1-15-52.9-36.3h105.7c-8.7 21.4-29.4 36.3-52.8 36.3zm90.6-68.3H67.9c11.4-14.7 20.8-30.9 27.9-48.2 10.6-25.6 16-52.6 16-80.3v-69.4c0-65.7 44.2-123.2 107.5-139.8 8.9-2.3 15.1-10.5 15.1-19.8V54c0-12.1 9.9-21.9 22.1-21.9 12.2 0 22.1 9.8 22.1 21.9v.1c0 9.3 6.2 17.5 15 19.8 63.3 16.7 107.5 74.2 107.5 139.9v75.3c0 44.5 15.6 88.1 43.2 122.5H347z"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </a>
                                </li> */}

                  {/* setting icon header */}

                  {/* <li className=" dropdown top-item ember-view">
                    <a
                      onClick={() => setShowSettingSideMenu(true)}
                      className="icon-menu cursor"
                      data-ember-action=""
                      data-ember-action-96="96"
                    >
                      <i id="ember97" className="tooltip-container ember-view">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                          className="icon cursor-pointer icon-lg align-text-bottom"
                        >
                          <path d="M258.3 149.8c-57.3 0-103.9 46.6-103.9 103.9 0 57.3 46.6 103.9 103.9 103.9s104-46.6 104-103.9c0-57.3-46.6-103.9-104-103.9zm0 175.9c-39.7 0-71.9-32.3-71.9-71.9 0-39.7 32.3-71.9 71.9-71.9 39.7 0 71.9 32.3 71.9 71.9.1 39.6-32.2 71.9-71.9 71.9z"></path>
                          <path d="M491.4 202.9l-38.7-14c-3-9.5-6.6-18.6-10.7-27.1l18.3-38.3c5.6-11.8 3.2-26-6-35.2l-30.5-30.6c-9.3-9.3-23.4-11.7-35.2-6.1l-38.2 18.1c-8.1-3.8-16.7-7.1-27.1-10.3l-14-38.7C304.9 8.5 293.2.4 280.2.4h-43.6c-12.9 0-24.6 8.2-29.1 20.4l-14.1 38.8c-9.7 3.1-18.8 6.7-27.1 10.7l-38.2-18.4c-11.8-5.6-26-3.2-35.2 6L62.1 88.3c-9.3 9.3-11.7 23.5-6 35.3l18 37.8c-5.2 9.8-9.2 18.7-12.2 27l-40.8 14.2C8.6 207 .2 218.7.2 232v43.3c0 13.2 8.4 25 20.7 29.2l40.9 14.3c3.1 8.8 6.9 17.7 11.7 27.1L55.6 384c-5.6 11.9-3.2 26 6.2 35.2L92.6 450c9.2 9.2 23.4 11.7 35.2 6l38.1-18.1c9.7 5.2 18.6 9.2 27.1 12.2l14.2 40.8c4.3 12.5 16 20.8 29.3 20.8h43.3c13.2 0 25-8.4 29.3-20.8l14.2-40.9c8.9-3.1 18-7 27.1-11.7l38.1 17.9c11.8 5.6 26 3.1 35.3-6.2l30.7-30.9c9.2-9.2 11.6-23.3 6-35.2l-18.2-38.4c3.8-8 7.1-16.6 10.3-27.1l38.6-14c12.3-4.3 20.6-16 20.6-29V232c0-13-8.2-24.7-20.4-29.1zm-11.6 71.8l-38.5 14c-9.2 3.3-16.1 10.6-19 19.9v.1c-2.8 9.3-5.7 16.8-8.9 23.5-4.1 8.5-4.2 18.3-.1 26.7l18.2 38.3-29.7 29.9-38-17.9c-8.9-4.1-18.8-4-27.3.5-8 4.1-16 7.6-23.7 10.3-8.9 2.9-16.1 10.1-19.3 19.1l-14.2 40.7h-41.8L223.2 439c-3.1-8.9-10-15.9-19-19-7.3-2.6-15-6.1-23.7-10.7s-19.1-4.8-27.9-.6l-37.9 18-29.9-29.8 17.9-37.9c4.1-8.9 4-18.9-.5-27.3-4.3-8.3-7.7-16.1-10.3-23.9-3.1-8.9-10.2-16-19.1-19.1l-40.6-14.2v-41.8l40.7-14.2c8.9-3.1 15.9-10 19-18.9 2.5-7 6-14.7 10.7-23.5 4.6-8.6 4.9-19 .6-27.9l-18-37.8L115 80.9l38 18.3c8.5 4.1 18.6 4 27.1-.2 7.1-3.5 15.1-6.6 23.6-9.3 9-2.9 16.3-9.9 19.6-19l14-38.5h42.1l13.9 38.4c3.3 9.2 10.6 16.1 19.9 19h.1c9.2 2.8 16.6 5.6 23.4 8.9 8.6 4.1 18.3 4.1 26.8.1l38-18 29.5 29.6-18.2 38-.1.2c-4 8.7-4 18.5.2 26.9 3.6 7.4 6.7 15.3 9.3 23.5 2.7 9 9.8 16.4 18.9 19.8l38.5 14v42.1z"></path>
                        </svg>
                      </i>
                    </a>
                  </li> */}
                  {/* <li id="ember92" className="dropdown top-item ember-view">
                                    <a className='icon-menu cursor' data-ember-action="" data-ember-action-93="93">
                                        <i id="ember94" className="tooltip-container ember-view">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon cursor-pointer icon-lg align-text-bottom">
                                                <path d="M317.1 147.5c-15.1-13.8-35.5-20.8-60.5-20.8-23.7 0-43.1 6.5-57.7 19.4-14.6 12.9-23.5 31.5-26.4 55.5l-.6 4.9 40.4 4.8.7-4.6c2.5-15.8 7.7-27.5 15.4-34.7 7.7-7.2 17.1-10.7 28.7-10.7 12 0 21.9 3.9 30.1 11.9 8.2 8 12.2 16.9 12.2 27.3 0 5.6-1.3 10.7-4 15.4-2.8 4.9-9.3 11.9-19.3 20.7-10.7 9.4-17.9 16.5-22.1 21.5-5.8 7-10 14-12.6 20.8-3.5 9.1-5.3 19.9-5.3 32.3 0 2.1.1 5.1.2 9l.1 4.7h38.4l.1-4.8c.3-14.3 1.4-21.4 2.3-24.7 1.3-4.7 3.2-8.8 5.9-12.5 2.8-3.8 9-10 18.5-18.4 15.1-13.4 25.1-24.6 30.4-34.2 5.4-9.7 8.1-20.4 8.1-31.9 0-19.9-7.7-37-23-50.9zM256.3 385.3c12.1 0 22-9.8 22-22 0-12.1-9.8-22-22-22-12.1 0-22 9.8-22 22s9.8 22 22 22z"></path>
                                                <path d="M437.4 74.6C388.9 26.1 324.5-.5 256-.5S123.1 26.2 74.6 74.6C26.1 123.1-.5 187.5-.5 256s26.7 132.9 75.1 181.4c48.5 48.5 112.9 75.1 181.4 75.1s132.9-26.7 181.4-75.1c48.5-48.5 75.1-112.9 75.1-181.4s-26.6-132.9-75.1-181.4zm-22.6 340.2c-42.4 42.4-98.8 65.8-158.8 65.8s-116.4-23.4-158.8-65.8C54.8 372.4 31.5 316 31.5 256S54.8 139.6 97.2 97.2C139.6 54.8 196 31.5 256 31.5s116.4 23.4 158.8 65.8c42.4 42.4 65.8 98.8 65.8 158.8s-23.4 116.3-65.8 158.7z"></path>
                                            </svg>
                                        </i>
                                    </a>
                                </li>
                                <li id="ember90" className="dropdown orglist-topband float-right top-item-fixed-width ember-view">
                                    <a href='#' className="cursor-pointer icon-menu">
                                        <img className="float-left rounded-circle" src="/inventory/inventory-software-demo/assets/images/demo-avathar-341d62cb40ea38f1d7ee72b5fd66911b.png" />
                                    </a>
                                </li> */}
                  {/* <li><a href="javascript:void(0);" className="search_toggle icon-menu" title="Search Result"><i className="icon-magnifier"></i></a></li> */}
                  {/* <li><a href="javascript:void(0);" className="right_toggle icon-menu" title="Right Menu"><i className="icon-bubbles"></i><span className="notification-dot bg-pink">2</span></a></li> */}
                  <li>
                    <a className="icon-menu" onClick={logout}>
                      <i className="icon-power"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="progress-container">
            <div className="progress-bar" id="myBar"></div>
          </div>
        </nav>
        {showSettingSideMenu ? (
          <div id="flyout-with-topbar" className="d-print-none">
            <div className="">
              <div id="ember733" className="flyout ember-view flyout-sm">
                <div tabIndex="-1" id="ember734" className="ember-view">
                  <div className="flyout-header">
                    <div className="section aliceblue-bg header">
                      <h5 style={{ marginTop: "10px" }}>
                        {/* cross btn */}
                        <span
                          className="float-right text-muted cursor-pointer font-xs"
                          data-ember-action=""
                          data-ember-action-735="735"
                          onClick={() => setShowSettingSideMenu(false)}
                        >
                          <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                            className="icon icon-xs"
                          >
                            <path d="M455.2 9.2L256 208.4 56.8 9.2C44.5-3.1 24.6-3.1 12.2 9.2l-2.9 2.9C-3 24.4-3 44.4 9.3 56.7L208.4 256 9.2 455.2c-12.3 12.3-12.3 32.3 0 44.6l2.9 2.9c12.3 12.3 32.3 12.3 44.6 0L256 303.6l199.2 199.2c12.3 12.3 32.3 12.3 44.6 0l2.9-2.9c12.3-12.3 12.3-32.3 0-44.6L303.6 256 502.8 56.8c12.3-12.3 12.3-32.3 0-44.6l-2.9-2.9c-12.5-12.4-32.4-12.4-44.7-.1z"></path>
                          </svg>
                        </span>
                        <div className="font-large">Settings</div>
                      </h5>
                      <div className="topband-search-container">
                        <div id="ember736" className="ember-view">
                          <div className="search-field">
                            <div className="input-group btn-group"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flyout-body navigation-list-container">
                    <div className="orglist-topband orgs-settings">
                      <div
                        id="ember738"
                        className="navigation-item ember-view orgs"
                        onClick={() => handleSettingOptionClick("orgProfile")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="icon icon-sm align-text-bottom"
                        >
                          <path d="M455.2 97.6H304.4v-41c0-31.3-25.4-56.7-56.7-56.7H56.6C25.3-.1-.1 25.4-.1 56.6v398.7c0 31.3 25.4 56.7 56.7 56.7h398.6c31.3 0 56.7-25.4 56.7-56.7V154.2c0-31.2-25.4-56.6-56.7-56.6zM31.9 455.3V56.6C31.9 43 43 31.9 56.6 31.9h191.1c13.6 0 24.7 11.1 24.7 24.7V480H216v-95.9c0-8.8-7.2-16-16-16h-88.6c-8.8 0-16 7.2-16 16V480H56.6c-13.6 0-24.7-11.1-24.7-24.7zm152 24.7h-56.6v-79.9h56.6V480zm296-24.7c0 13.6-11.1 24.7-24.7 24.7H304.4V129.6h150.9c13.6 0 24.7 11.1 24.7 24.7v301z"></path>
                          <path d="M220.3 272c8.8 0 16-7.2 16-16s-7.2-16-16-16H91.9c-8.8 0-16 7.2-16 16s7.2 16 16 16h128.4zM91.9 160.4h128.5c8.8 0 16-7.2 16-16s-7.2-16-16-16H91.9c-8.8 0-16 7.2-16 16s7.1 16 16 16zm339.5 175.7h-79.7c-8.8 0-16 7.2-16 16s7.2 16 16 16h79.7c8.8 0 16-7.2 16-16s-7.1-16-16-16zm0-96.1h-79.7c-8.8 0-16 7.2-16 16s7.2 16 16 16h79.7c8.8 0 16-7.2 16-16 0-8.9-7.1-16-16-16z"></path>
                        </svg>
                        &nbsp;&nbsp;&nbsp;&nbsp;Organization Profile
                        <div className="text-overflow font-small text-muted"></div>
                      </div>

                      <div
                        id="ember739"
                        className="navigation-item ember-view orgs"
                        onClick={() => handleSettingOptionClick("userRole")}
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                          className="icon icon-sm align-text-bottom"
                        >
                          <path d="M487.1 336.8c-15.8-19.2-38-30.2-66-32.5-20.9-5.7-43.2-16.3-47-22.3-1.5-6.5 5.7-26.2 15.4-41.9 7.2-7.4 26.7-29.5 25-52.9-.5-7.2-2.9-13.9-7-19.5 2.3-17.1 3.5-50.7-16.5-76.5-16.7-21.5-43.2-32.5-78.8-32.5-19.9 0-37 3.4-51.1 10.1 11.2 5.4 20.5 12.8 27.9 22.3 1.9 2.4 3.5 4.9 5 7.4 5.5-.9 11.5-1.4 18-1.4 23 0 39.1 5.8 48.2 17.3 16 20.5 7.8 54.3 7.7 54.5l-3.2 12.8 10.7 7.7c.2.2.5.3.6.5.3 4.8-7.8 16.9-15.1 23.9l-1.6 1.6-1.2 2c-.2.5-7 11.1-13 24.7-9.3 20.9-11.8 37.6-7.8 51.1 1.9 6.3 5.5 12.1 11.1 17.6 11.5 4.3 21.5 10.7 30.1 19.1 1 .5 2.1.9 3.2 1.4 15.9 6.7 30.8 10.7 31.4 10.8l1.8.5 1.8.2c18 1.2 31.3 7.4 40.7 18.7 12.2 14.8 15.5 35.7 16.2 50.1h-63.5c.2 4.8.1 9.1 0 12.5l-.9 26.1h102l.9-26.1c.9-18.1-.7-57.9-25-87.3z"></path>
                          <path d="M399.6 450H2.8l-2-16.9C.5 430.6-6 374.8 25.2 337c16-19.4 38.3-30.3 66.3-32.6 20.9-5.6 43.4-16 47.1-22 1.6-7.1-6.6-27.7-15.5-42.1-7.2-7.4-26.7-29.6-25-52.9.5-7.2 2.9-13.9 7-19.5-2.4-17.2-3.5-50.7 16.5-76.5 16.7-21.6 43.3-32.5 79-32.5 35.6 0 62.1 11 78.8 32.5 20 25.9 18.7 59.4 16.5 76.5 4.1 5.6 6.5 12.3 7 19.5 1.6 23.4-17.8 45.5-25 52.9-9.6 15.7-16.9 35.4-15.4 41.9 3.8 6 26 16.6 47 22.3 27.9 2.3 50.1 13.3 66 32.5 24.3 29.4 25.9 69.2 25.2 87.1l-1.1 25.9zM38.8 411.3h323.3c-.7-14.4-4.1-35.3-16.2-50.1-9.4-11.3-22.7-17.5-40.7-18.7l-1.8-.2-1.8-.5c-.6-.2-15.5-4.1-31.4-10.8-25.5-10.8-40-23.2-44.4-38-4-13.5-1.4-30.1 7.8-51.1 6-13.6 12.8-24.2 13-24.7l1.2-2 1.6-1.6c7.2-7 15.4-19.1 15.1-23.9-.2-.2-.4-.2-.6-.5l-10.7-7.7 3.2-12.8c.2-.2 8.3-34-7.7-54.5-9-11.5-25.2-17.3-48.2-17.3-23.2 0-39.6 5.9-48.5 17.6-15.8 20.5-7.7 54-7.6 54.4l3.4 12.8-10.7 7.7c-.2.2-.5.4-.6.5-.2 4.1 6.5 15.7 15.1 24.1l1.6 1.6 1.2 2c.2.5 7.1 11.2 13.1 24.8 9.3 21.1 11.8 37.8 7.8 51.1-4.3 14.8-18.9 27.2-44.5 37.8-15.9 6.6-30.9 10.5-31.5 10.6l-1.6.5-1.6.2c-18.2 1.2-31.5 7.4-40.9 18.7-12.4 14.8-15.8 35.6-16.4 50z"></path>
                        </svg>
                        &nbsp;&nbsp;&nbsp;&nbsp;Users &amp; Roles
                        <div className="text-overflow font-small text-muted"></div>
                      </div>

                      <div
                        id="ember740"
                        className="navigation-item ember-view orgs"
                        onClick={() => handleSettingOptionClick("warehouse")}
                      >
                        <svg
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="icon icon-sm align-text-bottom"
                        >
                          <path d="M496.2 147c-2.3 0-4.7-.5-6.9-1.6l-233-111.7-233 111.7c-8 3.8-17.5.5-21.3-7.5-3.8-8-.5-17.5 7.5-21.3l239.9-115c4.4-2.1 9.5-2.1 13.8 0l239.9 115c8 3.8 11.3 13.4 7.5 21.3-2.7 5.8-8.5 9.1-14.4 9.1zM436.2 456c-5.9-7.1-11.8-13.6-17.3-20.5-14.7-18.2-28.5-36.9-40.5-57.1-5.1-8.7-9.6-17.7-13.4-27.1-6.5-16-6.2-32.1 0-48 10.8-27.5 31-45 60.3-49.7 3.7-.6 6.9-1.2 10.5-1.2 35 0 67.3 26.1 74.8 61.1 3.3 15.6.7 30.1-5.8 44.3-6.6 14.6-15 28.2-24.1 41.4-13 19-27.2 37-42.4 54.5-.6.7-1.2 1.2-2.1 2.3zm38-132.8c0-20.9-17.1-37.9-38-37.9s-38 17.1-37.9 37.9c.1 21.2 16.9 38 37.9 38.1 21-.1 38-17.1 38-38.1zM496 511H16c-8.8 0-16-7.2-16-16s7.2-16 16-16h480c8.8 0 16 7.2 16 16s-7.2 16-16 16z"></path>
                          <path d="M70 492c-8.8 0-16-7.2-16-16V117c0-8.8 7.2-16 16-16s16 7.2 16 16v359c0 8.8-7.2 16-16 16zm371-278c-8.8 0-16-7.2-16-16v-81c0-8.8 7.2-16 16-16s16 7.2 16 16v81c0 8.8-7.2 16-16 16zm-189 27H146c-8.8 0-16-7.2-16-16s7.2-16 16-16h106c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 87H146c-8.8 0-16-7.2-16-16s7.2-16 16-16h106c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 86H146c-8.8 0-16-7.2-16-16s7.2-16 16-16h106c8.8 0 16 7.2 16 16s-7.2 16-16 16z"></path>
                        </svg>
                        &nbsp;&nbsp;&nbsp;&nbsp;Warehouses
                        <div className="text-overflow font-small text-muted"></div>
                      </div>

                      {/* <div id="ember741" className="navigation-item ember-view orgs">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M284.5 479.9h-28.3v-96.2c0-8.8-7.2-16-16-16H128.1c-4.2 0-8.3 1.7-11.3 4.7s-4.7 7.1-4.7 11.3v96.2H87.9c-13.2 0-24-10.8-24-24V50.1C63.9 40.3 72 32 81.6 32h202.2c9.6 0 17.7 8.3 17.7 18.1V112c0 8.8 7.2 16 16 16s16-7.2 16-16V50.1c0-27.6-22.3-50.1-49.7-50.1H81.7C54.2 0 31.9 22.5 31.9 50.1v405.8c0 30.9 25.1 56 56 56h196.6c8.8 0 16-7.2 16-16s-7.2-16-16-16zM144 399.7h80.1v80.1H144v-80.1z"
                                                ></path>
                                                <path
                                                    d="M256 128c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16s7.2 16 16 16h128c8.8 0 16-7.1 16-16zm-144 96c-8.8 0-16 7.2-16 16s7.2 16 16 16h79.9c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zm354.9-42.2c-26.8-21-58.5-24.1-74.9-24.1s-48.1 3.1-74.9 24.1c-27.8 21.8-41.9 55.4-41.9 99.9 0 93.3 100.1 218 104.4 223.2 3 3.8 7.6 5.9 12.4 5.9s9.4-2.2 12.4-5.9c4.3-5.3 104.4-130 104.4-223.2 0-44.6-14.1-78.1-41.9-99.9zM392 468.4c-26.2-35.8-84.8-122.8-84.8-186.7 0-34.2 10-59.3 29.6-74.7 19.3-15.1 42.9-17.3 55.2-17.3s36 2.3 55.2 17.3c19.6 15.4 29.6 40.5 29.6 74.7 0 63.9-58.6 150.9-84.8 186.7z"
                                                ></path>
                                                <circle cx="392" cy="271.5" r="37.8"></circle>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Branches
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div> */}
                      {/* <br /> */}
                      <div
                        id="ember742"
                        className="navigation-item ember-view orgs"
                        onClick={() => handleSettingOptionClick("preferences")}
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                          className="icon icon-sm align-text-bottom"
                        >
                          <path d="M487 239.7H213.3c-7.4-33.7-37.4-59.1-73.4-59.1S74 206 66.6 239.7H21c-8.8 0-16 7.2-16 16s7.2 16 16 16h45.6c7.4 33.7 37.4 59.1 73.4 59.1s66-25.3 73.4-59.1H487c8.8 0 16-7.2 16-16s-7.2-16-16-16zm-347 59.1c-23.8 0-43.1-19.3-43.1-43.1s19.3-43.1 43.1-43.1 43.1 19.3 43.1 43.1-19.4 43.1-43.1 43.1zM487 418.1h-62c-7.4-33.7-37.4-59.1-73.4-59.1s-66 25.3-73.4 59.1H21c-8.8 0-16 7.2-16 16s7.2 16 16 16h257.2c7.4 33.7 37.4 59.1 73.4 59.1s66-25.3 73.4-59.1h62c8.8 0 16-7.2 16-16s-7.2-16-16-16zm-92.3 16c0 23.8-19.3 43.1-43.1 43.1s-43.1-19.3-43.1-43.1 19.3-43.1 43.1-43.1 43.1 19.3 43.1 43.1zM21 93.4h257.2c7.4 33.7 37.4 59.1 73.4 59.1s66-25.3 73.4-59.1h62c8.8 0 16-7.2 16-16s-7.2-16-16-16h-62c-7.4-33.7-37.4-59.1-73.4-59.1s-66 25.3-73.4 59.1H21c-8.8 0-16 7.2-16 16s7.2 16 16 16zm330.6-59.1c23.8 0 43.1 19.3 43.1 43.1s-19.3 43.1-43.1 43.1-43.1-19.3-43.1-43.1 19.3-43.1 43.1-43.1z"></path>
                        </svg>
                        &nbsp;&nbsp;&nbsp;&nbsp;Preferences
                        <div className="text-overflow font-small text-muted"></div>
                      </div>
                      {/* <br /> */}
                      {/* <div id="ember743" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path d="M304.1 303.9c0-32-128.1-32-128.1-96s64-64 64-64v-32h32v32s64 0 64 64h-32c0-64-96-64-96 0 0 32 128.1 32 128.1 96s-64 64-64 64v32h-32v-32s-64 0-64-64h32c0 64 96 64 96 0z"></path>
                                                <path
                                                    d="M256.1 512C114.9 512 0 397.1 0 255.8S114.9-.2 256.1-.2c141.3 0 256.2 114.9 256.2 256.1S397.3 512 256.1 512zm0-482.1c-124.7 0-226 101.3-226 226s101.4 225.9 226 225.9c124.7 0 226-101.3 226-226S380.8 29.9 256.1 29.9z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Currencies
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div> */}

                      <div
                        id="ember744"
                        className="navigation-item ember-view orgs"
                        onClick={() => handleSettingOptionClick("taxes")}
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                          className="icon icon-sm align-text-bottom"
                        >
                          <path d="M147.9 358.4c-6.2-6.3-6.2-16.4 0-22.6l196.9-196.2c6.3-6.2 16.4-6.2 22.6 0 6.2 6.3 6.2 16.4 0 22.6L170.6 358.4c-6.3 6.2-16.4 6.2-22.7 0z"></path>
                          <circle cx="188.5" cy="179.5" r="39.5"></circle>
                          <circle cx="326.9" cy="318.4" r="39.5"></circle>
                          <path d="M254.6 511.6c-68.5 0-132.9-26.7-181.4-75.1-48.4-48.4-75-112.8-75-181.4S24.8 122.2 73.3 73.8C121.7 25.3 186.1-1.3 254.6-1.3c68.5 0 132.9 26.7 181.4 75.1s75.1 112.9 75.1 181.4-26.6 132.9-75.1 181.3-112.8 75.1-181.4 75.1zm0-480.9c-60 0-116.3 23.4-158.7 65.8-42.4 42.4-65.8 98.8-65.8 158.7s23.4 116.3 65.8 158.7c42.4 42.4 98.8 65.8 158.7 65.8s116.3-23.4 158.7-65.8c42.4-42.4 65.8-98.8 65.8-158.7 0-60-23.4-116.3-65.8-158.7C371 54 314.6 30.7 254.6 30.7z"></path>
                        </svg>
                        &nbsp;&nbsp;&nbsp;&nbsp;Taxes
                        <div className="text-overflow font-small text-muted"></div>
                      </div>

                      {/* <div id="ember745" className="navigation-item ember-view orgs">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon icon-sm align-text-bottom">
                                                <circle cx="132" cy="432" r="47.9"></circle>
                                                <circle cx="400" cy="432" r="47.9"></circle>
                                                <path
                                                    d="M505.5 294.5l-51.2-69-.1-.1c-10-13.3-26-21.3-42.7-21.3H336V73c0-22.5-17.9-40.8-40-40.8H40C18 32.1 0 50.4 0 73v330.3c0 22.5 17.9 40.8 40 40.8h24v-32H40c-4.4 0-8-4-8-8.8V73c0-4.9 3.6-8.8 8-8.8h256c4.4 0 8 4 8 8.8v330.4c0 4.9-3.6 8.8-8 8.8h-95.9v32H296c22.1 0 40-18.3 40-40.8V236.1h75.5c6.7 0 13.1 3.2 17.1 8.5l51.2 69 .1.1c.1.1.1.2.1.3v98.1h-12v32h18.3c14.1 0 25.6-11.5 25.6-25.6V314c.1-7-2.2-13.9-6.4-19.5z"
                                                ></path>
                                                <path
                                                    d="M244.1 220.1c0-49.9-30.3-84-74.8-84-45.4 0-77.3 35.7-77.3 79.9 0 48.9 34.3 80.2 79.8 80.2 23.2 0 46.7-7.3 61.5-21.2L216 249.2c-9.6 9.5-27.2 15.2-39.9 15.2-25.3 0-40.5-16.1-43-35.4h111v-8.9zm-111.6-18.3c1.9-15.2 12.4-33.8 36.8-33.8 26 0 35.9 19.3 36.8 33.8h-73.6z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;e-Way Bills
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>
                                        <br />
                                        <div id="ember746" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M423.1 79.1c-3.8-3.4-9-4.7-14-3.6l-34 7.4c-8.6 1.9-14.1 10.4-12.2 19.1 1.9 8.6 10.4 14.1 19.1 12.2l12.7-2.8-5.9 69.2v.1l-6.7 86-181.6 207L60.9 351.3l181.6-207 62.3-13.2c8.6-1.8 14.2-10.3 12.3-19-1.8-8.6-10.3-14.2-19-12.3L230.6 114c-3.4.7-6.4 2.5-8.7 5.1L26.3 342.2c-5.8 6.6-5.2 16.8 1.5 22.6l163.8 143.6c2.9 2.6 6.7 4 10.5 4h1c4.2-.3 8.2-2.2 11-5.4l195.6-223.1c2.3-2.6 3.7-5.9 3.9-9.3l7.1-91.3 7.7-90.8c.6-5.1-1.4-10-5.3-13.4z"
                                                ></path>
                                                <path
                                                    d="M455 6.5c-15.1-8.1-33.5-9-53.3-2.5-6.5 2.1-16.6 6.2-27.9 13.7-27.7 18.2-45.3 50.9-50.9 94.6-1.4 10.7-1.9 21-1.9 30-3.4 1.9-6.6 4.5-9.3 7.6-13.1 15-11.6 37.8 3.3 50.9s37.8 11.6 50.9-3.3 11.6-37.8-3.3-50.9c-2.9-2.6-6.2-4.6-9.6-6.1.1-7.4.5-15.6 1.6-24.1 4.4-34.6 16.8-58.8 36.7-71.9 7-4.6 31.3-19 48.4-9.7 11.7 6.3 18.8 22.1 17.8 39.3-.6 8.8 6.2 16.4 15 17 8.8.5 16.4-6.1 17-15C491.4 46 477.8 18.8 455 6.5z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Reporting Tags
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>
                                        <br />
                                        <div id="ember747" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M461.1 355.2c-23.3-21.7-60.1-21.5-82 .4-1.6 1.6-3.3 1.8-3.9 1.7-1.2 0-1.7-.6-1.9-.8l-.8-.9c-1.6-1.6-1.7-3.3-1.7-3.9 0-1.2.6-1.7.8-1.9l.5-.4 89.6-89.6c24.9-24.9 38.6-57.9 38.6-93.1s-13.7-68.2-38.6-93.1C413.1 25.1 348.4-1.6 279.3-1.6c-69 0-133.8 26.7-182.2 75.2-24.8 24.8-44 53.9-57 86.4-12.6 31.4-18.9 64.6-18.6 98.6.4 68.7 27.4 132.4 75.8 179.6 49.7 48.4 115 75.1 183.9 75.1 69.1 0 133.2-26.8 180.6-75.3l.3-.3c21.6-23.3 21.6-58.3 0-81.6l-1-.9zm-22.5 60.5c-41.2 42.3-97.2 65.5-157.5 65.5-60.6 0-117.9-23.4-161.6-66-42.3-41.2-65.7-96.9-66.1-156.9-.2-29.8 5.3-58.9 16.3-86.5 11.4-28.5 28.2-54 49.9-75.7 42.4-42.4 99.1-65.8 159.6-65.8s117.2 23.4 159.6 65.8c18.8 18.8 29.2 43.9 29.2 70.5s-10.4 51.7-29.2 70.5L350 325.9c-7 6.3-11.1 14.9-11.4 24.5-.4 10 3.5 20 10.6 27.4 6.3 7.1 15 11.1 24.5 11.5 10.2.4 20.4-3.6 27.8-11.1 9.6-9.6 26.5-9.5 37.2 0 10 11 9.9 26.6-.1 37.5z"
                                                ></path>
                                                <circle cx="385.5" cy="155.7" r="32.7"></circle>
                                                <circle cx="272" cy="103.2" r="32.7"></circle>
                                                <circle cx="167" cy="172.8" r="32.7"></circle>
                                                <circle cx="144.3" cy="299.8" r="32.7"></circle>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Templates
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>

                                        <div id="ember748" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path d="M160.3 96h159.9v32H160.3zM160.3 160h95.9v32h-95.9z"></path>
                                                <path
                                                    d="M448 128V.1H64.3V128l-64 64v319.8H512V191.9L448 128zm23.5 70.5l-23.5 18V175l23.5 23.5zM416.1 32.1v209L256.2 363.9 96.3 241.1v-209h319.8zM64.3 216.5l-23.5-18L64.3 175v41.5zm-32 263.3V235.1l223.9 171.7L480 235.1v244.7H32.3z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Emails
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>

                                        <div id="ember749" className="navigation-item ember-view orgs">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 477.71" className="icon icon-sm align-text-bottom">
                                                <g id="Layer_2" data-name="Layer 2">
                                                    <g id="Layer_1-2" data-name="Layer 1">
                                                        <path
                                                            d="M463.86 32H48.14A16.16 16.16 0 0032 48.14v291.43a16.16 16.16 0 0016.14 16.14h36.43a16 16 0 0115.68 12.78 16.28 16.28 0 01.32 3.22v60.37l112.2-73.63.17-.1.37-.22c.31-.19.62-.38.94-.55s.37-.18.55-.27.57-.27.87-.39.45-.17.68-.26l.78-.26c.27-.08.55-.15.83-.21s.45-.12.68-.16.65-.11 1-.15l.58-.08c.38 0 .75-.05 1.13-.06h242.51A16.16 16.16 0 00480 339.57V48.14A16.16 16.16 0 00463.86 32zM118 262.86c-24.53 0-42.15-8.24-54-20.31l14.75-20.69a56 56 0 0040.62 17.24c14.94 0 22.22-6.9 22.22-14.18 0-22.6-73.57-7.08-73.57-55.17 0-21.27 18.4-38.89 48.47-38.89 20.31 0 37.17 6.13 49.81 17.81l-15.13 19.93c-10.35-9.58-24.14-14-37.17-14-11.68 0-18.2 5.18-18.2 12.84 0 20.31 73.38 6.71 73.38 54.41 0 23.38-16.67 41.01-51.18 41.01zm210-.22h-27.69v-89.47l-36.4 89.47h-12l-36.4-89.47v89.47H188V134.86h38.53l31.37 77.2 31.37-77.2H328zm68.78.22c-24.52 0-42.15-8.24-54-20.31l14.75-20.69a56 56 0 0040.62 17.24c14.94 0 22.22-6.9 22.22-14.18 0-22.6-73.56-7.08-73.56-55.17 0-21.27 18.39-38.89 48.47-38.89 20.3 0 37.16 6.13 49.81 17.81l-15.17 19.93c-10.34-9.58-24.14-14-37.17-14-11.68 0-18.2 5.18-18.2 12.84 0 20.31 73.38 6.71 73.38 54.41 0 23.38-16.67 41.01-51.15 41.01z"
                                                            fill="none"
                                                        ></path>
                                                        <path
                                                            d="M463.86 0H48.14A48.2 48.2 0 000 48.14v291.43a48.19 48.19 0 0048.14 48.14h20.43v74a16 16 0 0024.78 13.38l133.15-87.38h237.36A48.19 48.19 0 00512 339.57V48.14A48.2 48.2 0 00463.86 0zM480 339.57a16.16 16.16 0 01-16.14 16.14H221.32c-.38 0-.75 0-1.13.06l-.58.08c-.32 0-.65.08-1 .15s-.46.1-.68.16-.56.13-.83.21l-.78.26c-.23.09-.46.16-.68.26s-.58.25-.87.39-.37.17-.55.27-.63.36-.94.55l-.37.22-.17.1-112.2 73.63v-60.34a16.28 16.28 0 00-.32-3.22 16 16 0 00-15.68-12.78h-36.4A16.16 16.16 0 0132 339.57V48.14A16.16 16.16 0 0148.14 32h415.72A16.16 16.16 0 01480 48.14z"
                                                        ></path>
                                                        <path
                                                            d="M95.8 167.45c0-7.66 6.52-12.84 18.2-12.84 13 0 26.82 4.41 37.17 14l15.13-19.93c-12.64-11.68-29.5-17.81-49.81-17.81C86.42 130.86 68 148.48 68 169.75c0 48.09 73.57 32.57 73.57 55.17 0 7.28-7.28 14.18-22.22 14.18a56 56 0 01-40.62-17.24L64 242.55c11.88 12.07 29.5 20.31 54 20.31 34.48 0 51.15-17.63 51.15-41 .03-47.7-73.35-34.1-73.35-54.41zm162.1 44.61l-31.37-77.2H188v127.78h27.5v-89.47l36.4 89.47h12.01l36.4-89.47v89.47H328V134.86h-38.73l-31.37 77.2zm116.65-44.61c0-7.66 6.52-12.84 18.2-12.84 13 0 26.83 4.41 37.17 14l15.14-19.93c-12.65-11.68-29.51-17.81-49.81-17.81-30.08 0-48.47 17.62-48.47 38.89 0 48.09 73.56 32.57 73.56 55.17 0 7.28-7.28 14.18-22.22 14.18a56 56 0 01-40.62-17.24l-14.75 20.69c11.88 12.07 29.51 20.31 54 20.31 34.48 0 51.15-17.63 51.15-41 .03-47.71-73.35-34.11-73.35-54.42z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;SMS Notifications
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>

                                        <div id="ember750" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M481.9 404.3l-4.9-4.9c-30-29.9-47.2-71.4-47.2-113.8v-74.4c0-76.4-49.6-143.7-121.7-166.5C303.9 19.4 281.8 0 255.2 0c-26.6 0-48.7 19.3-52.9 44.7-72.1 22.7-121.7 90-121.7 166.4v68.5c0 46.8-18.2 90.9-51.3 124l-.6.6c-5.7 5.7-7.4 14.3-4.3 21.8s10.3 12.3 18.4 12.3h125.9c8.2 41.3 44.4 72.4 86.7 72.4s78.6-31.1 86.7-72.4h125.7c8.1 0 15.4-4.8 18.5-12.3 3-7.5 1.3-16-4.4-21.7zm-188.5 58.8c-10.5 10.1-24.1 15.6-38.2 15.6-24.8 0-46.4-16.9-53.7-40.4H309c-3 9.4-8.3 17.9-15.6 24.8zm30.1-56.8H69.4c11.2-14.5 20.3-30.3 27.4-47.4 10.5-25.3 15.8-52 15.8-79.3v-68.5c0-64.8 43.6-121.5 106-137.8 8.8-2.3 15-10.4 15-19.8 0-11.8 9.7-21.5 21.7-21.5S277 41.6 277 53.6c0 9.3 6.2 17.4 15 19.7 62.4 16.5 106 73.2 106 137.9v74.4c0 43.8 15.3 86.8 42.5 120.7h-117z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Reminders
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>

                                        <div id="ember751" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M415.7 506.1h-.4c-3.5-.3-6.4-1.8-8.6-4.4-2.3-2.6-3.3-5.9-3.1-9.3v-.2l6.2-48.9c-44.9 38.9-102.3 60.3-161.8 60.3-9.7 0-19.5-.6-29.3-1.8C153.3 494 94.8 461.1 54 409.3 14.8 359.3-3.2 295.5 3.8 230c.1-1.3.3-2.6.4-3.9.3-3 1.3-5.6 3-7.9 3.4-5 8.9-7.8 14.8-7.8.7 0 1.5 0 2.2.1 4.8.6 9 3 12 6.7 2.9 3.7 4.3 8.3 3.8 13v.3c-.1 1.1-.3 2.2-.4 3.3-5.9 56.2 9.3 110.8 42.9 153.5 34.8 44.3 84.8 72.4 140.7 79 8.2 1 16.7 1.5 25 1.5 51.1 0 100.3-18.4 138.7-52.1 1.5-1.4 3.1-2.8 4.6-4.2l-60.2-1.5h-.3c-4.6-.3-8.7-2.3-11.3-5.3-1.8-2.1-2.7-4.8-2.5-7.3v-.2l.2-5.4.1-.4c.3-2.1 1.1-4.2 2.3-5.9 2.6-3.8 7-6.1 11.5-6.1h.6l95.6 2.7h.4l6.3.3c3.6.2 6.7 1.7 9 4.5 2.4 2.7 3.4 5.9 3.1 9.5l-.6 6.3-.1.4-11.6 93.3c-.2 2.2-.9 4.2-2.1 5.9-2.2 3.3-5.9 5.1-10.5 5.2h-.9l-4.8-1.4zm74.6-209.6c-.7 0-1.4 0-2.2-.1-9.7-1.2-16.8-10-15.8-19.7v-.3c.1-1 .2-2 .3-3.1 5.8-54.8-9.8-108.9-43.9-152.3C393.9 76.7 344 48.6 288 42c-8.8-1.1-17.7-1.6-26.4-1.6-20.5 0-40.9 3-60.4 8.7-28.3 8.4-53.8 22.5-76.1 41.9-4.9 4.3-9.6 8.8-14.2 13.6l61.1 1.5h.3c4.3.3 8.2 2.3 10.8 5.3 2.5 2.9 2.8 5.8 2.7 7.7v.3l.1 5-.1.6c-.3 2-1.1 4-2.2 5.7-2.6 3.8-7 6.1-11.5 6.1h-.6L76 134.1h-.3l-6.3-.3c-3.6-.2-6.7-1.7-9-4.4-2.4-2.7-3.4-5.9-3.1-9.6l.6-6.2.1-.4L69.6 20c.2-2.2 1-4.3 2.3-6.1 2.5-3.7 6.6-5.9 11-6.1h1l5 1.2h.4c2.8.2 5.3 1.6 7.2 3.9 2.5 2.9 3.8 7.1 3.5 11.2v.3l-6 47c2.6-2.5 5.3-4.9 7.9-7.2 26-22.7 55.9-39.3 89-49.2 23-6.8 46.9-10.3 70.9-10.3 10.1 0 20.5.6 30.7 1.9 65.4 7.8 123.9 40.7 164.7 92.5 39.9 50.8 58.1 114 51.4 178.2-.1 1.2-.3 2.5-.4 3.7-.4 2.9-1.4 5.6-3 7.9-3.4 4.7-8.9 7.6-14.9 7.6z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Automation
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>
                                        <br />
                                        <div id="ember752" className="navigation-item ember-view orgs">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M459.3 244.6l-66.2-66.2c-6.2-6.2-16.4-6.2-22.6 0-6.2 6.2-6.2 16.4 0 22.6l54.9 54.9-54.9 54.9c-6.2 6.2-6.2 16.4 0 22.6 3.1 3.1 7.2 4.7 11.3 4.7s8.2-1.6 11.3-4.7l66.2-66.2c6.3-6.2 6.3-16.3 0-22.6zM86.6 255.9l54.9-54.9c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0l-66.2 66.2c-6.2 6.2-6.2 16.4 0 22.6l66.2 66.2c3.1 3.1 7.2 4.7 11.3 4.7s8.2-1.6 11.3-4.7c6.2-6.2 6.2-16.4 0-22.6l-54.9-54.9zM296.5 80.7c-8.6-2-17.2 3.4-19.2 12l-73.7 319.4c-2 8.6 3.4 17.2 12 19.2 1.2.3 2.4.4 3.6.4 7.3 0 13.9-5 15.6-12.4l73.7-319.4c1.9-8.6-3.4-17.2-12-19.2z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Developer Space
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>
                                        <br />
                                        <div id="ember753" className="navigation-item ember-view orgs">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M511.9 142c0-10.1-3.9-19.5-11-26.6s-16.6-11-26.6-11H223.4c-20.7 0-37.6 16.9-37.6 37.6v133c0 20.7 16.9 37.6 37.6 37.6H474c20.7 0 37.8-16.8 37.8-37.5V142h.1zm-288.5-5.7h250.8c2 0 3.4 1 4 1.7.6.6 1.7 2 1.6 4v21h-262v-21c0-3.2 2.5-5.7 5.6-5.7zM474 280.5H223.4c-3.1 0-5.6-2.5-5.6-5.6v-80h262l-.1 80c0 3.1-2.6 5.6-5.7 5.6z"
                                                ></path>
                                                <path
                                                    d="M423.8 351.2c-8.8 0-16 7.2-16 16v5c0 6.5-5.3 11.7-11.9 11.7H54.4c-12.2 0-22.2-9.8-22.2-21.9V54.3c0-12.1 9.9-21.9 22.2-21.9h339.1c7.9 0 14.3 6.3 14.3 14.1 0 8.8 7.2 16 16 16s16-7.2 16-16c0-25.4-20.7-46-46.2-46H54.4C24.6.3.3 24.5.3 54.2v307.7c0 29.7 24.3 53.9 54.1 53.9h168.7v63.9h-54.6c-8.8 0-16 7.2-16 16s7.2 16 16 16h141c8.8 0 16-7.2 16-16s-7.2-16-16-16H255v-63.9h140.8c24.2 0 43.8-19.6 43.8-43.7v-5c.1-8.7-7-15.9-15.8-15.9z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Online Payments
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>

                                        <div id="ember754" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M496 160h-80V80c0-8.8-7.2-16-16-16H16C7.2 64 0 71.2 0 80v256c0 8.8 7.2 16 16 16h80v80c0 8.8 7.2 16 16 16h384c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16zM32 320V96h352v64H112c-8.8 0-16 7.2-16 16v144H32zm448 96H128V192h352v224z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Web Tabs
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>
                                        <br />
                                        <div id="ember755" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M66.9 439.2c18.4 20.6 53.7 20.7 94.6.2 33.6-16.7 61.8-12.5 68.6-3.1 6.4 8.9-6 28.2-21.1 43.2-6.5 6.5-6.5 17.1 0 23.6 3.1 3.3 7.4 5 11.7 5 4.2 0 8.6-1.7 11.8-4.9 15.5-15.5 25.6-30.6 30-44.9 4.7-15.4 2.8-30.1-5.4-41.5-9.6-13.4-26.8-21.2-48.3-22-19.6-.7-41.7 4.5-62.2 14.8-18.9 9.4-31.9 11.6-39.6 11.7-7.1.1-12.6-1.5-15.3-4.3-3.8-4.2-3.3-15.2 6.3-30 28.4-43.9 113.7-113.5 114.6-114.2.5-.4.9-.8 1.4-1.3 30.7 25.2 68.2 37.9 105.7 37.9 35.2 0 70.5-11.1 100.2-33.6 10-7.6 11-22.3 2.1-31.3l-31.5-31.4 62.8-62.8c2.5-2.5 3.8-5.7 3.8-9.3 0-3.4-1.4-6.8-3.8-9.3l-5.9-5.9c-2.5-2.5-5.7-3.8-9.3-3.8-3.4 0-6.8 1.4-9.3 3.8L366 188.6 272.5 95l62.8-62.8c2.5-2.5 3.8-5.7 3.8-9.3 0-3.5-1.4-6.8-3.8-9.3l-5.9-5.9c-2.5-2.5-5.7-3.8-9.3-3.8-3.4 0-6.8 1.4-9.3 3.8L248 70.6 217.4 40c-4-4-9.4-6.1-14.8-6.1-6.2 0-12.4 2.8-16.5 8.2-46.2 61.2-44.5 146.3 4.3 205.8-11.3 9.3-90.6 75-120.3 120.9-17.5 26.8-18.7 53.2-3.2 70.4zm158.3-202.5c-22.9-22.9-36.8-53.3-39-85.4-1.9-27.1 4.6-53.8 18.3-76.9l183 183C367 269.6 343.9 276 319.7 276c-35.6-.1-69.2-14-94.5-39.3z"
                                                ></path>
                                                <path d="M263.4 174.9c13.8 0 25.1 11.3 25.1 25.1s-11.3 25.1-25.1 25.1-25.1-11.3-25.1-25.1 11.3-25.1 25.1-25.1z"></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Integrations
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>
                                        <br />
                                        <div id="ember756" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M436.9 75.1C388.6 26.8 324.3.2 256 .2S123.4 26.8 75.1 75.1.2 187.7.2 256s26.6 132.6 74.9 180.9c48.3 48.3 112.6 74.9 180.9 74.9s132.6-26.6 180.9-74.9c48.3-48.3 74.9-112.6 74.9-180.9s-26.6-132.6-74.9-180.9zm-22.6 339.2c-42.3 42.3-98.5 65.6-158.3 65.6-59.8 0-116-23.3-158.3-65.6C55.4 372 32.2 315.8 32.2 256c0-59.8 23.3-116 65.6-158.3S196.2 32.2 256 32.2c59.8 0 116 23.3 158.3 65.6 42.3 42.3 65.6 98.5 65.6 158.3-.1 59.7-23.3 115.9-65.6 158.2z"
                                                ></path>
                                                <path d="M373.8 236.7c-8.8 0-16 7.2-16 16v78.6H154.2v-78.6c0-8.8-7.2-16-16-16s-16 7.2-16 16v94.6c0 8.8 7.2 16 16 16h235.6c8.8 0 16-7.2 16-16v-94.6c0-8.8-7.2-16-16-16z"></path>
                                                <path
                                                    d="M244.7 285.9c.4.4.8.7 1.2 1.1.2.1.4.3.5.4.2.2.5.4.7.5.2.1.4.3.7.4.2.1.4.3.7.4.2.1.5.2.7.3.2.1.5.2.7.3.2.1.5.2.7.3.3.1.5.2.8.3.2.1.5.1.7.2.3.1.5.1.8.2.3.1.6.1.8.1.2 0 .5.1.7.1.5.1 1.1.1 1.6.1s1.1 0 1.6-.1c.2 0 .5-.1.7-.1.3 0 .6-.1.8-.1.3-.1.5-.1.8-.2.2-.1.5-.1.7-.2.3-.1.5-.2.8-.3.2-.1.5-.2.7-.3.2-.1.5-.2.7-.3.2-.1.5-.2.7-.3.2-.1.5-.3.7-.4.2-.1.4-.3.7-.4.3-.2.5-.4.7-.5.2-.1.4-.3.5-.4.4-.3.8-.7 1.2-1.1l50.2-50.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L272 236v-90.5c0-8.8-7.2-16-16-16s-16 7.2-16 16V236l-22.9-22.9c-6.2-6.2-16.4-6.2-22.6 0-6.2 6.2-6.2 16.4 0 22.6l50.2 50.2z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Data Backup
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div>

                                        <div id="ember757" className="navigation-item ember-view orgs">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xmlSpace="preserve" className="icon icon-sm align-text-bottom">
                                                <path
                                                    d="M119.1 468.1c-37.8-24.3-68.5-58.5-88.8-99.1C9.9 328.4 1 282.5 4.6 236.4c3.6-46.2 19.5-90.1 46-127.1C73.7 76.9 105 50.1 141 31.7c35.8-18.3 75.7-28 115.3-28 9.5 0 19.2.6 28.6 1.7 46.5 5.5 90.9 24 128.3 53.4 34.3 27 61.4 63.9 78.2 106.9 17.4 44.4 21.3 93.2 11.3 141.2-9.9 47.6-32.7 91-66 125.4-3.2 3.3-6.6 6.6-10.1 9.8l-1.9 1.7 48.2-.4c8.5 0 15 6.6 15.1 15.3 0 8.8-6.5 15.6-15.1 15.6l-92 .8c-8.4 0-15.3-6.9-15.4-15.4l-.8-93.8c0-4.2 1.4-7.9 4.2-10.7 2.8-2.9 6.9-4.5 11.2-4.5 8.8 0 15.5 6.5 15.6 15.1l.5 61.7 1.6-1.3c30.9-25.9 55-61.1 67.9-99.2 13.5-39.7 15.3-82.4 5.3-123.4-9.7-39.7-30.3-76-59.6-105.2-30-29.9-68.5-50.4-111.3-59.4-14.2-3-28.9-4.5-43.5-4.5-67.9 0-133.6 32.2-176 86.1-25.5 32.5-41 72.2-44.9 114.7-3.8 42.2 4.1 84.1 22.8 121.1 17.8 35 44.7 64.8 77.8 86 35.7 22.9 77.4 35 120.6 35 7.1 0 14.2-.3 21.2-1 .4 0 .8-.1 1.2-.1 7.9 0 14.8 7.2 14.8 15.5 0 8.1-6.3 14.6-14.9 15.4-7.6.7-15.3 1.1-23 1.1-48.7.1-96.1-13.8-137.1-40.2zm176.4-164.4c0-32-128.1-32-128.1-96s64-64 64-64v-32h32v32s64 0 64 64h-32c0-64-96-64-96 0 0 32 128.1 32 128.1 96s-64 64-64 64v32h-32v-32s-64 0-64-64h32c0 64.1 96 64.1 96 0z"
                                                ></path>
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;&nbsp;Subscription
                                            <div className="text-overflow font-small text-muted"></div>
                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default HeaderComponent;
