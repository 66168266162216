import React, { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  getTaxTypeKeyValueList,
  getAllTaxSansGroup,
  addNewTax,
  taxDetails,
  updateTaxDetails,
  getAllTaxRecord,
  deleteTax,
} from "../../../services/Master/TaxServices";


const TaxList = () => {
  const [taxRecordList, setTaxRecordList] = useState([]);
  const [subTaxList, setSubTaxList] = useState([]);

  const [edit, setEdit] = useState(false);
  const [groupStyle, setGroupStyle] = useState(false);

  const [taxId, setTaxId] = useState("");
  const [taxName, setTaxName] = useState("");
  const [rate, setRate] = useState(0);
  const [taxType, setTaxType] = useState("");
  const [taxTypeOptions, setTaxTypeOptions] = useState([]);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setSubTaxList([]);
    setShow(false);
    setTaxName("");
    setRate("");
    setTaxType("");
    setGroupStyle(false);
    setEdit(false);
    setTaxId("");
  };


  useEffect(() => {
    GetAllTax();
    TaxKeyValue();
  }, []);

  const TaxKeyValue = () => {
    getTaxTypeKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        var option = res.responsePacket.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setTaxTypeOptions(option);
      } else {
        toast("👎" + res.message, {
          autoClose: 1000,
          closeOnClick: true,
        });
      }
    });
  };

  const GetAllTax = () => {
    var data = {
      page: 0,
      search: "",
      size: -1,
    };
    getAllTaxRecord(data).then((res) => {
      if (res.errorCode == 0) {
        var d = res.responsePacket.data;
        d.reverse();
        setTaxRecordList(d);
      } else {
        toast("👎" + res.message, {
          autoClose: 1000,
          closeOnClick: true,
        });
      }
    });
  };

  const getSubTax = (data) => {
    getAllTaxSansGroup().then((res) => {
      if (res.errorCode == 0) {
        let subList = res.responsePacket;
        if (data?.associatedTax != undefined) {
          console.log("true edit");
          var abc = subList.map((a) => {
            data.associatedTax.map((b) => {
              if (b.recordId == a.recordId) {
                a.ans = true;
              }
            });
            return a;
          });
          setSubTaxList(abc);
        } else {
          console.log("false edit");
          setSubTaxList(subList);
        }
      } else {
        toast("👎" + res.message, {
          autoClose: 1000,
          closeOnClick: true,
        });
      }
    });
  };

  useEffect(() => {
    console.log(subTaxList, "subTaxList");
  }, [subTaxList]);

  const handleEditTax = (data) => {
    setTaxId(data.recordId);
    setEdit(true);
    handleShow();

    if (data.isTaxGroup) {
      setGroupStyle(true);
    } else {
      setGroupStyle(false);
    }

    taxDetails(data.recordId).then((res) => {
      if (res.errorCode == 0) {
        var data = res.responsePacket;

        setTaxName(data.title);
        setRate(data.rate);
        setTaxType({
          label: data.taxType,
          value: 0,
        });
        getSubTax(data);
      } else {
        toast("👎" + res.message, {
          autoClose: 1000,
          closeOnClick: true,
        });
      }
    });
  };

  const columns = [
    {
      name: "TAX TYPE",
      selector: (data) => {
        return (
          <>
            {data.taxType}
            {data.isTaxGroup ? " (Group)" : ""}
          </>
        );
      },
    },
    {
      name: "TAX NAME",
      selector: (data) => data.title,
    },
    {
      name: "RATE(%)",
      selector: (data) => data.rate,
    },
    {
      name: "ACTIONS",
      selector: (data) => {
        return (
          <>
            {" "}
            <EditIcon
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => handleEditTax(data)}
            />{" "}
            &nbsp; &nbsp;
            <DeleteForeverIcon
              onClick={() => handleDeleteTax(data.recordId)}
              style={{ color: "tomato", fontSize: "20px", cursor: "pointer" }}
            />
          </>
        );
      }, 
    },
  ];

  const handleNewTax = (boolean) => {
    setGroupStyle(boolean);
    setEdit(false);
    setShow(true);
    getSubTax();
  };

  const handleDeleteTax = (taxId) => {
    Swal.fire({
      title: "Delete this record ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes ",
      cancelButtonText: " No",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTax(taxId).then((res) => {
          if (res.errorCode == 0) {
            GetAllTax();
            toast(res.message, {
              autoClose: 2000,
              pauseOnHover: true,
              closeOnClick: true,
            });
          } else {
            toast(res.message, {
              autoClose: 2000,
              pauseOnHover: true,
              closeOnClick: true,
            });
          }
        });
      }
    });
  };

  const handleSubTax = (e, i) => {
    let obj = [...subTaxList];
    obj[i].ans = e.target.checked;
    setSubTaxList(obj);
  };

  const handleSave = () => {
    let arr1 = subTaxList.filter((a) => a.ans);
    if (groupStyle && arr1.length < 1) {
      alert("Please Select atleast 1 associate tax");
      return;
    }

    if (taxName == "" || taxName == null) {
      alert("Please Select Tax Title");
      return;
    }
    if (rate == "" || rate == null) {
      alert("Please Select Tax rate");
      return;
    }
    if (taxType == "" || taxType == null) {
      alert("Please Select Tax type");
      return;
    }

    var data = {
      isTaxGroup: groupStyle,
      rate: rate,
      taxType: taxType.label,
      title: taxName,
    };

    if (groupStyle) {
      data.associatedTax = arr1;
    }

    if (taxId != "") {
      updateTaxDetails(taxId, data).then((res) => {
        if (res.errorCode == 0) {
          toast(res.message, { autoClose: 2000 });
          handleClose();
          GetAllTax();
        } else {
          toast(res.message, { autoClose: 2000 });
          return;
        }
      });
    } else {
      addNewTax(data).then((res) => {
        if (res.errorCode == 0) {
          toast(res.message, { autoClose: 2000 });
          handleClose();
          GetAllTax();
        } else {
          toast(res.message, { autoClose: 2000 });
          return;
        }
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>{edit ? "Edit Tax" : "New Tax"}</Modal.Header>

        <Modal.Body>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <table style={{ marginLeft: "10px" }}>
              <tr>
                <td className="required">Tax Name</td>
                <td>
                  <input
                    onChange={(e) => setTaxName(e.target.value)}
                    value={taxName}
                    type="text"
                    className="ember-text-field ember-view form-control"
                  />
                </td>
              </tr>

              <tr>
                <td className="required">Rate</td>
                <td>
                  {" "}
                  <input
                    onChange={(e) => setRate(e.target.value)}
                    value={rate}
                    className="ember-text-field ember-view form-control"
                    type="number"
                    min={0}
                  />
                </td>
              </tr>

              <tr>
                <td className="required">Tax Type</td>
                <td>
                  {" "}
                  <Select
                    value={taxType}
                    options={taxTypeOptions}
                    onChange={(e) => setTaxType(e)}
                  />
                </td>
              </tr>
            </table>

            <div>
              {groupStyle ? (
                <div>
                  <label className=" required">Associate Taxes</label>
                  <br />

                  <div style={{ overflowY: "scroll", maxHeight: "300px" }}>
                    <table
                      className="customTable"
                      style={{ marginRight: "25px" }}
                    >
                      {subTaxList &&
                        subTaxList.map((a, i) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={a.ans}
                                  onChange={(e) => handleSubTax(e, i)}
                                />
                              </td>
                              <td>{a.taxType}</td>
                              <td>{a.rate}</td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            {" "}
            <Button variant="primary" onClick={(e) => handleSave(e)}>
              Save
            </Button>
            <Button variant="secondary" onClick={(e) => handleClose(e)}>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <DataTable
        title="Tax List"
        columns={columns}
        data={taxRecordList}
        theme="light"
        // pagination
        fixedHeader
        subHeader
        subHeaderAlign="right"
        subHeaderComponent={
          <>
            <button
              className="btn btn-primary "
              onClick={() => handleNewTax(false)}
            >
              <AddIcon style={{ color: "white" }} />
               Tax
            </button>
            <button
              className="btn btn-primary "
              onClick={() => handleNewTax(true)}
            >
              <AddIcon style={{ color: "white" }} />
               Tax Group
            </button>
          </>
        }
      />
      <ToastContainer />
    </>
  );
};

export default TaxList;
