import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import AddIcon from "@mui/icons-material/Add";
import Address from "../SharedComponents/Address";
import PersonIcon from "@mui/icons-material/Person";

import {
  getVendorDetails,
  updateVendorDetailsByUUID,
  addVendor,
  getTdsKeyValuePair,
  addTDS,
  getPaymentTermKeyValuePair,
  getPurchasePriceKeyValuePair,
  getWarehouseKeyValueList,
} from "../../services/index";

const VendorNew = (props) => {
  let vendorId = props.match.params.id;
  //useParams()
  let history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ware, setWare] = useState("");
  const [wareOptions, setWareOptions] = useState("");

  const [tdsTitle, setTdsTitle] = useState("");
  const [tdsRate, setTdsRate] = useState("");

  const [gstNumber, setGstNumber] = useState("18AABCU9603R1ZM");

  const [tds, setTds] = useState([]);
  const [tdsOptions, setTdsOptions] = useState([]);

  const [priceListOptions, setPriceListOptions] = useState([]);
  const [priceList, setPriceList] = useState("");

  const [paymentTermsOptions, setPaymentTermsOptions] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState("");

  const [remarkData, setRemarkData] = useState("");

  const [requestData, setRequestData] = useState({
    recordId: null,
    fullName: "",
    vendorEmail: "",
    vendorPhone: "",
  });

  const [child1, setChild1] = useState({
    addressTypeEnum: "Billing",
    country: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const [child2, setChild2] = useState({
    addressTypeEnum: "Shipping",
    country: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const handleCopy = (d) => {
    d.addressTypeEnum = "Shipping";
    setChild2(d);
  };

  const handleChild1 = (e, key) => {
    if (e.value && e.label) {
      setChild1({
        ...child1,
        [key]: e,
      });
    } else if (e.target.value) {
      setChild1({
        ...child1,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChild2 = (e, key) => {
    if (e.value && e.label) {
      setChild2({
        ...child2,
        [key]: e,
      });
    } else if (e.target.value) {
      setChild2({
        ...child2,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    console.log(child1, "child1");
    console.log(child2, "child2");
  }, [child1, child2]);

  const handleToast = (res) => {
    toast(res.message, {
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  useEffect(() => {
    if (vendorId != null) {
      getVendorDetails(vendorId).then((res) => {
        if (res.errorCode == 0) {
          const vendorData = res.responsePacket;
          let d = res.responsePacket.addressList;

          let child1 = d.filter((a) => a.addressTypeEnum == "Billing");
          child1.map((a) => {
            a.country = { value: "c", label: a.country };
            a.state = { value: "b", label: a.state };
          });

          let child2 = d.filter((a) => a.addressTypeEnum == "Shipping");
          child2.map((a) => {
            a.country = { value: "c", label: a.country };
            a.state = { value: "b", label: a.state };
          });

          let a1 = child1[0];
          let a2 = child2[0];
          if (a1 != undefined) {
            setChild1(a1);
          }
          if (a2 != undefined) {
            setChild2(a2);
          }

          if (vendorData.warehouseVendorBeanList) {
            vendorData.warehouseVendorBeanList.map((a) => {
              a.value = a.warehouseId;
              a.label = a.warehouseTitle;
            });
            setWare(vendorData.warehouseVendorBeanList);
  
          }

          setRemarkData(vendorData?.remark);
          setRequestData({
            recordId: vendorData.recordId,
            fullName: vendorData.title,
            vendorEmail: vendorData.email,
            vendorPhone: vendorData.phone,
          });
          setGstNumber(vendorData.gstNumber);

          setPaymentTerms({
            value: vendorData.paymentTermRecordId,
            label: vendorData.paymentTermTitle,
          });

          setTds({
            value: vendorData.tdsRecordId,
            label: vendorData.tdsTitle,
          });

          setPriceList({
            value: vendorData.itemPriceListRecordId,
            label: vendorData.itemPriceListTitle,
          });
        } else {
          handleToast(res);
        }
      });
    }
  }, [props.detailsId]);

  useEffect(() => {
    getWarehouseKeyValueList().then((res) => {
      if (res.errorCode == 0) {
        const d = res.responsePacket;
        const options = d.map((a) => ({
          label: a.value,
          value: a.key,
        }));
        setWareOptions(options);
      } else {
        toast(res.message);
      }
    });

    getPurchasePriceKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setPriceListOptions(options);
      } else {
        handleToast(res);
      }
    });

    getTdsList();

    getPaymentTermKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        setPaymentTermsOptions(options);
      } else {
        handleToast(res);
      }
    });
  }, []);

  const getTdsList = () => {
    getTdsKeyValuePair().then((res) => {
      if (res.errorCode == 0) {
        const data = res.responsePacket;
        const options = data.map((d) => ({
          value: d.key,
          label: d.value,
        }));
        let button = {
          value: "-1",
          isDisabled: true,

          label: (
            <div style={{ cursor: "pointer" }} onClick={handleShow}>
              <AddIcon color="primary" /> Add TDS
            </div>
          ),
        };
        options.push(button);

        setTdsOptions(options);
      } else {
        handleToast(res);
      }
    });
  };

  const saveTds = () => {
    const request = {
      rate: tdsRate,
      title: tdsTitle,
    };

    addTDS(request).then((res) => {
      if (res.errorCode == 0) {
        setShow(false);
        getTdsList();
      } else {
        console.log("failure");
      }
    });
  };

  const handleSaveVendor1 = (e) => {
    e.preventDefault();
    let doNotSave = false;
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        requestData.vendorEmail
      )
    ) {
      alert("Please provide valid Mail without Spaces");
      return;
    }

    if (requestData.vendorPhone.toString().length !== 10) {
      if (requestData.vendorPhone.toString().length > 10) {
        alert("Mobile number cannot exceed the limit of 10");
        return;
      } else if (requestData.vendorPhone.toString().length < 10) {
        alert("Please provide valid Moble Number");
        return;
      }
    }

    if (requestData.fullName === null || requestData.fullName === "") {
      alert("please fill Company's Name");
      return;
    }

    if (vendorId == null) {
      if (requestData?.vendorPhone?.length != 10) {
        alert("please input 10 digit phone number");
        return;
      }
    }

    let ar = [];

    var deepCopy1 = JSON.parse(JSON.stringify(child1));
    var deepCopy2 = JSON.parse(JSON.stringify(child2));

    deepCopy1.state = child1?.state?.label;
    deepCopy1.country = child1?.country?.label;

    deepCopy2.state = child2?.state?.label;
    deepCopy2.country = child2?.country?.label;

    ar.push(deepCopy1);
    ar.push(deepCopy2);

    if(child1.country.label==undefined||child1.country.label==""){
      toast("Please select country")
      return
    }

    if(child1.country.label==undefined||child1.country.label==""){
      toast("Please select country")
      return
    }
    if(child1.state.label==undefined||child1.state.label==""){
      toast("Please select state")
      return
    }

    if(child1.zipcode==undefined||child1.zipcode==""){
      toast("Please select zipcode")
      return
    }
    if(child1.street1==undefined||child1.street1==""){
      toast("Please select street1")
      return
    }

    // for (let x in child1) {
    //   console.log(child1[x], "obj");
    //   if (child1[x] == "" || child1[x] == undefined) {
    //     toast(`Empty field Billing: ${[x]} `, {
    //       autoClose: 4000,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //     });
    //     doNotSave = true;
    //     return;
    //   }
    // }

    // for (let x in child2) {
    //   console.log(child2[x], "obj");
    //   if (child2[x] == "" || child2[x] == undefined) {
    //     toast(`Empty field Shipping: ${[x]} `, {
    //       autoClose: 4000,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //     });
    //     doNotSave = true;
    //     return;
    //   }
    // }

    console.log(ar, "ar");
  //  let  ware={
  //     ware.warehouseTitle = a.label;
  //     ware.warehouseId = a.value;
  //   }


    var request = {
      warehouseVendorBeanList: [{warehouseTitle:ware.label,warehouseId:ware.value}],
      addressList: ar,
      companyTitle: requestData?.fullName?.trim(),
      fullName: requestData.fullName?.trim(),
      title: requestData.fullName?.trim(),
      email: requestData.vendorEmail?.trim(),
      gstNumber: gstNumber,
      itemPriceListTitle: priceList.label,
      itemPriceListRecordId: priceList.value,
      paymentTermRecordId: paymentTerms.value,
      paymentTermTitle: paymentTerms.label,
      phone: requestData.vendorPhone,
      priceList: priceList.value,
      currencyRecordId: "dad7fa51-f498-42fb-a2d9-8efe52269ffc",
      currencyRecordTitle: "INR - Indian Rupee",
      tdsRecordId: tds.value,
      tdsTitle: tds.label,
      salutation: "Mr",
      remark: remarkData,
    };

    console.log(request, "request");

    if (!doNotSave) {
      if (vendorId != null) {
        updateVendorDetailsByUUID(vendorId, request).then((res) => {
          if (res.errorCode == 0) {
            handleToast(res);
            history.push("/inventory/vendors");
          } else {
            handleToast(res);
          }
        });
      } else {
        addVendor(request).then((res) => {
          if (res.errorCode == 0) {
            handleToast(res);
            history.push("/inventory/vendors");
          } else {
            handleToast(res);
          }
        });
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          Tds Info
          <br />
          <hr />
          <table>
            <tr>
              <td>TDS Title</td>
              <td>
                <input
                  type="text"
                  onChange={(e) => {
                    setTdsTitle(e.target.value);
                  }}
                  value={tdsTitle}
                />
              </td>
            </tr>
            <tr>
              <td>TDS Rate</td>
              <td>
                <input
                  type="number"
                  onChange={(e) => {
                    setTdsRate(e.target.value);
                  }}
                  value={tdsRate}
                />
              </td>
            </tr>
          </table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={saveTds}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="fill list-header d-flex justify-content-between align-items-center bg-white p-3 border-bottom">
        <h3 className="">
          <PersonIcon /> New Vendor
        </h3>
     
      </div>
      <div style={{ paddingBottom: "60px" }}>
        <div className="scroll-y noscroll-x fill body scrollbox">
          <div className="header">
            <div
              id="ember2534"
              className="ember-view pagetips-title float-right"
            >
              <div id="ember2536" className="ember-view">
                <div id="ember2537" className="ember-view" />
              </div>
            </div>
          </div>
          <div className="fill-container header-separator" />
          <form className="contact-create">
            <div
              tabIndex={-1}
              id="ember258"
              className="no-outline ember-view"
            ></div>
            <div className="row zb-txn-form">
              <div className="col-lg-8">
                <div className="row form-group">
                  <label className="col-form-label col-lg-3">
                    <span
                      id="ember2542"
                      className="tooltip-container  ember-view required"
                    >
                      Company's Name
                    </span>
                  </label>
                  <div className="col-lg-6">
                    <input
                      autoFocus
                      id="ember263"
                      className="form-control ember-text-field ember-view"
                      type="text"
                      value={requestData.fullName}
                      onChange={(e) =>
                        setRequestData({
                          ...requestData,
                          fullName: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <label className="col-form-label col-lg-3 required">
                    Vendor Email
                  </label>
                  <div className="col-lg-6">
                    <input
                      id="ember272"
                      className="form-control ember-text-field ember-view"
                      type="text"
                      value={requestData.vendorEmail}
                      onChange={(e) =>
                        setRequestData({
                          ...requestData,
                          vendorEmail: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <label className="col-form-label col-lg-3 required">
                    Vendor Phone
                  </label>
                  <div className="col-lg-6">
                    <input
                      placeholder="10 digit"
                      id="ember273"
                      className="form-control ember-text-field ember-view"
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      maxLength={10}
                      minLength={10}
                      value={requestData.vendorPhone}
                      onChange={(e) =>
                        setRequestData({
                          ...requestData,
                          vendorPhone: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <label className="col-form-label col-lg-3 required">
                    Warehouse
                  </label>
                  <div className="col-lg-6">
                    <Select
                    // isMulti
                      value={ware}
                      options={wareOptions}
                      onChange={(e) => setWare(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <Tabs>
              <TabList>
                <Tab>Other Details</Tab>
                <Tab>Address</Tab>
                <Tab>Remarks</Tab>
              </TabList>

              <TabPanel>
                <div className="row form-group">
                  <label className="col-form-label col-lg-3 ">GST No.</label>
                  <div className="col-lg-3">
                    <input
                      id="ember682"
                      placeholder="ex.18AABCU9603R1ZM"
                      maxLength="15"
                      className="ember-text-field ember-view form-control"
                      type="text"
                      value={gstNumber}
                      onChange={(e) => setGstNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <label className="col-form-label col-lg-3 ">TDS</label>
                  <div className="col-lg-3">
                    <Select
                      options={tdsOptions}
                      onChange={(e) => setTds(e)}
                      value={tds}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <label className="col-form-label col-lg-3 ">
                    Vendor PriceList
                  </label>
                  <div className="col-lg-3">
                    <Select
                      options={priceListOptions}
                      onChange={(e) => setPriceList(e)}
                      value={priceList}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <label className="col-form-label col-lg-3 ">
                    Payment Terms
                  </label>
                  <div className="col-lg-3">
                    <Select
                      options={paymentTermsOptions}
                      onChange={(e) => setPaymentTerms(e)}
                      value={paymentTerms}
                    />
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <Address
                  handleChild1={handleChild1}
                  child1={child1}
                  handleChild2={handleChild2}
                  child2={child2}
                  handleCopy={handleCopy}
                />
              </TabPanel>

              <TabPanel>
                <div className="col-lg-8">
                  <label className="col-form-label">
                    Remarks{" "}
                    <span className="text-muted">(For Internal Use)</span>
                  </label>{" "}
                  <textarea
                    rows={3}
                    id="ember719"
                    className="ember-text-area ember-view form-control"
                    value={remarkData}
                    onChange={(e) => setRemarkData(e.target.value)}
                  />
                </div>
              </TabPanel>
            </Tabs>

            <br />
            <hr style={{ size: "10px", color: "black" }} />

            <div className="row" style={{ margin: "20px" }}>
              <button
                className="btn btn-primary ember-view"
                onClick={handleSaveVendor1}
              >
                Save
              </button>
              &nbsp;
              <button
                className="btn btn-secondary"
                onClick={() => history.push("/inventory/vendors")}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default VendorNew;
