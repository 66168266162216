import { BASE_URL_INVENTORY } from "../CONSTANTS";
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData1");

const headersdata = {
  "content-type": "application/json",
  Accept: "application/json",
  Authorization: cook != undefined ? cook.accessToken : "",
};

export const logItemPriceListHistory = (priseListId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY +
            "logItemPriseListHistory/getItemPriseListHistory/" +
            priseListId,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const logVendorHistory = (vendorId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY + "logVendorHistory/getVendorHistory/" + vendorId,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  }); 
};

export const logSalesPaymentHistory = (paymentId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY +
            "logSalesPaymentHistory/getSalesPaymentHistory/" +
            paymentId,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const logSalesOrderHistory = (salesOrderNo) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY +
            "logSalesOrderHistory/getSalesOrderHistory/" +
            salesOrderNo,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const logRecipeHistory = (recipeId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY + "logRecipeHistory/getRecipeHistory/" + recipeId,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const logPurchasePaymentHistory = (paymentId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY +
            "logPurchasePaymentHistory/getPurchasePaymentHistory/" +
            paymentId,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const logPurchaseOrderHistory = (purchaseOrderNo) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY +
            "logPurchaseOrderHistory/getPurchaseOrderHistory/" +
            purchaseOrderNo,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const logItemHistory = (itemId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(BASE_URL_INVENTORY + "logItemHistory/getItemHistory/" + itemId, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const logIssuanceHistory = (issuanceCode) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY +
            "logIssuanceHistory/getIssuanceHistory/" +
            issuanceCode,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const logInvoiceHistory = (invoiceNo) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY +
            "logInvoiceHistory/getInvoiceHistory/" +
            invoiceNo,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const logCustomerHistory = (customerId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY +
            "logCustomerHistory/getCustomerHistory/" +
            customerId,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const logBillHistory = (billNo) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(BASE_URL_INVENTORY + "logBillHistory/getBillHistory/" + billNo, {
          headers: headersdata,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const logBatchesHistory = (batchesId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          BASE_URL_INVENTORY +
            "logBatchesHistory/getBatchesHistory/" +
            batchesId,
          {
            headers: headersdata,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logHistory axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
