import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Badge from "@mui/material/Badge";
import { Redirect, Link, useHistory } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import DataTable, {
  createTheme,
  FilterComponent,
} from "react-data-table-component";

import { getAllRecords, getAllBillRecords } from "../../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentIcon from "@mui/icons-material/Payment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const BillList = () => {
  //setting up variables
  let history = useHistory();
  const [billDetails, setBillDetails] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [data, setData] = useState([]);
  const [invisible, setInvisible] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [state, setState] = useState(false);
  const [dataId, setDataId] = useState(false);

  useEffect(() => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getAllBillRecords(list_data)
      .then((res) => {
        if (res.errorCode == 0) {
          const d = res.responsePacket.data;
          setBillDetails(res.responsePacket);

          setData(d);
          setFilteredData(d);
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("🙁" + err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  useEffect(() => {
    let list_data = {
      page: 0,
      size: -1,
      search: "",
    };
    getAllRecords(list_data)
      .then((res) => {
        if (res.errorCode == 0) {
          const d = res.responsePacket.data;

          // setData(d);
          // setFilteredData(d);
        } else {
          toast("🙁" + res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast("🙁" + err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  useEffect(() => {
    const result = data.filter((data) => {
      return (
        data.vendorTitle.toLowerCase().match(search.toLowerCase()) ||
        data.billNo.toLowerCase().match(search.toLowerCase()) ||
        data.billStatus.toLowerCase().match(search.toLowerCase()) ||
        data.billDate.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  let redirecting = state ? (
    <Redirect push to={`/inventory/bills/${dataId}`} />
  ) : (
    ""
  );

  const columns = [
    {
      name: "BILL No",
      selector: (data) => data.billNo,
      sortable: true,
    },

    {
      name: "PURCHASE No.",
      selector: (data) => data.purchaseOrderNo,
      sortable: true,
    },
    {
      name: "VENDOR NAME",
      selector: (data) => data.vendorTitle,
      sortable: true,
    },

    {
      name: "PAYMENT STATUS",
      selector: (data) =>
        data.isPaid ? (
          <Tooltip title="Paid" arrow placement="top">
            <PaymentIcon style={{ color: "greenyellow" }} />
          </Tooltip>
        ) : (
          <>
            <PaymentIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push({
                  pathname: "/inventory/PurchasePayment/" + data.recordId,
                  state: { id: data.recordId },
                })
              }
            />
          </>
        ),
    },

    {
      name: "AMOUNT",
      selector: (data) => data.amount.toFixed(2),
    },
    {
      name: "BALANCE DUE",
      selector: (data) => data.balanceDue.toFixed(2),
    },
  ];

  return (
    <>
      <div className="page-container">
        <div style={{ height: 500, width: "100%" }}>
          <DataTable
            title="All Bills"
            columns={columns}
            data={filteredData}
            defaultSortAsc={false}
            defaultSortFieldId={1}
            theme="light"
            pagination
            fixedHeader
            // pointerOnHover={true}
            highlightOnHover={true}
            onRowClicked={(data) => {
              setState(true);
              setDataId(data.recordId);
            }}
            subHeader
            subHeaderComponent={
              <>
                {" "}
                <SearchRoundedIcon />
                <input
                  className="ember-text-field ember-view form-control col-2"
                  type="text"
                  placeholder={"Search here"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ margin: "10px" }}
                />
                <button
                  id="ember5839"
                  className="tooltip-container ember-view btn btn-primary d-flex align-items-center"
                  onClick={() =>
                    history.push({
                      pathname: `/inventory/DirectBill`,
                      state: { directBill: true },
                    })
                  }
                >
                  <AddIcon />
                  New
                </button>
              </>
            }
            subHeaderAlign="right"
          />
          {redirecting}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default BillList;
