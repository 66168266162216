import { INVOICES_URL,PAYMENT_URL } from '../CONSTANTS'
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData1')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
    // 'Authorization': url.Auth_Token
}

export const addDirectInvoiceRecord1 = (data) => {
  return new Promise((resolve, reject) => {
      try {
          axios
              .post(INVOICES_URL() + 'addDirectInvoiceRecord', data, { headers: headersdata })
              .then((res) => {
                  resolve(res.data);
              })
              .catch((err) => {
                  reject("Error in addDirectInvoiceRecord axios!");
              });
      } catch (error) {
          reject(SYSTEM_ERROR);
      }
  });
};



export const getInvoiceDetailReport1 = (data) => {
  return new Promise((resolve, reject) => {
      try {
          axios
              .post(INVOICES_URL() + "getInvoiceDetail/", data, { headers: headersdata })
              .then((res) => {
                  resolve(res.data);
              })
              .catch((err) => {
                  reject("Error in getInvoiceDetail axios!");
              });
      } catch (error) {
          reject(SYSTEM_ERROR);
      }
  });
};

export const getInvoiceKeyList1 = () => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(INVOICES_URL() + "getKeyValueList/" , {
            headers: headersdata,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getKeyValueList axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };


export const updateRecordPayment = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PAYMENT_URL()  + "recordPayment/"+ id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateRecordPayment axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getIndividualPayment = (id) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(PAYMENT_URL()  + id, {
            headers: headersdata,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getIndividualPayment axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };



export const getAllRecordsPayment = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PAYMENT_URL() + "getAllRecords/", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getAllRecordsPayment axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

//-----------------------------------

export const getKeyValueListOfPackageByInvoiceId = (id) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(INVOICES_URL() + "getKeyValueListOfPackageByInvoiceId/" + id, {
            headers: headersdata,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getKeyValueListOfPackageByInvoiceId axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };


export const createShipping = (data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(INVOICES_URL()+"createShipping" ,data, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getItemList axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };

export const createPackage = (data) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(INVOICES_URL()+"createPackage" ,data, { headers: headersdata })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in getItemList axios!");
          });
      } catch (error) {
        reject(SYSTEM_ERROR);
      }
    });
  };

export const addInvoice = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(INVOICES_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addInvoice axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getInvoicesList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(INVOICES_URL() + 'getAllRecords', data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getAllRecords axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getInvoiceDetailsByUUID = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(INVOICES_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getAllRecords axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateInvoiceDetailsByUUID = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(INVOICES_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateInvoiceDetailsByUUID axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateInvoiceToWriteOff = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(INVOICES_URL() + "writeOff/" + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateInvoiceToWriteOff axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const cancelInvoiceToWriteOff = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(INVOICES_URL() + "cancelWriteOff/" + id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in cancelInvoiceToWriteOff axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
